import { feedbackApiClient } from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import feedbackSlice from './interface';

const slice = feedbackSlice
  .addAsyncSagas({
    getFeedback: invoke(feedbackApiClient.feedback.get),
  })
  .addReducers({
    'getFeedback/fulfilled': (state, action) => {
      const sortFeedbackByDate = action.payload.dossiers.sort(
        (a, b) =>
          new Date(b.reviewDate).getTime() - new Date(a.reviewDate).getTime()
      );
      state.feedback = { dossiers: sortFeedbackByDate };
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
