import React from 'react';
import { Radio, RadioGroupProps, Space } from 'antd';

export interface RadioOptions {
  label: string | React.ReactNode;
  value: string;
  disabled?: boolean;
}

export interface RadioProps extends Exclude<RadioGroupProps, 'options'> {
  options: RadioOptions[];
  direction?: 'vertical' | 'horizontal';
  label?: string;
  trailer?: React.ReactNode;
}

const RadioAntd: React.FC<RadioProps> = ({
  direction,
  options,
  trailer,
  ...props
}) => {
  return (
    <>
      <Radio.Group {...props}>
        <Space direction={direction}>
          {options.map(({ label, value, disabled }) => (
            <Radio key={value} value={value} disabled={disabled}>
              {label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      {trailer}
    </>
  );
};

export default RadioAntd;
