// inspired by redux-toolkit-saga, but it accepts a scheduler instead of SagaType
// and accepting effects from `typed-redux-saga`
// last updated from redux-toolkit-saga 1.2.0
// TODO: publish on npm.

/* eslint-disable redux-saga/no-unhandled-errors */

import {
  Action,
  ActionCreator,
  AnyAction,
  createAction,
} from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { ActionPattern, all, call } from 'redux-saga/effects';
import { __DEV__ } from '../constants';
import { resolveType } from '../helpers';

import type {
  CaseSaga,
  CreateOptionsSliceSaga,
  CreateOptionsSliceSagaAllCustom,
  CreateOptionsSliceSagaWithScheduler,
  CustomCaseSaga,
  SagaScheduler,
  SagaSlice,
  SagaSliceGenerator,
  SliceCaseSagas,
} from './interface';

export function createSaga<A extends Action = AnyAction>(
  scheduler: SagaScheduler<A>,
  type: ActionPattern<A>,
  sagaFunction: CaseSaga<A>
): () => Generator<unknown, void, any> {
  if (__DEV__ && typeof scheduler !== 'function') {
    throw new Error('scheduler is not a function');
  }
  return function* () {
    yield scheduler(type, sagaFunction);
  };
}

export function createSagas(
  sagas: (() => Generator<unknown, void, SagaIterator>)[]
): () => SagaSliceGenerator {
  const sagaTemp = sagas.map((saga: any) => call(saga));
  return function* () {
    yield all(sagaTemp);
  };
}

function isCustomCaseSaga(value: unknown): value is CustomCaseSaga {
  return (
    typeof value === 'object' &&
    typeof (value as CustomCaseSaga).saga === 'function'
  );
}

export function createSliceSaga<
  SCS extends SliceCaseSagas,
  Name extends string = string
>(
  options: CreateOptionsSliceSagaWithScheduler<SCS, Name>
): SagaSlice<SCS, Name>;
export function createSliceSaga<
  SCS extends { [K: string]: CustomCaseSaga },
  Name extends string = string
>(options: CreateOptionsSliceSagaAllCustom<SCS, Name>): SagaSlice<SCS, Name>;
export function createSliceSaga<
  SCS extends SliceCaseSagas,
  Name extends string = string
>(options: CreateOptionsSliceSaga<SCS, Name>): SagaSlice<SCS, Name> {
  const { caseSagas, name, scheduler: globalScheduler } = options;
  const caseSagasNames = Object.keys(caseSagas);
  const actionCreators: Record<string, ActionCreator<any>> = {};
  const caseSagasByName: Record<string, CaseSaga> = {};
  const caseSchedulerByName: Record<string, SagaScheduler> = {};
  const sagas = caseSagasNames.map((sagaName) => {
    const type = resolveType(name, sagaName);
    const currentCaseSaga = caseSagas[sagaName];

    actionCreators[sagaName] = createAction(type);

    let caseSaga: CaseSaga;
    let caseScheduler = globalScheduler;
    if (isCustomCaseSaga(currentCaseSaga)) {
      caseSaga = currentCaseSaga.saga;
      if (currentCaseSaga.scheduler) caseScheduler = currentCaseSaga.scheduler;
    } else {
      caseSaga = currentCaseSaga;
    }
    caseSagasByName[sagaName] = caseSaga;
    if (__DEV__ && !caseScheduler) throw new Error('Invalid sliceSaga config');
    caseSchedulerByName[sagaName] = caseScheduler!;
    return createSaga(caseScheduler!, type, caseSaga);
  });

  type R = SagaSlice<SCS, Name>;
  return {
    saga: createSagas(sagas),
    name,
    actions: actionCreators as R['actions'],
    caseSagas: caseSagasByName as R['caseSagas'],
    caseScheduler: caseSchedulerByName as R['caseScheduler'],
  };
}
