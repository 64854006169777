import { kebabCase } from 'lodash';
import { createLogger } from '@owl-lib/logger';
import type { InsightsConfig } from '../claims-assist-admin/interface';
import type { GetPresignedUploadResponse } from '../dossiers/interface';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  ClaimInfo,
  ClaimsDocument,
  ClaimsListItem,
  DossierEntry,
  ClaimsAssistInsight,
  ClaimsNote,
  TimelineEntry,
  SearchEntry,
  SearchNoteResultEntry,
  DocumentSearchMatch,
  ClaimSummary,
  ClaimActivity,
  ClaimDatapointsFetchResponse,
  ClaimNoteSummary,
  OdgDetails,
  ClaimDatapointsFetchParams,
  ClaimsDatapoint,
} from './interface';
import type {
  ClaimSegment,
  InsightSegment,
  SegmentDetails,
} from './nonmedical-interface';

export const DEMO_RESET_REQUEST_TIMEOUT = 100_000;
export const CLAIMS_ASSIST_GET_ENCOUNTERS_TIMEOUT = -1;

export const CLAIMS_ASSIST_SEARCH_TIMEOUT = 300_000; // 5 mins

const logger = createLogger(__filename);

const toKebabCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

const claimsAssistApiClient = {
  demo: {
    reset: async (): Promise<any> => {
      const url = new URL(`/api/v1/claims-assist/demo/data-reset`, baseURL);
      logger.trace('claimsAssistApiClient.demo.reset()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    export: async (): Promise<any> => {
      const url = new URL(`/api/v1/claims-assist/demo/data-export`, baseURL);
      logger.trace('claimsAssistApiClient.demo.export()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
  },
  claims: {
    list: async (data?: {
      searchTerm: string | undefined;
    }): Promise<ClaimsListItem[]> => {
      const url = new URL(`/api/v1/claims-assist/dossiers`, baseURL);
      if (data?.searchTerm) {
        url.searchParams.set('searchTerm', data.searchTerm);
      }
      logger.trace('claimsAssistApiClient.claims.list()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        DEMO_RESET_REQUEST_TIMEOUT
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    get: async (data: { dossierId: string }): Promise<ClaimInfo> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.claims.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    add: async (
      data: DossierEntry & { dossierType: ClaimInfo['dossierType'] }
    ): Promise<{ dossierId: string }> => {
      const url = new URL(`/api/v1/claims-assist/dossiers`, baseURL);
      logger.trace('claimsAssistApiClient.claims.add()');
      const { dossierType, ...dossierRecord } = data;
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            dossierType,
            dossierRecord,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);

      return deserialize(response);
    },
    update: async (data: {
      dossierId: string;
      dossierRecord: DossierEntry;
    }) => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.claims.update()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            dossierRecord: data.dossierRecord,
          }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);

      return deserialize(response);
    },
  },
  search: {
    fetchHistory: async (data: {
      dossierId: string;
    }): Promise<{ searchHistory: SearchEntry[] }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/search/history`,
        baseURL
      );

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });

      return deserialize(response);
    },
    documents: async (data: {
      dossierId: string;
      searchQuery: string;
    }): Promise<{
      answer: string;
      matches: DocumentSearchMatch[];
    }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/search`,
        baseURL
      );

      url.searchParams.set('searchQuery', data.searchQuery);

      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        CLAIMS_ASSIST_SEARCH_TIMEOUT
      );

      return deserialize(response);
    },
    notes: async (data: {
      dossierId: string;
      searchQuery: string;
    }): Promise<{ searchNoteResult: SearchNoteResultEntry[] }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/search/notes`,
        baseURL
      );

      url.searchParams.set('searchQuery', data.searchQuery);

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });

      return deserialize(response);
    },
    pocSearch: async (data: {
      dossierId: string;
      searchQuery: string;
    }): Promise<{
      answer: string;
      matches: DocumentSearchMatch[];
    }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/search/v2`,
        baseURL
      );

      url.searchParams.set('searchQuery', data.searchQuery);
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        CLAIMS_ASSIST_SEARCH_TIMEOUT
      );

      return deserialize(response);
    },
  },
  timeline: {
    get: async (data: {
      dossierId: string;
    }): Promise<{ events: TimelineEntry[] }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/timeline`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.timeline.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  documents: {
    list: async (data: { dossierId: string }): Promise<ClaimsDocument[]> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/documents`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.documents.list()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    create: async (data: {
      dossierId: string;
      docName: string;
      contentType: string;
      docType: string;
      docSubType: string;
      docDate: string;
      receivedDate: string;
      contentManualText?: string;
      contentRawRef: {
        contentRawBucket: string;
        contentRawKey: string;
      };
    }): Promise<void> => {
      const {
        dossierId,
        docName,
        contentType,
        docType,
        docSubType,
        docDate,
        contentRawRef,
        receivedDate,
        contentManualText,
      } = data;

      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/documents`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.documents.create()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            docName,
            contentType,
            docType,
            docSubType,
            docDate,
            contentRawRef,
            receivedDate,
            contentManualText,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    getPresignedUrl: async (data: {
      dossierId: string;
      docName: string;
      contentType: string;
      docType: string;
      docSubType: string;
      docDate: string;
      receivedDate: string;
    }): Promise<{
      url: string;
      contentType: string;
      metadata: {
        docName: string;
        docDate: string;
        docSubType: string;
        docType: string;
        receivedDate: string;
      };
      contentRawRef: {
        contentRawBucket: string;
        contentRawKey: string;
      };
    }> => {
      const {
        dossierId,
        docName,
        contentType,
        docType,
        docSubType,
        docDate,
        receivedDate,
      } = data;

      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/presigned-docs`,
        baseURL
      );

      url.searchParams.set('docName', docName);
      url.searchParams.set('contentType', contentType);
      url.searchParams.set('docType', docType);
      url.searchParams.set('docSubType', docSubType);
      url.searchParams.set('docDate', docDate);
      url.searchParams.set('receivedDate', receivedDate);

      logger.trace('claimsAssistApiClient.documents.getPresignedUrl()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    upload: async (data: GetPresignedUploadResponse) => {
      const { url: presignedUrl, contentType, file, metadata } = data;

      const metadataHeaders = Object.fromEntries(
        Object.entries(metadata).map(([k, v]) => [
          `x-amz-meta-${kebabCase(k)}`,
          v,
        ])
      );

      const url = new URL(presignedUrl);
      const paramKeys = Array.from(url.searchParams.keys()).map((k) =>
        k.toLowerCase()
      );
      if (
        !url.hostname.endsWith('.amazonaws.com') ||
        paramKeys.indexOf('x-amz-signature') === -1
      ) {
        logger.error(data, 'Not a valid Presigned S3 URL');
        throw new Error('Not a valid Presigned S3 URL');
      }

      logger.trace('documentSlice.uploadS3File()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          headers: { ...metadataHeaders, 'content-type': contentType },
          body: await file.arrayBuffer(),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    contentFetch: async (data: {
      dossierId: string;
      docId: string;
      docName: string;
      pageStart: number;
    }): Promise<{ url: string; numPages: number }> => {
      const { dossierId, docId, docName, pageStart } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/documents/${docId}/page-contents`,
        baseURL
      );

      url.searchParams.set('docName', docName);

      url.searchParams.set('pageStart', `${pageStart}`);

      logger.trace('claimsAssistApiClient.documents.contentFetch()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    contentFetchDeprecated: async (data: {
      dossierId: string;
      claimsDocument: ClaimsDocument;
    }): Promise<{ url: string }> => {
      const { dossierId, claimsDocument } = data;

      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/documents/${claimsDocument.docId}/contents`,
        baseURL
      );

      url.searchParams.set(
        'contentRawBucket',
        claimsDocument.contentRawRef.contentRawBucket
      );

      url.searchParams.set(
        'contentRawKey',
        claimsDocument.contentRawRef.contentRawKey
      );

      logger.trace('claimsAssistApiClient.documents.contentFetch()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getPresignedViewUrl: async (data: {
      tenantId: string;
      dossierId: string;
      claimsDocument: ClaimsDocument;
    }): Promise<{ url: string }> => {
      const { tenantId, dossierId, claimsDocument } = data;

      const url = new URL(
        `/api/v1/tenants/${tenantId}/claim-dossiers/dossiers/${dossierId}/documents/${claimsDocument.docId}/presigned-url`,
        baseURL
      );

      url.searchParams.set(
        'contentRawBucket',
        claimsDocument.contentRawRef.contentRawBucket
      );

      url.searchParams.set(
        'contentRawKey',
        claimsDocument.contentRawRef.contentRawKey
      );

      logger.trace('claimsAssistApiClient.documents.getPresignedViewUrl()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    delete: async (data: { docId: string; dossierId: string }) => {
      const { docId, dossierId } = data;

      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/documents/${docId}`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.documents.delete()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
    updateSummary: async (data: {
      dossierId: string;
      docId: string;
      content: string;
    }) => {
      const { dossierId, docId, content } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/documents/${docId}/summary`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.documents.updateSummary()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ content }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
  },
  notes: {
    get: async (data: {
      dossierId: string;
    }): Promise<{ notes: ClaimsNote[] }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/notes`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.notes.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    add: async (data: {
      dossierId: string;
      noteContent: string;
      documentIds?: string[];
      insightIds?: string[];
    }): Promise<{ noteId: string }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/notes`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.notes.add()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            noteContent: data.noteContent,
            documentIds: data.documentIds,
            insightIds: data.insightIds,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    update: async (data: {
      dossierId: string;
      noteId: string;
      noteContent: string;
    }) => {
      const { noteContent, dossierId, noteId } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/notes/${noteId}`,
        baseURL
      );

      logger.trace('claimsAssistApiClient.notes.update()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ noteContent }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    delete: async (data: { dossierId: string; noteId: string }) => {
      const { dossierId, noteId } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/notes/${noteId}`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.notes.delete()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
  },
  insights: {
    createFromSearch: async (data: {
      dossierId: string;
      title: string;
      query: string;
      answer: string;
      segments: {
        segmentId: string;
        startPage: number;
        endPage: number;
        docId: ClaimsDocument['docId'];
        docName: ClaimsDocument['docName'];
        contentRawRef: ClaimsDocument['contentRawRef'];
      }[];
    }): Promise<void> => {
      const { dossierId, title, query, answer, segments } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/insights`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.insights.createFromSearch()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            title,
            query,
            answer,
            segments,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);

      return deserialize(response);
    },
    list: async (data: {
      dossierId: string;
    }): Promise<{
      insights: ClaimsAssistInsight[];
    }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/insights`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.insights.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    feedback: async (data: {
      dossierId: string;
      insightId: string;
      decision: 'accepted' | 'rejected';
      details?: {
        reason: string;
        details?: string;
      };
    }): Promise<void> => {
      const { dossierId, insightId, decision, details } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/insights/${insightId}`,
        baseURL
      );

      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            decision,
            feedback: details?.reason,
            feedbackDetails: details?.details,
          }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);

      return deserialize(response);
    },
    update: async (data: {
      dossierId: string;
      insightId: string;
      answer: string;
      segments: InsightSegment[];
    }): Promise<void> => {
      const { dossierId, insightId, answer, segments } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/insights/${insightId}`,
        baseURL
      );

      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            answer,
            segments,
          }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);

      return deserialize(response);
    },
    delete: async (data: {
      dossierId: string;
      insightId: string;
    }): Promise<void> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/insights/${data.insightId}`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.insights.delete()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);

      return deserialize(response);
    },
  },
  reviews: {
    get: async (data: {
      dossierId: string;
    }): Promise<{
      documents: ClaimsDocument[];
      insights: ClaimsAssistInsight[];
    }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/reviews`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.reviews.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    deprecatedUpdate: async (data: {
      dossierId: string;
      insight?: {
        insightId: string;
        decision?: string;
        nextAction?: string;
        documents?: ClaimsDocument[];
        note?: string;
        details?: string;
        addToTimeline?: boolean;
      };
      document?: {
        docId: string;
        isReviewed?: boolean;
        nextAction?: string;
        note?: string;
      };
    }) => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/reviews`,
        baseURL
      );
      let bodyParams = {};
      logger.trace('claimsAssistApiClient.reviews.put()');
      if (data.insight) {
        const feedback = data.insight;
        bodyParams = {
          insight: {
            decision: feedback.decision,
            nextAction: feedback.nextAction,
            insightId: feedback.insightId,
            docIds: feedback.documents?.map((doc) => doc.docId),
            noteContent: feedback?.note,
            feedback: feedback?.details,
            addToTimeline: feedback?.addToTimeline,
          },
        };
      }
      if (data.document) {
        const feedback = data.document;
        bodyParams = {
          document: {
            isReviewed: feedback.isReviewed,
            nextAction: feedback.nextAction,
            docId: feedback.docId,
            noteContent: feedback?.note,
          },
        };
      }
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify(bodyParams),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);

      return deserialize(response);
    },
    update: async (data: {
      dossierId: string;
      reviewContent: {
        docId: string;
        docDate: string;
        docNotes?: { noteContent: string; noteId?: string }[];
        addToTimeline: boolean;
        insights?: {
          insightId: string;
          decision: string;
          feedback?: string;
        }[];
        activities?: {
          insightId?: string;
          activityId?: string;
          type: string;
          description: string;
          dueDate: string;
        }[];
      };
    }) => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/reviews`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.reviews.put()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify(data.reviewContent),
        },
        15_000
      );

      return deserialize(response);
    },
  },
  claimSummary: {
    get: async (data: {
      dossierId: string;
    }): Promise<{ dossierSummary: ClaimSummary }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/summary`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.claimSummary.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    add: async (data: {
      dossierId: string;
      content: string;
    }): Promise<{ dossierSummaryId: string }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/summary`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.claimSummary.add()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            content: data.content,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    update: async (data: {
      dossierId: string;
      dossierSummaryId: string;
      content: string;
    }) => {
      const { content, dossierId, dossierSummaryId } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/summary/${dossierSummaryId}`,
        baseURL
      );

      logger.trace('claimsAssistApiClient.dossierSummary.update()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ content }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
  },
  claimNoteSummaries: {
    get: async (data: { dossierId: string }): Promise<ClaimNoteSummary[]> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/note-summaries`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.claimNoteSummaries.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    setFeedback: async (data: {
      dossierId: string;
      noteSummaryId: string;
      feedbackReason: string;
      feedbackReasonDetail?: string;
    }): Promise<string> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/note-summaries/${data.noteSummaryId}/feedback`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.claimNoteSummaries.get()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          feedbackReason: data.feedbackReason,
          feedbackReasonDetail: data.feedbackReasonDetail ?? '',
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    update: async (data: {
      dossierId: string;
      noteSummaryId: string;
      content: string;
      matches: {
        docId: string;
        docName: string;
        page: number;
      }[];
    }) => {
      const { content, dossierId, noteSummaryId, matches } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/note-summaries/${noteSummaryId}`,
        baseURL
      );

      logger.trace('claimsAssistApiClient.claimNoteSummaries.update()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ content, matches }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    delete: async (data: { dossierId: string; noteSummaryId: string }) => {
      const { dossierId, noteSummaryId } = data;
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${dossierId}/note-summaries/${noteSummaryId}`,
        baseURL
      );

      logger.trace('claimsAssistApiClient.claimNoteSummaries.delete()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
  },
  insightsConfig: {
    get: async (): Promise<{ insightsConfigs: InsightsConfig[] }> => {
      const url = new URL(`/api/v1/claims-assist/insights-configs`, baseURL);
      logger.trace('claimsAssistApiClient.insightsConfigs.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  activities: {
    get: async (data: {
      dossierId: string;
    }): Promise<{
      activities: ClaimActivity[];
    }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/activities`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.activities.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
  },
  datapoints: {
    fetch: async (
      data: ClaimDatapointsFetchParams & { dossierId: string }
    ): Promise<ClaimDatapointsFetchResponse> => {
      let baseURLString = `/api/v1/claims-assist/dossiers/${
        data.dossierId
      }/datapoints?type=${toKebabCase(data.datapointKey)}`;

      const optionalQueryParams = {
        sortKey: data.sortKey,
        sortOrder: data.sortOrder,
        limit: data.limit,
        offset: data.offset,
      };

      const body = data.filters ? { filters: data.filters } : {};

      for (const [key, value] of Object.entries(optionalQueryParams)) {
        if (value) {
          baseURLString += `&${key}=${value}`;
        }
      }

      const url = new URL(baseURLString, baseURL);
      logger.trace('claimsAssistApiClient.datapoints.post()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify(body),
        },
        CLAIMS_ASSIST_GET_ENCOUNTERS_TIMEOUT
      );
      logger.debug({ response }, `POST ${url.href}`);

      return deserialize(response);
    },
    getByDocId: async (data: {
      dossierId: string;
      docId: string;
      // todo: swap `any` with `Datapoint[]`
    }): Promise<any> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/datapoints/documents/${data.docId}`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.datapoints.getByDocId');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
    setFeedback: async (data: {
      dossierId: string;
      datapointKey: string;
      datapointItem: ClaimsDatapoint;
      feedbackReason: string;
      feedbackReasonDetail?: string;
    }): Promise<{ datapointKey: string }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/datapoints/feedback`,
        baseURL
      );
      logger.trace('claimsAssistApiClient.datapoints.setFeedback()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          datapointKey: data.datapointKey,
          datapointItem: data.datapointItem,
          feedbackReason: data.feedbackReason,
          feedbackReasonDetail: data.feedbackReasonDetail ?? '',
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
  },
  segments: {
    get: async (data: {
      dossierId: string;
    }): Promise<{ segments: ClaimSegment[] }> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/segments`,
        baseURL
      );
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );

      return deserialize(response);
    },
    getSegment: async (data: {
      dossierId: string;
      segmentSubType: string;
      segmentId: string;
      clientDossierId: string;
    }): Promise<SegmentDetails> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/segments/${data.segmentId}`,
        baseURL
      );
      url.searchParams.set(
        'segmentSubType',
        data.segmentSubType.replace(/-/g, '_')
      );
      url.searchParams.set('clientDossierId', data.clientDossierId);
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );

      return deserialize(response);
    },
  },
  odg: {
    get: async (data: {
      dossierId: string;
      icdCodes: string[];
      claimData: {
        birthdate: string;
        occupationCode: string;
        disabilityDate: string;
      };
    }): Promise<OdgDetails> => {
      const url = new URL(
        `/api/v1/claims-assist/dossiers/${data.dossierId}/odg`,
        baseURL
      );
      const { birthdate, occupationCode, disabilityDate } = data.claimData;
      logger.trace('claimsAssistApiClient.dossiers.odg.get()');
      url.searchParams.set('icdCodes', data.icdCodes.join(','));
      if (birthdate !== '') {
        url.searchParams.set('birthdate', birthdate);
      }
      if (occupationCode !== '') {
        url.searchParams.set('occupationCode', occupationCode);
      }
      if (disabilityDate !== '') {
        url.searchParams.set('disabilityBeginDate', disabilityDate);
      }
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);

      return deserialize(response);
    },
  },
} as const;

export default claimsAssistApiClient;
