import type * as rtk from '@reduxjs/toolkit';
import type { IfIsAny, IfIsNever } from '@owl-lib/type-util';
import type { ExtraBuilder } from '../extra';
import { isAnyAsyncAction } from './actions';
// import type * as rtkq from '@reduxjs/toolkit-query';
import type {
  AnyAsyncAction,
  AsyncSagaConfig,
  GetRejectValue,
  GetSerializedErrorType,
} from './interface';

/** @see rtkq.QueryStatus */
export type Status = AsyncActionState['status'];
export interface AsyncActionStateAny<A = any> {
  status: Status;
  requestId?: string;
  /**
   * The payload of the request action.
   * @see {AsyncActionStateOptions['storeRequestArg']}
   */
  arg?: A;
  result?: any;
  error?: any;
}
export interface AsyncActionStateUninitialized extends AsyncActionStateAny {
  status: 'uninitialized';
  requestId?: never;
  arg?: never;
  result?: never;
  error?: never;
}
export interface AsyncActionStatePending<A = any>
  extends AsyncActionStateAny<A> {
  status: 'pending';
  requestId: string;
  result?: never;
  error?: never;
}
export interface AsyncActionStateFulfilled<T = any, A = any>
  extends AsyncActionStateAny<A> {
  status: 'fulfilled';
  requestId: string;
  result: T;
  error?: never;
}
export interface AsyncActionStateRejected<
  T = any,
  A = any,
  E = rtk.SerializedError
> extends AsyncActionStateAny<A> {
  status: 'rejected';
  requestId: string;
  result?: T;
  error: E;
}
export type AsyncActionState<
  T = any,
  A = never,
  // eslint-disable-next-line @typescript-eslint/ban-types
  SagaApiConfig extends AsyncSagaConfig = {}
> =
  | AsyncActionStateUninitialized
  | AsyncActionStatePending<A>
  | AsyncActionStateFulfilled<T, A>
  | AsyncActionStateRejected<
      GetRejectValue<SagaApiConfig, never>,
      A,
      GetSerializedErrorType<SagaApiConfig>
    >;
// eslint-disable-next-line @typescript-eslint/no-namespace, @typescript-eslint/no-redeclare
export declare namespace AsyncActionState {
  export type Any = AsyncActionStateAny;
  export type Uninitialized = AsyncActionStateUninitialized;
  export type Pending = AsyncActionStatePending;
  export type Fulfilled = AsyncActionStateFulfilled;
  export type Rejected = AsyncActionStateRejected;
}

export interface CaseReducerMutating<
  S = any,
  A extends rtk.Action = rtk.AnyAction
> extends rtk.CaseReducer<S, A> {
  (state: rtk.Draft<S>, action: A): void;
}

type StateWithAction<K extends string> = {
  actions: { [_ in K]?: AsyncActionState };
};
type AsyncActionStateReducer<K extends string> = CaseReducerMutating<
  StateWithAction<K>,
  AnyAsyncAction
>;
/**
 * Helper to update the state in `actions`.
 * @example
 * slice.addExtra(asyncActionStateMatchers(e): void => {
 *   e.addMatcher(
 *     isAnyAsyncAction(slice.actions, 'doAsyncOperation'),
 *     asyncActionStateReducer('doAsyncOperation')
 *   );
 * })
 */
export const asyncActionStateReducer = <K extends string>(
  actionStateKey: K
): AsyncActionStateReducer<K> =>
  ((state: StateWithAction<K>, action: AnyAsyncAction): void => {
    const { requestStatus: status, requestId } = action.meta;
    state.actions[actionStateKey as keyof typeof state.actions] =
      status === 'pending'
        ? { status, requestId }
        : status === 'fulfilled'
        ? { status, requestId, result: action.payload }
        : { status, requestId, error: action.error, result: action.payload };
  }) as AsyncActionStateReducer<K>;
export const asyncActionStateReducerWithArg = <K extends string>(
  actionStateKey: K
): AsyncActionStateReducer<K> =>
  ((state: StateWithAction<K>, action: AnyAsyncAction): void => {
    const { arg, requestStatus: status, requestId } = action.meta;
    state.actions[actionStateKey as keyof typeof state.actions] =
      status === 'pending'
        ? { arg, status, requestId }
        : status === 'fulfilled'
        ? { arg, status, requestId, result: action.payload }
        : {
            arg,
            status,
            requestId,
            error: action.error,
            result: action.payload,
          };
  }) as AsyncActionStateReducer<K>;

export interface AsyncActionStateOptions {
  /** Save the request argument as `arg`. Use only for repeatable actions. */
  storeRequestArg?: boolean;
}
/** Enforce `storeRequestArg: true` when `arg` is defined in the state */
type AsyncActionStateRequiredOptions<S extends StateWithAsyncActions<string>> =
  {
    // prettier-ignore
    [CK in keyof S['actions'] as S['actions'][CK] extends AsyncActionState<any, infer Arg, any>
      ? IfIsNever<Arg, never, IfIsAny<Arg, never, CK>>
      : never]: { storeRequestArg: true };
  };
export type StateWithAsyncActions<K extends string> = {
  actions: { [_ in K]?: AsyncActionStateAny };
};
export interface ActionStateMatchers<AK extends string, K extends string> {
  (builder: ExtraBuilder<StateWithAsyncActions<K>>): void;
  add<NK extends AK>(
    actionKey: NK,
    options?: AsyncActionStateOptions
  ): ActionStateMatchers<AK, K | NK>;
  add<NK extends string>(
    actionKey: AK,
    actionStateKey: NK,
    options?: AsyncActionStateOptions
  ): ActionStateMatchers<AK, K | NK>;
  all(options?: AsyncActionStateOptions): ActionStateMatchers<AK, K | AK>;
  config<S extends StateWithAsyncActions<K>>(
    options: AsyncActionStateRequiredOptions<S> & {
      [CK in keyof S['actions']]?: AsyncActionStateOptions;
    }
  ): ActionStateMatchers<AK, K>;
}

type OnlyStringKeys<T> = Pick<T, Extract<keyof T, string>>;
type OnlyAsyncInitActionKeys<AC> = OnlyStringKeys<{
  [K in keyof AC as K extends string
    ? AC extends {
        [_ in `${K}/pending` | `${K}/fulfilled` | `${K}/rejected`]: any;
      }
      ? K
      : never
    : never]: AC[K];
}>;

const getInitActionKeys = <AC extends { [k: string]: any }>(
  actionCreators: AC
): (keyof OnlyAsyncInitActionKeys<AC>)[] =>
  Object.keys(actionCreators).filter(
    (key) =>
      actionCreators[`${key}/pending`] &&
      actionCreators[`${key}/fulfilled`] &&
      actionCreators[`${key}/rejected`]
  ) as (keyof OnlyAsyncInitActionKeys<AC>)[];

/**
 * Helper to add lots of `asyncActionStateReducer`s.
 * @example
 * slice.addExtra(
 *   asyncActionStateMatchers(slice.actions)
 *     .add('myAsyncAction1')
 *     .add('myAsyncAction2', 'actionKeyForAsyncAction2')
 *     .add('myAsyncAction3')
 *     // ...
 * )
 */
export const asyncActionStateMatchers: <AC extends { [k: string]: any }>(
  actions: AC
) => ActionStateMatchers<keyof OnlyAsyncInitActionKeys<AC>, never> =
  function asyncActionStateBuilder(
    actions: Record<string, any>,
    actionKeys: [string, string, AsyncActionStateOptions?][] = []
  ) {
    const builderCallback = ((
      e: ExtraBuilder<{ actions: { [_ in string]?: AsyncActionState } }>
    ) => {
      for (const [actionKey, actionStateKey, options] of actionKeys) {
        const createReducer = options?.storeRequestArg
          ? asyncActionStateReducerWithArg
          : asyncActionStateReducer;
        e.addMatcher(
          isAnyAsyncAction(actions, actionKey),
          createReducer(actionStateKey)
        );
      }
    }) as ActionStateMatchers<string, string>;
    builderCallback.add = (
      actionKey: string,
      actionStateKey?: string | AsyncActionStateOptions,
      options?: AsyncActionStateOptions
    ) =>
      asyncActionStateBuilder(actions, [
        ...actionKeys,
        typeof actionStateKey === 'string'
          ? [actionKey, actionStateKey, options]
          : [actionKey, actionKey, options || actionStateKey],
      ]);
    builderCallback.all = () =>
      asyncActionStateBuilder(actions, [
        ...actionKeys,
        ...getInitActionKeys(actions).map((k): [string, string] => [k, k]),
      ]);
    builderCallback.config = (
      options: Partial<{ [actionStateKey: string]: AsyncActionStateOptions }>
    ) =>
      asyncActionStateBuilder(
        actions,
        actionKeys.map((a) =>
          options[a[1]]
            ? [a[0], a[1], a[2] ? { ...a[2], ...options[a[1]] } : options[a[1]]]
            : a
        )
      );
    return builderCallback;
  };
