import type { AssignmentDetails, Dossier } from '../dossiers/interface';

export type ApprovalModes = 'automatic' | 'manual' | 'errors' | 'duplicates';

export interface IngestionSettings {
  isEnabled: boolean;
  defaultConfig?: string;
  approval?: ApprovalModes[];
  isAutoIngest?: boolean;
  isTest?: boolean;
  topologyTarget?: string;
  notifyEmails?: string[];
}

export interface Tenant {
  tenantId: string;
  name: string;
  alias: string;
  createdAt: string;
  priority: number;
  executionQuantity: number;
  maxReviewLimit: number;
  recordsRemaining: number;
  enforceLimit: boolean;
  reviewRollover: boolean;
  reviewStartDate: number;
  ingestion?: IngestionSettings;
}
export type TenantUserFormValues = {
  name: string;
  email: string;
};

export interface Review {
  priority: number;
  recordId: string;
  peekedById: string;
  groupId: string;
  expires: number;
  insertedAt: string;
  data: { [k: string]: any };
  updatedAt?: string;
  assignExpires?: number;
  assignmentDetails?: AssignmentDetails;
}

export interface TenantUser {
  username: string;
  email: string;
  name: string | null;
  enabled: 'Enabled' | 'Disabled';
  status: string;
}

export type IngestionConfigKey = string;
export const INTO_LEGACY_CONFIG = {
  'names->0->firstName': 'firstName',
  'names->0->middleName': 'middleName',
  'names->0->lastName': 'lastName',
  'names->0->suffix': 'suffix',
  'addresses->0->street': 'street',
  'addresses->0->city': 'city',
  'addresses->0->regionCode': 'regionCode',
  'addresses->0->countryCode': 'countryCode',
  'addresses->0->postalCode': 'postalCode',
  'occupations->0->company': 'occupationCompany',
  'occupations->0->position': 'occupationPosition',
  'occupations->0->startDate': 'occupationStartDate',
  'occupations->0->endDate': 'occupationEndDate',
  'dossier->dateOfDisability': 'dateOfDisability',
  'dossier->diagnosisCategory': 'diagnosisCategory',
  'dossier->disabilityType': 'disabilityType',
  'dossier->creationDate': 'claimCreationDate',
  'dossier->closedDate': 'claimClosedDate',
};
export const FROM_LEGACY_CONFIG = Object.entries(INTO_LEGACY_CONFIG).reduce(
  (acc, [k, v]) => {
    acc[v] = k;
    return acc;
  },
  {}
);

interface IngestionConfigBase {
  configurationName: string;
  version?: number;
  dossierType?: Dossier['dossierType'];
  defaultCountryCode?: string;
  dateFormat: string;
  columnMappingConfig: Record<string, string[]>;
}

export interface IngestionConfig extends IngestionConfigBase {
  configurationId: string;
  tenantId: string;
  updatedAt: string;
  createdAt: string;
}

export type IngestionConfigFormValues = IngestionConfigBase & {
  configurationId?: IngestionConfig['configurationId'];
};

export interface BatchProcessLog {
  batchProcessLogId: string;
  tenantId: string;
  countTarget: number;
  countSuccess: number;
  countFail: number;
  status: 'running' | 'done';
  batchType: string;
  createdTimestamp: number;
  updatedTimestamp: number;
}
