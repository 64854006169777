import type { BatchReservations, ReservedItem } from '@owl-frontend/api-client';

const batchReservationsKey = (tkey: string) => `batchReservations/${tkey}`;

export function getBatchReservations(
  tkey: string
): BatchReservations | undefined {
  const json = localStorage.getItem(batchReservationsKey(tkey));
  if (json) {
    const data = JSON.parse(json);
    const now = Date.now();
    return {
      reservedItems: data?.reservedItems.filter((i) => i.expires > now),
    };
  }
}

export function setBatchReservations(
  tkey: string,
  data: { reservedItems: ReservedItem[] }
) {
  localStorage.setItem(batchReservationsKey(tkey), JSON.stringify(data));
}
