import type { EmptyObject } from 'redux';
import { ConfigurableSlice } from './slice';

export { default as mergeSlice } from './merge';
export { ConfigurableSlice } from './slice';

type CreateSliceConfigFor<State> = Record<never, never> extends State
  ? <N extends string>(name: N, state?: State) => ConfigurableSlice<State, N>
  : <N extends string>(name: N, state: State) => ConfigurableSlice<State, N>;

/**
 * Simple wrapper around `ConfigurableSlice` constructor, to ease typing usage.
 * @see ConfigurableSlice
 */
export function setupSlice<State>(): CreateSliceConfigFor<State>;
export function setupSlice<State, Name extends string>(
  name: Name,
  ...stateArg: EmptyObject extends State ? [State?] : [State]
): ConfigurableSlice<State, Name>;
export function setupSlice<State, Name extends string>(
  name?: Name,
  initialState?: State
): CreateSliceConfigFor<State> | ConfigurableSlice<State, Name> {
  if (!name) {
    type Fn = (...args: any) => any;
    return setupSlice as Fn as CreateSliceConfigFor<State>;
  }
  type ConfigurableSliceCtor = new (
    name: Name,
    initialState?: State
  ) => ConfigurableSlice<State, Name>;
  return new (ConfigurableSlice as ConfigurableSliceCtor)(name, initialState);
}
