import type {
  ClaimListItem,
  ClaimNote,
  ClaimsChunk,
  ClaimsDocumentDeprecated,
  DocumentSummary,
  Dossier,
  Insight,
  InsightsConfigEntry,
  MLConfig,
  QueryHistoryItem,
  QueryMatchResult,
  QueryResult,
} from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface ClaimsState {
  claims: ClaimListItem[];
  chunks: { [k: string]: { [k: string]: ClaimsChunk } };
  chunksList: { [k: string]: { [k: string]: ClaimsChunk[] } };
  documents: { [k: string]: ClaimsDocumentDeprecated[] };
  documentsToReview: {
    [dossierId: string]: {
      [docTitle: string]: boolean;
    };
  };
  queryHistory: { [k: string]: QueryHistoryItem[] };
  streamedAnswers: { [k: string]: string };
  claimDetails: {
    [dossierId: string]: Dossier;
  };
  queryResult?: QueryResult;
  config?: MLConfig;
  insightsConfig: {
    [dossierId: string]: InsightsConfigEntry[];
  };
  queryMatchResult?: QueryMatchResult;
  preSearchMatchResult?: QueryMatchResult;
  insights: {
    [k: string]: Insight[];
  };
  presignedUrl?: string;
  documentContent: { content: string; fileType: string };
  printContent?: { content: string; fileType: string };
  uploadResult: { successfulUploads: []; failedUploads: [] };
  summary?: {
    summary: string;
    matches: {
      docName: string;
      page: number;
      document: ClaimsDocumentDeprecated;
    }[];
  };
  notes: { [dossierId: string]: ClaimNote[] };
  documentSummaries: {
    [dossierId: string]: { [docId: string]: DocumentSummary };
  };
  searchRecommendations: {
    [dossierId: string]: string[];
  };
  actions: {
    claimFetch: AsyncActionState;
    claimsList: AsyncActionState<ClaimListItem[]>;
    querySearchFetch: AsyncActionState;
    listDocuments: AsyncActionState;
    setDocumentAsReviewed: AsyncActionState;
    markClaimAsReviewed: AsyncActionState;
    getConfig: AsyncActionState;
    saveConfig: AsyncActionState;
    documentFetch: AsyncActionState;
    uploadFiles: AsyncActionState;
    deleteDocument: AsyncActionState;
    queryHistoryFetch: AsyncActionState;
    querySearchMatch: AsyncActionState;
    preSearchMatch: AsyncActionState;
    getNextAnswerToken: AsyncActionState;
    getStreamedAnswer: AsyncActionState;
    saveInsight: AsyncActionState<Insight>;
    decideOnInsight: AsyncActionState<Insight>;
    deleteInsight: AsyncActionState;
    documentDownload: AsyncActionState;
    claimsInsightsFetch: AsyncActionState<{ insights: Insight[] }>;
    getDocumentContent: AsyncActionState;
    printContent: AsyncActionState;
    downloadAllDocuments: AsyncActionState;
    fetchChunk: AsyncActionState;
    listChunks: AsyncActionState;
    startListenForFinishedDocumentUpload: AsyncActionState;
    startListenForNewInsights: AsyncActionState;
    fetchClaimsInsightsConfig: AsyncActionState<InsightsConfigEntry[]>;
    saveClaimsInsightsConfig: AsyncActionState<InsightsConfigEntry[]>;
    fetchClaimSummary: AsyncActionState;
    getNotes: AsyncActionState;
    addNote: AsyncActionState;
    deleteNote: AsyncActionState;
    updateNote: AsyncActionState;
    getDocumentSummary: AsyncActionState;
    updateDocumentSummary: AsyncActionState;
    resetDemoData: AsyncActionState;
    triggerInvestigation: AsyncActionState;
    getSearchRecommendations: AsyncActionState;
  };
}

const initialState: ClaimsState = {
  claims: [],
  chunks: {},
  chunksList: {},
  claimDetails: {},
  documents: {},
  documentsToReview: {},
  queryHistory: {},
  streamedAnswers: {},
  insights: {},
  insightsConfig: {},
  documentContent: { content: '', fileType: '' },
  uploadResult: { successfulUploads: [], failedUploads: [] },
  notes: {},
  documentSummaries: {},
  searchRecommendations: {},
  actions: {
    claimFetch: { status: 'uninitialized' },
    claimsList: { status: 'uninitialized' },
    querySearchFetch: { status: 'uninitialized' },
    listDocuments: { status: 'uninitialized' },
    setDocumentAsReviewed: { status: 'uninitialized' },
    markClaimAsReviewed: { status: 'uninitialized' },
    getConfig: { status: 'uninitialized' },
    saveConfig: { status: 'uninitialized' },
    documentFetch: { status: 'uninitialized' },
    uploadFiles: { status: 'uninitialized' },
    deleteDocument: { status: 'uninitialized' },
    documentDownload: { status: 'uninitialized' },
    queryHistoryFetch: { status: 'uninitialized' },
    querySearchMatch: { status: 'uninitialized' },
    preSearchMatch: { status: 'uninitialized' },
    getNextAnswerToken: { status: 'uninitialized' },
    getStreamedAnswer: { status: 'uninitialized' },
    saveInsight: { status: 'uninitialized' },
    decideOnInsight: { status: 'uninitialized' },
    deleteInsight: { status: 'uninitialized' },
    claimsInsightsFetch: { status: 'uninitialized' },
    getDocumentContent: { status: 'uninitialized' },
    printContent: { status: 'uninitialized' },
    downloadAllDocuments: { status: 'uninitialized' },
    fetchChunk: { status: 'uninitialized' },
    listChunks: { status: 'uninitialized' },
    startListenForFinishedDocumentUpload: { status: 'uninitialized' },
    startListenForNewInsights: { status: 'uninitialized' },
    fetchClaimsInsightsConfig: { status: 'uninitialized' },
    saveClaimsInsightsConfig: { status: 'uninitialized' },
    fetchClaimSummary: { status: 'uninitialized' },
    getNotes: { status: 'uninitialized' },
    addNote: { status: 'uninitialized' },
    deleteNote: { status: 'uninitialized' },
    updateNote: { status: 'uninitialized' },
    getDocumentSummary: { status: 'uninitialized' },
    updateDocumentSummary: { status: 'uninitialized' },
    resetDemoData: { status: 'uninitialized' },
    triggerInvestigation: { status: 'uninitialized' },
    getSearchRecommendations: { status: 'uninitialized' },
  },
};

const claimsSlice = setupSlice('claims', initialState);
export default claimsSlice;
