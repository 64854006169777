import type { JSONSchema7, JSONSchema7Object } from 'json-schema';
import type {
  IngestionConfig,
  TopologyTarget,
} from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice, Status } from '@owl-frontend/redux';
import type {
  LongTermDisability,
  UiSchemaJson,
} from '@owl-lib/form-schema-config';

export interface ParsedData {
  [k: string]: string;
}

export interface UploadConfig {
  ingestionConfig: IngestionConfig;
  topology: TopologyTarget;
  isReinvestigation: boolean;
  priorityType: string;
  schema: JSONSchema7;
  uiSchema: UiSchemaJson;
}

export interface ValidatedRow {
  id: string;
  status: Status;
  messages?: string[];
  data?: JSONSchema7Object;
}

export interface IngestionState {
  filename?: string;
  parsedUploadFile?: string[][];
  unmappedColumnFields?: string[];
  config?: UploadConfig;
  parsedJson?: ParsedData[];
  normalizedData?: JSONSchema7Object[];
  validatedData?: ValidatedRow[];
  actions: {
    parseUploadFile: AsyncActionState<string[][]>;
    parseRowsToJson: AsyncActionState<{ [k: string]: string }>;
    normalizeAndMergeParsedJson: AsyncActionState<{
      unmappedFields: string[];
      normalizedData: LongTermDisability[];
    }>;
    validateData: AsyncActionState<ValidatedRow[]>;
  };
}

const initialState: IngestionState = {
  actions: {
    parseUploadFile: { status: 'uninitialized' },
    parseRowsToJson: { status: 'uninitialized' },
    normalizeAndMergeParsedJson: { status: 'uninitialized' },
    validateData: { status: 'uninitialized' },
  },
};

const ingestionSlice = setupSlice('ingestion', initialState);
export default ingestionSlice;
