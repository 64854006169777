import React from 'react';
import clsx from 'clsx';
import { Button as ButtonAntd, ButtonProps as ButtonAntdProps } from 'antd';
import type { CoreHTMLProps, Size } from '../../shared/interface';
import styles from './Button.module.scss';
export interface ButtonProps extends ButtonAntdProps {
  className?: string;
  label?: string;
}

export interface ButtonStyleProps extends Pick<CoreHTMLProps, 'className'> {
  /**
   * Is this the principal call to action on the page?
   */
  type?: ButtonProps['type'] | 'toggle';
  /**
   * What alertType to use
   */
  alertType?: 'success' | 'positive' | 'warning' | 'danger' | 'info';
  /** Use .delete instead of .button */
  delete?: boolean;
  /**
   * How large should the button be?
   */
  size?: Size;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { className, children, label, onClick, ...rest } = props;
  const onClickOverride = React.useCallback(
    (e) => {
      e.target.parentNode.blur();
      onClick?.(e);
    },
    [onClick]
  );
  return (
    <ButtonAntd
      {...rest}
      className={clsx(styles.override, className)}
      onClick={onClickOverride}
    >
      {label ?? children}
    </ButtonAntd>
  );
};

export default Button;
