// todo: change to expected value types (string, number, Date, DateRange)
export type Value = any | any[];
export type Operator = string | undefined;
export type FilterValue = { operator?: Operator; value?: Value } | undefined;

export type Filter<FieldName extends string = string> = {
  [Name in FieldName]?: FilterValue;
};

export interface SavedFilterItem {
  key: string;
  title: string;
  itemCount: number | null;
  filters?: Filter;
  searchTerm?: string;
  isTotal?: boolean;
}

export type SavedFilters = SavedFilterItem[];

interface State {
  searchTerm: string;
  searchTermKey: string;
  filters: Filter;
  activeFilterKey: string;
}

type Action =
  | {
      type: 'SET_SEARCH_TERM';
      searchTerm: string;
    }
  | {
      type: 'ADD_FILTER';
      field: string;
      value: FilterValue;
    }
  | {
      type: 'REMOVE_FILTER';
      field: string;
    }
  | {
      type: 'SET_ACTIVE_FILTER_KEY';
      activeFilterKey: string;
    }
  | {
      type: 'SET_FILTERS';
      filters: Filter;
      searchTerm?: string;
    }
  | { type: 'CLEAR_FILTER' }
  | { type?: never };

export const FILTER_KEY_TOTAL = 'FILTER_TOTAL';

const initialState = {
  searchTerm: '',
  searchTermKey: '',
  filters: {},
  activeFilterKey: 'FILTER_TOTAL',
};

export default filterReducer;
function filterReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.searchTerm,
        activeFilterKey: 'FILTER_TOTAL',
      };
    case 'ADD_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: action.value,
        },
        activeFilterKey: 'FILTER_TOTAL',
      };
    case 'REMOVE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: undefined,
        },
        activeFilterKey: 'FILTER_TOTAL',
      };
    case 'SET_ACTIVE_FILTER_KEY':
    case 'SET_FILTERS':
      return { ...state, ...action };
    case 'CLEAR_FILTER':
      return {
        ...state,
        searchTerm: '',
        filters: {},
        activeFilterKey: 'FILTER_TOTAL',
      };
    default:
      return state;
  }
}
