import { fulfillmentCapacityApiClient } from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import fulfillmentCapacitySlice from './interface';

const slice = fulfillmentCapacitySlice
  .addAsyncSagas({
    getSchedule: invoke(fulfillmentCapacityApiClient.schedule.get),
  })
  .addReducers({
    'getSchedule/fulfilled': (state, action) => {
      state.schedule = action.payload;
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
