import type {
  ClaimsDocument,
  ClaimsMLConfig,
  InsightsConfig,
} from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface ClaimsAdminState {
  insightsConfigs: {
    [tenantId: string]: InsightsConfig[];
  };
  errorDocuments: { [k: string]: ClaimsDocument[] };
  mlConfigs: {
    [tenantId: string]: ClaimsMLConfig;
  };
  actions: {
    fetchClaimsAdminInsightsConfig: AsyncActionState<InsightsConfig[]>;
    updateClaimsAdminInsightsConfig: AsyncActionState<any>; // todo: better type?
    createClaimsAdminInsightsConfig: AsyncActionState<{
      insightsConfigId: string;
    }>;
    deleteClaimsAdminInsightsConfig: AsyncActionState<any>; // todo: better type?
    getErrorDocuments: AsyncActionState;
    mlConfigFetch: AsyncActionState<ClaimsMLConfig>;
    updateMLConfig: AsyncActionState;
  };
}

const initialState: ClaimsAdminState = {
  insightsConfigs: {},
  errorDocuments: {},
  mlConfigs: {},
  actions: {
    fetchClaimsAdminInsightsConfig: { status: 'uninitialized' },
    updateClaimsAdminInsightsConfig: { status: 'uninitialized' },
    createClaimsAdminInsightsConfig: { status: 'uninitialized' },
    deleteClaimsAdminInsightsConfig: { status: 'uninitialized' },
    getErrorDocuments: { status: 'uninitialized' },
    mlConfigFetch: { status: 'uninitialized' },
    updateMLConfig: { status: 'uninitialized' },
  },
};

const claimsAdminSlice = setupSlice('claims-admin', initialState);
export default claimsAdminSlice;
