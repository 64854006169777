import { falconSettingsApiClient } from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import settingsSlice from './interface';

const slice = settingsSlice
  .addAsyncSagas({
    fetch: invoke(falconSettingsApiClient.fetch),
  })
  .addReducers({
    'fetch/fulfilled': (state, action) => {
      state.findings = {
        ...state.findings,
        ...action.payload.findings,
      };
      state.notes = {
        ...state.notes,
        ...action.payload.notes,
      };
      state.topologies = action.payload.topologies;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
