import type { User } from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface WorkersState {
  users: User[];
  actions: {
    listUsers: AsyncActionState;
    updateUser: AsyncActionState;
  };
}

const initialState: WorkersState = {
  users: [],
  actions: {
    listUsers: { status: 'uninitialized' },
    updateUser: { status: 'uninitialized' },
  },
};

const usersSlice = setupSlice('users', initialState);
export default usersSlice;
