/**
 * To eliminate dead code, ensure that
 * `new webpack.DefinePlugin(require('./globals'))` or
 * `new webpack.DefinePlugin({ __isProd: env.isProd })` is in the webpack config.
 */
export const __isProd =
  typeof process === 'undefined' ? true : process.env.NODE_ENV === 'production';

export const DEFAULT_DATE_FORMAT = 'MMM DD, YYYY';
/** Date format from mockups */
export const PRETTY_DATE_FORMAT = 'MMM D, YYYY';
export const PRETTY_DATE_TIME_FORMAT = 'MMM D, YYYY hh:mm A';
/** ISO_8601_DATE_FMT */
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT = 'YYYY-MM-DD hh:mm A';
