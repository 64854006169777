import { isAnyOf, ActionMatchingAnyOf } from '@reduxjs/toolkit';
import type { Get } from '@owl-lib/type-util';

interface HasMatchFunction<T> {
  match(v: any): v is T;
}

// prettier-ignore
export type AsyncActionsOf<A, K extends keyof A> = {
  pending:
    Extract<Get<A, `${Extract<K, string>}/pending`>, HasMatchFunction<any>>,
  fulfilled:
    Extract<Get<A, `${Extract<K, string>}/fulfilled`>, HasMatchFunction<any>>,
  rejected:
    Extract<Get<A, `${Extract<K, string>}/rejected`>, HasMatchFunction<any>>
}
/**
 * @example
 * type A = { a: 1, b: 2 };
 * // $ExpectType [1, 2]
 * type B = GetPropsToTuple<A, ['a', 'b']>
 */
export type GetPropsToTuple<T, K extends readonly (keyof T)[]> = {
  [I in keyof K]: T[Extract<K[I], keyof T>];
};

type AsyncActionMatcher<A, K extends keyof A> = (
  action: any
) => action is ActionMatchingAnyOf<
  GetPropsToTuple<AsyncActionsOf<A, K>, ['pending', 'fulfilled', 'rejected']>
>;
export const getAsyncActions = <A, K extends keyof A>(
  actions: A,
  actionKey: K
): AsyncActionsOf<A, K> => ({
  pending: actions[`${String(actionKey)}/pending`],
  fulfilled: actions[`${String(actionKey)}/fulfilled`],
  rejected: actions[`${String(actionKey)}/rejected`],
});
export const isAnyAsyncAction: {
  <A, K extends keyof A>(actions: A, actionKey: K): AsyncActionMatcher<A, K>;
  <A, K extends keyof A>(
    actions: A,
    ...actionKeys: [K, ...K[]]
  ): AsyncActionMatcher<A, K>;
} = <A, K extends keyof A>(actions: A, ...actionKeys: [K, ...K[]]) =>
  isAnyOf(
    ...(actionKeys.flatMap<HasMatchFunction<any>>((actionKey) =>
      Object.values(getAsyncActions(actions, actionKey))
    ) as [HasMatchFunction<any>, ...HasMatchFunction<any>[]])
  ) as AsyncActionMatcher<A, K>;
