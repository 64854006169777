import React from 'react';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  Badge,
  Dropdown,
  Layout,
  Menu,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { InsightsConfig } from '@owl-frontend/api-client/src/interface';
import {
  Dialog,
  Form,
  Option,
  Tooltip,
  toast,
  useEffectWithPrev,
} from '@owl-frontend/components';
import { Locale } from '../../../../../../context/AppContainer/AppContainer';
import ClaimsAdminHeader from '../../../ClaimsAdminHeaderComponent/ClaimsAdminHeaderComponent';
import ClaimsAdminInsightsConfigDialogComponent from '../ClaimsAdminInsightsConfigDialogComponent/ClaimsAdminInsightsConfigDialogComponent';
import { useClaimsAdminInsightsConfigs } from '../ClaimsAdminInsightsConfigHooks';
import { promptsImmediateActionNextActionValues, docSubTypes } from './helper';
import styles from './ClaimsAdminInsightsConfigDetailsContainer.module.scss';

interface Props {
  tenantId: string;
}

enum PRIORITY {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

const priorityOrder = {
  [PRIORITY.HIGH]: 3,
  [PRIORITY.MEDIUM]: 2,
  [PRIORITY.LOW]: 1,
};

export const priorityBadge = (priority) => {
  if (priority === PRIORITY.HIGH) {
    return 'red';
  } else if (priority === PRIORITY.MEDIUM) {
    return 'orange';
  } else {
    return 'yellow';
  }
};

const priorityRadioOptions = [
  {
    label: 'Low',
    value: 'low',
    disabled: false,
  },
  {
    label: 'Medium',
    value: 'medium',
    disabled: false,
  },
  {
    label: 'High',
    value: 'high',
    disabled: false,
  },
];

const configInitVals: InsightsConfig = {
  id: '',
  actions: [],
  docFilters: [],
  explanation: '',
  name: '',
  priority: 'low',
  query: '',
  review: 'manual',
};

interface FormProps {
  onClose(): void;
  visible?: boolean;
  actionOptions: Option[];
  docFilterOptions: Option[];
  isNewInsight: boolean;
}

const InsightsConfigForm: Form.MFC<
  Omit<FormProps, 'onSave'>,
  InsightsConfig
> = ({
  handleSubmit,
  onClose,
  visible,
  isNewInsight,
  actionOptions,
  docFilterOptions,
  form: rffForm,
}) => {
  const { messages } = React.useContext(Locale);

  React.useEffect(() => {
    if (!visible) {
      rffForm.restart();
    }
  }, [visible, rffForm]);

  return (
    <Dialog
      title={
        isNewInsight
          ? messages[
              'claimsAdmin.insightsConfig.createInsightsConfig.dialog.title'
            ]
          : messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.title'
            ]
      }
      size="medium"
      open={visible}
      onClose={onClose}
      className={styles.dialogContainer}
      destroyOnClose
      footer={{
        onConfirmOpts: {
          label: messages['shared.save'],
          onClick: handleSubmit,
        },
        onCancelOpts: {
          label: messages['shared.cancel'],
          onClick: onClose,
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Form.TextAreaManaged
          name="name"
          label={
            messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.details.name'
            ]
          }
          rows={1}
          validate={Form.Validators.required()}
        />
        <Form.RadioManaged
          name="priority"
          label={
            messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.details.priority'
            ]
          }
          size="small"
          optionType="button"
          buttonStyle="solid"
          options={priorityRadioOptions}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="query"
          label={
            messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.details.question'
            ]
          }
          rows={3}
          validate={Form.Validators.required()}
        />
        <Form.TextAreaManaged
          name="explanation"
          label={
            messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.details.explanation'
            ]
          }
          rows={3}
          validate={Form.Validators.required()}
        />
        <Form.MultiSelectAntdManaged
          name="actions"
          label={
            messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.details.action'
            ]
          }
          placeholder="Please Select"
          menuTitle="Select One or More"
          options={actionOptions}
          validate={Form.Validators.required()}
        />
        <Form.MultiSelectAntdManaged
          name="docFilters"
          label={
            messages[
              'claimsAdmin.insightsConfig.saveInsightsConfig.dialog.details.documentFilters'
            ]
          }
          placeholder="Please Select"
          menuTitle="Select One or More"
          options={docFilterOptions}
          validate={Form.Validators.required()}
        />
      </form>
    </Dialog>
  );
};

const InsightsConfigFormManaged = Form.manage(InsightsConfigForm);

const ClaimsAdminInsightsConfigDetailsContainer: React.FC<Props> = ({
  tenantId,
}) => {
  const { messages } = React.useContext(Locale);
  const [searchVal, setSearchVal] = React.useState<string>('');
  const [
    { insightsConfigs, updateStatus, createStatus, deleteStatus },
    claimsAdminInsightConfigHandler,
  ] = useClaimsAdminInsightsConfigs(tenantId);
  const [configDialog, setConfigDialog] = React.useState<boolean>(false);
  const [isNewInsight, setIsNewInsight] = React.useState<boolean>(false);
  const [editConfigDialog, setEditConfigDialog] =
    React.useState<boolean>(false);
  const [configDetails, setConfigDetails] =
    React.useState<InsightsConfig>(configInitVals);

  const actionOptions: Option[] = React.useMemo(
    () =>
      promptsImmediateActionNextActionValues.map((i) => ({
        label: messages[`claims.matchInsights.promptsImmediateActionOpts.${i}`],
        value: i,
      })),
    [messages]
  );

  const docFilterOptions: Option[] = React.useMemo(
    () =>
      docSubTypes.map((i) => ({
        label: messages[`claims.documents.uploadDialog.docSubType.${i}`],
        value: i,
      })),
    [messages]
  );

  React.useEffect(() => {
    if (!tenantId) {
      return;
    }

    claimsAdminInsightConfigHandler.getConfigs({ tenantId });
  }, [tenantId, claimsAdminInsightConfigHandler]);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !tenantId) {
        return;
      }

      if (updateStatus === 'fulfilled') {
        toast({
          key: 'SAVE_CLAIMS_CONFIGS_SUCCESS',
          type: 'success',
          message:
            messages['claimsAdmin.insightsConfig.saveInsightsConfig.success'],
        });
        if (tenantId) {
          claimsAdminInsightConfigHandler.getConfigs({ tenantId });
        }
      }

      if (updateStatus === 'rejected') {
        toast({
          key: 'SAVE_CLAIMS_CONFIGS_FAIL',
          type: 'error',
          message:
            messages['claimsAdmin.insightsConfig.saveInsightsConfig.error'],
        });
      }
    },
    [updateStatus, tenantId, claimsAdminInsightConfigHandler, messages]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !tenantId) {
        return;
      }

      if (createStatus === 'fulfilled') {
        toast({
          key: 'CREATE_CLAIMS_CONFIGS_SUCCESS',
          type: 'success',
          message:
            messages['claimsAdmin.insightsConfig.createInsightsConfig.success'],
        });
        if (tenantId) {
          claimsAdminInsightConfigHandler.getConfigs({ tenantId });
        }
      }

      if (createStatus === 'rejected') {
        toast({
          key: 'CREATE_CLAIMS_CONFIGS_FAIL',
          type: 'error',
          message:
            messages['claimsAdmin.insightsConfig.createInsightsConfig.error'],
        });
      }
    },
    [createStatus, tenantId, claimsAdminInsightConfigHandler, messages]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !tenantId) {
        return;
      }

      if (deleteStatus === 'fulfilled') {
        toast({
          key: 'DELETE_CLAIMS_CONFIGS_SUCCESS',
          type: 'success',
          message:
            messages['claimsAdmin.insightsConfig.deleteInsightsConfig.success'],
        });
        if (tenantId) {
          claimsAdminInsightConfigHandler.getConfigs({ tenantId });
        }
      }

      if (deleteStatus === 'rejected') {
        toast({
          key: 'DELETE_CLAIMS_CONFIGS_FAIL',
          type: 'error',
          message:
            messages['claimsAdmin.insightsConfig.deleteInsightsConfig.error'],
        });
      }
    },
    [deleteStatus, tenantId, claimsAdminInsightConfigHandler, messages]
  );

  const defaultSorter = (key: string) => (a: any, b: any) => a[key] - b[key];

  const onConfigClick = React.useCallback(
    (record: InsightsConfig) => {
      setConfigDetails(record);
      setConfigDialog(true);
    },
    [setConfigDialog, setConfigDetails]
  );

  const onConfigEdit = React.useCallback(
    (record: InsightsConfig) => {
      setConfigDetails(record);
      setEditConfigDialog(true);
      setIsNewInsight(false);
    },
    [setEditConfigDialog, setConfigDetails]
  );

  const onConfigAdd = React.useCallback(() => {
    setConfigDetails(configInitVals);
    setEditConfigDialog(true);
    setIsNewInsight(true);
  }, [setEditConfigDialog, setIsNewInsight]);

  const handleSave = React.useCallback(
    (data) => {
      if (!tenantId) {
        return;
      }

      if (isNewInsight) {
        claimsAdminInsightConfigHandler.createConfig({
          tenantId,
          insightsConfig: data,
        });
      } else {
        claimsAdminInsightConfigHandler.updateConfig({
          tenantId,
          insightsConfig: data,
        });
      }

      setEditConfigDialog(false);
      setIsNewInsight(false);
    },
    [claimsAdminInsightConfigHandler, tenantId, isNewInsight]
  );

  const columns: ColumnsType<InsightsConfig> = React.useMemo(() => {
    return [
      {
        title: messages['claimsAdmin.insightsConfig.columns.name'],
        width: 150,
        dataIndex: 'name',
        render: (_, record) => {
          return (
            <Link title={record.name} to={''}>
              {record.name}
            </Link>
          );
        },
      },
      {
        title: messages['claimsAdmin.insightsConfig.columns.question'],
        width: 400,
        dataIndex: 'query',
      },
      {
        title: messages['claimsAdmin.insightsConfig.columns.actions'],
        width: 200,
        dataIndex: 'actions',
        render: (_, record) => {
          return (
            <ul className={styles.li}>
              {record.actions.map((action, index) => {
                return (
                  <li key={index}>
                    {messages[
                      `claims.matchInsights.promptsImmediateActionOpts.${action}`
                    ] ?? action}
                  </li>
                );
              })}
            </ul>
          );
        },
        sorter: defaultSorter('actions'),
      },
      {
        title: messages['claimsAdmin.insightsConfig.columns.insights'],
        width: 80,
        dataIndex: 'insights',
        render: () => {
          return (
            <div className={styles.columnText}>
              <span className={styles.blockText}>
                <Tooltip
                  title={
                    messages[
                      'claimsAdmin.insightsConfig.columns.tooltip.insights'
                    ]
                  }
                >
                  <Typography.Link className={styles.linkText}>
                    {Math.floor(Math.random() * (99 - 40 + 1) + 40)}
                  </Typography.Link>
                  <p className={styles.subText}>
                    {messages['claimsAdmin.insightsConfig.columns.insights']}
                  </p>
                </Tooltip>
              </span>
            </div>
          );
        },
        sorter: defaultSorter('insights'),
      },
      {
        title: messages['claimsAdmin.insightsConfig.columns.acceptanceRate'],
        width: 100,
        dataIndex: 'passRate',
        render: () => {
          return (
            <div className={styles.columnText}>
              <span className={styles.blockText}>
                <Tooltip
                  title={
                    messages[
                      'claimsAdmin.insightsConfig.columns.tooltip.acceptanceRate'
                    ]
                  }
                >
                  <Typography.Link className={styles.linkText}>
                    {Math.floor(Math.random() * (99 - 30 + 1) + 30)}%
                  </Typography.Link>
                  <p className={styles.subText}>
                    {
                      messages[
                        'claimsAdmin.insightsConfig.columns.acceptanceRate'
                      ]
                    }
                  </p>
                </Tooltip>
              </span>
            </div>
          );
        },
        sorter: defaultSorter('passRate'),
      },
      {
        title: messages['claimsAdmin.insightsConfig.columns.priority'],
        width: 100,
        dataIndex: 'priority',
        render: (_, record) => {
          return (
            <Badge
              color={priorityBadge(record.priority)}
              text={`${record.priority[0].toUpperCase()}${record.priority
                .slice(1)
                .toLowerCase()}`}
            />
          );
        },
        sorter: (a, b) => {
          return priorityOrder[a.priority] - priorityOrder[b.priority];
        },
      },
      {
        title: '',
        width: 10,
        dataIndex: 'name',
        render: (_, record) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="analyze"
                  onClick={() => {
                    onConfigClick(record);
                  }}
                >
                  {messages['claimsAdmin.insightsConfig.dialog.analyze']}
                </Menu.Item>
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    onConfigEdit(record);
                  }}
                >
                  {messages['claimsAdmin.insightsConfig.dialog.edit']}
                </Menu.Item>
              </Menu>
            }
            overlayClassName={styles.menuContainer}
          >
            <EllipsisOutlined className={styles.ellipsisIcon} />
          </Dropdown>
        ),
      },
      {
        title: '',
        width: 10,
        dataIndex: 'name',
        render: (_, record) => (
          <Popconfirm
            title={
              messages[
                'claimsAdmin.insightsConfig.deleteInsightsConfig.dialog.title'
              ]
            }
            onConfirm={() =>
              claimsAdminInsightConfigHandler.deleteConfig({
                tenantId,
                insightsConfigId: record.id,
              })
            }
            okText={
              messages[
                'claimsAdmin.insightsConfig.deleteInsightsConfig.dialog.okText'
              ]
            }
            cancelText={
              messages[
                'claimsAdmin.insightsConfig.deleteInsightsConfig.dialog.cancelText'
              ]
            }
          >
            <DeleteOutlined className={styles.deleteIcon} />
          </Popconfirm>
        ),
      },
    ];
  }, [
    messages,
    onConfigClick,
    onConfigEdit,
    claimsAdminInsightConfigHandler,
    tenantId,
  ]);

  const onSearch = React.useCallback((e) => {
    const value = e.target.value;
    setSearchVal(value);
  }, []);

  return (
    <Layout.Content className={styles.claimsInsightsContainer}>
      <ClaimsAdminHeader
        title={messages['claimsAdmin.insightsConfig.title']}
        buttonProps={[
          {
            label: messages['claimsAdmin.insightsConfig.addInsights'],
            onClick: onConfigAdd,
          },
        ]}
        searchProps={{
          inputPlaceholder: messages['claimsAdmin.insightsConfig.search'],
          searchVal,
          onSearch,
        }}
      />

      <Layout.Content>
        <Table
          rowKey="key"
          columns={columns}
          dataSource={insightsConfigs.filter((c) =>
            c.name.toLowerCase().includes(searchVal.toLowerCase())
          )}
          pagination={false}
        />
      </Layout.Content>

      <ClaimsAdminInsightsConfigDialogComponent
        configDetails={configDetails}
        setConfigDialog={setConfigDialog}
        visible={configDialog}
      />
      <InsightsConfigFormManaged
        initialValues={configDetails}
        onSubmit={handleSave}
        onClose={() => {
          setEditConfigDialog(false);
          setIsNewInsight(false);
        }}
        visible={editConfigDialog}
        actionOptions={actionOptions}
        docFilterOptions={docFilterOptions}
        isNewInsight={isNewInsight}
      />
    </Layout.Content>
  );
};

export default ClaimsAdminInsightsConfigDetailsContainer;
