const flatten = (messages) => {
  const result = {};

  for (const i in messages) {
    if (typeof messages[i] === 'object') {
      const temp = flatten(messages[i]);
      for (const j in temp) {
        result[`${i}.${j}`] = temp[j];
      }
    } else {
      result[i] = messages[i];
    }
  }

  return result;
};

const localizationClient = {
  translations: {
    fetch: async (locale = 'en-ca'): Promise<{ [k: string]: string }> => {
      // TODO: extract flatten into script and return generated "flattened" file instead, if this gets to be too slow
      return flatten((await import(`./translations/${locale}.json`)).default);
    },
  },
};

export default localizationClient;
