import { parse as parseCsv, ParseResult } from 'papaparse';
import { call, SagaGenerator } from 'typed-redux-saga';
import { tenantsApiClient, uploadsApiClient } from '@owl-frontend/api-client';
import type { TenantUser } from '@owl-frontend/api-client/interface';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import tenantDetailsSlice from './interface';

/** 32KiB */
const IN_WORKER_SIZE = 32 << 10; // eslint-disable-line no-bitwise
export async function parseAsync(file: File): Promise<ParseResult<string[]>> {
  return new Promise<ParseResult<string[]>>((resolve, reject) => {
    parseCsv(file, {
      worker: file.size > IN_WORKER_SIZE,
      complete: resolve,
      error: reject,
      skipEmptyLines: true,
    });
  });
}

function* addMultipleTenantUsers(action: {
  payload: { tenantId: string | undefined; file: File };
}): SagaGenerator<TenantUser[]> {
  const { tenantId, file } = action.payload;

  const { data, errors } = yield* call(parseAsync, file);

  if (errors.length > 0) {
    throw new Error(`Failed to parse CSV: ${JSON.stringify(errors, null, 2)}`);
  }

  return yield* call(tenantsApiClient.tenants.addMultipleTenantUsers, {
    tenantId,
    formattedUsers: data.map((d) => ({
      name: d[0],
      email: d[1],
    })),
  });
}

const slice = tenantDetailsSlice
  .addAsyncSagas({
    fetchTenantDetails: invoke(tenantsApiClient.tenants.get),
    updateTenantIngestionSettings: invoke(
      tenantsApiClient.tenants.updateTenantIngestionSettings
    ),
    fetchReviews: invoke(tenantsApiClient.tenants.listReviews),
    fetchReviewsCount: invoke(tenantsApiClient.tenants.reviewsCount),
    fetchIngestionConfigs: invoke(
      tenantsApiClient.tenants.fetchIngestionConfigs
    ),
    sendUploadApproval: invoke(uploadsApiClient.uploads.sendUploadApproval),
    listUploads: invoke(uploadsApiClient.uploads.listUploads),
    getUpload: invoke(uploadsApiClient.uploads.getUpload),
    updateUpload: invoke(uploadsApiClient.uploads.updateUpload),
    listStagingDossiers: invoke(uploadsApiClient.uploads.listStagingDossiers),
    getStagingDossier: invoke(uploadsApiClient.uploads.getStagingDossier),
    updateStagingDossier: invoke(uploadsApiClient.uploads.updateStagingDossier),
    saveIngestionConfig: invoke(tenantsApiClient.tenants.saveIngestionConfig),
    deleteIngestionConfig: invoke(
      tenantsApiClient.tenants.deleteIngestionConfig
    ),
    rerankDossiers: invoke(tenantsApiClient.tenants.rerankDossiers),
    getLatestRerankDossiersLog: invoke(
      tenantsApiClient.tenants.getLatestRerankDossiersLog
    ),
    listUsers: invoke(tenantsApiClient.tenants.listTenantUsers),
    addTenantUser: invoke(tenantsApiClient.tenants.addTenantUser),
    addMultipleTenantUsers,
  })
  .addReducers({
    'fetchTenantDetails/fulfilled': (state, action) => {
      state.tenants[action.payload.tenantId] = action.payload;
    },
    'updateTenantIngestionSettings/fulfilled': (state, action) => {
      state.tenants[action.payload.tenantId] = action.payload;
    },
    'fetchReviews/fulfilled': (state, action) => {
      const { items } = action.payload;
      const tenantId = action.meta.arg.tenantId;
      state.reviews[tenantId] = {
        ...state.reviews[tenantId],
        items,
      };
    },
    'fetchReviewsCount/fulfilled': (state, action) => {
      const { count } = action.payload;
      const tenantId = action.meta.arg.tenantId;
      state.reviews[tenantId] = {
        ...state.reviews[tenantId],
        count,
      };
    },
    'fetchIngestionConfigs/fulfilled': (state, action) => {
      const tenantId = action.meta.arg.tenantId;
      state.ingestionConfigs[tenantId] = action.payload;
    },
    'listUploads/fulfilled': (state, action) => {
      const tenantId = action.meta.arg.tenantId;
      state.uploads[tenantId] = action.payload;
    },
    'getUpload/fulfilled': (state, action) => {
      const tenantId = action.meta.arg.tenantId;
      state.currentUpload[tenantId] = action.payload;
    },
    'listStagingDossiers/fulfilled': (state, action) => {
      const tenantId = action.meta.arg.tenantId;
      state.stagedDossiers[tenantId] = {
        items: action.payload.dossiers,
        lastCursor: action.payload.lastCursor,
      };
    },
    'getLatestRerankDossiersLog/fulfilled': (state, action) => {
      state.latestRerankDossiersLog = action.payload;
    },
    'listUsers/fulfilled': (state, action) => {
      const tenantId = action.meta.arg.tenantId;
      state.users[tenantId] = action.payload;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
