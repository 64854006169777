import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import type {
  ClaimListItem,
  ClaimNote,
  ClaimsChunk,
  ClaimsDocumentDeprecated,
  Dossier,
  Insight,
  SaveInsightRequest,
  MLConfig,
  MatchAnswer,
  QueryHistoryItem,
  UploadDocument,
  DecideOnInsightRequest,
} from '@owl-frontend/api-client';
import { toast, useEffectWithPrev } from '@owl-frontend/components';
import { Locale } from '../../context/AppContainer/AppContainer';
import { useBus } from '../../context/event-bus';
import type { StoreState } from '../../data/store';
import {
  actions as claimsActions,
  documentListenerEvent,
  DocumentListenerEvents,
} from './data/logic';

/**
 * MARK: CLAIMS LIST
 * =================
 */

export interface UseClaimsListState {
  claims: ClaimListItem[];
  loading: boolean;
}

export interface UseClaimsListHandler {
  claimsList(data: { tenantId: string }): void;
}

export function useClaimsList(): [UseClaimsListState, UseClaimsListHandler] {
  const dispatch = useDispatch();
  const claims = useSelector((s: StoreState) => s['claims'].claims);
  const claimsListAction = useSelector(
    (s: StoreState) => s['claims'].actions.claimsList
  );

  const handler = React.useMemo<UseClaimsListHandler>(
    () => ({
      claimsList: (data) => {
        dispatch(claimsActions.claimsList({ tenantId: data.tenantId }));
      },
    }),
    []
  );

  return [
    {
      claims,
      loading: claimsListAction.status === 'pending',
    },
    handler,
  ];
}

/**
 * MARK: CLAIM DETAILS
 * ===================
 */

export interface UseClaimDetailsState {
  loading: {
    claimFetch: boolean;
    listDocuments: boolean;
    queryHistoryFetch: boolean;
    documentFetch: boolean;
    documentDownload: boolean;
    summaryFetch: boolean;
  };
  claim?: Dossier & {
    documentsToReview?: {
      [docTitle: string]: boolean;
    };
  };
  queryHistory?: QueryHistoryItem[];
  documentPresignedUrl?: string;
  summary?: {
    summary: string;
    matches: {
      docName: string;
      page: number;
      document: ClaimsDocumentDeprecated;
    }[];
  };
}

export interface UseClaimDetailsHandler {
  claimFetch(data: { tenantId: string; dossierId: string }): void;
  querySearchFetch(data: {
    dossierId: string;
    searchQuery: string;
    historySearch: boolean;
    limit?: number;
    matchDocsKey: string;
  }): void;
  queryHistoryFetch(data: { dossierId: string }): void;
  querySearchMatch(data: {
    dossierId: string;
    searchQuery: string;
    limit?: number;
    parentId?: string;
  }): void;
  preSearchMatch(data: {
    dossierId: string;
    searchQuery: string;
    limit?: number;
  }): void;
  getStreamedAnswer(data: { dossierId: string; answerRequestId: string }): void;
  clearState(): void;
  getClaimSummary(data: { tenantId: string; dossierId: string }): void;
}

export function useClaimDetails(
  dossierId: string | undefined
): [UseClaimDetailsState, UseClaimDetailsHandler] {
  const dispatch = useDispatch();
  const claimDetails = useSelector((s: StoreState) => s['claims'].claimDetails);
  const queryHistory = useSelector((s: StoreState) => s['claims'].queryHistory);
  const summary = useSelector((s: StoreState) => s['claims'].summary);
  const documentPresignedUrl = useSelector(
    (s: StoreState) => s['claims'].presignedUrl
  );

  const {
    claimFetch,
    listDocuments,
    querySearchFetch,
    documentFetch,
    documentDownload,
    fetchClaimSummary,
  } = useSelector((s: StoreState) => s['claims'].actions);

  const handler = React.useMemo<UseClaimDetailsHandler>(
    () => ({
      claimFetch: (data) => {
        dispatch(
          claimsActions.claimFetch({
            tenantId: data.tenantId,
            dossierId: data.dossierId,
          })
        );
      },
      querySearchFetch: (data) => {
        dispatch(
          claimsActions.querySearchFetch({
            dossierId: data.dossierId,
            searchQuery: data.searchQuery,
            historySearch: data.historySearch,
            matchDocsKey: data.matchDocsKey,
          })
        );
      },
      queryHistoryFetch: (data) => {
        dispatch(
          claimsActions.queryHistoryFetch({ dossierId: data.dossierId })
        );
      },
      querySearchMatch: (data) => {
        dispatch(
          claimsActions.querySearchMatch({
            dossierId: data.dossierId,
            searchQuery: data.searchQuery,
            limit: data.limit,
            parentId: data.parentId,
          })
        );
      },
      preSearchMatch: (data) => {
        dispatch(
          claimsActions.preSearchMatch({
            dossierId: data.dossierId,
            searchQuery: data.searchQuery,
            limit: data.limit,
          })
        );
      },
      getStreamedAnswer: (data) => {
        dispatch(
          claimsActions.getStreamedAnswer({
            dossierId: data.dossierId,
            answerRequestId: data.answerRequestId,
          })
        );
      },
      clearState: () => {
        dispatch(claimsActions.clearState());
      },
      getClaimSummary: (data) => {
        dispatch(
          claimsActions.fetchClaimSummary({
            tenantId: data.tenantId,
            dossierId: data.dossierId,
          })
        );
      },
    }),
    []
  );

  return [
    {
      claim: dossierId ? claimDetails[dossierId] : undefined,
      queryHistory: dossierId ? queryHistory[dossierId] : undefined,
      documentPresignedUrl: documentPresignedUrl ?? undefined,
      summary,
      loading: {
        claimFetch: claimFetch.status === 'pending',
        listDocuments: listDocuments.status === 'pending',
        queryHistoryFetch: querySearchFetch.status === 'pending',
        documentFetch: documentFetch.status === 'pending',
        documentDownload: documentDownload.status === 'pending',
        summaryFetch: fetchClaimSummary.status === 'pending',
      },
    },
    handler,
  ];
}

/**
 * MARK: DOCUMENT UPLOAD LISTENER
 * ==============================
 */

export function useClaimDocumentsUploadListener({
  dossierId,
}: {
  dossierId?: string;
}): [
  {
    listenForFinishedUpload(numDocs: number): void;
    listenForFinishedInsightsUpload(numInsights: number): void;
  }
] {
  const dispatch = useDispatch();
  const bus = useBus<DocumentListenerEvents>();

  const handler = React.useMemo(
    () => ({
      listenForFinishedUpload: (numDocs: number) => {
        dispatch({ type: 'STOP_LISTEN_FINISHED_DOCUMENT_UPLOAD' });

        if (!dossierId) {
          return;
        }

        dispatch(
          claimsActions.startListenForFinishedDocumentUpload({
            dossierId,
            initialNumDocuments: numDocs,
            bus,
          })
        );
      },
      listenForFinishedInsightsUpload: (numInsights: number) => {
        dispatch({ type: 'STOP_LISTEN_FINISHED_INSIGHTS_UPLOAD' });

        if (!dossierId) {
          return;
        }

        dispatch(
          claimsActions.startListenForNewInsights({
            dossierId,
            initialNumInsights: numInsights,
            bus,
          })
        );
      },
    }),
    [dossierId]
  );

  React.useEffect(() => {
    if (!dossierId) {
      return;
    }

    return () => {
      dispatch({ type: 'STOP_LISTEN_FINISHED_DOCUMENT_UPLOAD' });
    };
  }, [dossierId]);

  return [handler];
}

/**
 * MARK: CLAIMS INSIGHTS
 * =====================
 */

export interface UseClaimsInsightsState {
  targetInsight?: Partial<Insight>;
  insights: Insight[];
  loading: boolean;
}

export interface UseClaimsInsightsHandler {
  fetchInsights(): void;
  saveInsight(insight: Insight, updateContent?: boolean): void;
  deleteInsight(data: { insightId: string }): void;
  setTargetInsight(insight?: Partial<Insight>): void;
}

export function useClaimsInsights({
  dossierId,
}: {
  dossierId?: string;
}): [UseClaimsInsightsState, UseClaimsInsightsHandler] {
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const { messages } = React.useContext(Locale);
  const insights = useSelector((s: StoreState) => s['claims'].insights);
  const [
    fetchInsightsAction,
    saveInsightAction,
    decideOnInsightAction,
    deleteInsightAction,
    resetDemoDataAction,
  ] = useSelector((s: StoreState) => [
    s['claims'].actions.claimsInsightsFetch,
    s['claims'].actions.saveInsight,
    s['claims'].actions.decideOnInsight,
    s['claims'].actions.deleteInsight,
    s['claims'].actions.resetDemoData,
  ]);
  const [targetInsight, setTargetInsight] = React.useState<Partial<Insight>>();
  const bus = useBus<DocumentListenerEvents>();

  const handler = React.useMemo<UseClaimsInsightsHandler>(
    () => ({
      fetchInsights: () => {
        if (!tenantId || !dossierId) {
          return;
        }
        dispatch(claimsActions.claimsInsightsFetch({ tenantId, dossierId }));
      },
      saveInsight: (insight: Insight, updateContent?: boolean) => {
        if (
          !tenantId ||
          !dossierId ||
          !insight.title ||
          !insight.answer ||
          !insight.customAnswer ||
          !insight.query
        ) {
          return;
        }

        const data = { ...insight, dossierId };

        if (updateContent) {
          dispatch(
            claimsActions.saveInsight({
              ...(data as SaveInsightRequest),
              tenantId,
            })
          );
        } else if (insight.insightId && insight.decision) {
          dispatch(
            claimsActions.decideOnInsight({
              ...data,
              tenantId,
            } as DecideOnInsightRequest)
          );
        }
      },
      deleteInsight: (data) => {
        if (!dossierId) {
          return;
        }
        dispatch(
          claimsActions.deleteInsight({
            dossierId,
            insightId: data.insightId,
          })
        );
      },
      setTargetInsight,
    }),
    [tenantId, dossierId, setTargetInsight]
  );

  documentListenerEvent.useListener(
    'invalidateNewInsightsListener',
    React.useCallback(() => {
      if (!dossierId) {
        return;
      }

      toast({
        key: 'NEW_INSIGHTS',
        type: 'success',
        message: messages['claims.matchInsights.fetchAction.success'],
      });

      handler.fetchInsights();
    }, [dossierId, messages, handler])
  );

  React.useEffect(() => {
    if (!dossierId) {
      return;
    }

    handler.fetchInsights();
  }, [dossierId, handler]);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (saveInsightAction.status === 'rejected') {
        toast({
          key: 'SAVE_INSIGHT_FAIL',
          type: 'error',
          message: messages['claims.matchInsights.form.error'],
        });
      }

      setTargetInsight(undefined);

      if (dossierId) {
        handler.fetchInsights();
      }
    },
    [saveInsightAction.status, setTargetInsight, dossierId, messages, handler]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (decideOnInsightAction.status === 'rejected') {
        toast({
          key: 'SAVE_INSIGHT_FAIL',
          type: 'error',
          message: messages['claims.matchInsights.form.error'],
        });
      }

      setTargetInsight(undefined);

      if (dossierId) {
        handler.fetchInsights();
      }
    },
    [
      decideOnInsightAction.status,
      setTargetInsight,
      dossierId,
      messages,
      handler,
    ]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (dossierId) {
        handler.fetchInsights();
      }
    },
    [resetDemoDataAction.status, dossierId, messages, handler]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (deleteInsightAction.status === 'fulfilled') {
        toast({
          key: 'DELETE_INSIGHT_SUCCESS',
          type: 'success',
          message: messages['claims.matchInsights.deleteAction.success'],
        });
      }

      if (deleteInsightAction.status === 'rejected') {
        toast({
          key: 'DELETE_INSIGHT_FAIL',
          type: 'error',
          message: messages['claims.matchInsights.deleteAction.error'],
        });
      }

      setTargetInsight(undefined);

      if (dossierId) {
        handler.fetchInsights();
      }
    },
    [deleteInsightAction.status, dossierId, messages, handler]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (fetchInsightsAction.status === 'rejected') {
        toast({
          key: 'FETCH_INSIGHTS_FAIL',
          type: 'error',
          message: messages['claims.matchInsights.fetchAction.error'],
        });
      }

      if (dossierId) {
        dispatch({ type: 'STOP_LISTEN_FINISHED_INSIGHTS_UPLOAD' });
        dispatch(
          claimsActions.startListenForNewInsights({
            dossierId,
            initialNumInsights:
              fetchInsightsAction.result?.insights?.length ?? 0,
            bus,
          })
        );
      }
    },
    [fetchInsightsAction.status, dossierId, messages, fetchInsightsAction]
  );

  return [
    {
      insights: dossierId ? insights[dossierId] : [],
      loading:
        saveInsightAction.status === 'pending' ||
        decideOnInsightAction.status === 'pending' ||
        deleteInsightAction.status === 'pending',
      targetInsight,
    },
    handler,
  ];
}

/**
 * MARK: CLAIMS ML CONFIG
 * ======================
 */

export interface UseClaimsMLConfigState {
  config?: MLConfig;
}

export interface UseClaimsMLConfigHandler {
  getConfig(): void;
}

export function useClaimsMLConfig(): [
  UseClaimsMLConfigState,
  UseClaimsMLConfigHandler
] {
  const dispatch = useDispatch();
  const config = useSelector((s: StoreState) => s['claims'].config);

  const handler = React.useMemo<UseClaimsMLConfigHandler>(
    () => ({
      getConfig: () => {
        dispatch(claimsActions.getConfig());
      },
    }),
    []
  );

  return [
    {
      config,
    },
    handler,
  ];
}

/**
 * MARK: CHUNKS
 * ============
 */

export interface UseClaimsChunksState {
  chunks?: { [k: string]: { [k: string]: ClaimsChunk } };
  chunksList?: { [k: string]: { [k: string]: ClaimsChunk[] } };
  chunksLoading: boolean;
  chunksListLoading: boolean;
}

export interface UseClaimsChunksHandler {
  fetchChunk(data: { dossierId: string; chunkId: string }): void;
  fetchChunksList(data: { dossierId: string; docId: string }): void;
}

export function useClaimsChunks(): [
  UseClaimsChunksState,
  UseClaimsChunksHandler
] {
  const dispatch = useDispatch();
  const chunks = useSelector((s: StoreState) => s['claims'].chunks);
  const chunksList = useSelector((s: StoreState) => s['claims'].chunksList);
  const fetchChunkAction = useSelector(
    (s: StoreState) => s['claims'].actions.fetchChunk
  );
  const listChunksAction = useSelector(
    (s: StoreState) => s['claims'].actions.listChunks
  );

  const handler = React.useMemo<UseClaimsChunksHandler>(
    () => ({
      fetchChunk: (data: { dossierId: string; chunkId: string }) => {
        dispatch(claimsActions.fetchChunk(data));
      },
      fetchChunksList: (data: { dossierId: string; docId: string }) => {
        dispatch(claimsActions.listChunks(data));
      },
    }),
    []
  );

  return [
    {
      chunks,
      chunksList,
      chunksLoading: fetchChunkAction.status === 'pending',
      chunksListLoading: listChunksAction.status === 'pending',
    },
    handler,
  ];
}

/**
 * MARK: UPLOAD DOCUMENT
 * ======================
 */

export interface UseUploadDocumentState {
  visible: boolean;
  loading: boolean;
  isLoadingDocuments: boolean;
  documents?: ClaimsDocumentDeprecated[];
}
export interface UseUploadDocumentHandler {
  listDocuments(): void;
  toggleDocumentUpload(v: boolean): void;
  uploadDocument(data: UploadDocument): void;
  documentFetch(data: {
    dossierId: string;
    docId: string;
    docName: string;
  }): void;
  documentDownload(data: {
    dossierId: string;
    docId: string;
    docName: string;
  }): void;
  deleteDocument(data: {
    dossierId: string;
    docId: string;
    docName: string;
  }): void;
  markDocumentAsReviewed(
    docId: string,
    nextAction?: string,
    note?: string
  ): void;
}
export function useDocumentUpload({
  dossierId,
  tenantId,
}: {
  dossierId?: string;
  tenantId?: string;
}): [UseUploadDocumentState, UseUploadDocumentHandler] {
  const { messages } = React.useContext(Locale);
  const intl = useIntl();
  const dispatch = useDispatch();
  const documents = useSelector((s: StoreState) => s['claims'].documents);
  const uploadDocumentAction = useSelector(
    (s: StoreState) => s['claims'].actions.uploadFiles
  );
  const deleteDocumentAction = useSelector(
    (s: StoreState) => s['claims'].actions.deleteDocument
  );
  const uploadResult = useSelector((s: StoreState) => s['claims'].uploadResult);
  const fetchDocumentsAction = useSelector(
    (s: StoreState) => s['claims'].actions.listDocuments
  );
  const [documentUploadHandler] = useClaimDocumentsUploadListener({
    dossierId,
  });

  const [visible, setVisible] = React.useState(false);

  documentListenerEvent.useListener(
    'invalidateDocumentListener',
    React.useCallback(() => {
      if (!dossierId || !tenantId) {
        return;
      }

      toast({
        key: 'FETCH_NEW_DOCS',
        type: 'success',
        message:
          messages['claims.documents.uploadDialog.success.finishedUploading'],
      });

      dispatch(claimsActions.listDocuments({ dossierId, tenantId }));
      dispatch(claimsActions.claimFetch({ dossierId, tenantId }));
    }, [dossierId, tenantId, messages])
  );

  const handler = React.useMemo<UseUploadDocumentHandler>(
    () => ({
      listDocuments: () => {
        if (!dossierId || !tenantId) {
          return;
        }
        dispatch(claimsActions.listDocuments({ dossierId, tenantId }));
      },
      uploadDocument: (documentData) => {
        if (!tenantId) {
          return;
        }
        dispatch(claimsActions.uploadFiles({ ...documentData, tenantId }));
      },
      toggleDocumentUpload: (val) => {
        setVisible(val);
      },
      documentFetch: (data) => {
        dispatch(
          claimsActions.documentFetch({
            dossierId: data.dossierId,
            docId: data.docId,
            docName: data.docName,
          })
        );
      },
      documentDownload: (data) => {
        dispatch(
          claimsActions.downloadDocument({
            dossierId: data.dossierId,
            docId: data.docId,
            docName: data.docName,
          })
        );
      },
      deleteDocument: (d) => {
        Modal.confirm({
          icon: <></>,
          title: messages['claims.documents.delete.title'],
          content: intl.formatMessage(
            { id: 'claims.documents.delete.content' },
            { docName: d.docName }
          ),
          okText: messages['claims.documents.delete.confirm'],
          onOk: () => {
            dispatch(
              claimsActions.deleteDocument({
                dossierId: d.dossierId,
                docId: d.docId,
                docName: d.docName,
              })
            );
          },
          cancelText: messages['shared.cancel'],
        });
      },
      markDocumentAsReviewed: (
        docId: string,
        nextAction?: string,
        note?: string
      ) => {
        if (!dossierId || !tenantId) {
          return;
        }
        dispatch(
          claimsActions.setDocumentAsReviewed({
            dossierId,
            tenantId,
            docId,
            nextAction,
            note,
          })
        );
      },
    }),
    [dossierId, intl, messages, tenantId]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !dossierId || !tenantId) {
        return;
      }

      handler.toggleDocumentUpload(false);

      if (uploadDocumentAction.status === 'fulfilled') {
        if (uploadResult.failedUploads.length === 0) {
          Modal.success({
            title: messages['claims.documents.uploadDialog.success.title'],
            content: messages['claims.documents.uploadDialog.success.content'],
          });
        } else {
          Modal.error({
            title: messages['claims.documents.uploadDialog.error.title'],
            content: intl.formatMessage(
              { id: 'claims.documents.uploadDialog.error.failedUpload' },
              { failedDocs: uploadResult.failedUploads.join(', ') }
            ),
          });
        }

        dispatch(claimsActions.listDocuments({ dossierId, tenantId }));
      }

      if (uploadDocumentAction.status === 'rejected') {
        Modal.error({
          title: messages['claims.documents.uploadDialog.error.title'],
          content: messages['claims.documents.uploadDialog.error.content'],
        });
      }
    },
    [
      uploadDocumentAction.status,
      dossierId,
      tenantId,
      messages,
      handler,
      intl,
      uploadResult.failedUploads,
    ]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !dossierId || !tenantId) {
        return;
      }

      if (deleteDocumentAction.status === 'fulfilled') {
        dispatch(claimsActions.listDocuments({ dossierId, tenantId }));
      } else {
        Modal.error({
          title: messages['claims.documents.delete.error.title'],
          content: messages['claims.documents.delete.error.content'],
        });
      }
    },
    [deleteDocumentAction.status, dossierId, tenantId, messages, handler]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !dossierId) {
        return;
      }

      if (fetchDocumentsAction.status === 'fulfilled') {
        documentUploadHandler.listenForFinishedUpload(
          documents?.[dossierId]?.length ?? 0
        );
      }
    },
    [fetchDocumentsAction.status, dossierId, documentUploadHandler, documents]
  );

  return [
    {
      documents: dossierId ? documents[dossierId] : [],
      visible,
      loading: uploadDocumentAction.status === 'pending',
      isLoadingDocuments: fetchDocumentsAction.status === 'pending',
    },
    handler,
  ];
}

/**
 * MARK: DOWNLOAD CLAIM DOCUMENTS
 * ======================
 */
export interface UseDownloadDocumentState {
  visible: boolean;
  loading: boolean;
}
export interface UseDownloadDocumentHandler {
  downloadAllDocuments(data);
  toggleDocumentDownload(visible: boolean);
}
export function useDocumentDownload(data: {
  dossierId?: string;
}): [UseDownloadDocumentState, UseDownloadDocumentHandler] {
  const { messages } = React.useContext(Locale);
  const dispatch = useDispatch();
  const downloadAllDocumentsAction = useSelector(
    (s: StoreState) => s['claims'].actions.downloadAllDocuments
  );

  const [visible, setVisible] = React.useState(false);

  const handler = React.useMemo<UseDownloadDocumentHandler>(
    () => ({
      downloadAllDocuments: (documents) => {
        dispatch(claimsActions.downloadAllDocuments(documents));
      },
      toggleDocumentDownload: (val) => {
        setVisible(val);
      },
    }),
    []
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending' || !data.dossierId) {
        return;
      }

      handler.toggleDocumentDownload(false);

      if (downloadAllDocumentsAction.status === 'fulfilled') {
        toast({
          key: 'DOWNLOAD_FILE_SUCCESS',
          type: 'success',
          message: messages['claims.documents.downloadDialog.success.content'],
        });
      } else {
        toast({
          key: 'DOWNLOAD_FILE_ERROR',
          type: 'error',
          message: messages['claims.documents.downloadDialog.error.content'],
        });
      }
    },
    [downloadAllDocumentsAction.status, data.dossierId, messages, handler]
  );
  return [
    {
      visible,
      loading: downloadAllDocumentsAction.status === 'pending',
    },
    handler,
  ];
}

export interface UseUStreamedAnswerState {
  streamedAnswer: { answer?: string; evidences?: string[] };
}
export function useStreamedAnswer(data: {
  answerRequestId?: string;
}): UseUStreamedAnswerState {
  const { answerRequestId } = data;

  const streamedAnswers = useSelector(
    (s: StoreState) => s['claims'].streamedAnswers
  );

  const streamedAnswer = React.useMemo(() => {
    const raw = answerRequestId && streamedAnswers[answerRequestId];

    if (!raw) {
      return {};
    }

    return { answer: raw };
  }, [streamedAnswers, answerRequestId]);

  return { streamedAnswer };
}

/**
 * MARK: CLAIMS DOCUMENT VIEWER
 * ======================
 */

export type TargetMatchDocument = {
  document: {
    contentRawRef?: string;
    contentTextRef?: string;
    docId: string;
    docName: string;
  };
  prompt: string;
  documentMatches: MatchAnswer[];
  initialPage: number;
  isInsight: boolean;
};

export interface UseDocumentViewerState {
  documentContent?: { content: string; fileType: string };
  targetMatchDocument?: TargetMatchDocument;
  loading: boolean;
}

interface UseDocumentViewerHandler {
  onOpenMatchDocument(
    document: ClaimsDocumentDeprecated,
    matches: MatchAnswer[],
    searchPrompt: string,
    dossierId: string,
    initialPage: number,
    insight?: Insight
  ): void;
  onCloseMatchDocument(): void;
  onLoadMatchDocument(
    dossierId: string,
    document: ClaimsDocumentDeprecated
  ): void;
  onPrintMatchDocument(
    dossierId: string,
    document: ClaimsDocumentDeprecated
  ): void;
}

export function useDocumentViewer(): [
  UseDocumentViewerState,
  UseDocumentViewerHandler
] {
  const [targetMatchDocument, setTargetMatchDocument] = React.useState<
    TargetMatchDocument | undefined
  >();
  const dispatch = useDispatch();
  const documentContentAction = useSelector(
    (s: StoreState) => s['claims'].actions.getDocumentContent
  );
  const documentContent = useSelector(
    (s: StoreState) => s['claims']?.documentContent
  );

  const handler = React.useMemo(
    () => ({
      onOpenMatchDocument: (
        selectedDocument: ClaimsDocumentDeprecated,
        matches: MatchAnswer[],
        searchPrompt: string,
        dossierId: string,
        initialPage: number,
        insight?: Insight
      ) => {
        setTargetMatchDocument({
          documentMatches: (insight?.matches ?? matches).filter(
            (m) => m.document.docId === selectedDocument.docId
          ),
          document: selectedDocument,
          prompt: insight ? insight.query : searchPrompt,
          initialPage,
          isInsight: !!insight,
        });
      },
      onCloseMatchDocument: () => {
        setTargetMatchDocument(undefined);
      },
      onLoadMatchDocument: (
        dossierId: string,
        document: ClaimsDocumentDeprecated
      ) => {
        dispatch(claimsActions.getDocumentContent({ dossierId, document }));
      },
      onPrintMatchDocument: (
        dossierId: string,
        document: ClaimsDocumentDeprecated
      ) => {
        dispatch(claimsActions.printContent({ dossierId, document }));
      },
    }),
    []
  );

  return [
    {
      documentContent,
      targetMatchDocument,
      loading: documentContentAction.status === 'pending',
    },
    handler,
  ];
}

export interface UseNotesState {
  notes: ClaimNote[];
  loading: boolean;
  addNoteLoading: boolean;
  updateNoteLoading: boolean;
}

export interface UseNotesHandler {
  fetchNotes(): void;
  addNote(
    noteContent: string,
    documents: ClaimsDocumentDeprecated[],
    insightId?: string
  ): void;
  deleteNote(noteId: string): void;
  updateNote(noteId: string, noteContent: string): void;
}

export function useNotesList({
  tenantId,
  dossierId,
}: {
  tenantId?: string;
  dossierId?: string;
} = {}): [UseNotesState, UseNotesHandler] {
  const dispatch = useDispatch();
  const notes = useSelector((s: StoreState) => s['claims'].notes);
  const [getNotesAction, addNoteAction, updateNoteAction] = useSelector(
    (s: StoreState) => [
      s['claims'].actions.getNotes,
      s['claims'].actions.addNote,
      s['claims'].actions.updateNote,
    ]
  );
  const handler = React.useMemo<UseNotesHandler>(
    () => ({
      fetchNotes: () => {
        if (!dossierId) {
          return;
        }

        dispatch(
          claimsActions.getNotes({
            dossierId,
          })
        );
      },
      addNote: (noteContent, documents, insightId) => {
        if (!dossierId || !noteContent) {
          return;
        }

        dispatch(
          claimsActions.addNote({
            dossierId,
            noteContent,
            documents,
            insightId,
          })
        );
      },
      deleteNote: (noteId: string) => {
        if (!dossierId) {
          return;
        }

        dispatch(
          claimsActions.deleteNote({
            dossierId,
            noteId,
          })
        );
      },
      updateNote: (noteId: string, noteContent: string) => {
        if (!dossierId) {
          return;
        }

        dispatch(
          claimsActions.updateNote({
            dossierId,
            noteId,
            noteContent,
          })
        );
      },
    }),
    [dossierId]
  );

  React.useEffect(() => {
    if (!tenantId || !dossierId) {
      return;
    }

    handler.fetchNotes();
  }, [tenantId, dossierId, handler]);

  return [
    {
      notes: dossierId ? notes[dossierId] ?? [] : [],
      loading: getNotesAction.status === 'pending',
      addNoteLoading: addNoteAction.status === 'pending',
      updateNoteLoading: updateNoteAction.status === 'pending',
    },
    handler,
  ];
}
