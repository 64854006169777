import type { ReactNode } from 'react';
import type { ColumnShape } from 'react-base-table';
import type { MarkRequired, RemoveIndexSignature } from '@owl-lib/type-util';

type OmitPreserveIndexSignature<T, X extends PropertyKey> = {
  // This part preserves the index signature, but breaks "go to declaration"
  [K in keyof T as K extends X ? never : K]: T[K];
} & Pick<T, Extract<Exclude<keyof RemoveIndexSignature<T>, X>, keyof T>>; // This part preserves "go to declaration"
type ColumnShapeRest<T> = OmitPreserveIndexSignature<
  ColumnShape<T>,
  'key' | 'dataKey' | 'title' | 'width'
>;

type MarkEitherRequired<T, K1 extends keyof T, K2 extends keyof T> =
  | MarkRequired<T, K1>
  | MarkRequired<T, K2>;
// This can be updated to fit any other needs.
/**
 * @template E Extra column metadata
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export interface ColumnsBuilder<T, E = {}> {
  data(
    dataKey: keyof T | string,
    title: ReactNode,
    width: number,
    rest?: ColumnShapeRest<T> & E
  ): ColumnsBuilder<T, E>;
  cust(
    key: string,
    title: string,
    width: number,
    rest?: E &
      MarkEitherRequired<ColumnShapeRest<T>, 'dataGetter', 'cellRenderer'>
  ): ColumnsBuilder<T, E>;
  columns: (ColumnShape<T> & E)[];
}

const buildColumns = <T, E>(
  columns: (ColumnShape<T> & E)[] = []
): ColumnsBuilder<T, E> => ({
  data: (key: keyof T, title: ReactNode, width: number, rest?: any) =>
    buildColumns([...columns, { key, dataKey: key, title, width, ...rest }]),
  cust: (key: string, title: ReactNode, width: number, rest?: any) =>
    buildColumns([...columns, { key, title, width, ...rest }]),
  columns,
});

export { buildColumns };
