import React from 'react';
import { useField } from 'react-final-form';
import Radio, { RadioProps } from '../../components/radio/RadioAntd';
import type { MarkLabelRequired } from '../../components/radio/shared';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import type { ManagedFieldProps } from '../interface';

const RadioManaged = (
  props: MarkLabelRequired<RadioProps & ManagedFieldProps>
) => {
  const { name, defaultValue, validate, ...radioProps } = props;
  const { input, meta } = useField(name, { defaultValue, validate });
  const { error, invalid, modified, touched } = meta;

  const modifiedOrTouched = modified || touched;
  const fetchErrorMessage = React.useCallback(() => {
    if (!modifiedOrTouched || !invalid || !error) {
      return null;
    }
    return error;
  }, [modifiedOrTouched, invalid, error]);

  return (
    <Radio
      onChange={input.onChange}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      value={input.value}
      {...radioProps}
      trailer={<ErrorDisplayComponent errorMessage={fetchErrorMessage()} />}
    />
  );
};

export default RadioManaged;
