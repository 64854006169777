import React from 'react';
import { Typography } from 'antd';
import styles from './CopyOnHover.module.scss';

interface CopyableTextProps {
  text: string;
  children: React.ReactNode;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text, children }) => {
  return (
    <Typography.Text copyable={{ text }} className={styles.copyOnHover}>
      {children}
    </Typography.Text>
  );
};

export default CopyableText;
