import React from 'react';
import dayjs from 'dayjs';
import { deepEqual } from 'fast-equals';
import { useDebouncedCallback } from 'use-debounce';
import { Space, Tag } from 'antd';
import {
  useEffectWithPrev,
  usePrev,
  useStableCallback,
} from '../../../../shared/hooks';
import Button from '../../../Button/Button';
import { DatePickerAntd, Radio, DateRangePickerAntd } from '../../../Form';
import type { Option, RadioProps } from '../../../Form';
import Input from '../../../Form/components/input/Input';
import MultiSelect, {
  Props as MultiSelectProps,
} from '../../../Form/components/select/multi-select/MultiSelectAntd';
import Select, {
  Props as SelectProps,
} from '../../../Form/components/select/Select';
import PopoverAntd from '../../../Popover/PopoverAntd';
import Tabs from '../../../Tabs/Tabs';

import { DEFAULT_OPERATOR, DEFAULT_OPERATORS } from './helpers';
import styles from './Filter.module.scss';
import filterReducer, {
  Filter,
  SavedFilters,
  Value,
  Operator,
  FilterValue,
  FILTER_KEY_TOTAL,
} from './FilterReducer';
import SavedFiltersComponent from './SavedFiltersComponent';

const DATE_FORMAT = 'MMM D, YYYY';

export type Operators = RadioProps['options'];

type FieldType =
  | 'text'
  | 'number'
  | 'date'
  | 'date-range'
  | 'select'
  | 'multi-select';
interface FieldBase<Name extends string = string> {
  name: Name;
  label?: string;
  nullValue?: string;
  operator?: string;
  operators?: Operators;
  convertValue?:
    | ((val: string) => string)
    | ((val: [string, string]) => [string, string | null]);
}

type FieldOption = Option;

export type Field<Name extends string = string> =
  | (FieldBase<Name> & { type: Exclude<FieldType, 'select'> })
  | (FieldBase<Name> & {
      type: 'select';
      options: FieldOption[];
      selectProps?: Partial<SelectProps>;
    })
  | (FieldBase<Name> & {
      type: 'multi-select';
      options: FieldOption[];
      selectProps?: Partial<MultiSelectProps>;
    });

export interface FilterChangeArgs<FieldName extends string = string> {
  activeFilterKey?: string;
  searchTerm: string;
  searchTermKey: FieldName;
  filters: Filter<FieldName>;
}

export interface FilterProps<FieldName extends string = string> {
  fields: Field<FieldName>[];
  searchTerm?: string;
  searchTermKey?: FieldName;
  filters?: Filter<FieldName>;
  savedFilters?: SavedFilters;
  filtersVisible?: boolean;
  initActiveFilterKey?: string;
  showTotal?: boolean;
  totalItemCount?: number | null;
  filteredItemCount?: number | null;
  showSearchTerm?: boolean;
  title?: React.ReactNode;
  onFilterChange(args: FilterChangeArgs<FieldName>): void;
}

export interface FiltersProps {
  filters: Filter;
  fields: Field[];
  onRemoveFilter(name: string): void;
}

const Filters = ({
  filters = {},
  fields = [],
  onRemoveFilter,
}: FiltersProps) => {
  const onClose = useStableCallback((name) => {
    onRemoveFilter(name);
  });

  const fieldsMap = React.useMemo(
    () => fields.reduce((r, i) => ({ ...r, [i.name]: i }), {}),
    // eslint-disable-next-line react-hooks2/exhaustive-deps
    fields
  );

  const formatField = React.useCallback(
    (name: string) => {
      const value = filters[name]?.value;
      const field = fieldsMap[name];

      if (!field || !value) {
        return value;
      }

      switch (field.type) {
        case 'date-range':
          const [startDate, endDate] = value;
          const startDateStr = dayjs(startDate).format(DATE_FORMAT);
          const endDateStr = endDate
            ? dayjs(endDate).format(DATE_FORMAT)
            : null;

          let dateStr = startDateStr;
          if (endDate && startDateStr !== endDateStr) {
            dateStr += ` to ${endDateStr}`;
          }
          return dateStr;
        case 'date':
          return dayjs(value).format(DATE_FORMAT);
        case 'multi-select':
          return value
            .map(
              (item) =>
                field.options.find((opt: FieldOption) => opt.value === item)
                  ?.label
            )
            .join(', ');
        case 'select':
          return field.options.find((opt: FieldOption) => opt.value === value)
            ?.label;
        default:
          return value;
      }
    },
    [fieldsMap, filters]
  );

  const formatOperator = React.useCallback(
    (name: string) => {
      const selectedOp = filters[name]?.operator;
      const { operators = [], type } = fieldsMap[name] ?? {};

      if (operators.length === 0) {
        return '';
      }

      const operator = operators.find(
        (op) =>
          op.value ===
          (selectedOp ?? DEFAULT_OPERATORS[type] ?? DEFAULT_OPERATOR)
      );

      return operator?.label ?? '';
    },
    [fieldsMap, filters]
  );

  return (
    <Space className={styles.filters}>
      {Object.keys(filters)
        .filter((name) => {
          const val = filters[name]?.value;

          return (val || val === 0) && (!Array.isArray(val) || val.length > 0);
        })
        .map((name) => (
          <Tag
            key={name}
            closable
            color="blue"
            onClose={() => {
              onClose(name);
            }}
          >
            {`${fieldsMap[name]?.label ?? name}: ${formatOperator(
              name
            )} ${formatField(name)}`}
          </Tag>
        ))}
    </Space>
  );
};

export interface FilterQueryProps {
  field: Field;
  value: Value;
  onChange(value: Value): void;
}

const FilterQuery = ({ field, value, onChange }: FilterQueryProps) => {
  const { type, name } = field;

  const prevValue = usePrev(value);
  const handleChange = React.useCallback(
    (filterValue) => {
      filterValue =
        Array.isArray(filterValue) && filterValue.length === 0
          ? undefined
          : filterValue;

      if (deepEqual(prevValue, filterValue)) {
        return;
      }

      return onChange(filterValue);
    },
    [prevValue, onChange]
  );

  switch (type) {
    case 'date':
      return (
        <DatePickerAntd
          name={name}
          onChange={(date) => {
            handleChange(date ?? null);
          }}
        />
      );
    case 'date-range':
      return (
        <DateRangePickerAntd
          name={name}
          onChange={handleChange}
          value={value}
        />
      );
    case 'select':
      return (
        <Select
          label=""
          options={field['options'].map((opt: FieldOption) => ({
            label: opt.label,
            value: opt.value,
            group: opt.group,
            extra: opt.extra,
          }))}
          onChange={handleChange}
          value={value}
          {...field['selectProps']}
        />
      );
    case 'multi-select':
      return (
        <MultiSelect
          label=""
          options={field['options'].map((opt: FieldOption) => ({
            label: opt.label,
            value: opt.value,
            group: opt.group,
          }))}
          onChange={handleChange}
          value={value}
          {...field['selectProps']}
        />
      );
    case 'number':
      return (
        <Input
          label=""
          type="number"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          value={value ?? ''}
        />
      );
    case 'text':
    default:
      return (
        <Input
          label=""
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          value={value ?? ''}
        />
      );
  }
};

export interface FilterQueryWithOperatorsProps {
  field: Field;
  value?: FilterValue;
  onChange(args: { name: string; value: FilterValue }): void;
}

const FilterQueryWithOperators = ({
  field,
  value: initValue,
  onChange,
}: FilterQueryWithOperatorsProps) => {
  const { name, type, operators = [] } = field;

  const [value, setValue] = React.useState<Value>(initValue?.value);
  const [operator, setOperator] = React.useState<Operator>(
    initValue?.operator ??
      (operators.length > 0
        ? DEFAULT_OPERATORS[type] ?? DEFAULT_OPERATOR
        : undefined)
  );

  useEffectWithPrev(
    (prevInitValue) => {
      if (deepEqual(prevInitValue, initValue)) {
        return;
      }

      setOperator(
        initValue?.operator ??
          (operators.length > 0
            ? DEFAULT_OPERATORS[type] ?? DEFAULT_OPERATOR
            : undefined)
      );
      setValue(initValue?.value);
    },
    [initValue, type, operators, setOperator, setValue]
  );

  useEffectWithPrev(
    (prevOperator, prevValue) => {
      if (prevOperator === operator && deepEqual(prevValue, value)) {
        return;
      }

      const newValue =
        (!value && value !== 0) || (Array.isArray(value) && value.length === 0)
          ? undefined
          : operator
          ? { operator, value }
          : { value };

      if (deepEqual(initValue, newValue)) {
        return;
      }

      onChange({ name, value: newValue });
    },
    [operator, value, name, onChange, initValue]
  );

  return (
    <>
      <FilterQuery field={field} onChange={setValue} value={value} />
      <Radio
        className={styles.operators}
        aria-label="Operators"
        options={operators}
        onChange={(e) => setOperator(e.target.value)}
        value={operator}
      />
    </>
  );
};

export interface FilterQueryBuilderProps {
  fields: FilterProps['fields'];
  filters: Filter;
  onFilterContentChange(args: { name: string; value: string }): void;
}

const FilterQueryBuilder = ({
  fields,
  filters,
  onFilterContentChange,
}: FilterQueryBuilderProps) => {
  const onFieldChange = useStableCallback(({ name, value }) => {
    onFilterContentChange({ name, value });
  });

  return (
    <Tabs
      items={fields.map((field) => ({
        key: field.name,
        label: field.label ?? field.name,
        children: (
          <div className={styles.filterQueryContent}>
            <FilterQueryWithOperators
              field={field}
              onChange={onFieldChange}
              value={filters[field.name]}
            />
          </div>
        ),
      }))}
      tabPosition="left"
      aria-label="fields"
    />
  );
};

const FilterComponent = ({
  fields,
  searchTerm = '',
  searchTermKey = 'name',
  filters = {},
  savedFilters,
  filtersVisible = true,
  onFilterChange: propsOnFilterChange,
  initActiveFilterKey = FILTER_KEY_TOTAL,
  showTotal,
  totalItemCount,
  filteredItemCount,
  showSearchTerm = true,
  title,
}: FilterProps) => {
  const [filter, dispatchFilterAction] = React.useReducer(filterReducer, {
    searchTerm,
    searchTermKey,
    filters,
    activeFilterKey: initActiveFilterKey,
  });

  const onSearchTermChangeWrapper = React.useCallback((e) => {
    const { value } = e.target;
    dispatchFilterAction({
      type: 'SET_SEARCH_TERM',
      searchTerm: value,
    });
  }, []);

  const onFilterContentChange = React.useCallback(
    // prettier-align
    ({ name, value }) => {
      dispatchFilterAction({
        type: 'ADD_FILTER',
        field: name,
        value,
      });
    },
    []
  );

  const onRemoveFilter = React.useCallback((name) => {
    dispatchFilterAction({
      type: 'REMOVE_FILTER',
      field: name,
    });
  }, []);

  const clearFilter = React.useCallback(() => {
    dispatchFilterAction({
      type: 'CLEAR_FILTER',
    });
  }, []);

  const setFilters = React.useCallback(
    (newFilters: Filter<string>, newSearchTerm?: string) => {
      if (
        deepEqual(filter.filters, newFilters) &&
        filter.searchTerm === newSearchTerm
      ) {
        return;
      }
      dispatchFilterAction({
        type: 'SET_FILTERS',
        filters: newFilters,
        searchTerm: newSearchTerm,
      });
    },
    [filter.filters, filter.searchTerm]
  );

  useEffectWithPrev(
    (prevFilters, prevSearchTerm) => {
      if (!deepEqual(prevFilters, filters) || prevSearchTerm !== searchTerm) {
        setFilters(filters, searchTerm);
      }
    },
    [filters, searchTerm, setFilters]
  );

  const onFilterChange = React.useCallback(
    (f: FilterChangeArgs) => {
      if (f.activeFilterKey !== 'FILTER_TOTAL') {
        const savedFilter = savedFilters?.find(
          (s) => s.key === f.activeFilterKey
        );

        if (!deepEqual(f.filters, savedFilter?.filters)) {
          dispatchFilterAction({
            type: 'SET_ACTIVE_FILTER_KEY',
            activeFilterKey: 'FILTER_TOTAL',
          });
        }
      }

      propsOnFilterChange(f);
    },
    [propsOnFilterChange, savedFilters]
  );
  const debouncedOnFilterChange = useDebouncedCallback(onFilterChange, 100);

  useEffectWithPrev(
    (prevFilter) => {
      if (
        deepEqual(prevFilter?.filters, filter.filters) &&
        prevFilter?.searchTerm === filter.searchTerm &&
        prevFilter?.searchTermKey === filter.searchTermKey &&
        prevFilter?.activeFilterKey === filter.activeFilterKey
      ) {
        return;
      }

      const shouldDebounce =
        prevFilter?.activeFilterKey === filter.activeFilterKey;
      const fn = shouldDebounce ? debouncedOnFilterChange : onFilterChange;

      fn({
        activeFilterKey: filter.activeFilterKey,
        filters: filter.filters,
        searchTerm: filter.searchTerm,
        searchTermKey: filter.searchTermKey,
      });
    },
    [filter, debouncedOnFilterChange, onFilterChange]
  );

  const filterItems = React.useMemo(() => {
    if (!showTotal) {
      return savedFilters ?? [];
    }

    const totalVisible =
      filter.activeFilterKey !== FILTER_KEY_TOTAL ||
      (filter.searchTerm === '' &&
        Object.values(filter.filters).every((f) => {
          const { value: v } = f ?? {};
          return (!v && v !== 0) || (Array.isArray(v) && v.length === 0);
        }));

    return [
      {
        key: FILTER_KEY_TOTAL,
        isTotal: true,
        title: totalVisible ? 'Total' : 'Results',
        itemCount: (totalVisible ? totalItemCount : filteredItemCount) ?? null,
        searchTerm: totalVisible ? '' : undefined,
        filters: totalVisible ? {} : undefined,
      },
      ...(savedFilters ?? []),
    ];
  }, [
    savedFilters,
    totalItemCount,
    filteredItemCount,
    showTotal,
    filter.activeFilterKey,
    filter.searchTerm,
    filter.filters,
  ]);

  const setActiveFilterKey = React.useCallback(
    (activeFilterKey: string) => {
      const filterItem = filterItems.find((f) => f.key === activeFilterKey);
      const newFilters = filterItem?.filters;
      const newSearchTerm = filterItem?.searchTerm;

      dispatchFilterAction({
        type: 'SET_ACTIVE_FILTER_KEY',
        activeFilterKey,
      });

      if (newFilters || newSearchTerm) {
        setFilters(newFilters ?? {}, newSearchTerm);
      }
    },
    [filterItems, setFilters]
  );

  return (
    <div>
      {filterItems.length > 0 && (
        <SavedFiltersComponent
          filters={filterItems}
          value={filter.activeFilterKey}
          onChange={setActiveFilterKey}
        />
      )}

      {filtersVisible && (
        <div className={styles.container}>
          {title && <div className={styles.title}>{title}</div>}
          {showSearchTerm && (
            <Input
              placeholder="Search Claim Name"
              onChange={onSearchTermChangeWrapper}
              value={filter.searchTerm}
            />
          )}
          <Filters
            fields={fields}
            filters={filter.filters}
            onRemoveFilter={onRemoveFilter}
          />
          <div className={styles.filterControls}>
            <PopoverAntd
              content={
                <FilterQueryBuilder
                  fields={fields}
                  filters={filter.filters}
                  onFilterContentChange={onFilterContentChange}
                />
              }
              aria-label="Add filter"
              placement="bottomRight"
            >
              <Button type="link">+ Add Filter</Button>{' '}
            </PopoverAntd>
            <Button type="text" onClick={clearFilter}>
              Clear Filters
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
