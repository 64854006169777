import { nanoid } from '@reduxjs/toolkit';
import { call, delay, race, put } from 'typed-redux-saga';
import { createLogger, ILogger } from '@owl-lib/logger';

import { ASYNC_TIMEOUT_MS } from '@owl-lib/util';
import { __DEV__ } from '../../constants';
import { miniSerializeError, RejectWithValue } from '../../rtk';
import type { CaseSaga } from '../../sagaToolkit/interface';
import type {
  ActionDefWithMetaWrapped,
  ActionResult,
  ActionWithMeta,
  CasePreparer,
  PrepareActionAny,
  SliceActionCreators,
  SliceCases,
  _SliceCases,
} from '../interface';
import type {
  AsyncActionMeta,
  AsyncCaseSaga,
  AsyncSagaConfig,
  AsyncSagaOptions,
  AsyncSagaStatusActionPreparers,
  GetRejectValue,
  GetSerializedErrorType,
} from './interface';

const defaultLogger = createLogger('redux:slice:async');
const noop = Function.prototype as (args: any) => void;
const voidLogger: ILogger = {
  debug: noop,
  error: noop,
  fatal: noop,
  info: noop,
  isLevelEnabled: () => false,
  level: 'void',
  name: 'void',
  trace: noop,
  warn: noop,
};

type RejectWithValueAPI = <T>(value: T, cause?: Error) => RejectWithValue<T>;
export const rejectWithValue: RejectWithValueAPI = (value, cause) =>
  new RejectWithValue(value, cause);

export function createAsyncActionPrepare<
  ActionDef,
  // eslint-disable-next-line @typescript-eslint/ban-types
  SagaApiConfig extends AsyncSagaConfig = {}
>(
  inner: CasePreparer<ActionDef>,
  options?: AsyncSagaOptions<SagaApiConfig>
): ActionDefWithMetaWrapped<ActionDef, AsyncActionMeta> {
  let prepare: ((...args: any) => { meta: any; payload: any }) & {
    inner?: CasePreparer<ActionDef>;
  };
  if (typeof inner === 'function') {
    type PrepareFn = Extract<typeof inner, PrepareActionAny<any>>;
    prepare = (...args: Parameters<PrepareFn>) => {
      const requestId = (options?.idGenerator ?? nanoid)();
      type Prepared = { payload: any; meta?: any };
      const prepared: Prepared = (inner as PrepareFn)(...(args as any[]));
      if (__DEV__) {
        if (!prepared) {
          throw new Error('prepareAction did not return an object');
        }
        const { meta } = prepared;
        if (meta === null || (meta !== undefined && typeof meta !== 'object')) {
          throw new Error('prepareAction.meta did not return an object');
        }
        if (meta.requestId) {
          throw new Error('prepareAction.meta must not return "requestId"');
        }
      }
      return {
        ...prepared,
        meta: { ...prepared.meta, requestId },
      };
    };
  } else if (inner === 'auto') {
    prepare = (arg: { payload: any; meta?: any; error?: any }) => {
      const requestId = (options?.idGenerator ?? nanoid)();
      if (__DEV__) {
        if (!arg) {
          throw new Error('action creator argument is not an object');
        }
        const { meta } = arg;
        if (meta === null || (meta !== undefined && typeof meta !== 'object')) {
          throw new Error('action creator argument.meta is not an object');
        }
        if (meta.requestId) {
          throw new Error(
            'action creator argument.meta must not have "requestId"'
          );
        }
      }
      return { ...arg, meta: { ...arg.meta, requestId } };
    };
  } else {
    prepare = (payload: any) => {
      const requestId = (options?.idGenerator ?? nanoid)();
      return { payload, meta: { requestId } };
    };
  }
  prepare.inner = inner;
  return prepare as ActionDefWithMetaWrapped<ActionDef, AsyncActionMeta>;
}

export function createAsyncStatusCases<
  ActionDef,
  // eslint-disable-next-line @typescript-eslint/ban-types
  SagaApiConfig extends AsyncSagaConfig = {},
  ActionType extends string = string
>(
  options?: AsyncSagaOptions<SagaApiConfig>
): SliceCases<
  any,
  AsyncSagaStatusActionPreparers<
    any,
    ActionResult<ActionDef, ActionType>,
    SagaApiConfig
  >,
  ActionType
> {
  type SagaAction = ActionResult<ActionDef, ActionType>;
  type StatusCases = _SliceCases<
    any,
    AsyncSagaStatusActionPreparers<any, SagaAction, SagaApiConfig>,
    string
  >;

  const fulfilled: StatusCases['fulfilled'] = {
    prepare: (result, requestId, { payload }) => ({
      payload: result,
      meta: { arg: payload, requestId, requestStatus: 'fulfilled' },
    }),
  };
  const pending: StatusCases['pending'] = {
    prepare: (requestId, { payload }) => ({
      payload: undefined,
      meta: { arg: payload, requestId, requestStatus: 'pending' },
    }),
  };
  const rejected: StatusCases['rejected'] = {
    prepare: (error, requestId, { payload }) => {
      type RejectedValue = GetRejectValue<SagaApiConfig>;
      const aborted = !!error && error.name === 'AbortError';
      const condition = !!error && error.name === 'ConditionError';

      const isRejectWithValue = error instanceof RejectWithValue;
      return {
        payload: isRejectWithValue
          ? (error as RejectWithValue<RejectedValue>).payload
          : undefined,
        error: (options?.serializeError || miniSerializeError)(
          (isRejectWithValue && (error as RejectWithValue).cause) ||
            error ||
            'Rejected'
        ) as GetSerializedErrorType<SagaApiConfig>,
        meta: {
          arg: payload,
          requestId,
          rejectedWithValue: isRejectWithValue,
          requestStatus: 'rejected' as const,
          aborted,
          condition,
        },
      };
    },
  };
  return { fulfilled, pending, rejected };
}

function* asyncSagaTimeoutWrapper({
  requestId,
  saga,
  action,
  logger,
  timeoutLimit = ASYNC_TIMEOUT_MS,
}) {
  if (timeoutLimit <= 0) {
    return yield* call(saga, action, { rejectWithValue });
  }

  const { result, timeout } = yield race({
    result: call(saga, action, { rejectWithValue }),
    timeout: delay(timeoutLimit),
  });

  if (timeout) {
    const timeoutError = new Error('Async saga timed out!');
    logger.error(
      '[%s] Rejected %s: %s',
      requestId,
      action.type,
      (__DEV__ && timeoutError.stack) || timeoutError.message
    );
    throw timeoutError;
  }

  return result;
}

export function createAsyncSaga<
  Returned,
  ActionDef,
  // eslint-disable-next-line @typescript-eslint/ban-types
  SagaApiConfig extends AsyncSagaConfig = {},
  ActionType extends string = string
>(
  statusActions: SliceActionCreators<
    AsyncSagaStatusActionPreparers<
      any,
      ActionResult<ActionDef, ActionType>,
      SagaApiConfig
    >,
    ActionType
  >,
  saga: AsyncCaseSaga<
    ActionResult<ActionDef, ActionType>,
    Returned,
    SagaApiConfig
  >,
  options: AsyncSagaOptions<SagaApiConfig> = {}
): CaseSaga<ActionResult<ActionDef, ActionType>> & {
  inner: typeof saga;
} {
  type AsyncSaga = CaseSaga<ActionResult<ActionDef, ActionType>> & {
    inner?: typeof saga;
  };
  const loggerOpt = options.logger;
  const logger =
    loggerOpt == null || loggerOpt === true
      ? defaultLogger
      : loggerOpt || voidLogger;
  const timeoutLimit = options?.timeoutLimit;
  const asyncSaga: AsyncSaga = function* (action) {
    const { requestId }: AsyncActionMeta = (
      action as ActionWithMeta<typeof action, AsyncActionMeta>
    ).meta;
    if (__DEV__ && !requestId) {
      throw new Error('Action passed to async saga missing requestId');
    }
    try {
      yield put(statusActions.pending(requestId, action));

      const result = yield call(asyncSagaTimeoutWrapper, {
        saga,
        action,
        requestId,
        logger,
        timeoutLimit,
      });

      if (result instanceof RejectWithValue) {
        yield put(statusActions.rejected(result, requestId, action));
      } else {
        yield put(statusActions.fulfilled(result, requestId, action));
      }
    } catch (rawErr) {
      let err = rawErr;
      if (
        __DEV__ &&
        !(err instanceof Error || err instanceof RejectWithValue)
      ) {
        logger.warn('Non error used as rejection! %o', { err });
        err = new Error(`Async action error ${String(err)}`);
      }
      logger.error(
        '[%s] Rejected %s: %s',
        requestId,
        action.type,
        (__DEV__ && err.stack) || err.message
      );
      yield put(statusActions.rejected(err as Error, requestId, action));
    }
  };
  asyncSaga.inner = saga;
  return asyncSaga as AsyncSaga & Required<AsyncSaga>;
}
