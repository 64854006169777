import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { all, call, SagaGenerator } from 'typed-redux-saga';
import {
  dossiersApiClient,
  investigationsApiClient,
  HTTPError,
  uploadsApiClient,
} from '@owl-frontend/api-client';
import type {
  Dossier,
  ImportDossier,
  SaveDossiersBatchResponse,
  TopologyTarget,
  UploadReportRequest,
} from '@owl-frontend/api-client/interface';
import { PRETTY_DATE_TIME_FORMAT } from '@owl-frontend/components';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import dossiersImportSlice, { UploadReportProps } from './interface';

dayjs.extend(utc);
dayjs.extend(timezone);

const UUID_PREFIX_REGEX =
  /^([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}_+)+/;
const DATE_POSTFIX_REGEX =
  /_(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z).csv$/;

export type SaveDossierErrorResponse = {
  data: {
    existingClientDossierIds: string[];
    message: string;
  };
  clientDossierIds: string[];
};

export interface SaveDossierSuccessResponse extends SaveDossiersBatchResponse {
  clientDossierIds: string[];
}

const ROW_THRESHOLD = 300;

const chunkImportData = (inputData) => {
  const editedChunk: any[] = [];
  for (let i = 0; i < inputData.length; i += ROW_THRESHOLD) {
    const chunk = inputData.slice(i, i + ROW_THRESHOLD);
    editedChunk.push(chunk);
  }
  return editedChunk;
};

function* saveABatch(
  batchData,
  uploadId: string,
  tenantId: string,
  topology: TopologyTarget,
  investigationId: string | null
) {
  const clientDossierIds = batchData.map((item) => item.clientDossierId);
  try {
    const response = yield* call(
      dossiersApiClient.dossiers.saveBatch,
      uploadId,
      tenantId,
      // FIXME: force all dossiers to long-term-disability type
      'long-term-disability',
      batchData,
      topology,
      investigationId
    );
    const responseWithDossierIds: SaveDossierSuccessResponse = {
      ...response,
      clientDossierIds,
    };
    return responseWithDossierIds;
  } catch (error) {
    const responseWithDossierIds: SaveDossierErrorResponse = {
      ...error,
      clientDossierIds,
    };
    return responseWithDossierIds;
  }
}

function* saveDossiersBatch(action: {
  payload: {
    uploadId: string;
    tenantId: string;
    dossierType?: Dossier['dossierType'];
    topology: TopologyTarget;
    data: ImportDossier[];
    isReinvestigation?: boolean;
  };
}) {
  const { uploadId, tenantId, topology, data } = action.payload;
  const chunkedData = chunkImportData(data);

  const { investigationId } = yield* call(
    investigationsApiClient.investigations.create,
    tenantId,
    'upload',
    topology
  );

  const result = yield* all(
    chunkedData.map((chunkData) =>
      call(saveABatch, chunkData, uploadId, tenantId, topology, investigationId)
    )
  );

  const numCreated = result.reduce(
    (acc, curr) => (acc = acc + ('numCreated' in curr ? curr.numCreated : 0)),
    0
  );

  yield* call(
    investigationsApiClient.investigations.update,
    investigationId,
    numCreated
  );

  return result;
}

export function renderUtcDateTime(value: string): string {
  return `${dayjs(value)
    .utc()
    .tz('America/New_York')
    .format(PRETTY_DATE_TIME_FORMAT)} (EST)`;
}

function* sendUploadReport(action: {
  payload: UploadReportProps &
    Pick<UploadReportRequest, 'filename' | 'tenantId'>;
}): SagaGenerator<void> {
  const {
    tenantId,
    emails,
    cc,
    bcc,
    numCreated,
    duplicateIds,
    invalidRows,
    filename,
  } = action.payload;

  return yield* call(uploadsApiClient.uploads.sendUploadReport, {
    tenantId,
    emails,
    cc,
    bcc,
    filename: filename
      .replace(UUID_PREFIX_REGEX, '')
      .replace(DATE_POSTFIX_REGEX, ''),
    uploadTime: yield* call(
      renderUtcDateTime,
      DATE_POSTFIX_REGEX.exec(filename)?.[1] ?? new Date().toISOString()
    ),
    numCreated,
    numInvalid: invalidRows.length,
    duplicateIds,
    invalidRowsCsv: invalidRows.reduce(
      (acc, curr) => {
        const { data, messages } = curr;
        for (const message of messages ?? []) {
          const path = message.split(' ')[0];
          acc.push([
            `${data?.clientDossierId ?? ''}`,
            path,
            message.substring(path.length + 1),
          ]);
        }
        return acc;
      },
      [['ID', 'Error Path', 'Error']]
    ),
  });
}

const slice = dossiersImportSlice.addAsyncSagas(
  {
    saveDossiersBatch,
    reinvestigations: invoke(dossiersApiClient.dossiers.reinvestigations),
    sendUploadReport,
  },
  {
    serializeError: (args) => {
      const { error } = args as unknown as { error: HTTPError };
      return error;
    },
    timeoutLimit: -1,
  }
);

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
