import React from 'react';
import clsx from 'clsx';
import { Layout, Tabs, type TabsProps } from 'antd';
import ClaimsAssistDocumentComponent, {
  type Props as DocumentProps,
} from './components/ClaimsAssistDocumentComponent';
import styles from './ClaimsAssistDocumentViewerComponent.module.scss';

export interface Props extends React.PropsWithChildren {
  containerClassName?: string;
  tabItems?: TabsProps['items'];
  siderWidth?: number | string;
  documentProps: DocumentProps;
}

const DEFAULT_SIDER_WIDTH = 600;

const ClaimsAssistDocumentViewerComponent: React.FC<Props> = ({
  containerClassName,
  tabItems,
  siderWidth = DEFAULT_SIDER_WIDTH,
  documentProps,
  children,
}) => {
  return (
    <Layout
      className={clsx(styles.container, containerClassName)}
      hasSider={!!(children || tabItems)}
    >
      <Layout.Content>
        <ClaimsAssistDocumentComponent {...documentProps} />
      </Layout.Content>
      {(children || tabItems) && (
        <Layout.Sider
          className={styles.containerSider}
          theme="light"
          width={siderWidth}
        >
          {children}
          {tabItems && <Tabs className={styles.tabs} items={tabItems} />}
        </Layout.Sider>
      )}
    </Layout>
  );
};

export default ClaimsAssistDocumentViewerComponent;
