import dayjs from 'dayjs';
import { getIn, setIn, update, updateIn } from 'timm';
import type {
  Insight,
  DecideOnInsightRequest,
  ClaimsDocumentDeprecated,
  ClaimListItem,
} from '@owl-frontend/api-client';
import { sleepMillis } from '@owl-lib/util';
import { USER_NAME } from '../components/ClaimDetailsComponent/ClaimsInsightsComponent/ClaimsInsightsComponent';

type LocalDemoDossier = {
  lastReviewed?: string;
  acceptedInsights: string[];
  examiner?: string;
  impact: [];
  insights: {
    [insightId: string]: Pick<
      Insight,
      'insightId' | 'feedback' | 'decision' | 'title'
    >;
  };
  reviewedDocuments: {
    [docId: string]: ClaimsDocumentDeprecated['isReviewed'];
  };
  documentsToReview: {
    [docTitle: string]: boolean;
  };
  latestInvestigation: string;
};
type LocalDemoData = {
  unreviewedDossierIds: string[];
  user: {
    username: string;
    userType: 'manager' | 'examiner';
  };
  dossiers: {
    [dossierId: string]: LocalDemoDossier;
  };
};

const initDossierIds = {
  'aaaf1588-9ef3-11ec-aed1-a76b8df35e26': {
    unreviewedDossierIds: ['dave-johnson-17', 'bernard-smith-2'],
    user: {
      username: 'Julie Smith',
      userType: 'examiner',
    },
    dossiers: {
      'dave-johnson-17': {
        documentsToReview: {
          'Annual Financial Inquiry.pdf': true,
          'APS#4-3.pdf': true,
        },
      },
    },
  },
  demo: {
    dossiers: {
      // dave johnson
      'dave-johnson-16': {
        documentsToReview: {
          'Attending Physician Statement #4.pdf': true,
        },
      },
      // susan wilson
      'dave-johnson-12': {
        documentsToReview: {
          'Occupational Assessment.pdf': true,
        },
      },
      // steven wu
      'dave-johnson-20': {
        documentsToReview: {
          'Nurse review.pdf': true,
        },
      },
      // michael brown
      'dave-johnson-8': {
        documentsToReview: {
          'Operative Report.pdf': true,
        },
      },
      // clara wagner
      'clara-wagner-1': {
        documentsToReview: {
          'Attending Physician Statement #1.pdf': true,
        },
      },
      // joyce williams
      'joyce-williams-1': {
        documentsToReview: {
          'Attending Physician Statement #2.pdf': true,
        },
      },
    },
  },
  'demo-siu': {
    unreviewedDossierIds: ['dave-johnson-siu-1'],
    user: {
      username: 'Janice Thomas',
      userType: 'manager',
    },
    dossiers: {
      'eric-davis-siu-1': {
        lastReviewed: '2023-10-24T08:00:00.000Z',
        acceptedInsights: ['Physical capability'],
        impact: 'No Impact',
        examiner: 'Tiffany Sullivan',
      },
      'clara-wagner-siu-1': {
        lastReviewed: '2023-10-31T08:00:00.000Z',
        acceptedInsights: ['Promotion', 'New Job'],
        impact: 'In Progress',
        examiner: 'Tiffany Sullivan',
      },
      'susan-wilson-siu-1': {
        lastReviewed: '2023-10-25T08:00:00.000Z',
        acceptedInsights: ['Death'],
        impact: 'Termination',
        examiner: 'Ruben Wallace',
      },
      'michael-brown-siu-1': {
        lastReviewed: '2023-10-25T08:00:00.000Z',
        acceptedInsights: ['Rental Income'],
        impact: 'In Progress',
        examiner: 'Ruben Wallace',
      },
      'joyce-williams-siu-1': {
        lastReviewed: '2023-10-23T08:00:00.000Z',
        acceptedInsights: ['Fundraising activities'],
        impact: 'Adjustment',
        examiner: 'Julie Smith',
      },
      'scott-parker-siu-1': {
        lastReviewed: '2023-10-10T08:00:00.000Z',
        acceptedInsights: ['Physical capability'],
        impact: 'In Progress',
        examiner: 'Julie Smith',
      },
      'brian-hernandez-siu-1': {
        lastReviewed: '2023-10-10T08:00:00.000Z',
        acceptedInsights: ['Not Investigated'],
        impact: 'Not Investigated',
        examiner: 'Julie Smith',
      },
    },
  },
  'demo-wc': {
    dossiers: {
      // deborah smith
      'wc-deborah-smith-1': {
        documentsToReview: {
          'Office Visit Note.pdf': true,
          'PT Order.pdf': true,
        },
      },
      // jessica dubois
      'wc-jessica-dubois-1': {
        documentsToReview: {
          'Orthopedic Follow Up 8.31.2023.pdf': true,
        },
      },
      // gerald castillo
      'wc-gerald-castillo-1': {
        documentsToReview: {
          'Medical Note.pdf': true,
        },
      },
      // maria martinez
      'wc-maria-martinez-1': {
        documentsToReview: {
          'First Notice of Loss.pdf': true,
        },
      },
      // sophia hernandez
      'wc-sophia-hernandez-1': {
        documentsToReview: {
          'DWC1 FNOL.pdf': true,
        },
      },
      // fatima brown
      'wc-fatima-brown-1': {
        documentsToReview: {
          'Form 102 Physicans Report of Injury.pdf': true,
        },
      },
    },
  },
};

const demoDataKey = (tenantId: string) => `claimsAssistDemo/${tenantId}`;

export function isSiuTenant(tenantId: string) {
  return {
    'demo-siu': true,
  }[tenantId];
}

export const IS_DEMO = true;

export const DEFAULT_USER: {
  username: string;
  userType: 'examiner' | 'manager';
} = { username: 'Julie Smith', userType: 'examiner' };
export const EXAMINERS: {
  username: string;
  userType: 'examiner' | 'manager';
}[] = [
  { username: 'Julie Smith', userType: 'examiner' },
  { username: 'Tiffany Sullivan', userType: 'examiner' },
  { username: 'Ruben Wallace', userType: 'examiner' },
];
export const MANAGER: { username: string; userType: 'examiner' | 'manager' }[] =
  [{ username: 'Janice Thomas', userType: 'manager' }];

export function isDemoTenant() {
  return IS_DEMO;
}

export function isDemoManagerTenant(tenantId: string) {
  return (
    tenantId == 'demo-siu' || tenantId == 'aaaf1588-9ef3-11ec-aed1-a76b8df35e26'
  );
}
export function getLocalDemoData(tenantId: string): LocalDemoData {
  const json = localStorage.getItem(demoDataKey(tenantId));
  return json ? JSON.parse(json) : {};
}

export function getLocalDemoUser(tenantId: string) {
  if (!isDemoManagerTenant(tenantId)) {
    return DEFAULT_USER;
  }
  return getLocalDemoData(tenantId)?.user ?? DEFAULT_USER;
}

export async function setLocalDemoData(tenantId: string, data: LocalDemoData) {
  await sleepMillis(100);

  if (!data) {
    return data;
  }

  localStorage.setItem(demoDataKey(tenantId), JSON.stringify(data));
  return data;
}

export async function resetLocalDemoData(tenantId: string) {
  await sleepMillis(100);
  const initData = initDossierIds[tenantId];

  if (!initData) {
    localStorage.removeItem(demoDataKey(tenantId));
    return;
  }

  localStorage.setItem(demoDataKey(tenantId), JSON.stringify(initData));
  return initData;
}

export async function switchLocalDemoUser(
  tenantId: string,
  username: string,
  userType: 'manager' | 'examiner'
) {
  await sleepMillis(100);
  let initData: LocalDemoData = initDossierIds[tenantId];
  let filteredDossiers = initData.dossiers;
  if (userType !== 'manager') {
    filteredDossiers = Object.fromEntries(
      Object.entries(initData.dossiers).filter(
        ([, dossier]: [string, LocalDemoDossier]) =>
          dossier.examiner === username
      )
    );
  }

  initData = { ...initData, dossiers: filteredDossiers };
  if (!initData) {
    localStorage.removeItem(demoDataKey(tenantId));
    return;
  }

  initData.user = { username, userType };
  localStorage.setItem(demoDataKey(tenantId), JSON.stringify(initData));
  window.location.reload();
  return initData;
}

export async function setInsight(insight: DecideOnInsightRequest) {
  const {
    tenantId,
    dossierId,
    insightId,
    title,
    feedback,
    decision,
    addToTimeline,
  } = insight;

  const demoData = getLocalDemoData(tenantId) ?? {};
  let updated = setIn(
    demoData,
    ['dossiers', dossierId, 'insights', insightId],
    {
      insightId,
      title,
      feedback,
      decision,
      feedbackUpdatedAt: new Date().toISOString(),
      addToTimeline,
    }
  ) as LocalDemoData;

  if (decision === 'accepted') {
    updated = updateIn(
      updated,
      ['dossiers', dossierId, 'acceptedInsights'],
      (acceptedInsights?: string[]) =>
        acceptedInsights
          ? [...acceptedInsights, insight.title]
          : [insight.title]
    ) as LocalDemoData;
  }

  await setLocalDemoData(tenantId, updated);

  return insight;
}

export async function setDocumentTitlesToReview(data: {
  docTitles: string[];
  dossierId: string;
  tenantId: string;
}) {
  const { docTitles, dossierId, tenantId } = data;
  let updated = getLocalDemoData(tenantId) ?? {};

  for (const docTitle of docTitles) {
    updated = setIn(
      updated,
      ['dossiers', dossierId, 'documentsToReview', docTitle],
      true
    ) as LocalDemoData;
  }

  await setLocalDemoData(tenantId, updated);
}

export async function setLastReviewedDate(data: {
  dossierId: string;
  tenantId: string;
}) {
  const { dossierId, tenantId } = data;
  let updated = getLocalDemoData(tenantId) ?? {};
  updated = setIn(
    updated,
    ['dossiers', dossierId, 'lastReviewed'],
    new Date().toISOString()
  ) as LocalDemoData;
  await setLocalDemoData(tenantId, updated);
  return updated;
}

export async function setDocumentTitlesToReviewed(data: {
  docTitles: string[];
  dossierId: string;
  tenantId: string;
}) {
  const { docTitles, dossierId, tenantId } = data;
  let updated = getLocalDemoData(tenantId) ?? {};

  for (const docTitle of docTitles) {
    updated = setIn(
      updated,
      ['dossiers', dossierId, 'documentsToReview', docTitle],
      false
    ) as LocalDemoData;
  }

  await setLocalDemoData(tenantId, updated);
}

export async function markDocumentAsReviewed(data: {
  docId: string;
  dossierId: string;
  tenantId: string;
  nextAction?: string;
  note?: string;
}) {
  const { docId, dossierId, tenantId, nextAction, note } = data;
  const demoData = getLocalDemoData(tenantId) ?? {};
  const updated = setIn(
    demoData,
    ['dossiers', dossierId, 'reviewedDocuments', docId],
    {
      nextAction,
      reviewerName: USER_NAME,
      reviewDate: dayjs().format('MMM D, YYYY — h:mmA'),
      note,
    }
  ) as LocalDemoData;

  await setLocalDemoData(tenantId, updated);
}

export function getNextUnreviewedDossierId(tenantId: string) {
  const { unreviewedDossierIds } = getLocalDemoData(tenantId);
  return unreviewedDossierIds?.[0];
}

export async function markClaimAsReviewed(data: {
  tenantId: string;
  dossierId: string;
}) {
  const { tenantId, dossierId } = data;
  const demoData = getLocalDemoData(tenantId);
  let updated = update(
    demoData,
    'unreviewedDossierIds',
    (dossierIds: string[]) => dossierIds?.filter((id) => id !== dossierId) ?? []
  ) as LocalDemoData;

  updated = setIn(
    updated,
    ['dossiers', dossierId, 'lastReviewed'],
    new Date().toISOString()
  ) as LocalDemoData;

  return await setLocalDemoData(tenantId, updated);
}

export function overrideLocalInsights(
  tenantId: string,
  dossierId: string,
  payloadInsights: Insight[]
) {
  const localInsights =
    getLocalDemoData(tenantId)?.dossiers?.[dossierId]?.insights;
  return payloadInsights.map((insight) => {
    const localInsight = localInsights?.[insight.insightId];
    const exclude = ['decision', 'feedback', 'feedbackUpdatedAt'];
    const payloadInsight = Object.entries(insight).reduce((acc, [k, v]) => {
      return exclude.includes(k) ? acc : { ...acc, [k]: v };
    }, {});

    if (localInsight) {
      return { ...payloadInsight, ...localInsight };
    }

    return payloadInsight;
  }) as Insight[];
}

export function overrideLocalDocuments(
  tenantId: string,
  dossierId: string,
  payloadDocuments: ClaimsDocumentDeprecated[]
) {
  const reviewedDocuments = getIn(getLocalDemoData(tenantId), [
    'dossiers',
    dossierId,
    'reviewedDocuments',
  ]) as LocalDemoData['dossiers'][string]['reviewedDocuments'];

  return payloadDocuments.map((d) => ({
    ...d,
    isReviewed: reviewedDocuments?.[d.docId],
  }));
}

export function overrideLocalClaims(
  tenantId: string,
  payloadClaims: ClaimListItem[]
) {
  const localClaims = getLocalDemoData(tenantId)?.dossiers;

  return payloadClaims.map((claim) => {
    const localClaim = localClaims?.[claim.dossierId];
    const exclude = ['lastReviewed'];
    const payloadClaim = Object.entries(claim).reduce((acc, [k, v]) => {
      return exclude.includes(k) ? acc : { ...acc, [k]: v };
    }, {});

    if (localClaim) {
      return { ...payloadClaim, ...localClaim };
    }

    return payloadClaim;
  });
}

export async function setLatestInvestigation(
  tenantId: string,
  dossierId: string
) {
  const demoData = getLocalDemoData(tenantId) || {};
  const updated = setIn(
    demoData,
    ['dossiers', dossierId, 'latestInvestigation'],
    new Date().toISOString()
  ) as LocalDemoData;
  await setLocalDemoData(tenantId, updated);
}

export function getLatestInvestigation(tenantId: string, dossierId: string) {
  return getLocalDemoData(tenantId)?.dossiers?.[dossierId]?.latestInvestigation;
}
