import { createAction } from '@reduxjs/toolkit';
import type { EmptyOr } from '@owl-lib/type-util';
import { __DEV__ } from '../constants';
import { identity, resolveType } from '../helpers';
import type {
  ActionCreatorDef,
  AnySliceCase,
  CasePreparer,
  CasePreparers,
  MergeDef,
  SliceActionCreators,
  SliceCase,
  _SliceActionCreators,
  _SliceCases,
} from './interface';
import type {
  ConfigurableSlice,
  ConfigurableSliceInfo,
  ConfigurableSliceMembers,
} from './slice';

/**
 * A utility function to create an action creator for the given action type
 * string, using a shorthand of `true`, `'auto'` or a prepare method.
 *
 * @param prepare
 *   * `true` (or omitted): the action creator accepts a single argument, which
 *     will be included in the action object as a field called `payload`.
 *   * `'auto'`: the action creator accepts a single argument - an object with
 *     the props `{ payload, meta?, error? }` will be included in the action
 *     object as fields called `payload`, `meta` and `error`, respectively.
 *   * a method that takes any number of arguments and returns an object: the
 *     resulting action creator will pass its arguments to this method, and the
 *     resulting action will include the `payload`, `meta` and `error` from the
 *     object.
 *
 * The action creator function will also have its toString() overriden so that
 * it returns the action type, allowing it to be used in reducer logic that is
 * looking for that action type.
 *
 * @see createAction
 */
export function createActionFromDef<ActionDef, Type extends string = string>(
  type: string,
  prepare: CasePreparer<ActionDef>
): ActionCreatorDef<ActionDef, Type> {
  const prepareCallback =
    typeof prepare === 'function'
      ? (prepare as Extract<typeof prepare, (...args: any) => any>)
      : prepare === 'auto'
      ? identity
      : undefined;
  return (
    prepareCallback ? createAction(type, prepareCallback) : createAction(type)
  ) as ActionCreatorDef<ActionDef, Type>;
}

type AddActionsResult<State, Name extends string, ActionsDef> = EmptyOr<
  Pick<ConfigurableSliceMembers<State, Name, ActionsDef>, 'actions' | 'cases'>
>;

export function addActions<ActionsDef, DefNext>(
  slice: ConfigurableSliceInfo<any, any, ActionsDef>,
  preparers: CasePreparers<DefNext>
): AddActionsResult<any, any, MergeDef<ActionsDef, DefNext>> {
  type Cur = ConfigurableSliceInfo<any, string, ActionsDef>;
  type NextActionsDef = MergeDef<ActionsDef, DefNext>;
  type Next = ConfigurableSlice<any, string, NextActionsDef>;

  const actionKeys = Object.keys(preparers);
  if (actionKeys.length === 0) return {};

  let newCases = false;
  const actions = { ...slice.actions } as Cur['actions'] & Next['actions'];
  const cases: Record<string, AnySliceCase> = { ...slice.cases };
  for (const actionKey of actionKeys) {
    const prepare: CasePreparer<any> =
      preparers[actionKey as keyof typeof preparers];
    const oldCase = cases[actionKey] as SliceCase<any, any> | undefined;
    if (oldCase) {
      if (__DEV__ && oldCase.prepare !== prepare) {
        throw new Error(
          `Action ${actionKey} already defined as ${oldCase.prepare}`
        );
      }
      continue;
    }
    newCases = true;
    cases[actionKey] = { prepare };
    actions[actionKey] = createActionFromDef<any>(
      resolveType(slice.name, actionKey),
      prepare
    );
  }
  return newCases
    ? { actions, cases: cases as Cur['cases'] & Next['cases'] }
    : {};
}

export function createActionsFromCases<ActionsDef, SliceName extends string>(
  sliceName: SliceName,
  cases: _SliceCases<any, ActionsDef, SliceName>
): SliceActionCreators<ActionsDef, SliceName> {
  const actions = {} as _SliceActionCreators<ActionsDef, SliceName>;
  type ActionKey = Extract<keyof typeof cases, string>;
  for (const actionKey of Object.keys(cases) as ActionKey[]) {
    const actionCase = cases[actionKey];
    // Attempting to assign string to type `${SliceName}/${Extract<Extract<keyof ActionsDef, string>, string>}`
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    actions[actionKey] = createActionFromDef(
      resolveType(sliceName, actionKey),
      actionCase.prepare
    );
  }
  return actions as SliceActionCreators<ActionsDef, SliceName>;
}
