import { usersApiClient } from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import usersSlice from './interface';

const slice = usersSlice
  .addAsyncSagas({
    listUsers: invoke(usersApiClient.users.list),
    updateUser: invoke(usersApiClient.users.updateUser),
  })
  .addReducers({
    'listUsers/fulfilled': (state, action) => {
      state.users = action.payload;
    },
    'updateUser/fulfilled': (state, action) => {
      state.users = state.users.map((user) => {
        if (action.meta.arg.username === user.username) {
          return { ...user, roles: action.payload.roles };
        }

        return user;
      });
    },
  })
  .addReducers({
    clearItems: (state) => {
      state.users = [];
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
