import React, { ReactNode } from 'react';
import clsx from 'clsx';
import type { SizeProps } from '../../shared/interface';
import styles from './Icon.module.scss';

type Color =
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'primary'
  | 'text'
  | 'grey'
  | 'white'
  | 'red';

export interface Props extends SizeProps {
  color?: Color;
  textColor?: Color;
  text?: string;
  textClassName?: string;
  children: ReactNode;
  className?: string;
  textPosition?: 'left' | 'right';
}

const Icon = ({
  size,
  color,
  textColor,
  text,
  textClassName,
  children,
  className,
  textPosition = 'right',
}: Props) => {
  const renderIcon = React.useCallback(
    () => (
      <span
        className={clsx(
          styles.icon,
          size === 'small' && styles.iconSmall,
          size === 'medium' && styles.iconMedium,
          size === 'large' && styles.iconLarge,
          color && `has-text-${color}`,
          className
        )}
      >
        {children}
      </span>
    ),
    [size, color, className, children]
  );

  const renderText = React.useCallback(() => {
    return <span className={textClassName}>{text}</span>;
  }, [text, textClassName]);

  if (!text) {
    return renderIcon();
  }

  return (
    <span
      className={clsx(
        styles.iconText,
        color && !textColor && `has-text-${color}`,
        textColor && `has-text-${textColor}`
      )}
    >
      {text && textPosition === 'left' && renderText()}
      {renderIcon()}
      {text && textPosition === 'right' && renderText()}
    </span>
  );
};

export default Icon;
