import React from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { actions as localizationSliceActions } from '../data/localization/logic';
import type { StoreState } from '../data/store';
import { EventBusProvider } from './event-bus';

interface Props extends React.PropsWithChildren {
  Router?: React.ComponentType;
}
const ContextProvider: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { Router = BrowserRouter } = props;
  const { messages, locale } = useSelector(
    (s: StoreState) => s['localization']
  );

  React.useEffect(() => {
    dispatch(localizationSliceActions.fetchMessages({ locale }));
  }, [locale]);

  return (
    <EventBusProvider>
      <Router>
        <IntlProvider messages={messages} locale={locale} defaultLocale="en">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#1d211c',
                colorPrimaryHover: '#60646c',
                colorPrimaryBorderHover: ' #60646c',
              },
              components: {
                Button: {
                  fontWeight: 500,
                  borderRadius: 4,
                },
              },
            }}
          >
            {props.children}
          </ConfigProvider>
        </IntlProvider>
      </Router>
    </EventBusProvider>
  );
};
export default ContextProvider;
