import React from 'react';
import createEventEmitter, { Emitter, Handler } from 'mitt';

const globalBus = createEventEmitter();
export const BusContext = React.createContext(globalBus);

export function EventBusProvider({ children }) {
  const [bus] = React.useState(() => createEventEmitter());
  return <BusContext.Provider value={bus}>{children}</BusContext.Provider>;
}

type EmptyObject = Record<never, unknown>;
function useBus<Events extends EmptyObject>(): Emitter<Events> {
  return React.useContext(BusContext) as Emitter<Events>;
}

function useListener<T>(name: string, fn: Handler<T>) {
  const bus = useBus<Record<string, any>>();
  React.useEffect(() => {
    bus.on(name, fn);
    return () => {
      bus.off(name, fn);
    };
  }, [bus, name, fn]);
}

function useEmitter<T>(name: string) {
  const bus = useBus<Record<string, any>>();
  return React.useCallback<Handler<T>>(
    (event) => bus.emit(name, event),
    [name, bus]
  );
}

function usePublishState(name: string, value: any) {
  const bus = useBus<Record<string, any>>();
  React.useEffect(() => {
    // send on every update
    bus.emit(name, value);
  });
}

function useSubscribeState<T>(name: string, initialValue?: T | (() => T)): T {
  const [value, setValue] = React.useState(initialValue);
  useListener<T>(name, setValue);
  return value as T;
}

// TODO: use a globally published event type to avoid conflicts
// ie, `declare global { interface OwlEventBusEvents { ... }}`

/** Event Bus Hooks with typings bound to the given event map */
export type TypedEventBus<Events extends EmptyObject> = {
  useBus(): Emitter<Events>;
  useListener<Key extends keyof Events>(
    type: Key,
    handler: Handler<Events[Key]>
  ): void;
  useEmitter<Key extends keyof Events>(type: Key): Handler<Events[Key]>;
  usePublishState<Key extends keyof Events>(
    name: Key,
    value: Events[Key]
  ): void;
  useSubscribeState<Key extends keyof Events>(
    name: Key,
    initialValue: Events[Key] | (() => Events[Key])
  ): Events[Key];
  useSubscribeState<Key extends keyof Events>(
    type: undefined extends Events[Key] ? Key : never
  ): Events[Key];
};

/**
 * @example
 * import eventBusHooks, { TypedEventBus } from '../context/event-bus';
 * const events: TypedEventBus<{
 *   myEvent: { value: string }
 * }> = eventBusHooks;
 * ...
 *   events.useListener('myEvent', React.useCallback((event) => {
 *     dispatch(actions.thing(event));
 *   }))
 * @example
 * type EB = TypedEventBus<{
 *   myEvent: { value: string }
 * }>;
 * ...
 *   (useListener as EB['useListener'])(...)
 */
export default {
  useBus,
  useListener,
  useEmitter,
  usePublishState,
  useSubscribeState,
};
export { useBus, useListener, useEmitter, usePublishState, useSubscribeState };
