import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { FalconRole } from './interface';

const logger = createLogger(__filename);

const rolesApiClient = {
  roles: {
    list: async (): Promise<FalconRole[]> => {
      const url = new URL(`/api/v1/roles`, baseURL);
      logger.trace('rolesApiClient.roles.list');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
};

export default rolesApiClient;
