import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import type { FieldValidator } from 'final-form';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// TODO: merge with regex definitions in core-lib/validate -- and use those
// string format validators here.

// checked safe with https://makenowjust-labo.github.io/recheck/
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const postalCodeRegex = /^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)$/;
const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/; // eslint-disable-line security/detect-unsafe-regex
const ssnRegex = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/;
const allowedUrlProtocolsRegex = /^(https?|ftp):\/\//i;

// const minLengthRe = /\{\s*minLength\s*\}/g;
// const maxLengthRe = /\{\s*maxLength\s*\}/g;
// const targetLengthRe = /\{\s*targetLength\s*\}/g;
// const compareValueRe = /\{\s*compareValue\s*\}/g;

const DEFAULT_ERROR = 'Invalid!';

export const combineValidators =
  (validators: ReadonlyArray<FieldValidator<any>>): FieldValidator<any> =>
  (value, allValues, meta) =>
    validators.reduce((acc, validator) => {
      return acc || validator(value, allValues, meta);
    }, undefined as string | undefined);

export const required =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: number | boolean | { length?: unknown }): undefined | string => {
    const error = errorMessage ?? 'Required!';
    if ((!value && value !== 0) || (value as string | any[]).length === 0) {
      return error;
    }
    // value && typeof value === 'string' ? value.trim().length > 0 ? undefined : errorMessage || 'Required!'
    if (typeof value === 'string') {
      return value.trim().length > 0 ? undefined : error;
    }

    return undefined;
  };

export const regex =
  ({ pattern, errorMessage }: { pattern: RegExp; errorMessage?: string }) =>
  (value?: { length?: unknown }): undefined | string => {
    if (!value) {
      return undefined;
    }
    return value.length === (value as string).match(pattern)?.join('').length
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;
  };

export const minLength =
  ({
    lengthValue,
    errorMessage,
    trim = false,
  }: {
    lengthValue: number;
    errorMessage?: string;
    trim?: boolean;
  }) =>
  (value?: string): undefined | string =>
    !value || lengthValue <= (trim ? value.trim() : value).length
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const maxLength =
  ({
    lengthValue,
    errorMessage,
    trim = false,
  }: {
    lengthValue: number;
    errorMessage?: string;
    trim?: boolean;
  }) =>
  (value?: string): undefined | string =>
    !value || lengthValue >= (trim ? value.trim() : value).length
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const isLength =
  ({
    lengthValue,
    errorMessage,
  }: {
    lengthValue: number;
    errorMessage?: string;
  }) =>
  (value?: number | { length: number }): undefined | string => {
    if (typeof value === 'number') {
      value = String(value);
    }
    return !value || value.length === lengthValue
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;
  };

export const notEmpty =
  ({ errorMessage }: { errorMessage?: string } = { errorMessage: undefined }) =>
  (values?: any): undefined | string =>
    values && values.length > 0 ? undefined : errorMessage ?? 'Required!';

export const equality =
  ({
    compareValue,
    errorMessage,
  }: {
    compareValue: any;
    errorMessage?: string;
  }) =>
  (value?: any): undefined | string =>
    value === compareValue ? undefined : errorMessage || 'Values must match!';

export const notEqual =
  ({
    compareValue,
    errorMessage,
  }: {
    compareValue: any;
    errorMessage?: string;
  }) =>
  (value?: any): undefined | string =>
    value !== compareValue
      ? undefined
      : errorMessage ?? `Input cannot equal to: ${compareValue}`;

export const isInteger =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: unknown): undefined | string =>
    !value || Number.isInteger(Number(value))
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const lessThan =
  ({
    compareValue,
    errorMessage,
  }: {
    compareValue: any;
    errorMessage?: string;
  }) =>
  (value?: unknown): undefined | string =>
    typeof value === 'undefined' || value === '' || Number(value) < compareValue
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const lessEqualThan =
  ({
    compareValue,
    errorMessage,
  }: {
    compareValue: any;
    errorMessage?: string;
  }) =>
  (value?: unknown): undefined | string =>
    typeof value === 'undefined' ||
    value === '' ||
    Number(value) <= compareValue
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const greaterThan =
  ({
    compareValue,
    errorMessage,
  }: {
    compareValue: any;
    errorMessage?: string;
  }) =>
  (value?: unknown): undefined | string =>
    typeof value === 'undefined' || value === '' || Number(value) > compareValue
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const greaterEqualThan =
  ({
    compareValue,
    errorMessage,
  }: {
    compareValue: any;
    errorMessage?: string;
  }) =>
  (value?: unknown): undefined | string =>
    typeof value === 'undefined' ||
    value === '' ||
    Number(value) >= compareValue
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const email =
  ({ errorMessage }: { errorMessage?: string } = { errorMessage: undefined }) =>
  (value: string): undefined | string =>
    emailRegex.exec(value) ? undefined : errorMessage ?? DEFAULT_ERROR;

// compares within closest day
export const isSameOrBeforeDate =
  ({
    compareToValue,
    getCompareValue,
    errorMessage,
  }: {
    /** Ignored if `getCompareValue` is defined */
    compareToValue?: Date | dayjs.Dayjs;
    getCompareValue?: (allValues: {
      [k: string]: any;
    }) => Date | dayjs.Dayjs | undefined;
    errorMessage?: string;
  }) =>
  (
    value: dayjs.Dayjs | undefined,
    allValues: { [k: string]: any }
  ): undefined | string =>
    !value ||
    !(typeof getCompareValue === 'function'
      ? (compareToValue = getCompareValue(allValues))
      : compareToValue) ||
    dayjs(value).isSameOrBefore(dayjs(compareToValue), 'day')
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

// compares within closest day
export const isSameOrAfterDate =
  ({
    compareToValue,
    getCompareValue,
    errorMessage,
  }: {
    /** Ignored if `getCompareValue` is defined */
    compareToValue?: Date | dayjs.Dayjs;
    getCompareValue?: (allValues: {
      [k: string]: any;
    }) => Date | dayjs.Dayjs | undefined;
    errorMessage?: string;
  }) =>
  (
    value: dayjs.Dayjs | undefined,
    allValues: { [k: string]: any }
  ): undefined | string =>
    !value ||
    !(typeof getCompareValue === 'function'
      ? (compareToValue = getCompareValue(allValues))
      : compareToValue) ||
    dayjs(value).isSameOrAfter(compareToValue!, 'day')
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const postalCode =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: string): undefined | string =>
    !value || postalCodeRegex.exec(value)
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const zipCode =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: string): undefined | string =>
    !value || zipCodeRegex.exec(value)
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const zipCodeOrPostalCode =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: string): undefined | string =>
    !value || zipCodeRegex.exec(value) || postalCodeRegex.exec(value)
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;

export const ssn =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: string): undefined | string =>
    !value || ssnRegex.exec(value) ? undefined : errorMessage ?? DEFAULT_ERROR;

export const fileSize =
  ({ errorMessage, sizeLimit }: { errorMessage?: string; sizeLimit: number }) =>
  (value?: File): undefined | string => {
    if (!value || !value.size) {
      return undefined;
    }

    return value.size / 1024 / 1024 < sizeLimit
      ? undefined
      : errorMessage ?? DEFAULT_ERROR;
  };

export const notIn =
  ({ errorMessage, values }: { errorMessage?: string; values?: string[] }) =>
  (value?: string): undefined | string => {
    const error = errorMessage ?? 'Must be unique';
    if (value && values?.indexOf(value) !== -1) {
      return error;
    }
    return undefined;
  };

export const isUrl =
  ({ errorMessage }: { errorMessage?: string } = {}) =>
  (value?: string): undefined | string => {
    if (!value) {
      return undefined;
    }

    const error =
      errorMessage ??
      'Invalid URL! Is it missing a scheme (https://, or http://)?';
    if (!value.match(allowedUrlProtocolsRegex)) {
      return error;
    }

    try {
      const dummy = new URL(value);
    } catch (_) {
      return error;
    }
  };
