// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pages-claims-admin-components-ClaimsAdminDocComponent-__sider--CUOwg {\n  margin: 1rem;\n  width: 100%;\n  overflow: auto;\n}\n\n.pages-claims-admin-components-ClaimsAdminDocComponent-__section--6spwo {\n  margin-bottom: 1rem;\n}\n\n.pages-claims-admin-components-ClaimsAdminDocComponent-__fullHeight--Lysr9 {\n  height: 100%;\n}\n\n.pages-claims-admin-components-ClaimsAdminDocComponent-__errorField--oZ4FW {\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/claims-admin/components/ClaimsAdminDocComponent/ClaimsAdminDocComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,cAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".sider {\n  margin: 1rem;\n  width: 100%;\n  overflow: auto;\n}\n\n.section {\n  margin-bottom: 1rem;\n}\n\n.fullHeight {\n  height: 100%;\n}\n\n.errorField {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sider": "pages-claims-admin-components-ClaimsAdminDocComponent-__sider--CUOwg",
	"section": "pages-claims-admin-components-ClaimsAdminDocComponent-__section--6spwo",
	"fullHeight": "pages-claims-admin-components-ClaimsAdminDocComponent-__fullHeight--Lysr9",
	"errorField": "pages-claims-admin-components-ClaimsAdminDocComponent-__errorField--oZ4FW"
};
export default ___CSS_LOADER_EXPORT___;
