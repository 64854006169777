import React from 'react';
import { Image, Spin } from 'antd';
import type {
  ClaimsDocumentDeprecated,
  MatchAnswer,
} from '@owl-frontend/api-client/interface';
import { StoreState, useSelector } from '../../../../data/store';
import { useDocumentViewer } from '../../ClaimsHooks';
import { TXT_TO_PDF_FILES, getPdfFileName } from '../../data/logic';
import PDFDocumentViewer from '../DocumentViewerComponent/components/PDFDocumentViewer/PDFDocumentViewer';
import TextDocumentViewer from '../DocumentViewerComponent/components/TextDocumentViewer/TextDocumentViewer';
import pdfStyles from './components/PDFDocumentViewer/PDFDocumentViewer.module.scss';

export enum FILETYPE {
  PDF = 'pdf',
  TXT = 'txt',
  JPEG = 'jpeg',
}

export interface Props {
  dossierId: string;
  document?: ClaimsDocumentDeprecated;
  documentMatches?: MatchAnswer[];
  initialPage?: number;
  onChangePage?(num: number): void;
  highlight?: [number, number, number, number];
}

const DocumentComponent: React.FC<Props> = ({
  dossierId,
  document,
  documentMatches = [],
  initialPage = 1,
  onChangePage,
  highlight,
}) => {
  const [{ loading }, useDocumentViewerHandler] = useDocumentViewer();

  const { content, fileType } = useSelector(
    (s: StoreState) => s['claims']?.documentContent
  );

  React.useEffect(() => {
    if (!document) {
      return;
    }
    useDocumentViewerHandler.onLoadMatchDocument(dossierId, document);
  }, [document, dossierId, useDocumentViewerHandler]);

  const documentUrl = React.useMemo(() => {
    if (!dossierId || !document) {
      return;
    }

    const fileName = encodeURIComponent(
      getPdfFileName(document.contentRawRef?.split('/').pop() ?? '')
    );

    return `/api/v1/claim-documents/dossiers/${dossierId}/documents/${document.docId}?docName=${fileName}`;
  }, [dossierId, document]);

  if (loading) {
    return (
      <Spin spinning={true}>
        <div className={pdfStyles.documentLoading} />
      </Spin>
    );
  }

  if (!document || !content) {
    return null;
  }

  if (
    TXT_TO_PDF_FILES.includes(document.docName) ||
    fileType === FILETYPE.PDF
  ) {
    return (
      <PDFDocumentViewer
        documentPresignedUrl={content}
        initialPage={initialPage}
        onChangePage={onChangePage}
        documentMatches={documentMatches}
        highlight={highlight}
        documentUrl={documentUrl}
      />
    );
  }

  if (fileType === FILETYPE.TXT) {
    return (
      <TextDocumentViewer content={content} documentMatches={documentMatches} />
    );
  }

  if (fileType === FILETYPE.JPEG) {
    return <Image width={500} src={content} />;
  }

  return null;
};

export default DocumentComponent;
