import React from 'react';
import { useDispatch } from 'react-redux';
import type { InsightsConfig } from '@owl-frontend/api-client/interface';
import { StoreState, useSelector } from '../../../../../data/store';
import { actions as claimsAdminActions } from '../../../data/logic';

export interface ClaimsAdminInsightsConfigState {
  insightsConfigs: InsightsConfig[];
  createStatus?: string;
  updateStatus?: string;
  deleteStatus?: string;
}

export interface UseClaimsAdminInsightsConfigHandler {
  getConfigs(data: { tenantId: string });
  updateConfig(data: { tenantId: string; insightsConfig: InsightsConfig });
  createConfig(data: { tenantId: string; insightsConfig: InsightsConfig });
  deleteConfig(data: { tenantId: string; insightsConfigId: string });
}

export function useClaimsAdminInsightsConfigs(
  tenantId
): [ClaimsAdminInsightsConfigState, UseClaimsAdminInsightsConfigHandler] {
  const dispatch = useDispatch();
  const insightsConfigs = useSelector(
    (s: StoreState) => s['claims-admin'].insightsConfigs
  );
  const updateInsightsConfigAction = useSelector(
    (s: StoreState) => s['claims-admin'].actions.updateClaimsAdminInsightsConfig
  );
  const createInsightsConfigAction = useSelector(
    (s: StoreState) => s['claims-admin'].actions.createClaimsAdminInsightsConfig
  );
  const deleteInsightsConfigAction = useSelector(
    (s: StoreState) => s['claims-admin'].actions.deleteClaimsAdminInsightsConfig
  );

  const handler = React.useMemo<UseClaimsAdminInsightsConfigHandler>(
    () => ({
      getConfigs: () => {
        dispatch(
          claimsAdminActions.fetchClaimsAdminInsightsConfig({
            tenantId,
          })
        );
      },
      updateConfig: (data: {
        tenantId: string;
        insightsConfig: InsightsConfig;
      }) => {
        dispatch(
          claimsAdminActions.updateClaimsAdminInsightsConfig({
            tenantId: data.tenantId,
            insightsConfig: data.insightsConfig,
          })
        );
      },
      createConfig: (data: {
        tenantId: string;
        insightsConfig: InsightsConfig;
      }) => {
        dispatch(
          claimsAdminActions.createClaimsAdminInsightsConfig({
            tenantId: data.tenantId,
            insightsConfig: data.insightsConfig,
          })
        );
      },
      deleteConfig: (data: { tenantId: string; insightsConfigId: string }) => {
        dispatch(
          claimsAdminActions.deleteClaimsAdminInsightsConfig({
            tenantId: data.tenantId,
            insightsConfigId: data.insightsConfigId,
          })
        );
      },
    }),
    [tenantId]
  );

  React.useEffect(() => {
    if (!tenantId) {
      return;
    }

    handler.getConfigs({ tenantId });
  }, [tenantId, handler]);

  return [
    {
      insightsConfigs: tenantId ? insightsConfigs[tenantId] ?? [] : [],
      updateStatus: updateInsightsConfigAction.status,
      createStatus: createInsightsConfigAction.status,
      deleteStatus: deleteInsightsConfigAction.status,
    },
    handler,
  ];
}
