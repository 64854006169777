import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { ClaimsDocument, ClaimsMLConfig } from '../interface';
import type { InsightsConfig } from './interface';

const logger = createLogger(__filename);

const claimsAssistApiClient = {
  insightsConfig: {
    get: async (data: {
      tenantId: string;
    }): Promise<{ insightsConfigs: InsightsConfig[] }> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/insights-configs`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.insightsConfigs.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    update: async (data: {
      tenantId: string;
      insightsConfig: InsightsConfig;
    }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/insights-configs/${data.insightsConfig.id}`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.insightsConfigs.put()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify(data.insightsConfig),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    create: async (data: {
      tenantId: string;
      insightsConfig: InsightsConfig;
    }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/insights-configs`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.insightsConfigs.post()');
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id: _, ...body } = data.insightsConfig;
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify(body),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    delete: async (data: { tenantId: string; insightsConfigId: string }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/insights-configs/${data.insightsConfigId}`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.insightsConfigs.delete()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
  },
  errorDocuments: {
    get: async (data: {
      tenantId: string;
    }): Promise<{ documents: ClaimsDocument[] }> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/error-documents`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.errorDocuments.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  mlConfig: {
    get: async (data: { tenantId: string }): Promise<ClaimsMLConfig> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/ml-configs`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.mlConfig.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    update: async (data: {
      tenantId: string;
      mlDetails: Partial<ClaimsMLConfig>;
    }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claims-assist/ml-configs`,
        baseURL
      );
      logger.trace('claimsAssistAdminApiClient.mlConfig.update()');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ updatedConfig: data.mlDetails }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default claimsAssistApiClient;
