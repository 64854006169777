import type { FalconRole, User } from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export type UserPermission = {
  [key in FalconRole]?: boolean;
};
export interface FalconUser extends User {
  permission?: UserPermission;
}
export interface SessionState {
  current?: FalconUser;
  actions: {
    current: AsyncActionState;
    logFrontendEvents: AsyncActionState<void>;
    logout: AsyncActionState<void>;
    refresh: AsyncActionState<void>;
  };
}

const initialState: SessionState = {
  actions: {
    current: { status: 'uninitialized' },
    logFrontendEvents: { status: 'uninitialized' },
    logout: { status: 'uninitialized' },
    refresh: { status: 'uninitialized' },
  },
};

const sessionSlice = setupSlice('session', initialState);
export default sessionSlice;
