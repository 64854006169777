// TODO: set up react ssr
// TODO: optimize core-js/tslib/regenerator-runtime/objectFitPolyfill loading conditionally in webpack config

import 'antd/dist/reset.css';

// Avoid importing more modules than necessary here;
// init base and lazy-load components as needed
import React from 'react';
import { render } from 'react-dom';
// polyfill
import 'setimmediate';

import AppContainer from './context/AppContainer/AppContainer';
import AppProvider from './context/AppProvider';
import ReduxProvider from './context/ReduxProvider';
import store from './data/store';
import './styles/index.scss';

const root = document.getElementById('falcon-app-root');

render(
  <React.StrictMode>
    <ReduxProvider reduxStore={store.reduxStore}>
      <AppProvider>
        <AppContainer />
      </AppProvider>
    </ReduxProvider>
  </React.StrictMode>,
  root
);
