import type { FalconRole } from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface RolesState {
  roles: FalconRole[];
  actions: {
    listRoles: AsyncActionState;
  };
}

const initialState: RolesState = {
  roles: [],
  actions: {
    listRoles: { status: 'uninitialized' },
  },
};

const rolesSlice = setupSlice('roles', initialState);
export default rolesSlice;
