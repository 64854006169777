import { createLogger } from '@owl-lib/logger';
import type { ReservationResponse } from '../dossiers/interface';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  BatchReservations,
  FindingsCompletedTask,
  LabellingCompletedTask,
  TaskCount,
  ReserveBatchItemResponse,
  ReservedItem,
  Topology,
  WorkerData,
} from './interface';

const logger = createLogger(__filename);

const workersApiClient = {
  workers: {
    getNextTask: async (data: {
      tkey: string;
      taskKey?: string;
    }): Promise<ReservationResponse> => {
      const url = new URL(`/api/v1/workers/topologies/reservations`, baseURL);
      logger.trace('workersApiClient.workers.getNextItem()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getNextBatch: async (data: {
      tkey: string;
    }): Promise<BatchReservations> => {
      const url = new URL(
        `/api/v1/workers/topologies/batch-reservations/reserve`,
        baseURL
      );
      logger.trace('workersApiClient.workers.getNextBatch()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    reserveNextBatchItem: async (data: {
      tkey: string;
      batch: ReservedItem[];
    }): Promise<ReserveBatchItemResponse> => {
      const url = new URL(
        `/api/v1/workers/topologies/batch-reservations/reserve-item`,
        baseURL
      );
      logger.trace('workersApiClient.workers.reserveNextBatchItem()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    completeTask: async (
      data: FindingsCompletedTask | LabellingCompletedTask
    ): Promise<ReservationResponse> => {
      const url = new URL(
        `/api/v1/workers/topologies/reservations/${data.recordId}/complete`,
        baseURL
      );
      logger.trace('workersApiClient.workers.tasks.complete()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    releaseTask: async (data: {
      recordId: string;
      tenantId: string;
      tkey: string;
      taskKey: string;
    }): Promise<ReservationResponse> => {
      const url = new URL(
        `/api/v1/workers/topologies/reservations/${data.recordId}/release`,
        baseURL
      );
      logger.trace('workersApiClient.workers.tasks.release()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getTopologies: async (data?: {
      showHidden?: boolean;
    }): Promise<Topology[]> => {
      const url = new URL('/api/v1/workers/topologies', baseURL);
      if (data?.showHidden) {
        url.searchParams.set('show-hidden', `${data.showHidden}`);
      }
      logger.trace('workersApiClient.workers.getTopologies()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getTaskCount: async (data: { username: string }): Promise<TaskCount[]> => {
      const url = new URL(
        `/api/v1/workers/task-count/${data.username}`,
        baseURL
      );
      logger.trace('workersApiClient.workers.getTaskCount()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },

    data: {
      get: async (data: {
        category: string;
        key: string;
      }): Promise<WorkerData> => {
        const url = new URL('/api/v1/workers/data', baseURL);

        url.searchParams.set('category', data.category);
        url.searchParams.set('key', data.key);

        logger.trace('workersApiClient.workers.data.get()');
        const response = await fetchWrapper(url.href, {
          method: 'GET',
          credentials: 'include',
        });
        logger.debug({ response }, `GET ${url.href}`);
        return deserialize(response);
      },
      save: async (data: {
        category: string;
        key: string;
        payload: WorkerData;
      }) => {
        const url = new URL(`/api/v1/workers/data`, baseURL);
        logger.trace('workersApiClient.workers.data.save()');
        const response = await fetchWrapper(url.href, {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        logger.debug({ response }, `GET ${url.href}`);
        return deserialize(response);
      },
    },
  } as const,
} as const;

export default workersApiClient;
