// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pages-claims-admin-components-ClaimsAdminMLConfigComponent-__mlConfigDetailsContainer--Sa\\+0N {\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n  gap: 8px;\n}\n\n.pages-claims-admin-components-ClaimsAdminMLConfigComponent-__editButton--YQOvj {\n  width: 50px;\n}", "",{"version":3,"sources":["webpack://./src/pages/claims-admin/components/ClaimsAdminMLConfigComponent/ClaimsAdminMLConfigComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,QAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".mlConfigDetailsContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n  gap: 8px;\n}\n\n.editButton {\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mlConfigDetailsContainer": "pages-claims-admin-components-ClaimsAdminMLConfigComponent-__mlConfigDetailsContainer--Sa+0N",
	"editButton": "pages-claims-admin-components-ClaimsAdminMLConfigComponent-__editButton--YQOvj"
};
export default ___CSS_LOADER_EXPORT___;
