import type { FalconSettings } from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface FalconSettingsState extends FalconSettings {
  actions: {
    fetch: AsyncActionState;
  };
}

const initialState: FalconSettingsState = {
  notes: {
    enabled: false,
    characterMaxLength: 400,
  },
  findings: {
    findingDescription: {
      characterMaxLength: 100_000,
      characterWarningThreshold: 50_000,
      characterDangerThreshold: 99000,
    },
  },
  topologies: [],
  actions: {
    fetch: { status: 'uninitialized' },
  },
};

const falconSettingsSlice = setupSlice('settings', initialState);
export default falconSettingsSlice;
