import { createAction, Reducer } from '@reduxjs/toolkit';
import { createLogger } from '@owl-lib/logger';
import { __DEV__ } from '../constants';
import { resolveType } from '../helpers';
import type { AnySliceCase, CaseReducers, MergeDef } from './interface';
import type { ConfigurableSliceInfo, ConfigurableSliceMembers } from './slice';

const logger = createLogger(__filename);

type AddReducersResult<State, Name extends string, ActionsDef> = Partial<
  Pick<ConfigurableSliceMembers<State, Name, ActionsDef>, 'actions' | 'cases'>
>;

export function addReducers<ActionsDef, DefNext>(
  slice: ConfigurableSliceInfo<any, any, ActionsDef>,
  caseReducers: Partial<CaseReducers<any, ActionsDef, any>> &
    CaseReducers<any, DefNext, any>
): AddReducersResult<any, any, MergeDef<ActionsDef, DefNext>> {
  type Cur = ConfigurableSliceInfo<any, string, ActionsDef>;
  type NextActionsDef = MergeDef<ActionsDef, DefNext>;
  type Next = ConfigurableSliceMembers<any, string, NextActionsDef>;

  const actionKeys = Object.keys(caseReducers);
  if (actionKeys.length === 0) {
    return {};
  }
  let newActions = false;
  const actions = { ...slice.actions } as Cur['actions'] & Next['actions'];
  const cases: Record<string, AnySliceCase> = { ...slice.cases };
  for (const actionKey of actionKeys) {
    const reducer = caseReducers[actionKey] as Reducer;
    const oldCase = cases[actionKey];
    if (oldCase?.reducer) {
      if (__DEV__ && oldCase.reducer !== reducer) {
        logger.warn(oldCase, `Reducer ${actionKey} already defined`);
        throw new Error(`Reducer ${actionKey} already defined`);
      }
      continue;
    }
    if (oldCase) {
      cases[actionKey] = { ...oldCase, reducer };
    } else {
      newActions = true;
      cases[actionKey] = { prepare: true, reducer };
      actions[actionKey] = createAction(resolveType(slice.name, actionKey));
    }
  }
  return (newActions ? { actions, cases } : { cases }) as Cur & Next;
}
