import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { InvestigationItem } from './interface';
import type { TopologyTarget } from '../interface';

const logger = createLogger(__filename);

const investigationsApiClient = {
  investigations: {
    create: async (
      tenantId: string,
      investigationType: string,
      topologyTarget: TopologyTarget
    ): Promise<InvestigationItem> => {
      const url = new URL(`/api/v1/investigations`, baseURL);
      logger.trace('investigationsApiClient.investigations.create()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          tenantId,
          investigationType,
          topologyTarget,
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    list: async (): Promise<InvestigationItem[]> => {
      const url = new URL(`/api/v1/investigations/active`, baseURL);
      logger.trace('investigationsApiClient.investigations.list()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    update: async (
      investigationId: string,
      uploadCount: number
    ): Promise<InvestigationItem> => {
      const url = new URL(`/api/v1/investigations/${investigationId}`, baseURL);
      logger.trace('investigationsApiClient.investigations.update()');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          uploadCount,
        }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default investigationsApiClient;
