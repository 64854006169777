import { kebabCase } from 'lodash';
import { createLogger } from '@owl-lib/logger';
import type { GetPresignedUploadResponse } from '../dossiers/interface';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  ApprovalResponse,
  StagedDossier,
  UploadItem,
  UploadReportRequest,
} from './interface';

const logger = createLogger(__filename);

export const UPLOAD_TIMEOUT_LIMIT = -1;

const uploadsApiClient = {
  dossiers: {
    uploadS3: async (data: {
      presigned: GetPresignedUploadResponse;
    }): Promise<void> => {
      const { url: presignedUrl, contentType, file, metadata } = data.presigned;

      const metadataHeaders = Object.fromEntries(
        Object.entries(metadata).map(([k, v]) => [
          `x-amz-meta-${kebabCase(k)}`,
          v,
        ])
      );

      const url = new URL(presignedUrl);
      const paramKeys = Array.from(url.searchParams.keys()).map((k) =>
        k.toLowerCase()
      );
      if (
        !url.hostname.endsWith('.amazonaws.com') ||
        paramKeys.indexOf('x-amz-signature') === -1
      ) {
        logger.error(data.presigned, 'Not a valid Presigned S3 URL');
        throw new Error('Not a valid Presigned S3 URL');
      }

      logger.trace('dossiersSlice.uploadS3File()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          headers: { ...metadataHeaders, 'content-type': contentType },
          body: await file.arrayBuffer(),
        },
        UPLOAD_TIMEOUT_LIMIT
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    uploadPart: async (data: {
      chunk: ArrayBuffer;
      partNumber: number;
      presignedUrl: string;
    }): Promise<{ partNumber: number; eTag: string }> => {
      const { chunk, partNumber, presignedUrl } = data;
      const url = new URL(presignedUrl);

      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: chunk,
        },
        UPLOAD_TIMEOUT_LIMIT
      );
      logger.debug({ response }, `PUT ${url.href}`);

      return Promise.resolve({
        partNumber,
        eTag: JSON.parse(response.headers.get('Etag')!),
      });
    },
  },
  uploads: {
    sendUploadReport: async (data: UploadReportRequest): Promise<void> => {
      const {
        tenantId,
        emails,
        cc = [],
        bcc = [],
        numCreated,
        numInvalid,
        duplicateIds,
        invalidRowsCsv,
        filename,
        uploadTime,
      } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/report`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          emails,
          cc,
          bcc,
          numUploaded: numCreated,
          numInvalid,
          duplicateIds,
          invalidRowsCsv,
          fileName: filename,
          uploadTime,
        }),
      });
      return deserialize(response);
    },
    sendUploadApproval: async (data: ApprovalResponse): Promise<void> => {
      const { tenantId, uploadId, ...rest } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/${uploadId}/approval`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ data: rest }),
      });
      return deserialize(response);
    },
    listUploads: async (data: { tenantId: string }): Promise<UploadItem[]> => {
      const { tenantId } = data;
      const url = new URL(`/api/v1/tenants/${tenantId}/uploads`, baseURL);
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
      });
      return deserialize(response);
    },
    getUpload: async (data: {
      tenantId: string;
      uploadId: string;
    }): Promise<UploadItem> => {
      const { tenantId, uploadId } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/${uploadId}`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
      });
      return deserialize(response);
    },
    updateUpload: async (data: {
      tenantId: string;
      uploadId: string;
      uploadItem: Partial<UploadItem>;
    }): Promise<UploadItem> => {
      const { tenantId, uploadId, uploadItem } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/${uploadId}`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ ...uploadItem }),
      });
      return deserialize(response);
    },
    listStagingDossiers: async (data: {
      tenantId: string;
      uploadId: string;
    }): Promise<{ dossiers: StagedDossier[]; lastCursor?: string }> => {
      const { tenantId, uploadId } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/${uploadId}/dossiers`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
      });
      return deserialize(response);
    },
    getStagingDossier: async (data: {
      tenantId: string;
      uploadId: string;
      dossierId: string;
    }): Promise<{ dossier: StagedDossier }> => {
      const { tenantId, uploadId, dossierId } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/${uploadId}/dossiers/${dossierId}`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
      });
      return deserialize(response);
    },
    updateStagingDossier: async (data: {
      tenantId: string;
      uploadId: string;
      dossierId: string;
      stagedDossier: StagedDossier;
    }): Promise<{ dossier: StagedDossier }> => {
      const { tenantId, uploadId, dossierId, stagedDossier } = data;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/uploads/${uploadId}/dossiers/${dossierId}`,
        baseURL
      );
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ ...stagedDossier }),
      });
      return deserialize(response);
    },
  },
};

export default uploadsApiClient;
