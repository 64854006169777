import type { OwlStoreOptions } from '@owl-frontend/redux';

const storeOptions: OwlStoreOptions = {
  serializableCheck: {
    ignoredActionPaths: [
      'meta.arg',
      'meta.blob',
      'payload.file',
      'payload.files',
      'payload.images',
      'payload.bus',
    ],
    ignoredPaths: [
      // these value is always inferred from other data and can be safely
      // omitted and then re-calculated if we need to hydrate
    ],
  },
  // clear out form flow "next" so that it doesnt flatten references and use
  // more memory than needed
  devtools: {},
};
export default storeOptions;
