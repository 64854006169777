// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-frontend-components-src-lib-Button-__override--gHEPh {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n._-_-frontend-components-src-lib-Button-__override--gHEPh._-_-frontend-components-src-lib-Button-__override--gHEPh._-_-frontend-components-src-lib-Button-__override--gHEPh .ant-btn-icon:not(:last-child) {\n  margin-inline-end: 4px;\n}", "",{"version":3,"sources":["webpack://./../frontend-components/src/lib/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAGM;EACE,sBAAA;AADR","sourcesContent":[".override {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &#{&}#{&} {\n    :global(.ant-btn-icon) {\n      &:not(:last-child) {\n        margin-inline-end: 4px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"override": "_-_-frontend-components-src-lib-Button-__override--gHEPh"
};
export default ___CSS_LOADER_EXPORT___;
