import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { Feedback } from './interface';

const logger = createLogger(__filename);

const feedbackApiClient = {
  feedback: {
    get: async (data: {
      username: string;
      before?: string;
      after?: string;
    }): Promise<Feedback> => {
      const url = new URL(`/api/v1/workers/feedback/qa`, baseURL);
      const currentDate = new Date();
      url.searchParams.set('username', data.username);
      // we only want data within the last month for dqas
      if (!data.before) {
        currentDate.setDate(currentDate.getDate() + 1);
        url.searchParams.set('before', currentDate.toISOString().split('T')[0]);
      } else {
        url.searchParams.set('before', data.before);
      }
      if (!data.after) {
        currentDate.setMonth(currentDate.getMonth() - 1);
        url.searchParams.set('after', currentDate.toISOString().split('T')[0]);
      } else {
        url.searchParams.set('after', data.after);
      }
      logger.trace('feedbackApiClient.feedback.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default feedbackApiClient;
