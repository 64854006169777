import type pino from 'pino';
import { assert } from 'ts-essentials';
import type { Loglevel } from './ILogger';

export function get(ob: any, pth: string[]): any {
  let o = ob;
  for (const p of pth ?? []) {
    if (o === null || typeof o !== 'object') {
      o = undefined;
      break;
    } else {
      o = o[p];
    }
  }
  return o;
}

export const redact = ['*.password', '*.apiKey', '*.key'];

export const levelMap: { [key in pino.Level]: pino.Level } & {
  [key: string]: pino.Level;
} = {
  fatal: 'error',
  error: 'error',
  wsError: 'error',
  warn: 'warn',
  wsResp: 'info',
  wsReq: 'info',
  log: 'info', // Not really used; just there for init
  info: 'info',
  verbose: 'info',
  redux: 'trace',
  debug: 'debug',
  trace: 'trace',
  silly: 'trace',
};

const MAX_LOG_ENTRIES = 12;
const MAX_DEPTH = 4;
const MAX_LOG_STRING = 1024;

export function abbrOb<T>(ob: T): T;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function abbrOb<T>(ob: T, depth = 0): T {
  if (typeof ob === 'string') {
    if (ob.length > MAX_LOG_STRING) {
      const n = Math.floor(MAX_LOG_STRING / 2);
      const diff = ob.length - MAX_LOG_STRING;
      return `${ob.substr(0, n)}«…(${diff}…)»${ob.substr(-n)}` as unknown as T;
    } else {
      return ob;
    }
  } else if (ob === null || typeof ob !== 'object') {
    return ob;
  }

  if (depth > MAX_DEPTH) {
    return (Array.isArray(ob)
      ? `«array(${ob.length})»`
      : `«object(${Object.keys(ob).length})»`) as unknown as T;
  }

  if (Array.isArray(ob)) {
    const len = Math.min(ob.length, MAX_LOG_ENTRIES);
    const copy = new Array(len);
    let changed = false;
    for (let i = 0; i < len; i++) {
      const oldVal = ob[i];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newVal = oldVal ? abbrOb(oldVal, depth + 1) : oldVal;
      copy[i] = newVal;
      changed = changed || newVal !== oldVal;
    }
    if (len < ob.length) {
      copy.push(`+«${ob.length - MAX_LOG_ENTRIES} more»`);
      changed = true;
    }
    return (changed ? copy : ob) as any;
  } else {
    const entries = Object.entries(ob);
    const len = Math.min(entries.length, MAX_LOG_ENTRIES);
    const copy: Partial<T> = {};
    let changed = false;
    for (let i = 0; i < len; i++) {
      const [key, oldVal] = entries[i];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newVal = oldVal ? abbrOb(oldVal, depth + 1) : oldVal;
      (copy as any)[key] = newVal;
      changed = changed || newVal !== oldVal;
    }

    if (len < entries.length) {
      (copy as any)['+'] = `«${entries.length - MAX_LOG_ENTRIES} more»`;
      changed = true;
    }
    return (changed ? copy : ob) as T;
  }
}

export const owlLogConfigKey = 'owlLogConfig' as const;

export interface LogOpts {
  level?: Loglevel;
  serviceName?: string;
  local?: boolean;
  pretty?: boolean;
  stream?: NodeJS.WritableStream;
}

const TRUNCATE_HEX_OVERHEAD = `<hex[1234]:…>`.length;
const TRUNCATE_B64_OVERHEAD = `<b64[1234]:…>`.length;
const TRUNCATE_GENERIC_OVERHEAD = `<string[1234]:…>`.length;
/** @internal */
export const MAX_BIN_STR_LEN = 64;
/** @internal */
export const MAX_STR_LEN = 1024 * (process.env.owl_server_local ? 16 : 1);

export function truncateHex<T>(input: T): T {
  if (
    !input ||
    typeof input !== 'string' ||
    input.length <= MAX_BIN_STR_LEN + TRUNCATE_HEX_OVERHEAD
  ) {
    return input;
  }

  if (!/^[a-fA-F0-9]+$/.test(input)) {
    return input;
  }

  return `<hex[${input.length}]:${input.substr(
    0,
    MAX_BIN_STR_LEN / 2
  )}...${input.substr(-MAX_BIN_STR_LEN / 2)}>` as unknown as T;
}

export function truncateB64<T>(input: T): T {
  if (
    !input ||
    typeof input !== 'string' ||
    input.length <= MAX_BIN_STR_LEN + TRUNCATE_B64_OVERHEAD
  ) {
    return input;
  }

  if (
    !/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(
      input
    )
  ) {
    return input;
  }

  return `<b64[${input.length}]:${input.substr(
    0,
    MAX_BIN_STR_LEN / 2
  )}...${input.substr(-MAX_BIN_STR_LEN / 2)}>` as unknown as T;
}

export function truncateStr<T>(input: T): T {
  if (
    !input ||
    typeof input !== 'string' ||
    input.length <= MAX_STR_LEN + TRUNCATE_GENERIC_OVERHEAD
  ) {
    return input;
  }

  return `<string[${input.length}]:${input.substr(
    0,
    MAX_STR_LEN / 2
  )}...${input.substr(-MAX_STR_LEN / 2)}>` as unknown as T;
}

const hasOwnProperty = Object.prototype.hasOwnProperty;

export function truncateObject<T>(o: T): T {
  return doTruncateObject(o, new Set());
}

/**
 * Special logger keys.
 *
 * Pino internals are handled separately: 'level', 'time', 'pid', 'hostname',
 * 'name', and 'msg'
 *
 * A few end up in the object but should never be stripped:
 */
const specialKeys = [
  'cause',
  'err',
  'hostname',
  'level',
  'message',
  'msg',
  'name',
  'pid',
  'stack',
  'threadId',
  'time',
  'type',
];
const specialKeySet = new Set(specialKeys);

/** @internal */
export const MAX_KEYS = 128 + specialKeys.length;
/** @internal */
export const MAX_ARRAY_LENGTH = 256;
assert(MAX_ARRAY_LENGTH > MAX_KEYS);

function doTruncateObject<T>(
  o: T,
  seen: Set<Exclude<any, string | number | boolean | null | undefined>>
): T {
  if (!o) {
    return o;
  }

  if (typeof o === 'string') {
    // Note: we must truncate hex *first*, since hex strings are also valid
    // b64 strings.
    return o.length >= MAX_STR_LEN
      ? truncateStr(truncateB64(truncateHex(o)))
      : o.length >= MAX_BIN_STR_LEN
      ? truncateB64(truncateHex(o))
      : o;
  } else if (typeof o !== 'object') {
    return o;
  }

  if (Array.isArray(o)) {
    const len = Math.min(o.length, MAX_ARRAY_LENGTH);
    const copy = new Array(len);
    let changed = len < o.length;
    for (let i = 0; i < len; i++) {
      const oldVal = o[i];
      const newVal = (copy[i] = doTruncateObject(oldVal, seen));
      changed ||= newVal !== oldVal;
    }
    return changed ? (copy as any as T) : o;
  } else if (seen.has(o)) {
    return '<cycle>' as any;
  } else {
    seen.add(o);

    const keys = Object.keys(o);
    const forceKeys =
      o instanceof Error ? ['type', 'message', 'stack', 'cause'] : [];
    for (const k of forceKeys) {
      if (!keys.includes(k)) keys.push(k);
    }

    if (keys.length <= MAX_KEYS) {
      const copy: any = {};
      let changed = false;
      for (const key in o) {
        if (hasOwnProperty.call(o, key)) {
          const oldVal = o[key];
          const newVal = (copy[key] = doTruncateObject(oldVal, seen));
          changed ||= newVal !== oldVal;
        }
      }
      return changed ? (copy as any as T) : o;
    } else {
      let useKeys = keys.slice(0, MAX_KEYS);
      const allKeysSet = new Set(keys);
      const useKeysSet = new Set(useKeys);
      for (const specialKey of specialKeys) {
        if (
          (allKeysSet.has(specialKey) || forceKeys.includes(specialKey)) &&
          !useKeysSet.has(specialKey)
        ) {
          useKeys.unshift(specialKey);
          useKeysSet.add(specialKey);
        }
      }

      // We might have too many keys through a combination of taking MAX_KEYS
      // and adding special keys. If so, let's strip keys until we're down to
      // the desired limit.
      //
      // It's easier to iterate from the front, but we'd rather strip later keys
      useKeys = useKeys.reverse();
      for (let i = 0; i < useKeys.length && useKeys.length > MAX_KEYS; ) {
        const key = useKeys[i];
        if (specialKeySet.has(key) || forceKeys.includes(key)) {
          // Keep this special key, move on
          i++;
        } else {
          useKeys.splice(i, 1);
        }
      }

      const copy: any = {};
      // Reverse again to get the original key order
      for (const k of useKeys.reverse()) {
        copy[k] = doTruncateObject((o as any)[k], seen);
      }

      const moreMsg = `+ ${keys.length - useKeys.length} more keys!!`;
      if (Array.isArray(copy)) {
        copy.push(moreMsg);
      } else {
        copy['...'] = moreMsg;
      }

      return copy;
    }
  }
}

export function getLoggerName(
  nameOrPath: string,
  suffix?: string | number | { [key: string]: string | number }
): string {
  if (/\.[jt]sx?$/i.test(nameOrPath)) {
    const m =
      /* eslint-disable redos/no-vulnerable */
      /.*@owl-\w+\/([^/]+)\/(.*)/.exec(nameOrPath) ??
      /.*(?:^|\/)packages\/([^/]+)\/(.*)/.exec(nameOrPath) ??
      /.*\/([^/]+)\/((?:src|test)\/.*)/.exec(nameOrPath);
    /* eslint-enable redos/no-vulnerable */
    if (m) {
      const [, pkg, rest] = m;
      nameOrPath = `${pkg}:${rest
        .replace(/^(src|lib)\//, '')
        .replace(/\.[jt]sx?/i, '')}`;
    }
  }

  if (typeof suffix === 'string' || typeof suffix === 'number') {
    nameOrPath += ` [${suffix}]`;
  } else if (suffix && typeof suffix === 'object') {
    nameOrPath += ` [${Object.keys(suffix)
      .map((k) => `${k}: ${suffix[k]}`)
      .join(', ')}]`;
  }

  return nameOrPath;
}
