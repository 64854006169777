import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { ClaimsDocument } from '@owl-frontend/api-client';
import { StoreState, useSelector } from '../../../data/store';
import { actions } from '../data/logic';

interface UseErrorDocumentState {
  getErrorDocuments(): void;
  errorDocuments?: ClaimsDocument[];
}

export function useErrorDocuments(tenantId?: string): UseErrorDocumentState {
  const dispatch = useDispatch();
  const errorDocuments = useSelector(
    (s: StoreState) => s['claims-admin'].errorDocuments
  );

  const getErrorDocuments = useCallback(() => {
    if (!tenantId) {
      return;
    }

    dispatch(actions.getErrorDocuments({ tenantId }));
  }, [dispatch, tenantId]);

  return {
    getErrorDocuments,
    errorDocuments: tenantId ? errorDocuments[tenantId] : undefined,
  };
}

export default useErrorDocuments;
