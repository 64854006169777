import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { Tooltip } from '@owl-frontend/components';

import styles from './ClaimsAdminInsightsConfigCardComponent.module.scss';

const ClaimsAdminInsightsConfigCardComponent = ({
  title,
  toolTipMessage,
  children,
}) => (
  <Card
    title={<b>{title}</b>}
    extra={
      <Tooltip title={toolTipMessage} overlayClassName={styles.infoOverlay}>
        <InfoCircleOutlined />
      </Tooltip>
    }
    className={styles.cardContainer}
  >
    {children}
  </Card>
);

export default ClaimsAdminInsightsConfigCardComponent;
