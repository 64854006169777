import React from 'react';
import clsx from 'clsx';
import { Radio } from 'antd';
import { useEffectWithPrev } from '../../../../shared/hooks';
import styles from './Filter.module.scss';

export interface Props {
  filters: any[];
  value?: string;
  onChange?(value: string): void;
}

const Filter = (props) => {
  const { title, itemCount, isTotal, checked } = props;

  return (
    <div
      className={clsx(
        styles.savedFilterItem,
        checked && styles.active,
        isTotal && styles.total
      )}
    >
      <div className={styles.count}>{itemCount ?? '-'}</div>
      <div className={styles.name}>{title}</div>
    </div>
  );
};

const SavedFiltersComponent = (props: Props) => {
  const { filters, value = 'FILTER_TOTAL', onChange } = props;
  const [selectedValue, setSelectedValue] = React.useState(value);

  useEffectWithPrev(
    (prevValue) => {
      if (prevValue !== value) {
        setSelectedValue(value);
      }
    },
    [value]
  );

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <Radio.Group
      aria-label="filters"
      onChange={handleRadioChange}
      value={selectedValue}
      className={styles.savedFilters}
    >
      {filters.map((filter) => (
        <label key={filter.key}>
          <Radio
            value={filter.key}
            className={styles.hiddenInput}
            data-testid={`filter--${filter.key}`}
          />
          <Filter {...filter} checked={selectedValue === filter.key} />
        </label>
      ))}
    </Radio.Group>
  );
};

export default SavedFiltersComponent;
