import React from 'react';
import {
  ExportOutlined,
  LeftOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { Document, Page, pdfjs } from 'react-pdf';
import { Space, Spin } from 'antd';
import type { MatchAnswer } from '@owl-frontend/api-client';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useEffectWithPrev } from '../../../../shared/hooks';
import Button from '../../../Button/Button';
import * as Form from '../../../Form';
import { useResize } from './PDFDocumentResizeHook';
import styles from './PDFDocumentViewerComponent.module.scss';

// Needed for the react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Area = { left: number; top: number; width: number; height: number };

export interface Props {
  documentUrl?: string;
  file?: File | string;
  initialPage?: number;
  docDisplayPage?: number;
  docNumPages?: number;
  onChangePage?(number: number): void;
  pageNumber?: number;
  documentMatches?: MatchAnswer[];
  highlight?: [number, number, number, number];
}

const areaToKey = (area: Area) => {
  const { top, left, width, height } = area;
  return `${top}-${left}-${width}-${height}`;
};

const ZOOM_SCALE = 0.25;
const PAGE_OFFSET = 1;
const PADDING = 16;

export const NoDataComponent: React.FC = () => {
  const [showSpinner, setShowSpinner] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowSpinner(false);
    }, 5_000);
  }, []);

  if (!showSpinner) {
    return <>No page specified</>;
  }

  return <Spin className={styles.documentLoading} spinning />;
};

const PDFDocumentViewer: React.FC<Props> = ({
  file,
  documentUrl,
  initialPage = 1,
  docDisplayPage,
  docNumPages,
  onChangePage,
  pageNumber,
  documentMatches = [],
  highlight,
}) => {
  const setPageInputDiv = React.useRef<HTMLDivElement>(null);
  const [scale, setScale] = React.useState<number>(0.85);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [localPageNumber, setLocalPageNumber] =
    React.useState<number>(initialPage);
  const [renderedPageNumber, setRenderedPageNumber] = React.useState<number>();
  const [originalPageWidth, setOriginalPageWidth] = React.useState<number>();
  const [setPageMode, setSetPageMode] = React.useState(false);
  const documentContainerRef = React.useRef<HTMLDivElement>(null);
  const { width: pageWidth } = useResize(documentContainerRef);

  const changeScale = (offset: number) => {
    setScale((prevScale) => prevScale + offset);
  };

  const changePage = React.useCallback(
    (offset: number) => {
      if (docNumPages) {
        onChangePage?.((docDisplayPage ?? 0) + offset);
        return;
      }

      setLocalPageNumber((prevPageNumber) => prevPageNumber + offset);
    },
    [docDisplayPage, docNumPages, onChangePage]
  );

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(docNumPages ?? pdf._pdfInfo.numPages);

    if (docNumPages ?? localPageNumber > pdf._pdfInfo.numPages) {
      setLocalPageNumber(1);
      return;
    }

    setLocalPageNumber(localPageNumber);
  };

  const isLoading = renderedPageNumber !== localPageNumber;

  React.useEffect(() => {
    if (docNumPages) {
      setLocalPageNumber(1);
      return;
    }

    if (pageNumber) {
      setLocalPageNumber(pageNumber);
    }
  }, [docNumPages, pageNumber]);

  useEffectWithPrev(
    (prevInitialPage) => {
      if (!initialPage || prevInitialPage === initialPage) {
        return;
      }

      if (docNumPages) {
        setLocalPageNumber(1);
        return;
      }

      setLocalPageNumber(initialPage);
    },
    [initialPage, setLocalPageNumber, numPages, docNumPages]
  );

  const terms = React.useMemo(() => {
    return documentMatches
      .map((d) => {
        if (!d.snippet) {
          return null;
        }

        const emphasizedText = d.snippet.match('<em>(.*?)</em>');

        if (!emphasizedText) {
          return null;
        }

        return emphasizedText[1].trim();
      })
      .filter((d) => !!d);
  }, [documentMatches]);

  const highlightScale = React.useMemo(() => {
    if (!pageWidth || !scale || !originalPageWidth) {
      return 0;
    }

    return ((pageWidth - PADDING) * scale) / originalPageWidth;
  }, [originalPageWidth, pageWidth, scale]);

  const areas = React.useMemo<Area[]>(
    () =>
      documentMatches
        .map((d) => {
          if (!d.bb || d.page !== localPageNumber) {
            return null;
          }

          const [left, top, width, height] = d.bb.map(
            (i) => i * highlightScale
          );
          return { left, top, width, height };
        })
        .filter((d): d is Area => d !== null),
    [documentMatches, localPageNumber, highlightScale]
  );

  const onPageRenderSuccess = React.useCallback(
    (data) => {
      setOriginalPageWidth(data.originalWidth);
      setRenderedPageNumber(localPageNumber);
    },
    [localPageNumber, setRenderedPageNumber]
  );

  const onSetPage = React.useCallback(
    (page) => {
      if (docNumPages) {
        onChangePage?.(page);
        return;
      }

      if (page <= 0) {
        setLocalPageNumber(0);
        return;
      }

      setLocalPageNumber(page);
    },
    [docNumPages, onChangePage]
  );

  const onSetPageSubmit = React.useCallback(
    (e) => {
      setSetPageMode(false);
      const val = Number(e.target[0].value);
      onSetPage(Math.min(val, numPages));
    },
    [numPages, onSetPage]
  );

  const onSetPageBlur = React.useCallback(
    (e) => {
      setSetPageMode(false);
      const val = Number(e.target.value);
      onSetPage(Math.min(val, numPages));
    },
    [numPages, onSetPage]
  );

  React.useEffect(() => {
    if (!setPageMode || !setPageInputDiv.current) {
      return;
    }

    const input = setPageInputDiv.current.getElementsByTagName('input')[0];

    if (!input) {
      return;
    }

    input.focus();
  }, [setPageMode]);

  return (
    <div className={styles.documentContainer}>
      <div ref={documentContainerRef} className={styles.documentWrapper}>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<Spin className={styles.documentLoading} spinning />}
          noData={<NoDataComponent />}
        >
          {isLoading && renderedPageNumber ? (
            <Page
              key={renderedPageNumber}
              pageNumber={renderedPageNumber}
              renderAnnotationLayer={false}
              scale={scale}
              width={pageWidth - PADDING}
              noData={<NoDataComponent />}
            />
          ) : null}
          <Page
            className={clsx(isLoading && renderedPageNumber && styles.hidden)}
            key={localPageNumber}
            pageNumber={localPageNumber}
            renderAnnotationLayer={false}
            scale={scale}
            width={pageWidth - PADDING}
            onRenderSuccess={onPageRenderSuccess}
            loading={<Spin className={styles.documentLoading} spinning />}
            noData={<NoDataComponent />}
            customTextRenderer={({ str }) => {
              if (str.trim().length === 0) {
                return '';
              }

              const result: string[] = [];
              for (const term of terms) {
                if (!term) {
                  continue;
                }

                if (str.indexOf(term) > -1) {
                  result.push(
                    str.replace(
                      term,
                      `<span style="background: yellow; opacity: 0.35">${term}</span>`
                    )
                  );
                }
              }

              return result.join('');
            }}
          >
            {areas.map((area) => (
              <div
                key={areaToKey(area)}
                className={styles.pdfSectionMatch}
                style={area}
              />
            ))}
            {highlight && (
              <div
                className={styles.pdfHighlight}
                style={{
                  left: highlight?.[0] * highlightScale,
                  top: highlight?.[1] * highlightScale,
                  width: highlight?.[2] * highlightScale,
                  height: highlight?.[3] * highlightScale,
                }}
              />
            )}
          </Page>
        </Document>
      </div>
      <div className={styles.pdfUtil}>
        <div>
          <Space align="start">
            <Button
              data-testid="pdf-viewer--zoom-out"
              size="small"
              icon={<MinusOutlined />}
              disabled={scale <= 0.5}
              onClick={() => {
                changeScale(-ZOOM_SCALE);
              }}
            />
            <Button
              data-testid="pdf-viewer--zoom-in"
              size="small"
              icon={<PlusOutlined />}
              disabled={scale >= 3}
              onClick={() => {
                changeScale(ZOOM_SCALE);
              }}
            />
            {documentUrl && (
              <Button
                data-testid="pdf-viewer--doc-url"
                className={styles.openInNewTab}
                size="small"
                icon={<ExportOutlined />}
                href={documentUrl}
                target="_blank"
              >
                Open PDF
              </Button>
            )}
          </Space>
        </div>
        <Space>
          <Button
            data-testid="pdf-viewer--page-dec"
            size="small"
            icon={<LeftOutlined />}
            disabled={
              docDisplayPage ? docDisplayPage <= 1 : localPageNumber <= 1
            }
            onClick={() => {
              changePage(-1 * PAGE_OFFSET);
            }}
          />
          {setPageMode ? (
            <div style={{ width: 64 }} ref={setPageInputDiv}>
              <form onSubmit={onSetPageSubmit} onBlur={onSetPageBlur}>
                <Form.Input type="number" label="" />
              </form>
            </div>
          ) : (
            <Button
              data-testid="pdf-viewer--page-num"
              size="small"
              onClick={() => {
                setSetPageMode(true);
              }}
            >
              {docDisplayPage ?? localPageNumber ?? (numPages ? 1 : '--')} /{' '}
              {numPages ?? '--'}
            </Button>
          )}
          <Button
            data-testid="pdf-viewer--page-inc"
            size="small"
            icon={<RightOutlined />}
            disabled={
              docDisplayPage
                ? docDisplayPage >= numPages
                : localPageNumber >= numPages
            }
            onClick={() => {
              changePage(PAGE_OFFSET);
            }}
          />
        </Space>
      </div>
    </div>
  );
};

export default PDFDocumentViewer;
