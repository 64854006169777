import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { UploadCadence } from './interface';

const logger = createLogger(__filename);

const fulfillmentCapacityApiClient = {
  schedule: {
    get: async (): Promise<UploadCadence[]> => {
      const url = new URL(`/api/v1/capacity/schedule`, baseURL);
      logger.trace('fulfillmentCapacityApiClient.schedule.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default fulfillmentCapacityApiClient;
