import React from 'react';
import { useParams } from 'react-router-dom';
import { List, Typography } from 'antd';
import type { ClaimsDocument } from '@owl-frontend/api-client';
import { ClaimsAssistDocumentViewerComponent } from '@owl-frontend/components';
import { Locale } from '../../../../context/AppContainer/AppContainer';
import { useDocument } from '../../../workers/hooks/useDocument';
import useErrorDocuments from '../../hooks/useErrorDocuments';
import styles from './ClaimsAdminDocComponent.module.scss';

interface ErrorFieldProps {
  title: string;
  extractionError: {
    errorType: string;
    errorNotes?: string;
  };
}

const ErrorFieldComponent: React.FC<ErrorFieldProps> = (props) => {
  const { title, extractionError } = props;

  return (
    <div className={styles.errorField}>
      <List.Item.Meta
        title={title}
        description={
          <>
            <div>
              <b>Error Type:</b> {extractionError.errorType}
            </div>
            {extractionError.errorNotes && (
              <div>
                <b>Notes:</b> {extractionError.errorNotes}
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

interface Props {
  tenantId: string;
  dossierId: string;
  document: ClaimsDocument;
}

const ClaimsAdminDocComponent: React.FC<Props> = (props) => {
  const { messages } = React.useContext(Locale);
  const { tenantId, dossierId, document } = props;
  const [pageNumber, setPageNumber] = React.useState(1);

  const { presignedUrl, getPresignedUrl } = useDocument(
    tenantId,
    dossierId,
    document
  );

  React.useEffect(() => {
    getPresignedUrl();
  }, [getPresignedUrl]);

  if (!presignedUrl) {
    return null;
  }

  return (
    <ClaimsAssistDocumentViewerComponent
      siderWidth={450}
      documentProps={{
        initialPage: pageNumber,
        onChangePage: setPageNumber,
        content: {
          content: presignedUrl,
          fileType: document.contentType,
        },
      }}
    >
      <div className={styles.sider}>
        <Typography.Title level={5}>Document Error</Typography.Title>
        <div className={styles.section}>
          <div>
            <b>Error:</b>{' '}
            {messages[`claimsAdmin.errorDocs.errors.${document.documentError}`]}
          </div>
          <div>
            <b>Ingest Status:</b> {document.ingestStatus}
          </div>
        </div>
        <div className={styles.section}>
          <div>
            <b>Doc Type:</b>{' '}
            {
              messages[
                `claims.documents.uploadDialog.docType.${document.docType}`
              ]
            }
          </div>
          <div>
            <b>Doc Sub-type:</b>{' '}
            {
              messages[
                `claims.documents.uploadDialog.docSubType.${document.docSubType}`
              ]
            }
          </div>
        </div>
        {document.documentErrorDetails && (
          <div className={styles.section}>
            <Typography.Title level={5}>Field Errors</Typography.Title>
            <List
              dataSource={document.documentErrorDetails.documentErrorFields?.map(
                ({ fieldKey, extractionError }) => ({
                  title: `Field: ${fieldKey}`,
                  extractionError,
                })
              )}
              renderItem={(item) => <ErrorFieldComponent {...item} />}
            />
          </div>
        )}
      </div>
    </ClaimsAssistDocumentViewerComponent>
  );
};

const ClaimsAdminDocComponentWrapper: React.FC = () => {
  const { tenantId, docId } = useParams();
  const { errorDocuments, getErrorDocuments } = useErrorDocuments(tenantId);

  const document = React.useMemo(
    () => errorDocuments?.find((d) => d.docId === docId),
    [errorDocuments, docId]
  );

  React.useEffect(() => {
    if (!errorDocuments) {
      getErrorDocuments();
    }
  }, [errorDocuments, getErrorDocuments]);

  if (!tenantId || !document) {
    return null;
  }

  return (
    <div className={styles.fullHeight}>
      <ClaimsAdminDocComponent
        tenantId={tenantId}
        dossierId={document.dossierId}
        document={document}
      />
    </div>
  );
};

export default ClaimsAdminDocComponentWrapper;
