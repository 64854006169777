import React from 'react';
import clsx from 'clsx';
import { Skeleton } from 'antd';
import type {
  default as ReactBaseTableType,
  AutoResizer as AutoResizerType,
  SortOrder,
  BaseTableProps,
} from 'react-base-table';
import AutoResizerImpl from 'react-base-table/lib/AutoResizer';
import ReactBaseTableImpl from 'react-base-table/lib/BaseTable';
import 'react-base-table/styles.css';

import { buildColumns, ColumnsBuilder } from './columnsBuilder';
import EmptyRendererComponent from './components/EmptyRenderer/EmptyRendererComponent';
import Filter, { FilterProps } from './components/Filter/Filter';
import styles from './Table.module.scss';

const AutoResizer: typeof AutoResizerType = AutoResizerImpl;
const ReactBaseTable: typeof ReactBaseTableType = ReactBaseTableImpl;

export type ForwardTablePropKeys =
  | 'rowKey'
  | 'columns'
  | 'data'
  | 'rowClassName'
  | 'sortState'
  | 'onColumnSort'
  | 'onEndReachedThreshold'
  | 'onEndReached'
  | 'onScroll';

export interface TableProps<T, FilterFieldName extends string = string>
  extends Pick<BaseTableProps<T>, ForwardTablePropKeys> {
  rowKey: string;
  columns: NonNullable<BaseTableProps<T>['columns']>;
  sortState: {
    [field: string]: SortOrder;
  };
  isLoading?: boolean;
  emptyRenderer?: React.ReactNode;
  filterOpts?: FilterProps<FilterFieldName>;
  actions?: React.ReactNode[];
  actionsLabel?: React.ReactChild;
  actionsVisible?: boolean;
  expandColumnKey?: string;
  fixed?: boolean;
  // extraProps injected to cellRenderer `container` in order to render cell
  extra?: {
    [k: string]: any;
  };
}

interface InjectedProps {
  width: number;
  height: number;
}

type TableRender =
  // prettier-align
  React.ForwardRefRenderFunction<
    ReactBaseTableType<{ [key: string]: any }>,
    InjectedProps & TableProps<{ [key: string]: any }, string>
  >;
const Table: TableRender = (props, ref) => {
  const {
    rowKey,
    columns,
    data,
    rowClassName,
    sortState,
    isLoading,
    emptyRenderer = <EmptyRendererComponent />,
    onColumnSort,
    onEndReachedThreshold = 0,
    onEndReached,
    onScroll,
    fixed,
    expandColumnKey,
    extra,
    // injected props
    width,
    height,
  } = props;

  const loadingOverlay = React.useCallback(() => {
    if (!isLoading) {
      return null;
    }

    const isLoadingMore = data?.length && isLoading;

    if (isLoadingMore) {
      return (
        <div className={styles.loadingMoreOverlay}>
          <Skeleton paragraph={false} active />
        </div>
      );
    }

    return (
      <div className={styles.loadingOverlay}>
        <Skeleton paragraph={false} active />
        <Skeleton paragraph={false} active />
        <Skeleton paragraph={false} active />
        <Skeleton paragraph={false} active />
      </div>
    );
  }, [isLoading, data]);

  return (
    <ReactBaseTable
      className={clsx(data?.length && isLoading && styles.isLoadingMore)}
      rowKey={rowKey}
      columns={columns}
      data={data}
      rowClassName={rowClassName}
      sortState={sortState}
      overlayRenderer={loadingOverlay}
      onColumnSort={onColumnSort}
      onEndReachedThreshold={onEndReachedThreshold}
      onEndReached={onEndReached}
      onScroll={onScroll}
      emptyRenderer={isLoading ? null : emptyRenderer}
      expandColumnKey={expandColumnKey}
      fixed={fixed}
      {...extra}
      // injected props
      width={width}
      height={height}
      ref={ref}
    />
  );
};

const TableWithRef = React.forwardRef(Table);

// eslint-disable-next-line @typescript-eslint/ban-types
export type BuildColumns = <T = any, E = {}>() => ColumnsBuilder<T, E>;

interface TableWrapperFn {
  <T = any, F extends string = string>(
    props: TableProps<T, F> & React.RefAttributes<ReactBaseTableType<T>>
  ): JSX.Element;

  /**
   * Compact helper for defining table columns.
   * @example
   * Table.buildColumns<FormFlowConfigurationSerialized>()
   *   .data('name', 'Name', 400, { flexGrow: 1 })
   *   .data('updatedAt', 'Date Updated', 200, { cellRenderer: <TableDateCell /> })
   *   .cust('actions', '', 300, {
   *     cellRenderer: ({ rowData }): React.ReactNode => (
   *       <Menu>...</Menu>
   *     ),
   *   })
   */
  buildColumns: BuildColumns;
}
type TableWrapperRender =
  // prettier-align
  React.ForwardRefRenderFunction<
    ReactBaseTableType<{ [key: string]: any }>,
    TableProps<{ [key: string]: any }, string>
  >;
const TableWrapper: TableWrapperRender = (
  { filterOpts, actions, actionsLabel, actionsVisible, ...props },
  ref
) => {
  const tableInResizer = (
    <AutoResizer>
      {({ width, height }) => (
        <TableWithRef ref={ref} width={width} height={height} {...props} />
      )}
    </AutoResizer>
  );
  return (
    <div className={styles.tableContainer}>
      {(filterOpts || (actionsVisible && actions)) && (
        <div className={styles.filterActionsContainer}>
          {filterOpts && <Filter {...filterOpts} />}
          {actionsVisible && actions && (
            <div className={styles.tableActions}>
              {actionsLabel && (
                <div className={styles.actionsLabel}>{actionsLabel}</div>
              )}
              <div className={styles.actions}>{actions}</div>
            </div>
          )}
        </div>
      )}
      <div className={styles.resizerContainer}>{tableInResizer}</div>
    </div>
  );
};

const TableWrapperWithRef = React.forwardRef(TableWrapper) as Omit<
  TableWrapperFn,
  'buildColumns'
> & {
  buildColumns?: BuildColumns;
};

TableWrapperWithRef.buildColumns = buildColumns;

export default TableWrapperWithRef as TableWrapperFn;
