import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  PausedTaskQueues,
  PausedTopologyTaskQueue,
  TaskQueuePausedState,
  Topology,
  TopologyState,
  TopologyTaskQueues,
} from './interface';

const logger = createLogger(__filename);

const fowlApiClient = {
  topologies: {
    list: async (): Promise<{
      topologies: Topology[];
      defaults: [string, string][];
    }> => {
      const url = new URL(`/api/v1/fowl/topologies`, baseURL);
      logger.trace('topologiesApiClient.fowl.topologies.list');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getPauseState: async (data: { arn: string }): Promise<TopologyState> => {
      const url = new URL(
        `/api/v1/fowl/topologies/${encodeURIComponent(data.arn)}/paused`,
        baseURL
      );
      logger.trace('topologiesApiClient.fowl.topologies.getState');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    updatePauseState: async (data: {
      arn: string;
      paused: boolean;
    }): Promise<TopologyState> => {
      const url = new URL(
        `/api/v1/fowl/topologies/${encodeURIComponent(data.arn)}/paused`,
        baseURL
      );
      logger.trace('topologiesApiClient.fowl.topologies.updateState');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          paused: data.paused,
        }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    getTaskQueuesMappingState: async (data: {
      topologyArn: string;
      arn: string;
    }): Promise<TopologyTaskQueues> => {
      const url = new URL(`/api/v1/fowl/task-queues/state`, baseURL);
      url.searchParams.set('arn', data.arn);
      logger.trace(
        'topologiesApiClient.fowl.topologies.getTaskQueuesMappingState'
      );
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getTaskQueuesPausedState: async (): Promise<PausedTaskQueues> => {
      const url = new URL(`/api/v1/fowl/task-queues/paused`, baseURL);
      logger.trace(
        'topologiesApiClient.fowl.topologies.getTaskQueuesPausedState'
      );
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    updateTaskQueuePauseState: async (data: {
      arn: string;
      paused: boolean;
    }): Promise<TaskQueuePausedState> => {
      const url = new URL(`/api/v1/fowl/task-queues/paused`, baseURL);
      logger.trace('topologiesApiClient.fowl.topologies.updateTaskQueueState');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
  },
};

export default fowlApiClient;
