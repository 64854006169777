import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  TenantUserFormValues,
  BatchProcessLog,
  IngestionConfig,
  IngestionConfigFormValues,
  IngestionSettings,
  Review,
  Tenant,
  TenantUser,
} from './interface';

const logger = createLogger(__filename);

const tenantApiClient = {
  // Phoenix
  tenant: {
    get: async (): Promise<Tenant> => {
      const url = new URL(`/api/v1/tenant`, baseURL);
      logger.trace('tenantApiClient.tenant.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  // Falcon
  tenants: {
    list: async (
      data: {
        cursor?: string;
        limit?: number;
      } = {}
    ): Promise<{
      lastCursor: string | null;
      tenants: Tenant[];
    }> => {
      const url = new URL('/api/v1/tenants', baseURL);

      if (data.cursor) {
        url.searchParams.set('cursor', data.cursor);
      }

      if (data.limit) {
        url.searchParams.set('limit', `${data.limit}`);
      }

      logger.trace('tenantApiClient.tenants.list()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    get: async (data: { tenantId: string }): Promise<Tenant> => {
      const url = new URL(`/api/v1/tenants/${data.tenantId}`, baseURL);
      logger.trace('tenantApiClient.tenants.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    updateTenantIngestionSettings: async (data: {
      tenantId: string;
      ingestion: IngestionSettings;
    }): Promise<Tenant> => {
      const url = new URL(`/api/v1/tenants/${data.tenantId}`, baseURL);
      logger.trace('tenantApiClient.tenants.updateTenantPriorities()');
      const response = await fetchWrapper(url.href, {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          data: { ingestion: data.ingestion },
        }),
      });
      logger.debug({ response }, `PATCH ${url.href}`);
      return deserialize(response);
    },
    updateTenantPriorities: async (data: {
      tenants: {
        tenantId: string;
        priority: number;
        executionQuantity: number;
        maxReviewLimit: number;
        enforceLimit: boolean;
        reviewRollover: boolean;
      }[];
    }): Promise<Tenant[] | string[]> => {
      const url = new URL(`/api/v1/tenants`, baseURL);
      logger.trace('tenantApiClient.tenants.updateTenantPriorities()');
      const response = await fetchWrapper(url.href, {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          tenants: data.tenants,
        }),
      });
      logger.debug({ response }, `PATCH ${url.href}`);
      return deserialize(response);
    },
    listReviews: async (data: {
      tenantId: string;
    }): Promise<{
      items: Review[];
      lastCursor: string | null;
    }> => {
      const url = new URL(`/api/v1/tenants/${data.tenantId}/reviews`, baseURL);
      url.searchParams.set('groupId', 'ready');

      logger.trace('tenantApiClient.tenants.listReviews()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    reviewsCount: async (data: {
      tenantId: string;
    }): Promise<{ count: number }> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/reviews/count`,
        baseURL
      );
      url.searchParams.set('groupId', 'ready');

      logger.trace('tenantApiClient.tenants.reviewsCount()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    fetchIngestionConfigs: async (data: {
      tenantId: string;
    }): Promise<IngestionConfig[]> => {
      const url = new URL(
        `/api/v1/ingestion-configs/${data.tenantId}`,
        baseURL
      );

      logger.trace('tenantApiClient.tenants.fetchIngestionConfigs()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    saveIngestionConfig: async (data: {
      tenantId: string;
      values: IngestionConfigFormValues;
    }): Promise<void> => {
      let path = `/api/v1/ingestion-configs/${data.tenantId}`;

      if (data.values.configurationId) {
        path += `/${data.values.configurationId}`;
      }

      const url = new URL(path, baseURL);
      const method = data.values.configurationId ? 'PUT' : 'POST';

      logger.trace('tenantApiClient.tenants.saveIngestionConfigs()');
      const {
        configurationName,
        dateFormat,
        columnMappingConfig,
        version,
        dossierType,
        defaultCountryCode,
      } = data.values;
      const response = await fetchWrapper(url.href, {
        method,
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          configurationName,
          dateFormat,
          columnMappingConfig,
          version,
          dossierType,
          defaultCountryCode,
        }),
      });
      logger.debug({ response }, `${method} ${url.href}`);
      return deserialize(response);
    },
    deleteIngestionConfig: async (data: {
      tenantId;
      configurationId;
    }): Promise<void> => {
      const url = new URL(
        `/api/v1/ingestion-configs/${data.tenantId}/${data.configurationId}`,
        baseURL
      );
      logger.trace('tenantApiClient.tenants.deleteIngestionConfigs()');
      const response = await fetchWrapper(url.href, {
        method: 'DELETE',
        credentials: 'include',
      });
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
    rerankDossiers: async (data: {
      tenantId: string;
      investigationType: string;
      force?: boolean;
      groupId?: string;
    }): Promise<BatchProcessLog> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/reviews/reranks`,
        baseURL
      );
      logger.trace('tenantApiClient.tenants.rerankDossiers()');
      const { investigationType, force, groupId } = data;
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          investigationType,
          force,
          groupId,
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    getLatestRerankDossiersLog: async (data: {
      tenantId: string;
    }): Promise<BatchProcessLog> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/reviews/reranks/latest`,
        baseURL
      );

      logger.trace('tenantApiClient.tenants.getLatestRerankDossiersLog()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },

    addTenantUser: async (data: {
      tenantId: string;
      userData: TenantUserFormValues;
    }): Promise<TenantUser> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/tenant-users`,
        baseURL
      );

      logger.trace('tenantApiClient.tenants.addTenantUser()');
      const singleUser = [
        { name: data.userData.name, email: data.userData.email },
      ];
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          tenantId: data.tenantId,
          phoenixUsers: singleUser,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },

    addMultipleTenantUsers: async (data: {
      tenantId: string | undefined;
      formattedUsers: { name: string; email: string }[];
    }): Promise<TenantUser[]> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/tenant-users`,
        baseURL
      );

      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          phoenixUsers: data.formattedUsers,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      return deserialize(response);
    },

    listTenantUsers: async (data: {
      tenantId: string;
    }): Promise<TenantUser[]> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/tenant-users`,
        baseURL
      );

      logger.trace('tenantApiClient.tenants.listTenantUsers()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default tenantApiClient;
