// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-frontend-components-src-lib-Toast-__primary--Pxahg {\n  border-left: 4px solid #1890ff;\n}\n\n._-_-frontend-components-src-lib-Toast-__success--BECiN {\n  border-left: 4px solid #52c41a;\n}\n\n._-_-frontend-components-src-lib-Toast-__error--yLdtW {\n  border-left: 4px solid #f5222d;\n}\n\n._-_-frontend-components-src-lib-Toast-__warning--VtzZU {\n  border-left: 4px solid #faad14;\n}\n\n._-_-frontend-components-src-lib-Toast-__info--U3La3 {\n  border-left: 4px solid #1890ff;\n}", "",{"version":3,"sources":["webpack://./../frontend-components/src/lib/Toast/ToastAntd.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF","sourcesContent":[".primary {\n  border-left: 4px solid #1890ff;\n}\n\n.success {\n  border-left: 4px solid #52c41a;\n}\n\n.error {\n  border-left: 4px solid #f5222d;\n}\n\n.warning {\n  border-left: 4px solid #faad14;\n}\n\n.info {\n  border-left: 4px solid #1890ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "_-_-frontend-components-src-lib-Toast-__primary--Pxahg",
	"success": "_-_-frontend-components-src-lib-Toast-__success--BECiN",
	"error": "_-_-frontend-components-src-lib-Toast-__error--yLdtW",
	"warning": "_-_-frontend-components-src-lib-Toast-__warning--VtzZU",
	"info": "_-_-frontend-components-src-lib-Toast-__info--U3La3"
};
export default ___CSS_LOADER_EXPORT___;
