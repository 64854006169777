import React from 'react';
import clsx from 'clsx';
import { Modal as ModalAntd, ModalProps as ModalAntdProps, Space } from 'antd';

import Button, { ButtonProps } from '../Button/Button';
import styles from './Dialog.module.scss';

export interface ActionButtonProps
  extends Omit<ButtonProps, 'type' | 'backgroundColor' | 'size'> {
  label: string;
  /** Use for a trailing icon */
  children?: React.ReactNode;
}

export interface DialogFooterProps {
  extra?: React.ReactNode;
  onCancelOpts?: ActionButtonProps;
  onSecondaryOpts?: ActionButtonProps;
  onConfirmOpts?: ActionButtonProps;
}

const DialogFooter: React.FC<DialogFooterProps> = ({
  extra,
  onCancelOpts,
  onSecondaryOpts,
  onConfirmOpts,
}) => {
  return (
    <div className={clsx(styles.footer, extra && styles.hasExtra)}>
      {extra && <Space className={styles.actions}>{extra}</Space>}{' '}
      <Space aria-label="actions" className={styles.actions}>
        {' '}
        {onCancelOpts && (
          <Button {...onCancelOpts} type="text">
            {onCancelOpts.label}
          </Button>
        )}
        {onSecondaryOpts && (
          <Button {...onSecondaryOpts} type="default">
            {onSecondaryOpts.label}
          </Button>
        )}
        {onConfirmOpts && (
          <Button {...onConfirmOpts} type="primary">
            {onConfirmOpts.label}
          </Button>
        )}
      </Space>
    </div>
  );
};
export type DialogProps = Omit<
  ModalAntdProps,
  | 'width'
  | 'onOk'
  | 'okText'
  | 'okButtonProps'
  | 'cancelButtonProps'
  | 'cancelText'
  | 'footer'
  // closable overrides
  | 'closeIcon'
> & {
  size?: 'small' | 'medium' | 'large' | 'full';
  onClose?(): void;
  footer?: {
    extra?: React.ReactNode;
    onCancelOpts?: ActionButtonProps;
    onSecondaryOpts?: ActionButtonProps;
    onConfirmOpts?: ActionButtonProps;
  };
};

const Dialog: React.FC<DialogProps> = ({
  children,
  size = 'small',
  title,
  onClose,
  footer,
  maskClosable = false,
  keyboard = false,
  className,
  ...rest
}) => {
  return (
    <ModalAntd
      {...rest}
      className={clsx(
        styles.dialog,
        size === 'small' && styles.small,
        size === 'medium' && styles.medium,
        size === 'large' && styles.large,
        size === 'full' && styles.full,
        className
      )}
      title={
        <header className={styles.header}>
          {typeof title === 'string' ? (
            <span className={clsx(styles.title)}>{title}</span>
          ) : (
            title
          )}
        </header>
      }
      onCancel={onClose}
      maskClosable={maskClosable}
      keyboard={keyboard}
      footer={footer ? <DialogFooter {...footer} /> : null}
    >
      {children}
    </ModalAntd>
  );
};

export default Dialog;
