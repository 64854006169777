import type { UploadCadence } from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface FulfillmentCapacityState {
  schedule: UploadCadence[];
  actions: {
    getSchedule: AsyncActionState;
  };
}

const initialState: FulfillmentCapacityState = {
  schedule: [],
  actions: {
    getSchedule: { status: 'uninitialized' },
  },
};

const fulfillmentCapacitySlice = setupSlice(
  'fulfillmentCapacity',
  initialState
);
export default fulfillmentCapacitySlice;
