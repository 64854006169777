// unused imports are for @see hints.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createAction, createReducer } from '@reduxjs/toolkit';
import type { EmptyObject, Reducer } from 'redux';
import type { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { createLogger } from '@owl-lib/logger';
import type {
  MarkCoRequired,
  RecordOf,
  ValueOrReturn,
} from '@owl-lib/type-util';

import { __DEV__ } from '../constants';
import { resolveType, isEmpty, valProp } from '../helpers';
import { createSaga, createSagas } from '../sagaToolkit';
import type {
  SagaScheduler,
  SagaSliceGenerator,
} from '../sagaToolkit/interface';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { addActions, createActionFromDef } from './actions';
import { addAsyncActions, addAsyncSagas } from './async';
import type {
  AsyncActionDefsFromCaseSagas,
  AsyncActionsDef,
  AsyncCaseSagas,
  AsyncSagaConfig,
  AsyncSagaOptions,
  ExtractReturnTypes,
} from './async/interface';
import {
  executeReducerBuilderCallback,
  ExtraBuilder,
  initialExtra,
  isEmptyExtra,
  SliceExtra,
} from './extra';
import { createSliceSelector, SliceSelector } from './helpers';
import type {
  CaseReducers,
  ActionsDefEmpty,
  CasePreparers,
  MergeDef,
  SliceActionCreators,
  SliceCases,
  SliceCase,
  CaseSagasCustom,
  CaseSagas,
  ActionsDefAny,
} from './interface';
import merge from './merge';
import { addReducers } from './reducer';
import { addSagas } from './saga';

const logger = createLogger(__filename);

/** Get the actions definition type of a defined type */
export type ActionsDefType<SliceType> = SliceType extends ConfigurableSlice<
  infer dummyState,
  infer dummyName,
  infer ActionsDef
>
  ? ActionsDef
  : never;

// The data that can be changed on a `clone`
export interface ConfigurableSliceMembers<
  State,
  Name extends string = string,
  ActionsDef = ActionsDefEmpty
> {
  initialState: State;
  actions: SliceActionCreators<ActionsDef, Name>;
  cases: SliceCases<State, ActionsDef, Name>;
  extra: SliceExtra<State>;
}

export interface ConfigurableSliceInfo<
  State,
  Name extends string = string,
  ActionsDef = ActionsDefEmpty
> extends ConfigurableSliceMembers<State, Name, ActionsDef> {
  name: Name;
}

export class ConfigurableSlice<
  State,
  Name extends string = string,
  ActionsDef = ActionsDefEmpty
> implements ConfigurableSliceInfo<State, Name, ActionsDef>
{
  static merge = merge;

  // MARK: init

  /**
   * The slice's name. Used to namespace the generated action types.
   */
  readonly name: Name;

  /**
   * The initial state to be returned by the slice reducer.
   */
  readonly initialState: State;

  /**
   * Action creators for the types of actions that are handled by the slice
   * reducer.
   */
  readonly actions = {} as SliceActionCreators<ActionsDef, Name>;

  /**
   * The individual case prepare, reducer and saga functions that were passed in the parameters.
   * This enables reuse and testing if they were defined inline when calling `createSlice`.
   */
  readonly cases = {} as SliceCases<State, ActionsDef, Name>;

  readonly extra: Readonly<SliceExtra<State>> = initialExtra;

  readonly selector: SliceSelector<State, Name>;
  // TODO: extra reducers and extra sagas

  constructor(
    name: Name,
    ...stateArg: EmptyObject extends State
      ? [initialState?: State]
      : [initialState: State]
  );
  constructor(name: Name, initialState: State = {} as State) {
    this.name = name;
    this.initialState = initialState;
    this.selector = createSliceSelector<State, Name>(name);
  }

  clone(
    members?: Partial<ConfigurableSliceMembers<State, Name, ActionsDef>>
  ): this;
  clone<DefNext = ActionsDef, StateNext = State>(
    members?: Partial<ConfigurableSliceMembers<StateNext, Name, DefNext>>
  ): ConfigurableSlice<StateNext, Name, DefNext>;
  clone<DefNext = ActionsDef, StateNext = State>(
    members?:
      | Partial<ConfigurableSliceMembers<State, Name, ActionsDef>>
      | Partial<ConfigurableSliceMembers<StateNext, Name, DefNext>>
  ): this | ConfigurableSlice<StateNext, Name, DefNext> {
    type Next = ConfigurableSlice<StateNext, Name, DefNext>;
    const desc = Object.getOwnPropertyDescriptors(this) as {
      [P in keyof Next]: TypedPropertyDescriptor<this[P] | Next[P]>;
    };
    if ((__DEV__ && desc['reducer']) || desc['saga']) {
      logger.warn(
        `Do not clone slice ${this.name} after the ${
          desc['reducer'] ? 'reducer' : 'saga'
        } has already been added to the store!`
      );
    }
    if (members) {
      if (members.initialState) desc.initialState.value = members.initialState;
      if (members.actions) desc.actions.value = members.actions;
      if (members.cases) desc.cases.value = members.cases;
      if (members.extra) desc.extra.value = members.extra;
    }
    return Object.create(this.constructor.prototype, desc);
  }

  // MARK: configure

  /**
   * Add new action creators to the slice. Simple actions require definition of
   * a type annotation, and actions that use a prepare method can have their
   * types inferred.
   *
   * @param preparers
   *   * `true` (or omitted): the action creator accepts a single argument, which
   *     will be included in the action object as a field called `payload`.
   *   * `'auto'`: the action creator accepts a single argument - an object with
   *     the props `{ payload, meta?, error? }` will be included in the action
   *     object as fields called `payload`, `meta` and `error`, respectively.
   *   * a method that takes any number of arguments and returns an object: the
   *     resulting action creator will pass its arguments to this method, and the
   *     resulting action will include the `payload`, `meta` and `error` from the
   *     object.
   * @example
   * const sliceWithShorthandActions = slice.addActions<{
   *   actionType: ActionPayload;
   *   actionWithMetaOrError: { payload: ActionPayload, meta: ActionMeta };
   * }>({
   *   actionType: true,
   *   actionWithMetaOrError: 'auto',
   * })
   * dispatch(sliceWithShorthandActions.actionType({ actionPayload: true }));
   * dispatch(sliceWithShorthandActions.actionWithMetaOrError({
   *   payload: { actionPayload: true },
   *   meta: { actionMeta: true },
   * }));
   *
   * @example
   * const sliceWithPreparedActions = slice.addActions({
   *   actionWithDefinedPrepareFn: (
   *     reportId: string,
   *     navigate: NavigateFunction
   *   ) => ({
   *     payload: { reportId },
   *     meta: { navigate },
   *   }),
   * })
   * // ...
   * const navigate = useNavigate(); // from react-router
   * // ...
   * dispatch(sliceWithPreparedActions.actionWithDefinedPrepareFn('report123', navigate));
   *
   * @see createActionFromDef
   */
  addActions<DefNext>(
    preparers: CasePreparers<DefNext>
  ): ConfigurableSlice<State, Name, MergeDef<ActionsDef, DefNext>> {
    type NextActionsDef = MergeDef<ActionsDef, DefNext>;
    type Next = ConfigurableSlice<State, Name, NextActionsDef>;
    const newMembers = addActions(this, preparers);
    if (isEmpty(newMembers)) return this as this & Next;
    return this.clone<NextActionsDef, State>(newMembers);
  }

  /**
   * Add new action creators for async operations to the slice.
   * Simple actions require definition of a type annotation, and actions that use a prepare method can have their
   * types inferred.
   *
   * @param preparers
   *   * `true` (or omitted): the action creator accepts a single argument, which
   *     will be included in the action object as a field called `payload`.
   *   * `'auto'`: the action creator accepts a single argument - an object with
   *     the props `{ payload, meta?, error? }` will be included in the action
   *     object as fields called `payload`, `meta` and `error`, respectively.
   *   * a method that takes any number of arguments and returns an object: the
   *     resulting action creator will pass its arguments to this method, and the
   *     resulting action will include the `payload`, `meta` and `error` from the
   *     object.
   */
  // This first signature, like `setupSlice` is to allow async return types to
  // be defined and infer the action types.
  addAsyncActions<RT>(): <
    DefNext extends RecordOf<DefNext, any>,
    ApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
  >(
    preparers: CasePreparers<DefNext>,
    options?: AsyncSagaOptions<ApiConfig>
  ) => ConfigurableSlice<
    State,
    Name,
    MergeDef<ActionsDef, AsyncActionsDef<DefNext, RT, ApiConfig, Name>>
  >;
  addAsyncActions<
    DefNext,
    RT = {}, // eslint-disable-line @typescript-eslint/ban-types
    ApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
  >(
    preparers: CasePreparers<DefNext>,
    options?: AsyncSagaOptions<ApiConfig>
  ): ConfigurableSlice<
    State,
    Name,
    MergeDef<ActionsDef, AsyncActionsDef<DefNext, RT, ApiConfig, Name>>
  >;
  addAsyncActions<
    DefNext,
    RT extends RecordOf<DefNext, any>,
    ApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
  >(
    preparers?: CasePreparers<DefNext>,
    options?: AsyncSagaOptions<ApiConfig>
  ): ValueOrReturn<
    ConfigurableSlice<
      State,
      Name,
      MergeDef<ActionsDef, AsyncActionsDef<DefNext, RT, ApiConfig, Name>>
    >
  > {
    if (!preparers) {
      return (...args) => this.addAsyncActions(...(args as [any, ...any]));
    }
    type NextActionsDef = MergeDef<
      ActionsDef,
      AsyncActionsDef<DefNext, RT, ApiConfig, Name>
    >;
    type Next = ConfigurableSlice<State, Name, NextActionsDef>;
    const newMembers = addAsyncActions(this, preparers, options);
    if (isEmpty(newMembers)) return this as this & Next;
    return this.clone<NextActionsDef, State>(newMembers as any);
  }

  /**
   * Add a mapping from action types to action-type-specific *case reducer*
   * functions. For every action type that hasn't already been created,
   * a matching action creator will be generated using `createAction()`.
   *
   * For ideal type inference, do not mix already defined actions with newly
   * defined actions
   *
   * @see createAction
   * @see createReducer
   * @see https://redux-toolkit.js.org/api/createReducer#usage-with-the-map-object-notation
   */
  public addReducers(
    caseReducers: Partial<CaseReducers<State, ActionsDef, Name>>
  ): this;
  public addReducers<DefNext extends ActionsDefAny>(
    caseReducers: Partial<CaseReducers<State, ActionsDef, Name>> &
      CaseReducers<State, DefNext, Name>
  ): ConfigurableSlice<State, Name, MergeDef<ActionsDef, DefNext>>;
  public addReducers<DefNext>(
    caseReducers: Partial<CaseReducers<State, ActionsDef, Name>> &
      CaseReducers<State, DefNext, Name>
  ): ConfigurableSliceNext<State, Name, ActionsDef, DefNext> {
    type NextActionsDef = MergeDef<ActionsDef, DefNext>;
    const newMembers = addReducers<ActionsDef, DefNext>(this, caseReducers);
    if (isEmpty(newMembers)) return this;
    return this.clone<NextActionsDef, State>(newMembers);
  }

  /**
   * Add a mapping from action types to action-type-specific *case saga*
   * generator functions. For every action type that hasn't already been
   * created, a matching action creator will be generated using
   * `createAction()`.
   *
   * For ideal type inference, do not mix already defined actions with newly
   * defined actions
   *
   * The default scheduler is `takeEvery`
   *
   * @see createAction
   * @see createSaga
   */
  public addSagas(caseSagas: Partial<CaseSagasCustom<ActionsDef, Name>>): this;
  public addSagas<DefNext>(
    caseSagas: Partial<CaseSagas<ActionsDef, Name>> & CaseSagas<DefNext, Name>
  ): ConfigurableSlice<State, Name, MergeDef<ActionsDef, DefNext>>;
  public addSagas(
    scheduler: SagaScheduler,
    caseSagas: Partial<CaseSagas<ActionsDef, Name>>
  ): this;
  public addSagas<DefNext>(
    scheduler: SagaScheduler,
    caseSagas: Partial<CaseSagas<ActionsDef, Name>> & CaseSagas<DefNext, Name>
  ): ConfigurableSlice<State, Name, MergeDef<ActionsDef, DefNext>>;
  public addSagas<DefNext>(
    schedulerOrCaseSagas:
      | SagaScheduler
      | Partial<CaseSagas<ActionsDef /* & DefNext */, Name>>, // "Next" commented due to signature mismatch
    caseSagas?: Partial<CaseSagas<ActionsDef & DefNext, Name>>
  ): ConfigurableSliceNext<State, Name, ActionsDef, DefNext> {
    let globalScheduler: SagaScheduler = takeEvery;
    type NextActionsDef = MergeDef<ActionsDef, DefNext>;
    type CaseSagasNext = Partial<CaseSagas<ActionsDef & DefNext, Name>>;
    if (!caseSagas) {
      caseSagas = schedulerOrCaseSagas as CaseSagasNext;
    } else {
      globalScheduler = schedulerOrCaseSagas as SagaScheduler;
    }
    const newMembers = addSagas<ActionsDef, DefNext>(
      this,
      caseSagas as Partial<CaseSagas<any, Name>>,
      globalScheduler
    );
    if (isEmpty(newMembers)) return this;
    return this.clone<NextActionsDef, State>(newMembers);
  }

  /**
   * Add a mapping from action types to action-type-specific *case saga*
   * generator functions. For every action type that hasn't already been
   * created, a matching action creator will be generated using
   * `createAction()`.
   *
   * For ideal type inference, do not mix already defined actions with newly
   * defined actions
   *
   * The default scheduler is `takeEvery`
   *
   * @see createAction
   * @see createSaga
   */
  public addAsyncSagas(
    caseSagas: Partial<
      AsyncCaseSagas<ActionsDef, ExtractReturnTypes<ActionsDef>, Name>
    >,
    options?: AsyncSagaOptions<any>
  ): this;
  public addAsyncSagas<CaseSagaTypes extends AsyncCaseSagas<any, any, Name>>(
    caseSagas: CaseSagaTypes,
    options?: AsyncSagaOptions<any>
  ): ConfigurableSlice<
    State,
    Name,
    MergeDef<
      ActionsDef,
      AsyncActionDefsFromCaseSagas<CaseSagaTypes, { sliceName: Name }>
    >
  >;
  public addAsyncSagas<DefNext>(
    caseSagas: Partial<
      AsyncCaseSagas<ActionsDef, ExtractReturnTypes<ActionsDef>, Name>
    > &
      AsyncCaseSagas<DefNext, any, Name>,
    options: AsyncSagaOptions<any>
  ): any {
    type NextActionsDef = MergeDef<
      ActionsDef,
      AsyncActionsDef<DefNext, any, any, Name>
    >;
    type Next = ConfigurableSlice<State, Name, NextActionsDef>;
    const newMembers = addAsyncSagas(
      this,
      caseSagas as Partial<CaseSagas<any, Name>>,
      options
    );
    if (isEmpty(newMembers)) return this as this & Next;
    return this.clone<NextActionsDef, State>(newMembers as any);
  }

  /**
   * Works the same as rtk's builder, with the addition of "addSaga" to add
   * arbitrary sagas, outside of the action-specific case sagas
   *
   * @see https://redux-toolkit.js.org/api/createReducer#builder-methods
   * @see https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
   */
  public addExtra(
    builderCallback: (builder: ExtraBuilder<State>) => void
  ): this {
    const extra = executeReducerBuilderCallback(this, builderCallback);
    if (isEmptyExtra(extra)) {
      return this;
    }
    return this.clone({
      extra: {
        actionsMap: { ...this.extra.actionsMap, ...extra.actionsMap },
        actionMatchers: [...this.extra.actionMatchers, ...extra.actionMatchers],
        defaultCaseReducer:
          this.extra.defaultCaseReducer || extra.defaultCaseReducer,
        sagas: [...this.extra.sagas, ...extra.sagas],
      },
    });
  }

  // MARK: finalize

  get reducer(): Reducer<State> {
    const reducer = this.getReducer();
    Object.defineProperty(this, 'reducer', valProp(reducer));
    return reducer;
  }

  /**
   * The slice's reducer.
   */
  getReducer(): Reducer<State> {
    const caseReducers: CaseReducers<State, any, any> = {
      ...this.extra.actionsMap,
    };
    type ActionKey = Extract<keyof this['cases'], string>;
    for (const actionKey of Object.keys(this.cases) as ActionKey[]) {
      const { reducer } = this.cases[actionKey] as SliceCase<State, any>;
      if (reducer) caseReducers[resolveType(this.name, actionKey)] = reducer;
    }
    return createReducer(
      this.initialState,
      caseReducers,
      this.extra.actionMatchers,
      this.extra.defaultCaseReducer
    );
  }

  get saga(): (() => SagaSliceGenerator) | undefined {
    const saga = this.getSaga();
    Object.defineProperty(this, 'saga', valProp(saga));
    return saga;
  }

  /**
   * The slice's saga.
   */
  getSaga(): (() => SagaSliceGenerator) | undefined {
    const caseSagas: (() => Generator<unknown, void, SagaIterator>)[] = [];
    type ActionKey = Extract<keyof this['cases'], string>;
    for (const actionKey of Object.keys(this.cases) as ActionKey[]) {
      type SC = MarkCoRequired<SliceCase<State, any>, 'saga' | 'scheduler'>;
      const sc = this.cases[actionKey] as SC;
      if (sc.saga) {
        const actionType = resolveType(this.name, actionKey);
        caseSagas.push(createSaga(sc.scheduler, actionType, sc.saga));
      }
    }
    const finalSagas = [...caseSagas, ...this.extra.sagas];
    if (finalSagas.length === 0) return;
    return createSagas(finalSagas);
  }
}

/** @internal */
type ConfigurableSliceNext<State, Name extends string, Defs, DefsNext> =
  | ConfigurableSlice<State, Name, Defs>
  | ConfigurableSlice<State, Name, MergeDef<Defs, DefsNext>>;
