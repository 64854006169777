import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { StoreState, useSelector } from '../../../data/store';
import { actions } from '../data/logic';

interface UseDocumentState {
  getPresignedUrl(): void;
  presignedUrl?: string;
}

export function useDocument(tenantId, dossierId, document): UseDocumentState {
  const { docId } = document;

  const dispatch = useDispatch();
  const presignedUrls = useSelector(
    (s: StoreState) => s['workers'].claimDocumentsPresignedUrls
  );

  const getPresignedUrl = useCallback(() => {
    if (!tenantId || !dossierId || !document) {
      return;
    }

    dispatch(
      actions.getPresignedViewUrl({
        tenantId,
        dossierId,
        claimsDocument: document,
      })
    );
  }, [dispatch, tenantId, dossierId, document]);

  return {
    getPresignedUrl,
    presignedUrl: docId ? presignedUrls[docId] : undefined,
  };
}
