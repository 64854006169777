import { kebabCase } from 'lodash';
import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  GetPresignedUploadResponse,
  InsightsConfigEntry,
} from '../interface';
import type {
  CreatedBy,
  Insight,
  MatchAnswer,
  ClaimsDocumentDeprecated,
  ClaimNote,
} from './interface';

const logger = createLogger(__filename);

const claimDocumentsApiClient = {
  dossiers: {
    //todo: add promise type for all
    get: async (data: { tenantId: string; dossierId: string }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claim-dossiers/dossiers/${data.dossierId}`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    listClaimants: async (data: { tenantId: string }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claim-dossiers/dossiers`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.listClaimants()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getSummary: async (data: { tenantId: string; dossierId: string }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claim-dossiers/dossiers/${data.dossierId}/summary`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.getSummary()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getNextAnswerToken: async (data: {
      dossierId: string;
      answerRequestId: string;
    }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/search/get-next-answer-token`,
        baseURL
      );
      url.searchParams.set('answerRequestId', data.answerRequestId);
      logger.trace('claimDocumentsApiClient.dossiers.getNextAnswerToken()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    listDocuments: async (data: {
      dossierId: string;
    }): Promise<{ documents: ClaimsDocumentDeprecated[] }> => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/documents`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.listDocuments()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getDocument: async (data: {
      dossierId: string;
      docName: string;
      docId: string;
    }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/documents/${data.docId}`,
        baseURL
      );
      url.searchParams.set('docName', data.docName);
      logger.trace('claimDocumentsApiClient.dossiers.getDocument()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return response.url;
    },
    getDocumentSummary: async (data: { dossierId: string; docId: string }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/documents/${data.docId}/summary`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.getDocumentSummary()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    updateDocumentSummary: async (data: {
      dossierId: string;
      docId: string;
      documentSummaryContent: string;
    }) => {
      const { documentSummaryContent } = data;

      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/documents/${data.docId}/summary`,
        baseURL
      );

      logger.trace('claimDocumentsApiClient.dossiers.updateDocumentSummary()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ documentSummaryContent }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    getDocumentContent: async (presignedUrl) => {
      const url = new URL(presignedUrl);
      logger.trace('claimDocumentsApiClient.dossiers.getDocumentContent()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return await response.text();
    },
    getPresignedUrl: async (
      dossierId: string,
      fileName: string,
      fileType: string,
      docType: string,
      docSubType: string,
      docDate: string
    ): Promise<GetPresignedUploadResponse> => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${dossierId}/documents`,
        baseURL
      );

      logger.trace('claimDocumentsApiClient.dossiers.getPresignedUrl()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            originalFilename: fileName,
            // todo: is fileType the same as contentType
            contentType: fileType,
            docType,
            docSubType,
            docDate,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    uploadDocument: async (data: { presigned: GetPresignedUploadResponse }) => {
      const { url: presignedUrl, contentType, file, metadata } = data.presigned;

      const metadataHeaders = Object.fromEntries(
        Object.entries(metadata).map(([k, v]) => [
          `x-amz-meta-${kebabCase(k)}`,
          v,
        ])
      );

      const url = new URL(presignedUrl);
      const paramKeys = Array.from(url.searchParams.keys()).map((k) =>
        k.toLowerCase()
      );
      if (
        !url.hostname.endsWith('.amazonaws.com') ||
        paramKeys.indexOf('x-amz-signature') === -1
      ) {
        logger.error(data.presigned, 'Not a valid Presigned S3 URL');
        throw new Error('Not a valid Presigned S3 URL');
      }

      logger.trace('documentSlice.uploadS3File()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          headers: { ...metadataHeaders, 'content-type': contentType },
          body: await file.arrayBuffer(),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    deleteDocument: async (data: {
      dossierId: string;
      docId: string;
      docName: string;
    }) => {
      const { dossierId, docId, docName } = data;
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${dossierId}/documents/${docId}`,
        baseURL
      );

      logger.trace('documentSlice.deleteDocument()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            docName,
          }),
        },
        -1
      );

      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
    listQueryHistory: async (data: { dossierId: string }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/query-history`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.listQueryHistory()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    search: async (data: {
      dossierId: string;
      searchQuery: string;
      historySearch: boolean;
      matchDocsKey: string;
    }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/search`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.search()');
      url.searchParams.set('searchQuery', data.searchQuery);
      url.searchParams.set('historySearch', `${data.historySearch}`);
      url.searchParams.set('matchDocsKey', `${data.matchDocsKey}`);

      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    matches: async (data: {
      dossierId: string;
      searchQuery: string;
      limit?: number;
      parentId?: string;
    }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/search/matches`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.matches()');
      url.searchParams.set('searchQuery', data.searchQuery);
      if (data.parentId) {
        url.searchParams.set('parentId', data.parentId);
      }
      if (data.limit) {
        url.searchParams.set('limit', `${data.limit}`);
      }

      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    saveInsight: async (data: {
      insightId?: string;
      dossierId: string;
      title: string;
      query: string;
      answer: string;
      customAnswer: string;
      matches: MatchAnswer[];
      decision?: Insight['decision'];
      feedback?: Insight['feedback'];
      createdBy: CreatedBy;
    }) => {
      const { dossierId } = data;

      let path = `/api/v1/claim-documents/dossiers/${dossierId}/insights`;

      if (data.insightId) {
        path = `${path}/${data.insightId}`;
      }

      const url = new URL(path, baseURL);

      logger.trace('claimDocumentsApiClient.dossiers.saveInsight()');

      const response = await fetchWrapper(
        url.href,
        {
          method: data.insightId ? 'PUT' : 'POST',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            query: data.query,
            title: data.title,
            answer: data.answer,
            customAnswer: data.customAnswer,
            matches: data.matches,
            decision: data.decision,
            feedback: data.feedback,
            createdBy: data.createdBy,
          }),
        },
        -1
      );
      logger.debug(
        { response },
        `${data.insightId ? 'PUT' : 'POST'} ${url.href}`
      );
      return deserialize(response);
    },
    deleteInsight: async (data: { dossierId: string; insightId: string }) => {
      const { dossierId, insightId } = data;

      const url = new URL(
        `/api/v1/claim-documents/dossiers/${dossierId}/insights/${insightId}`,
        baseURL
      );

      logger.trace('claimDocumentsApiClient.dossiers.deleteInsight()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
    listInsights: async (data: {
      dossierId: string;
    }): Promise<{ insights: Insight[] }> => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/insights`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.insights()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getChunk: async (data: { dossierId: string; chunkId: string }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/chunks/${data.chunkId}`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.getChunk()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    listChunks: async (data: { dossierId: string; docId: string }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/documents/${data.docId}/chunks`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.getChunks()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getNotes: async (data: {
      dossierId: string;
    }): Promise<{ notes: ClaimNote[] }> => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/notes`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.getNotes()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    addNote: async (data: {
      dossierId: string;
      noteContent?: string;
      documents?: ClaimsDocumentDeprecated[];
      insightId?: string;
      insightContent?: string;
      insightDetails?: string;
      insightNextAction?: string;
    }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/notes`,
        baseURL
      );

      logger.trace('claimDocumentsApiClient.dossiers.addNote()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            noteContent: data.noteContent,
            documents: data.documents,
            insightId: data.insightId,
            insightContent: data.insightContent,
            insightDetails: data.insightDetails,
            insightNextAction: data.insightNextAction,
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    updateNote: async (data: {
      dossierId: string;
      noteId: string;
      noteContent: string;
    }) => {
      const url = new URL(
        `/api/v1/claim-documents/dossiers/${data.dossierId}/notes/${data.noteId}`,
        baseURL
      );

      logger.trace('claimDocumentsApiClient.dossiers.updateNote()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ noteContent: data.noteContent }),
        },
        -1
      );
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    deleteNote: async (data: { dossierId: string; noteId: string }) => {
      const { dossierId, noteId } = data;

      const url = new URL(
        `/api/v1/claim-documents/dossiers/${dossierId}/notes/${noteId}`,
        baseURL
      );

      logger.trace('claimDocumentsApiClient.dossiers.deleteNote()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        },
        -1
      );
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
    triggerInvestigation: async (data: { dossierId: string }) => {
      const { dossierId } = data;
      const url = new URL(
        `/api/v1/claim-dossiers/dossiers/${dossierId}/investigation`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.dossiers.triggerInvestigation()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-type': 'application/json',
          },
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    getSearchRecommendations: async (data: {
      dossierId: string;
      tenantId: string;
    }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claim-dossiers/dossiers/${data.dossierId}/search-recommendations`,
        baseURL
      );
      logger.trace('claimDocumentsApiCLient.getSearchRecommendations()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  config: {
    get: async () => {
      const url = new URL('/api/v1/claim-documents/ml-config', baseURL);
      logger.trace('claimDocumentsApiClient.config.get()');

      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  insightsConfig: {
    get: async (data: {
      tenantId: string;
    }): Promise<{ insightsConfig: InsightsConfigEntry[] }> => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claim-dossiers/insights-config`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.insightsConfig.get()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    save: async (data: {
      tenantId: string;
      insightsConfig: InsightsConfigEntry[];
    }) => {
      const url = new URL(
        `/api/v1/tenants/${data.tenantId}/claim-dossiers/insights-config`,
        baseURL
      );
      logger.trace('claimDocumentsApiClient.insightsConfig.post()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ insightsConfig: data.insightsConfig }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default claimDocumentsApiClient;
