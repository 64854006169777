import React from 'react';
import { Form as Form, FormRenderProps } from 'react-final-form';

import { __isProd } from '../../../shared/constants';
import type {
  FinalFormExtra,
  FormPropsExtra,
  FormPropsNoRender,
} from '../../../shared/final-form-types';

export type ManagedFormComponent<
  Props = {}, // eslint-disable-line @typescript-eslint/ban-types
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>
> = React.FC<Props & FormRenderProps<FormValues, InitialFormValues>>;
/** This is a render prop function and must _not_ use hooks. */
export type ManagedFormRender<
  Props = {}, // eslint-disable-line @typescript-eslint/ban-types
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>
> = (
  props: Props & FormRenderProps<FormValues, InitialFormValues>
) => React.ReactNode;
export type { ManagedFormComponent as MFC, ManagedFormRender as MFR };

/**
 * If you get type errors with inferred types, explicitly define the `Props` type, or define your form component with `ManagedFormComponent`.
 */
export function manage<
  Props,
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>
>(
  Component: ManagedFormComponent<Props, FormValues, InitialFormValues>,
  useProps?: (
    props: Props & FormPropsNoRender<FormValues, InitialFormValues>
  ) => Props & FormPropsNoRender<FormValues, InitialFormValues>
): React.FC<Props & FormPropsNoRender<FormValues, InitialFormValues>> {
  const WrappingComponent: React.FC<
    Props & FormPropsNoRender<FormValues, InitialFormValues>
  > = (props) => {
    // eslint-disable-next-line react-hooks2/rules-of-hooks
    const p = useProps ? useProps(props) : props;
    // we use `createElement` directly to resolve typings manually
    // Equivalent to `<Form {...props} component={Component} />`
    type P = FormPropsExtra<Props, FormValues, InitialFormValues>;
    type FF = FinalFormExtra<Props>;
    return React.createElement<P>(Form as FF, { ...p, component: Component });
  };
  if (!__isProd) {
    WrappingComponent.displayName = `${
      Component.displayName || Component.name || 'Form'
    }Managed`;
  }
  return WrappingComponent;
}

/**
 * This passes a render prop to react-final-form's `Form`, which creates less
 * component nesting, but the render function can _not_ use hooks
 */
export function createManagedForm<
  Props,
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>
>(
  render: ManagedFormRender<Props, FormValues, InitialFormValues>,
  useProps?: (
    props: Props & FormPropsNoRender<FormValues, InitialFormValues>
  ) => Props & FormPropsNoRender<FormValues, InitialFormValues>
): React.FC<Props & FormPropsNoRender<FormValues, InitialFormValues>> {
  const WrappingComponent: React.FC<
    Props & FormPropsNoRender<FormValues, InitialFormValues>
  > = (props) => {
    // eslint-disable-next-line react-hooks2/rules-of-hooks
    const p = useProps ? useProps(props) : props;
    // we use `createElement` directly to resolve typings manually
    // Equivalent to `<Form {...props} render={render} />`
    type P = FormPropsExtra<Props, FormValues, InitialFormValues>;
    type FF = FinalFormExtra<Props>;
    return React.createElement<P>(Form as FF, { ...p, render });
  };
  return WrappingComponent;
}
