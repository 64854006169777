import dayjs from 'dayjs';
import type { Emitter } from 'mitt';
import {
  layoutMultilineText,
  PDFDocument,
  rgb,
  StandardFonts,
  TextAlignment,
} from 'pdf-lib';
import printJS from 'print-js';
import { all, call, delay, put, race, select, take } from 'typed-redux-saga';
import type { SagaGenerator } from 'typed-redux-saga/types';
import {
  claimDocumentsApiClient,
  ClaimsDocumentDeprecated,
  type CreatedBy,
  DecideOnInsightRequest,
  type Insight,
  type MatchAnswer,
  QueryResult,
  StagedFileDeprecated,
} from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import eventBusHooks, { TypedEventBus } from '../../../context/event-bus';
import { USER_NAME } from '../components/ClaimDetailsComponent/ClaimsInsightsComponent/ClaimsInsightsComponent';
import { FILETYPE } from '../components/DocumentViewerComponent/DocumentComponent';
import {
  getLocalDemoData,
  isDemoTenant,
  markClaimAsReviewed,
  markDocumentAsReviewed,
  overrideLocalClaims,
  overrideLocalDocuments,
  overrideLocalInsights,
  resetLocalDemoData,
  setDocumentTitlesToReview,
  setDocumentTitlesToReviewed,
  setInsight,
  setLastReviewedDate,
  setLatestInvestigation,
  switchLocalDemoUser,
} from './helpers';
import claimsSlice from './interface';

const FONT_SIZE = 12;
const PAGE_WIDTH_BOUND = 800;
const PAGE_CHUNK_SIZE = 35;

function* querySearchFetch(action: {
  payload: {
    dossierId: string;
    searchQuery: string;
    historySearch: boolean;
    matchDocsKey: string;
  };
}): SagaGenerator<QueryResult> {
  const { dossierId, searchQuery, historySearch, matchDocsKey } =
    action.payload;
  return yield* call(claimDocumentsApiClient.dossiers.search, {
    dossierId,
    searchQuery,
    historySearch,
    matchDocsKey,
  });
}

function* uploadFile(stagedFile, dossierId) {
  const { docType, docSubType, docDate, file } = stagedFile;
  try {
    const presigned = yield call(
      claimDocumentsApiClient.dossiers.getPresignedUrl,
      dossierId,
      file.name,
      file.type,
      docType,
      docSubType,
      docDate
    );
    yield call(claimDocumentsApiClient.dossiers.uploadDocument, {
      presigned: {
        ...presigned,
        docType,
        docSubType,
        docDate,
        file,
      },
    });
    return;
  } catch (uploadError) {
    return file.name;
  }
}

function* uploadFiles(action: {
  payload: {
    dossierId: string;
    tenantId: string;
    files: StagedFileDeprecated[];
  };
}) {
  const { files, dossierId, tenantId } = action.payload;
  const failedUploads: string[] = [];

  const uploadTasks = yield all(
    files.map((file) => call(uploadFile, file, dossierId))
  );
  for (const uploadTask of uploadTasks) {
    if (uploadTask) {
      failedUploads.push(uploadTask);
    }
  }

  yield* call(setDocumentTitlesToReview, {
    dossierId,
    tenantId,
    docTitles: files.map((f) => f.file.name),
  });

  yield* call(setLastReviewedDate, { tenantId, dossierId });

  yield put({
    type: 'claims/uploadResult',
    payload: {
      successfulUploads: files.length - failedUploads.length,
      failedUploads,
    },
  });
}

function* getStreamedAnswer(action: {
  payload: { dossierId: string; answerRequestId: string };
}): SagaGenerator<void> {
  let done = false;
  do {
    const result = yield* call(
      claimDocumentsApiClient.dossiers.getNextAnswerToken,
      action.payload
    );

    if (result.token) {
      yield* put({
        type: 'claims/updateAnswerToken',
        payload: {
          answerRequestId: action.payload.answerRequestId,
          token: result.token,
        },
      });
    }
    done = result.done;
  } while (!done);
}

export const TXT_TO_PDF_FILES = [
  'Activities of Daily Living Form',
  'Annual Financial Inquiry',
  'Annual Financial Inquiry #1',
  'Annual Financial Inquiry #2',
  'Attending Physician Statement #1',
  'Attending Physician Statement #2',
  'Attending Physician Statement #3',
  'Attending Physician Statement #4',
  'Authorization',
  'Employee Statement',
  'Employer Statement',
  'Employers Statement',
  'Employability Analysis',
  'Functional Status Update',
  'Functional Status Update #1',
  'Functional Status Update #2',
  'HIPAA Authorization',
  'HIPAA Authorization #1',
  'HIPAA Authorization #2',
  'Reimbursement Agreement',
  'Reimbursement Agreement for LTD',
  'Supervisor Statement',
  'Training Education and Experience Form',
  'Witness Statement',
];

export const getPdfFileName = (docNameWithExtension) => {
  const docName = docNameWithExtension.split('.')[0];
  return TXT_TO_PDF_FILES.includes(docName)
    ? `${docName}.pdf`
    : docNameWithExtension;
};
function* getDocumentContent(action: {
  payload: { dossierId: string; document: ClaimsDocumentDeprecated };
}): SagaGenerator<string> {
  const { dossierId, document } = action.payload;
  const fileName = getPdfFileName(
    document.contentRawRef?.split('/').pop() ?? ''
  );
  const fileExtension = fileName.split('.').pop() ?? '';
  const documentPresignedUrl = yield* call(
    claimDocumentsApiClient.dossiers.getDocument,
    {
      dossierId,
      docId: document.docId,
      docName: fileName,
    }
  );
  if (documentPresignedUrl && fileExtension !== FILETYPE.TXT) {
    return documentPresignedUrl;
  } else {
    return yield* call(
      claimDocumentsApiClient.dossiers.getDocumentContent,
      documentPresignedUrl
    );
  }
}

const getBase64FromUrl = async (url: string): Promise<string> => {
  try {
    const data = await fetch(url);
    if (data.status === 200) {
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data as string);
        };
      });
    } else {
      throw new Error('Error fetching data');
    }
  } catch (e) {
    throw new Error('Error creating base64 image');
  }
};
function* printContent(action: { payload: { dossierId; document } }) {
  const { dossierId, document: claimDocument } = action.payload;
  const fileName = getPdfFileName(
    claimDocument.contentRawRef?.split('/').pop()
  );
  const fileExtension = fileName.split('.').pop() ?? '';
  const documentPresignedUrl = yield* call(
    claimDocumentsApiClient.dossiers.getDocument,
    {
      dossierId,
      docId: claimDocument.docId,
      docName: fileName,
    }
  );
  if (documentPresignedUrl && fileExtension !== FILETYPE.TXT) {
    const base64 = yield* call(getBase64FromUrl, documentPresignedUrl);
    printJS({
      printable: base64.split(',')[1],
      type: fileExtension === FILETYPE.PDF ? 'pdf' : 'image',
      base64: true,
    });
  } else {
    const txtFileContent = yield* call(
      claimDocumentsApiClient.dossiers.getDocumentContent,
      documentPresignedUrl
    );
    const iframe = document.createElement('iframe');
    const styledContent = `<html><head><style>
      body,
      pre { background-color: #fff; white-space: pre-wrap; }
      </style></head><body><pre>${txtFileContent}</pre></body></html>`;
    iframe.height = '800px';
    iframe.frameBorder = '0';

    iframe.width = '100%';
    iframe.srcdoc = styledContent;
    printJS({
      printable: iframe,
      type: 'html',
    });
  }
}

const mergeAllPDFs = async (
  dossierId,
  documents: ClaimsDocumentDeprecated[]
): Promise<string> => {
  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  for (const document of documents) {
    const fileExtension = document.contentRawRef?.split('.').pop() ?? '';
    const fileName = document.contentRawRef?.split('/').pop() ?? '';
    const documentUrl = await claimDocumentsApiClient.dossiers
      .getDocument({
        dossierId,
        docName: fileName,
        docId: document.docId,
      })
      .then((res) => res);
    if (fileExtension !== FILETYPE.TXT) {
      const originalPdfBytes = await fetch(documentUrl).then((res) =>
        res.arrayBuffer()
      );
      const originalPdfDoc = await PDFDocument.load(originalPdfBytes);
      const docLength = originalPdfDoc.getPageCount();
      for (let k = 0; k < docLength; k++) {
        const [originalPdfPage] = await pdfDoc.copyPages(originalPdfDoc, [k]);
        pdfDoc.addPage(originalPdfPage);
      }
    } else {
      const fileContent = await fetch(documentUrl).then((res) => res.text());
      // standard font doesn't recognize special symbols, this works for all files in dave-johnson-8
      // if needed, can add in custom fonts to accommodate all characters
      const fileContentCopy = fileContent.replace(/\u200B|\uF0B7/g, ' ');
      const multiText = layoutMultilineText(fileContentCopy, {
        alignment: TextAlignment.Left,
        font: timesRomanFont,
        fontSize: FONT_SIZE,
        bounds: {
          width: 10000,
          height: 1000,
          x: PAGE_WIDTH_BOUND,
          y: 800,
        },
      });
      const multiTextCopy = multiText.lines.filter((line) => line.text !== '');
      let page = pdfDoc.addPage();
      const { width, height } = page.getSize();
      let currentLine = 1;
      // need to manually split up long text into different pages
      multiTextCopy.forEach((line) => {
        if (currentLine + line.width / width > PAGE_CHUNK_SIZE) {
          page = pdfDoc.addPage();
          currentLine = 1;
        }
        page.drawText(`${line.text}`, {
          x: 25,
          y: height - 24 * currentLine,
          maxWidth: width - 50,
          size: FONT_SIZE,
          font: timesRomanFont,
          color: rgb(0, 0, 0),
        });
        currentLine += width > 0 ? line.width / width + 1 : 1;
      });
    }
  }

  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  return pdfDataUri.split(',')[1];
};

function* downloadAllDocuments(action: { payload: { dossierId; documents } }) {
  const { dossierId, documents } = action.payload;

  const downloadableBase64 = yield* call(mergeAllPDFs, dossierId, documents);
  const linkSource = `data:application/pdf;base64,${downloadableBase64}`;
  const downloadLink = document.createElement('a');
  const fileName = `${dossierId}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export type DocumentListenerEvents = {
  invalidateDocumentListener: void;
  invalidateNewInsightsListener: void;
};
export const documentListenerEvent: TypedEventBus<DocumentListenerEvents> =
  eventBusHooks;

const TXT_FILE_REGEX = /.txt$/;

function* listenForFinishedDocumentUpload(action: {
  payload: {
    dossierId: string;
    initialNumDocuments: number;
    bus: Emitter<DocumentListenerEvents>;
  };
}) {
  while (true) {
    yield* delay(5_000);
    const docs = yield* call(claimDocumentsApiClient.dossiers.listDocuments, {
      dossierId: action.payload.dossierId,
    });
    if (
      docs.documents.filter((d) => {
        if (!d.contentRawRef) {
          return true;
        }
        return !TXT_FILE_REGEX.test(d.contentRawRef);
      }).length > action.payload.initialNumDocuments
    ) {
      action.payload.bus.emit('invalidateDocumentListener');
      yield* put({
        type: 'claims/invalidateDocumentListener',
        payload: {},
        meta: {
          requestId: 'redundant',
        },
      });
    }
  }
}

function* startListenForFinishedDocumentUpload(action: {
  payload: {
    dossierId: string;
    initialNumDocuments: number;
    bus: Emitter<DocumentListenerEvents>;
  };
}) {
  yield* race({
    listen: listenForFinishedDocumentUpload(action),
    stop: take('STOP_LISTEN_FINISHED_DOCUMENT_UPLOAD'),
  });
}

function* listenForFinishedInsightsUpload(action: {
  payload: {
    dossierId: string;
    initialNumInsights: number;
    bus: Emitter<DocumentListenerEvents>;
  };
}) {
  while (true) {
    yield* delay(30_000);
    const result = yield* call(claimDocumentsApiClient.dossiers.listInsights, {
      dossierId: action.payload.dossierId,
    });

    if (result.insights.length > action.payload.initialNumInsights) {
      action.payload.bus.emit('invalidateNewInsightsListener');
      yield* put({
        type: 'claims/invalidateNewInsightsListener',
        payload: {},
        meta: {
          requestId: 'redundant',
        },
      });
    }
  }
}

function* startListenForNewInsights(action: {
  payload: {
    dossierId: string;
    initialNumInsights: number;
    bus: Emitter<DocumentListenerEvents>;
  };
}) {
  yield* race({
    listen: listenForFinishedInsightsUpload(action),
    stop: take('STOP_LISTEN_FINISHED_INSIGHTS_UPLOAD'),
  });
}

function* claimsInsightsFetch(action: {
  payload: { tenantId: string; dossierId: string };
}) {
  const { tenantId, dossierId } = action.payload;
  const response = yield* call(claimDocumentsApiClient.dossiers.listInsights, {
    dossierId,
  });

  if (isDemoTenant()) {
    return {
      insights: overrideLocalInsights(tenantId, dossierId, response.insights),
    };
  }

  return response;
}

function* claimsDocumentsFetch(action: {
  payload: { tenantId: string; dossierId: string };
}) {
  const { tenantId, dossierId } = action.payload;
  const response = yield* call(claimDocumentsApiClient.dossiers.listDocuments, {
    dossierId,
  });

  if (isDemoTenant()) {
    return {
      documents: overrideLocalDocuments(
        tenantId,
        dossierId,
        response.documents
      ),
    };
  }

  return response;
}

function* setDocumentAsReviewed(action: {
  payload: {
    docId: string;
    dossierId: string;
    tenantId: string;
    nextAction?: string;
    note?: string;
  };
}): SagaGenerator<{ targetDoc: ClaimsDocumentDeprecated | null }> {
  const { docId, dossierId, tenantId, nextAction, note } = action.payload;
  yield* call(markDocumentAsReviewed, {
    docId,
    dossierId,
    tenantId,
    nextAction,
    note,
  });

  const loadedDocs = yield* select((s) => s['claims'].documents) ?? {};
  const loadedDossierDocs = loadedDocs[dossierId] ?? [];
  const targetDoc: ClaimsDocumentDeprecated = loadedDossierDocs.find(
    (d) => d.docId === docId
  );

  if (isDemoTenant() && targetDoc) {
    const targetDocName = targetDoc.contentRawRef?.split('/').slice(-1)?.[0];
    if (targetDocName) {
      yield* call(setDocumentTitlesToReviewed, {
        docTitles: [targetDocName],
        dossierId,
        tenantId,
      });
    }
    return {
      targetDoc: {
        ...targetDoc,
        isReviewed: {
          nextAction,
          reviewerName: USER_NAME,
          reviewDate: dayjs().format('MMM D, YYYY — h:mmA'),
          note,
        },
      },
    };
  }

  return {
    targetDoc: null,
  };
}

function* decideOnInsight(action: { payload: DecideOnInsightRequest }) {
  const { tenantId, ...rest } = action.payload;
  const { dossierId } = action.payload;

  const insight = isDemoTenant()
    ? yield setInsight(action.payload)
    : yield* call(claimDocumentsApiClient.dossiers.saveInsight, rest);

  if (action.payload.decision === 'accepted') {
    const noteContent = action.payload.feedback?.note;
    const noteResponse = yield* call(claimDocumentsApiClient.dossiers.addNote, {
      noteContent,
      dossierId,
      insightId: insight.insightId,
      insightContent: insight.customAnswer,
      insightDetails: insight.feedback.details,
      insightNextAction: insight.feedback.nextAction,
      documents: action.payload.matches.map((match) => match.document),
    });

    yield* put({
      type: 'claims/addNote/fulfilled',
      payload: noteResponse,
      meta: { arg: { dossierId } },
    });
  }

  return insight;
}

function* saveInsight(action: {
  payload: {
    insightId?: string;
    dossierId: string;
    tenantId: string;
    title: string;
    query: string;
    answer: string;
    customAnswer: string;
    matches: MatchAnswer[];
    decision?: Insight['decision'];
    feedback?: Insight['feedback'];
    createdBy: CreatedBy;
  };
}) {
  const { tenantId, ...rest } = action.payload;
  const { dossierId } = action.payload;
  yield* call(setLastReviewedDate, { tenantId, dossierId });
  return yield* call(claimDocumentsApiClient.dossiers.saveInsight, { ...rest });
}

function* getDocumentSummary(action: {
  payload: { dossierId: string; docId: string };
}) {
  const { dossierId, docId } = action.payload;

  try {
    const result = yield* call(
      claimDocumentsApiClient.dossiers.getDocumentSummary,
      { dossierId, docId }
    );
    return { ...result, dossierId, docId };
  } catch (error) {
    if (error.response?.status === 404) {
      return { dossierId, docId };
    }

    throw error;
  }
}

function* claimsList(action: { payload: { tenantId: string } }) {
  const { tenantId } = action.payload;
  const claims = yield* call(claimDocumentsApiClient.dossiers.listClaimants, {
    tenantId,
  });

  if (isDemoTenant()) {
    const dossiers = getLocalDemoData(tenantId).dossiers;
    const unreviewedDossierIds =
      getLocalDemoData(tenantId).unreviewedDossierIds ?? [];
    return overrideLocalClaims(
      tenantId,
      claims
        .filter((c) => !unreviewedDossierIds.includes(c.dossierId))
        .map((c) => {
          const result = {
            ...c,
          };

          if (dossiers?.[c.dossierId]?.insights) {
            const reviewedInsightTitles = Object.values(
              dossiers[c.dossierId].insights
            ).map((i) => i.title);

            result.insightTypes = c.insightTypes.filter(
              (insightType) => !reviewedInsightTitles.includes(insightType)
            );
          }

          if (dossiers?.[c.dossierId]?.documentsToReview) {
            result.documentsToReview =
              dossiers?.[c.dossierId]?.documentsToReview;
          }
          return result;
        })
    );
  }

  return claims;
}

function* claimFetch(action: {
  payload: { dossierId: string; tenantId: string };
}) {
  const { dossierId, tenantId } = action.payload;
  const claim = yield* call(claimDocumentsApiClient.dossiers.get, {
    dossierId,
    tenantId,
  });

  const localData = yield* call(getLocalDemoData, tenantId);

  if (!localData?.dossiers) {
    return claim;
  }

  return {
    ...claim,
    documentsToReview: localData.dossiers[dossierId]?.documentsToReview,
  };
}

function* resetDemoData(action: { payload: { tenantId: string } }) {
  yield resetLocalDemoData(action.payload.tenantId);
}

function* switchDemoUser(action: {
  payload: {
    tenantId: string;
    username: string;
    userRole: 'examiner' | 'manager';
  };
}) {
  const { tenantId, username, userRole } = action.payload;
  yield switchLocalDemoUser(tenantId, username, userRole);
}

function* triggerInvestigation(action: {
  payload: { dossierId: string; tenantId: string };
}) {
  const { dossierId, tenantId } = action.payload;
  yield* delay(10_000); // To simulate running an actual owl investigation
  yield* call(claimDocumentsApiClient.dossiers.triggerInvestigation, {
    dossierId,
  });
  yield setLatestInvestigation(tenantId, dossierId);
}

function* getSearchRecommendations(action: {
  payload: { dossierId: string; tenantId: string };
}) {
  const { dossierId, tenantId } = action.payload;
  const result = yield* call(
    claimDocumentsApiClient.dossiers.getSearchRecommendations,
    {
      tenantId,
      dossierId,
    }
  );
  return result;
}

const slice = claimsSlice
  .addAsyncSagas(
    {
      claimsList,
      claimFetch,
      querySearchFetch,
      listDocuments: claimsDocumentsFetch,
      setDocumentAsReviewed,
      markClaimAsReviewed: invoke(markClaimAsReviewed),
      getConfig: invoke(claimDocumentsApiClient.config.get),
      documentFetch: invoke(claimDocumentsApiClient.dossiers.getDocument),
      uploadFiles,
      deleteDocument: invoke(claimDocumentsApiClient.dossiers.deleteDocument),
      queryHistoryFetch: invoke(
        claimDocumentsApiClient.dossiers.listQueryHistory
      ),
      querySearchMatch: invoke(claimDocumentsApiClient.dossiers.matches),
      preSearchMatch: invoke(claimDocumentsApiClient.dossiers.matches),
      getStreamedAnswer,
      downloadDocument: invoke(claimDocumentsApiClient.dossiers.getDocument),
      downloadAllDocuments,
      decideOnInsight,
      saveInsight,
      deleteInsight: invoke(claimDocumentsApiClient.dossiers.deleteInsight),
      claimsInsightsFetch,
      fetchChunk: invoke(claimDocumentsApiClient.dossiers.getChunk),
      listChunks: invoke(claimDocumentsApiClient.dossiers.listChunks),
      getDocumentContent,
      printContent,
      startListenForFinishedDocumentUpload,
      startListenForNewInsights,
      fetchClaimsInsightsConfig: invoke(
        claimDocumentsApiClient.insightsConfig.get
      ),
      saveClaimsInsightsConfig: invoke(
        claimDocumentsApiClient.insightsConfig.save
      ),
      fetchClaimSummary: invoke(claimDocumentsApiClient.dossiers.getSummary),
      getNotes: invoke(claimDocumentsApiClient.dossiers.getNotes),
      addNote: invoke(claimDocumentsApiClient.dossiers.addNote),
      deleteNote: invoke(claimDocumentsApiClient.dossiers.deleteNote),
      updateNote: invoke(claimDocumentsApiClient.dossiers.updateNote),
      getDocumentSummary,
      updateDocumentSummary: invoke(
        claimDocumentsApiClient.dossiers.updateDocumentSummary
      ),
      resetDemoData,
      triggerInvestigation,
      switchDemoUser,
      getSearchRecommendations,
    },
    {
      timeoutLimit: -1,
    }
  )
  .addReducers({
    'claimsList/fulfilled': (state, action) => {
      let mediumCount = 0;
      const prioritizedClaims = action.payload.map((record) => {
        const documentsToReview = Object.keys(
          record.documentsToReview ?? {}
        ).reduce<string[]>((acc, curr) => {
          if (!record.documentsToReview?.[curr]) {
            return acc;
          }

          return [...acc, curr];
        }, []);

        const lastReviewed = record.lastReviewed;

        const result = {
          ...record,
          lastReviewed,
        };

        if (record.insightTypes?.length > 0) {
          return {
            ...result,
            priority: 2,
            lastReviewed: lastReviewed ?? dayjs().format('YYYY-MM-DD'),
          };
        }

        if (documentsToReview.length > 0) {
          mediumCount = mediumCount + 1;
          return {
            ...result,
            priority: 1,
            lastReviewed:
              lastReviewed ??
              dayjs()
                .subtract(mediumCount > 2 ? 2 : 1, 'days')
                .format('YYYY-MM-DD'),
          };
        }

        return {
          ...result,
          priority: 0,
        };
      });

      state.claims = prioritizedClaims.sort((a, b) => {
        if (b.priority === a.priority) {
          return dayjs(b.lastReviewed).diff(dayjs(a.lastReviewed));
        }

        return b.priority - a.priority;
      });
    },
    'claimFetch/fulfilled': (state, action) => {
      state.claimDetails[action.meta.arg.dossierId] = action.payload;
    },
    'querySearchFetch/fulfilled': (state, action) => {
      state.queryResult = action.payload;
    },
    'listDocuments/fulfilled': (state, action) => {
      state.documents[action.meta.arg.dossierId] =
        action.payload.documents.filter((d) => {
          if (!d.contentRawRef) {
            return true;
          }

          return !TXT_FILE_REGEX.test(d.contentRawRef);
        });
    },
    'setDocumentAsReviewed/fulfilled': (state, action) => {
      if (!action.payload.targetDoc) {
        return;
      }

      state.documents[action.meta.arg.dossierId] = state.documents[
        action.meta.arg.dossierId
      ].map((d) => {
        if (action.payload.targetDoc!.docId === d.docId) {
          return action.payload.targetDoc!;
        }

        return d!;
      });
    },
    'queryHistoryFetch/fulfilled': (state, action) => {
      state.queryHistory[action.meta.arg.dossierId] = [
        ...action.payload.history,
      ].sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return 1;
        }

        if (a.timestamp > b.timestamp) {
          return -1;
        }

        return 0;
      });
    },
    'getConfig/fulfilled': (state, action) => {
      state.config = action.payload;
    },
    'documentFetch/fulfilled': (state, action) => {
      state.presignedUrl = action.payload;
    },
    'downloadDocument/fulfilled': (state, action) => {
      window.open(action.payload, '_blank');
    },
    'querySearchMatch/fulfilled': (state, action) => {
      state.queryMatchResult = action.payload;
    },
    'preSearchMatch/fulfilled': (state, action) => {
      state.preSearchMatchResult = action.payload;
    },
    'claimsInsightsFetch/fulfilled': (state, action) => {
      state.insights[action.meta.arg.dossierId] = action.payload.insights;
    },
    'fetchChunk/fulfilled': (state, action) => {
      const { dossierId, chunkId } = action.meta.arg;
      state.chunks[dossierId] = {
        ...state.chunks[dossierId],
        [chunkId]: action.payload,
      };
    },
    'listChunks/fulfilled': (state, action) => {
      const { dossierId, docId } = action.meta.arg;
      state.chunksList[dossierId] = {
        ...state.chunksList[dossierId],
        [docId]: action.payload.chunks.sort((a, b) => a.seq - b.seq),
      };
    },
    'getDocumentContent/fulfilled': (state, action) => {
      state.documentContent = {
        content: action.payload,
        fileType: getPdfFileName(
          action.meta.arg.document.contentRawRef?.split('/').pop()
        )
          .split('.')
          .pop(),
      };
    },
    'fetchClaimsInsightsConfig/fulfilled': (state, action) => {
      state.insightsConfig[action.meta.arg.tenantId] =
        action.payload.insightsConfig;
    },
    'fetchClaimSummary/fulfilled': (state, action) => {
      state.summary = action.payload;
    },
    'getNotes/fulfilled': (state, action) => {
      state.notes = {
        ...state.notes,
        [action.meta.arg.dossierId]: action.payload.notes,
      };
    },
    'addNote/fulfilled': (state, action) => {
      const { dossierId } = action.meta.arg;

      state.notes = {
        ...state.notes,
        [dossierId]: [action.payload.note, ...state.notes[dossierId]],
      };
    },
    'deleteNote/fulfilled': (state, action) => {
      const { dossierId, noteId } = action.meta.arg;

      state.notes = {
        ...state.notes,
        [dossierId]: state.notes[dossierId].filter(
          (note) => note.noteId !== noteId
        ),
      };
    },
    'updateNote/fulfilled': (state, action) => {
      const { dossierId } = action.meta.arg;

      state.notes = {
        ...state.notes,
        [dossierId]: state.notes[dossierId].map((note) => {
          if (note.noteId === action.payload.note.noteId) {
            return action.payload.note;
          }

          return note;
        }),
      };
    },
    'getDocumentSummary/fulfilled': (state, action) => {
      const { dossierId, docId } = action.meta.arg;

      state.documentSummaries = {
        ...state.documentSummaries,
        [dossierId]: {
          ...state.documentSummaries[dossierId],
          [docId]: action.payload.documentSummary,
        },
      };
    },
    'updateDocumentSummary/fulfilled': (state, action) => {
      const { dossierId, docId } = action.meta.arg;

      state.documentSummaries = {
        ...state.documentSummaries,
        [dossierId]: {
          ...state.documentSummaries[dossierId],
          [docId]: action.payload.documentSummary,
        },
      };
    },
    'getSearchRecommendations/fulfilled': (state, action) => {
      const { dossierId } = action.meta.arg;
      state.searchRecommendations = {
        ...state.searchRecommendations,
        [dossierId]: action.payload,
      };
    },
  })
  .addReducers({
    updateAnswerToken: (
      state,
      action: {
        type: string;
        payload: { answerRequestId: string; token: string };
      }
    ) => {
      if (!action.payload?.token) {
        return;
      }

      state.streamedAnswers[action.payload.answerRequestId] =
        (state.streamedAnswers[action.payload.answerRequestId] ?? '') +
        action.payload.token;
    },
    clearState: (state) => {
      state.queryResult = undefined;
      state.preSearchMatchResult = undefined;
      state.streamedAnswers = {};
    },
  })
  .addReducers({
    uploadResult: (
      state,
      action: {
        type: string;
        payload: { successfulUploads: []; failedUploads: [] };
      }
    ) => {
      state.uploadResult['successfulUploads'] =
        action.payload.successfulUploads;
      state.uploadResult['failedUploads'] = action.payload.failedUploads;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
