import type { V3Base } from '@owl-lib/form-schema-config';
import type { CreatedBy } from '../claim-documents--falcon/interface';
import type { ClaimSegment, InsightSegment } from './nonmedical-interface';

export enum PRIORITY {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export const PRIORITY_ORDER = {
  [PRIORITY.LOW]: 0,
  [PRIORITY.MEDIUM]: 1,
  [PRIORITY.HIGH]: 2,
};

export type ClaimsListItem = {
  dossierId: string;
  clientDossierId: string;
  dossierName: string;
  documents: Pick<ClaimsDocument, 'docId' | 'docName' | 'createdAt'>[];
  insights: Pick<ClaimsAssistInsight, 'title'>[];
  priority?: PRIORITY;
  daysUnreviewed?: number;
  dossierType?: 'ltd' | 'std' | 'wc';
  data?: DossierEntry;
};

export type ClaimInfo = {
  dossierId: string;
  clientDossierId: string;
  dossierName: string;
  dossierType?: 'ltd' | 'std' | 'wc';
  data: {
    names: { firstName: string; lastName: string }[];
    [key: string]: any;
  };
};

export enum CLAIMS_EVENT_TYPE {
  Checkmark = 'checkmark',
  ClaimAcceptance = 'claim-acceptance',
  ClaimSubmission = 'claim-submission',
  ClaimantInformation = 'claimant-information',
  ClaimantPhoneCall = 'claimant-phone-call',
  DateOfDisability = 'date-of-disability',
  Decision = 'decision',
  Denial = 'denial',
  DocumentReceived = 'document-received',
  JobRelated = 'job-related',
  MaxBenefit = 'max-benefit',
  MedicalRecord = 'medical-record',
  MedicalVisit = 'medical-visit',
  NurseReview = 'nurse-review',
  PaymentPaidToClaimant = 'payment-to-claimant',
  ReservesAdjusted = 'reserves-set-or-adjusted',
  ReturnToWork = 'return-to-work',
  SocialSecurity = 'social-security',
  Surveillance = 'surveillance',
  VocationalRehab = 'vocational-rehab',
  DocumentRequested = 'document-requested',
  DocumentAdded = 'document-added',
  DocumentReviewed = 'document-reviewed',
  NotApplicable = 'not-applicable',
}

export type TimelineEntry = {
  date: string;
  type: CLAIMS_EVENT_TYPE;
  title: string;
  summary: string;
  documents?: Pick<ClaimsDocument, 'docId' | 'docName'>[];
};

export type ClaimsAssistInsightDecision = 'accepted' | 'rejected' | 'undecided';

export type ClaimsDocumentSummary = {
  content: string;
  createdAt: string;
  updatedAt: string;
  updatedByUsername: string;
  updatedByName: string;
};

export type ClaimsDocument = {
  docId: string;
  dossierId: string;
  contentType: string;
  contentRawRef: {
    contentRawBucket: string;
    contentRawKey: string;
  };
  contentManualText?: string;
  docName: string;
  docSubType: string;
  docType: string;
  docDate: string;
  docSource?: string;
  docUserNotes?: string;
  docContentTags?: {
    docType: string;
    docSubTypes: string[];
    pages: [number, number];
  }[];
  receivedDate?: string;
  createdAt: string;
  summary?: ClaimsDocumentSummary;
  isReviewed?: boolean;
  nextAction?: string;
  feedback?: string;
  feedbackUpdatedByUser?: string;
  feedbackUpdatedAt?: string;
  ingestStatus?: 'in-progress' | 'success' | 'error';
  documentError?:
    | 'unreadable'
    | 'wrong-doc-type'
    | 'unknown-doc-type'
    | 'field-errors';
  documentErrorDetails?: {
    documentErrorFields?: {
      fieldKey: string;
      extractionError: {
        errorType: string;
        errorNotes?: string;
      };
    }[];
  };
  insights?: ClaimsAssistInsight[];
};

export type ClaimsAssistInsight = {
  insightId: string;
  createdBy: CreatedBy;
  query: string;
  title: string;
  answer: string;
  customAnswer: string;
  matches: SearchResultEntry[];
  decision: ClaimsAssistInsightDecision;
  createdAt: string;
  updatedAt: string;
  nextAction?: string;
  feedback?: string;
  feedbackDetails?: string;
  feedbackByUser?: string;
  feedbackUpdatedAt?: string;
  addToTimeline?: boolean;
  source?: 'owl' | 'user';
  segments?: (ClaimSegment | InsightSegment)[];
};

export type DeprecatedClaimsReviewItem = ClaimsAssistInsight | ClaimsDocument;
export interface ReviewItem extends ClaimsDocument {
  insights: ClaimsAssistInsight[];
  existingNotes: ClaimsNote[];
  existingActivities: ClaimActivity[];
}

export type ClaimReviewItem = ReviewItem;

export type ClaimActivity = {
  type: string;
  dueDate: string;
  dossierId: string;
  description?: string;
  checked?: boolean;
  activityId?: string;
  createdAt?: string;
  createdByUsername?: string;
  status?: string;
  insightId?: string;
  documents?: Pick<ClaimsDocument, 'docId'>[];
};
export interface StagedReviewItem extends ClaimReviewItem {
  docNotes?: { noteContent: string; noteId?: string }[];
  activities?: ClaimActivity[];
}

export type DossierEntry = V3Base;

export type SearchEntry = {
  searchId: string;
  dossierId: string;
  searchQuery: string;
  createdByUsername: string;
  createdAt: string;
};

export type DocumentSearchMatch = {
  content: string;
  dossierId: string;
  dot: number;
  embedding: number[];
  id: string;
  seq: number;
  summary: string;
  document: Pick<
    ClaimsDocument,
    'docId' | 'docName' | 'contentRawRef' | 'ingestStatus'
  >;
  page: number;
};

export type SearchResultEntry = {
  updatedAt: string;
  content: string;
  page: number;
  summary: string;
  id: string;
  embedding: number[];
  dot: number;
  seq: number;
  document: Pick<
    ClaimsDocument,
    'docId' | 'docName' | 'contentRawRef' | 'ingestStatus'
  >;
  dossierId: string;
};

export type SearchNoteResultEntry = {
  createdAt: string;
  createdByUsername: string;
  dossierId: string;
  dot: number;
  embedding: number[];
  noteContent: string;
  noteId: string;
  source: 'owl' | 'user';
  status: 'pending' | 'active';
  updatedByUsername: string;
};

export type ClaimsNote = {
  noteId: string;
  dossierId: string;
  noteContent: string;
  createdByUsername: string;
  createdAt: string;
  updatedByUsername: string;
  documents?: Pick<ClaimsDocument, 'docId' | 'docName' | 'contentRawRef'>[]; // Reusing part of ClaimsDocument
  source: 'owl' | 'user';
  status: 'pending' | 'active';
  summary?: string;
  insights?: {
    insightId: string;
    answer?: string;
    source?: 'owl' | 'user';
  }[];
  noteSubject?: string;
  completionTime?: string;
};

export type ClaimsNotes = {
  [dossierId: string]: ClaimsNote[];
};

export type ClaimSummary = {
  dossierSummaryId: string;
  dossierId: string;
  content: string;
  matches: {
    chunkId: string;
    content: string;
    page: number;
    document: {
      docId: string;
      docName: string;
      contentRawRef: {
        contentRawBucket: string;
        contentRawKey: string;
      };
      contentTextRef: string;
      ingestStatus?: 'in-progress' | 'success' | 'error';
    };
  }[];
  createdAt: string;
  updatedAt: string;
  createdByUsername: string;
  updatedByUsername: string;
};

export type ClaimsAssistFeedback = {
  feedbackId: string;
  feedbackReason: string;
  feedbackReasonDetail?: string;
  createdAt: string;
  createdByUsername: string;
};

export type ClaimNoteSummary = {
  noteSummaryId: string;
  content: string;
  fromMetadata: boolean;
  noteSummaryType?: string;
  categories?: { category: string }[];
  matches?: {
    docId: string;
    docName: string;
    page: number;
  }[];
  dossierId: string;
  createdAt: string;
  feedback?: ClaimsAssistFeedback;
  deletedAt?: string;
  deletedByUsername?: string;
  notes?: { noteId: string }[];
};

export type ClaimSummaries = {
  [dossierId: string]: ClaimSummary;
};

export type ClaimActivities = {
  [dossierId: string]: ClaimActivity;
};

export type EntityCitation = {
  docId: string;
  docName: string;
  relevantPages: number[];
};
export enum LAB_TEST_CATEGORY {
  CompleteBloodCount = 'complete-blood-count',
  LipidPanel = 'lipid-panel',
  Hormones = 'hormones',
  Urinalysis = 'urinalysis',
  ChemistryPanel = 'chemistry-panel',
  Imaging = 'imaging',
  Others = 'others',
}

export type DatapointMetadata = {
  dossierId: string;
  date: string;
  citation?: EntityCitation; // TODO: remove when db gets updated
  citations?: EntityCitation[];
  noteCitations?: { noteId: string }[];
  provider?: DatapointProvider;
  providers?: DatapointProvider[];
  feedback?: ClaimsAssistFeedback;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ClaimsDatapoint = DatapointMetadata & any;

export type ProviderAddress = {
  postalCode?: string;
  streetAddress?: string;
  city?: string;
  stateProvince?: string;
  country?: string;
};

export type ProviderAffiliation = {
  affiliatedHospitalClinic?: string;
  affiliationType?: string;
  labFacilityName?: string;
};

export type ProviderPractice = {
  practiceName: string;
  practiceType: string;
  hoursOfOperation: string;
};

export type DatapointProvider = DatapointMetadata & {
  providerId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  titles?: string[];
  medicalSpecializations?: string[];
  medicalCredentials?: string[];
  type?: string;
  isMedical?: boolean;
  additionalNotes?: string[];
  affiliations?: ProviderAffiliation[];
  addresses?: ProviderAddress[];
  contactInformation?: {
    phone_numbers?: string[];
    email_addresses?: string[];
    fax_numbers?: string[];
    websites?: string[];
  };
  practices?: ProviderPractice[];
};

export type DatapointMedicalCondition = DatapointMetadata & {
  medicalConditionId: string;
  name: string;
  conceptName?: string;
  snomedCode?: string;
  icd10Code?: string;
  icd10CodeBlock: string;
  icd10CodeBlockDescription?: string;
  icd10CodeDescription?: string;
  diagnosisCodes?: string[];
  acuity?: string;
  quality?: string;
  direction?: string;
  systemOrganSite?: string;
  diagnosisDate?: string;
  mentionDate?: string;
  lowConfidence?: boolean;
  negation?: boolean;
  isHistorical?: boolean;
  isFamily?: boolean;
  type?: string;
  diagnosingProviders?: DatapointProvider[];
  mentioningProviders?: DatapointProvider[];
};

export type DatapointMedicalTest = DatapointMetadata & {
  primaryProvider?: DatapointProvider;
  medicalTestId: string;
  name: string;
  date: string;
  section?: string;
  snomedScore?: number;
  cptCode?: string;
  cptCodeDescription?: string;
  isRefAlert?: boolean;
  refRange?: string;
  nameSnowmedCode?: string;
  value?: string;
  valueSnomedCode?: string;
  unit?: string;
  unitSnomedCode?: string;
  referenceRange?: string;
  category?: LAB_TEST_CATEGORY;
};

export type DatapointMedication = DatapointMetadata & {
  medicationId: string;
  name: string;
  genericName?: string;
  brandName?: string;
  isGeneric?: boolean;
  casNumber?: string;
  startDate?: string;
  endDate?: string;
  mentionDate?: string;
  prescriptionDate?: string;
  rxnormCode?: string;
  negation?: boolean;
  dosage?: string;
  num?: string;
  unit?: string;
  duration: string;
  form?: string;
  frequency?: string;
  instructions?: string;
  rate?: string;
  routeOrMode?: string;
  strength?: string;
  pastHistory?: boolean;
};

export type DatapointSubstance = DatapointMetadata & {
  substanceId: string;
  substance?: 'alcohol' | 'tobacco' | 'rec-drug';
  frequency?: string;
  amount?: string;
  duration?: string;
  negation?: boolean;
  pastHistory?: boolean;
};

export type DatapointAllergy = DatapointMetadata & {
  allergyId: string;
  name: string;
  negation?: boolean;
  pastHistory?: boolean;
};

export type DatapointProcedure = DatapointMetadata & {
  procedureId: string;
  name: string;
  date: string;
  type?: string;
  snomedCode?: string;
  negation?: boolean;
  future?: boolean;
  pastHistory?: boolean;
  bodyPart?: string;
  bodySystem?: string;
  cdOperation?: string;
  diagnosis?: string;
  diagnosisCodes?: string;
  icd10CodeDescription?: string;
  icd10CodeSymbol?: string;
  icdApproach?: string;
  duration?: string;
  frequency?: string;
  reason?: string;
  section?: string;
  mentionDate?: string;
};

export type DatapointRestriction = DatapointMetadata & {
  restrictionId: string;
  description: string;
  beginDate?: string;
  endDate?: string;
  justification?: string;
  reviewDate?: string;
  assessmentDate?: string;
};

export type DatapointAdlAssessment = DatapointMetadata & {
  adlAssessmentId: string;
  painLevel: number;
  activities: {
    abilityLevel: string;
    activity: string;
    adlActivityId: string;
  }[];
};

export type DatapointReturnToWork = DatapointMetadata & {
  returnToWorkId: string;
  returnDate?: string;
  workSchedule?: string;
  assessmentDate?: string;
  accommodationNeeds?: string;
  restriction?: DatapointRestriction;
};

export type DatapointVisit = DatapointMetadata & {
  visitId: string;
  date: string;
  chiefComplaint?: string;
  ccLocation?: string;
  ccAllAgg?: string;
  ccCharacterization?: string;
  ccDuration?: string;
  ccOnset?: string;
  ccRadiation?: string;
  ccSeverity?: string;
  ccTemporal?: string;
  hpi?: string;
  isIcdBillable?: boolean;
  category?: string;
  reason?: string;
  diagnosis?: string;
  primaryProvider?: DatapointProvider;
  referringProvider?: DatapointProvider;
  assessments?: string[];
  recommendations?: string[];
  treatments?: string[];
  examinationFindings?: {
    clinicalObservations?: string;
    physicalExamResults?: string;
  };
  plan?: {
    diagnosticTestsOrdered?: string;
    prescriptionsMedications?: string[];
    proceduresInterventions?: string;
    recommendedFollowUp?: string;
  };
  type?: string;
};

export type DatapointTreatmentProcedure = DatapointMetadata & {
  treatmentProcedureId: string;
  date: string;
  anesthesiologistName?: string;
  assistantSurgeons?: string;
  procedureDetails?: Record<string, string>;
  procedureExecution?: Record<string, string>;
  postProcedureDetails?: Record<string, string>;
  reportDetails?: Record<string, string>;
  provider?: DatapointProvider;
};

export type RehabTherapyAssessment = {
  initialAssessmentFindings?: string;
  progressNotes?: string;
};

export type RehabTherapyDetails = {
  exercisesInterventionsPerformed?: string;
  patientResponseToTherapy?: string;
  modificationsToTherapyPlan?: string;
};

export type RehabTherapyPlan = {
  goalsForTherapy?: string;
  recommendedFrequency?: string;
  homeExerciseProgram?: string;
};

export type RehabTherapySessionDetails = {
  dateOfSession?: string;
  typeOfTherapy?: string;
  reasonForTherapy?: string;
};

export type RehabTherapyReportDetails = {
  dateOfReport: string;
};

export type DatapointRehabTherapies = DatapointMetadata & {
  rehabilitationTherapyId: string;
  assessment?: RehabTherapyAssessment;
  therapyDetails?: RehabTherapyDetails[];
  plan?: RehabTherapyPlan;
  sessionDetails?: RehabTherapySessionDetails[];
  reportDetails?: RehabTherapyReportDetails;
  provider: DatapointProvider;
};

export type DatapointHospitalRecord = DatapointMetadata & {
  hospitalRecordId: string;
  admissionDetails: Record<string, any>;
  summaryOfHospitalStay: Record<string, any>;
  initialAssessment: Record<string, any>;
  dischargeDetails: Record<string, any>;
  dischargeInstructions: Record<string, any>;
  dischargeSummaryDetails: {
    dateOfDischargeSummary: string;
  };
  admissionOrders: Record<string, any>;
};

export type DatapointDiagnosticReport = DatapointMetadata & {
  diagnosticReportId: string;
  orderingProvider?: DatapointProvider[];
  reportDetails?: {
    dateOfReport?: string;
  };
  impressions?: {
    riskFactors?: string;
    actionableRecommendation?: string;
    diagnosticConclusions?: string;
  };
  results?: {
    comparisonToPreviousResults?: string;
    findingsMeasurements?: string;
    interpretationOfResults?: string;
    referenceRanges?: string;
  };
  testDetails?: {
    testType?: string;
    dateOfTest?: string;
    reasonForTest?: string;
    testLocation?: string;
    testMethodology?: string;
    sampleCollectionMethod?: string;
  };
};

// based on schema defined here: https://github.com/owldotraven/owlcode/blob/main/docsearch/src/owl/docsearch/schema/datapoint/encounter.clj
export type ClaimDatapoints = {
  dossierId: string;
  // other entities
  visits?: { data: DatapointVisit[]; total: number };
  medications?: { data: DatapointMedication[]; total: number };
  medicalConditions?: { data: DatapointMedicalCondition[]; total: number };
  substanceUses?: { data: DatapointSubstance[]; total: number };
  allergies?: { data: DatapointAllergy[]; total: number };
  tests?: { data: DatapointMedicalTest[]; total: number };
  procedures?: { data: DatapointProcedure[]; total: number };
  providers?: { data: DatapointProvider[]; total: number };
  restrictions?: { data: DatapointRestriction[]; total: number };
  adlAssessments?: { data: DatapointAdlAssessment[]; total: number };
  returnToWork?: { data: DatapointReturnToWork[]; total: number };
  treatmentProcedures?: { data: DatapointTreatmentProcedure[]; total: number };
  rehabilitationTherapies?: { data: DatapointRehabTherapies[]; total: number };
  hospitalRecords?: { data: DatapointHospitalRecord[]; total: number };
  diagnosticReports?: { data: DatapointDiagnosticReport[]; total: number };
};

export type ClaimDatapointsFetchResponse = {
  datapoints: {
    [category in Category]:
      | DatapointVisit[]
      | DatapointMedication[]
      | DatapointMedicalCondition[]
      | DatapointSubstance[]
      | DatapointAllergy[]
      | DatapointMedicalTest[]
      | DatapointProvider[]
      | DatapointRestriction[]
      | DatapointAdlAssessment[]
      | DatapointReturnToWork[]
      | DatapointTreatmentProcedure[]
      | DatapointRehabTherapies[]
      | DatapointHospitalRecord[]
      | DatapointDiagnosticReport[];
  } & {
    meta: {
      total: number;
      offset: number | null;
      limit: number | null;
    };
  };
};

export type ClaimDatapointsFetchParams = {
  datapointKey: Category;
  sortKey?: string;
  sortOrder?: 'asc' | 'desc';
  limit?: number;
  offset?: number;
  filters?: { [key: string]: string[] | boolean[] };
};

export type OdgCost = {
  indemnity: number;
  medical: number;
  expense: number;
  total: number;
};

export type OdgDetails = {
  icdCodeDescription: {
    icdCode: string;
    description: string;
  }[];
  returnToWorkDuration: {
    rtwMin: number;
    rtwBestPractice: number;
    rtwAvg: number;
    rtwMax: number;
  };
  costs: {
    costBestPractice: OdgCost;
    costAvg: OdgCost;
    costMax: OdgCost;
  };
  riskAssessment: {
    score: number;
  };
  bodyParts: {
    bodyPart: string;
    severity: string;
  }[];
  cptCodes: {
    cptCode: string;
    description: string;
  }[];
};

export type Category =
  | 'summary'
  | 'visits'
  | 'restrictions'
  | 'adlAssessments'
  | 'hospitalRecords'
  | 'rehabilitationTherapies'
  | 'returnToWork'
  | 'treatmentProcedures'
  | 'diagnosticReports'
  // "chart type" categories
  | 'medicalConditions'
  | 'medications'
  | 'procedures'
  | 'tests'
  | 'providers';
