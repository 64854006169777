export type TopologyTags = {
  stackDeployedFromCi: boolean;
  gitAuthorEmail: string;
  gitBranch: string;
  gitCommitDate: string;
  gitShaShort: string;
  stackDeployedBy: string;
  stackLastModified: string;
  topologyKey: string;
  owner?: string;
} & { [tag: string]: string };

export type DefaultVersions = { [topologyKey: string]: string };

export type TaskQueueArn = string;
export type ExecutorLambdaArn = string;
export type TopologyTaskQueue = {
  state: string;
  uuid: string;
  eventSourceArn: string;
};
export type TaskQueuePausedState = {
  arn: string;
  uuid: string;
  state: string;
};
export type TopologyTaskQueues = {
  taskQueues: TopologyTaskQueue[];
};
export type PausedTopologyTaskQueue = string;
export type PausedTaskQueues = {
  paused: PausedTopologyTaskQueue[];
};

export interface Topology {
  arn: string;
  topologyKey: string;
  tags: TopologyTags;
  env: string;
  version: string;
  stackStatus: string;
  isDefault: boolean;
  state?: TopologyState;
  taskQueueArns: TaskQueueArn[];
  executorLambdaArn?: ExecutorLambdaArn;
  hasTaskQueuePaused?: boolean;
}

export interface Lambda {
  arn: string;
  paused: boolean;
  concurrency: number;
}

export interface TopologyState {
  paused: boolean;
  lambdas: Lambda[];
}
