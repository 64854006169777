import type {
  Action,
  ActionCreator,
  ActionReducerMapBuilder,
  AnyAction,
  CaseReducer,
} from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';
import { createLogger } from '@owl-lib/logger';
import { __DEV__ } from '../constants';
import { isEmpty } from '../helpers';
import type { ConfigurableSliceInfo } from './slice';

const logger = createLogger(__filename);

declare module '@reduxjs/toolkit' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface ActionReducerMapBuilder<State> {
    // Augment to allow chaining in our ExtraBuilder.
    // Don't use in `rtk.createReducer` or `rtk.createSlice`.
    addSaga: ExtraBuilder<State>['addSaga'];
  }
}

export interface TypedActionCreator<Type extends string>
  extends ActionCreator<Action<Type>> {
  type: Type;
}

export interface SliceExtra<S> {
  actionsMap: { [actionType: string]: CaseReducer<S> };
  actionMatchers: ActionMatcherItem<S, any>[];
  defaultCaseReducer?: CaseReducer<S, AnyAction>;
  sagas: (() => Generator<unknown, void, SagaIterator>)[];
}

export interface ActionMatcherItem<S, A extends Action = AnyAction> {
  matcher: (action: AnyAction) => action is A;
  reducer: CaseReducer<S, A>;
}

export interface ExtraBuilder<State> extends ActionReducerMapBuilder<State> {
  addSaga(
    saga: () => Generator<unknown, void, SagaIterator>
  ): Pick<ExtraBuilder<State>, 'addSaga'>;
}

export const initialExtra = {
  actionsMap: {},
  actionMatchers: [],
  defaultCaseReducer: undefined,
  sagas: [],
};

export const isEmptyExtra = (
  extra: SliceExtra<any>
): extra is typeof initialExtra =>
  isEmpty(extra.actionsMap) &&
  extra.actionMatchers.length === 0 &&
  !extra.defaultCaseReducer &&
  extra.sagas.length === 0;

// mostly copied from rtk's `mapBuilders:executeReducerBuilderCallback`
export function executeReducerBuilderCallback<S>(
  slice: ConfigurableSliceInfo<S, string>,
  builderCallback: (builder: ExtraBuilder<S>) => void
): SliceExtra<S>;
export function executeReducerBuilderCallback<S>(
  slice: ConfigurableSliceInfo<S, string, Partial<Record<string, any>>>,
  builderCallback: (builder: ExtraBuilder<S>) => void
): SliceExtra<S> {
  const prev = slice?.extra ?? initialExtra;
  const actionsMap: SliceExtra<S>['actionsMap'] = {};
  const actionMatchers: SliceExtra<S>['actionMatchers'] = [];
  let defaultCaseReducer: SliceExtra<S>['defaultCaseReducer'];
  const sagas: SliceExtra<S>['sagas'] = [];
  const builder = {
    addCase(
      typeOrActionCreator: string | TypedActionCreator<string>,
      reducer: CaseReducer<S>
    ) {
      if (__DEV__) {
        /*
         to keep the definition by the user in line with actual behavior,
         we enforce `addCase` to always be called before calling `addMatcher`
         as matching cases take precedence over matchers
         */
        if (actionMatchers.length > 0 || prev.actionMatchers.length > 0) {
          logger.warn(
            new Error(
              '`builder.addCase` should only be called before calling `builder.addMatcher`'
            )
          );
        }
        if (defaultCaseReducer || prev.defaultCaseReducer) {
          logger.warn(
            new Error(
              '`builder.addCase` should only be called before calling `builder.addDefaultCase`'
            )
          );
        }
        if (sagas.length > 0 || prev.sagas.length > 0) {
          logger.warn(
            new Error(
              '`builder.addCase` should only be called before calling `builder.addSaga`'
            )
          );
        }
      }
      const type =
        typeof typeOrActionCreator === 'string'
          ? typeOrActionCreator
          : typeOrActionCreator.type;
      if (__DEV__) {
        if (slice && type.startsWith(`${slice.name}/`)) {
          throw new Error(
            'addCase cannot be used with core actions of this slice. Use `slice.addReducers`'
          );
        }
        if (type in actionsMap || type in prev.actionsMap) {
          throw new Error(
            'addCase cannot be called with two reducers for the same action type'
          );
        }
      }
      actionsMap[type] = reducer;
      return builder;
    },
    addMatcher<A extends AnyAction>(
      matcher: ActionMatcherItem<S, A>['matcher'],
      reducer: ActionMatcherItem<S, A>['reducer']
    ) {
      if (__DEV__) {
        if (defaultCaseReducer || prev.defaultCaseReducer) {
          logger.warn(
            new Error(
              '`builder.addMatcher` should only be called before calling `builder.addDefaultCase`'
            )
          );
        }
        if (sagas.length > 0 || prev.sagas.length > 0) {
          logger.warn(
            new Error(
              '`builder.addMatcher` should only be called before calling `builder.addSaga`'
            )
          );
        }
      }
      actionMatchers.push({ matcher, reducer });
      return builder;
    },
    addDefaultCase(reducer: CaseReducer<S, AnyAction>) {
      if (__DEV__) {
        if (defaultCaseReducer || prev.defaultCaseReducer) {
          throw new Error('`builder.addDefaultCase` can only be called once');
        }
        if (sagas.length > 0 || prev.sagas.length > 0) {
          logger.warn(
            new Error(
              '`builder.addDefaultCase` should only be called before calling `builder.addSaga`'
            )
          );
        }
      }
      defaultCaseReducer = reducer;
      return builder;
    },
    addSaga(saga: () => Generator<unknown, void, SagaIterator>) {
      sagas.push(saga);
      return builder;
    },
  };
  builderCallback(builder);
  return { actionsMap, actionMatchers, defaultCaseReducer, sagas };
}
