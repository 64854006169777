import Ajv from 'ajv';
import type { Options as AjvOptions } from 'ajv';
import ajvFormats from 'ajv-formats';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type {
  JSONSchema7,
  JSONSchema7Array,
  JSONSchema7Object,
} from 'json-schema';
import { isEqual } from 'lodash';
import {
  INTO_LEGACY_CONFIG,
  IngestionConfig,
  IngestionConfigKey,
} from '@owl-frontend/api-client/interface';
import type { Status } from '@owl-frontend/redux';
import type { ExtractFunction } from '@owl-lib/type-util';
import {
  __isProd,
  ISO_DATE_FORMAT,
} from '../../../../../../../shared/constants';

dayjs.extend(customParseFormat);

/**
 * Reverse map columnMappingConfig in order to easily map parsed JSON to valid Dossier data
 *
 * ex.
 * columnsToDossierFieldFn({ clientDossierId: ['apple'] }) // { apple: 'clientDossierId' };
 */
export const columnsToDossierFieldFn = (
  columnMappingConfig: IngestionConfig['columnMappingConfig']
): { [k: string]: keyof IngestionConfig['columnMappingConfig'] } => {
  return Object.keys(columnMappingConfig).reduce(
    (acc, curr) => ({
      ...acc,
      ...columnMappingConfig[curr].reduce(
        (currAcc, currCurr) => ({
          ...currAcc,
          [currCurr]: INTO_LEGACY_CONFIG[curr] ?? curr,
        }),
        {}
      ),
    }),
    {}
  );
};

export const getUnmappedFields = (
  columns: string[],
  ingestionColumnMappingConfig: IngestionConfig['columnMappingConfig']
): string[] => {
  const columnsToDossierField = columnsToDossierFieldFn(
    ingestionColumnMappingConfig
  );
  return columns.filter((column) => !columnsToDossierField[column]);
};

/**
 * MARK: INGESTION CONFIGURATION MAPPING
 */

export const isFieldKeyNames = (key: IngestionConfigKey): boolean =>
  key === 'firstName' ||
  key === 'middleName' ||
  key === 'lastName' ||
  key === 'suffix';

export const isFieldKeyAddresses = (key: IngestionConfigKey): boolean =>
  key === 'street' ||
  key === 'city' ||
  key === 'countryCode' ||
  key === 'regionCode' ||
  key === 'postalCode';

export const isFieldKeyOccupations = (key: IngestionConfigKey): boolean =>
  key === 'occupationCompany' ||
  key === 'occupationPosition' ||
  key === 'occupationStartDate' ||
  key === 'occupationEndDate';

export const isFieldKeyDossier = (key: IngestionConfigKey): boolean =>
  key === 'dateOfDisability' ||
  key === 'disabilityType' ||
  key === 'diagnosisCategory' ||
  key === 'claimCreationDate' ||
  key === 'claimClosedDate';

export const isFieldKeyDate = (key: IngestionConfigKey): boolean =>
  key === 'birthDate' ||
  key === 'dateOfDisability' ||
  key === 'claimCreationDate' ||
  key === 'claimClosedDate' ||
  key === 'occupationStartDate' ||
  key === 'occupationEndDate';

export const isFieldKeyList = (key: IngestionConfigKey): boolean =>
  key === 'emails' || key === 'phoneNumbers' || key === 'links';

export const columnMappingKeyToSchemaKey = {
  occupationCompany: 'company',
  occupationPosition: 'position',
  occupationStartDate: 'startDate',
  occupationEndDate: 'endDate',
  dateOfDisability: 'dateOfDisability',
  disabilityType: 'disabilityType',
  diagnosisCategory: 'diagnosisCategory',
  claimCreationDate: 'creationDate',
  claimClosedDate: 'closedDate',
};

export const formatRawDateToIso = ({
  raw,
  ingestionConfigDateFormat,
}: {
  raw: string;
  ingestionConfigDateFormat: string;
}): string => {
  const parsed = dayjs(raw, ingestionConfigDateFormat.toUpperCase(), true);
  if (!parsed.isValid() || parsed.format(ISO_DATE_FORMAT) === 'Invalid Date') {
    throw new Error(
      `Invalid date input w/ dateFormat! "${raw}", ${ingestionConfigDateFormat}`
    );
  }
  return parsed.format(ISO_DATE_FORMAT);
};

export const extractListFieldKeys = (data: JSONSchema7Object): string[] => {
  const result: string[] = [];
  for (const key of Object.keys(data)) {
    if (Array.isArray(data[key])) {
      result.push(key);
    }
  }
  return result;
};

export const dedupeListFields = (
  data: JSONSchema7Object
): JSONSchema7Object => {
  const copy = { ...data };
  const listFieldKeys = extractListFieldKeys(copy);

  for (const listField of listFieldKeys) {
    const result: JSONSchema7Object[] = [];

    for (let i = 0; i < (data[listField] as JSONSchema7Array).length; i++) {
      const entry = data[listField]?.[i];
      let j = 0;
      let exists = false;

      while (j < result.length && !exists) {
        // lodash performs deep comparison between two values
        // "Objects are compared by their own, not inherited, enumerable properties."
        if (isEqual(result[j], entry)) {
          exists = true;
        }

        j++;
      }

      if (!exists) {
        result.push(entry);
      }
    }

    copy[listField] = result;
  }

  return copy;
};

export const mergeByClientDossierId = (clientDossierIdMap: {
  [clientDossierId: string]: Array<{ clientDossierId: string } & any>;
}): JSONSchema7Object[] => {
  const result: JSONSchema7Object[] = [];

  for (const id of Object.keys(clientDossierIdMap)) {
    result.push(
      clientDossierIdMap[id].reduce((acc, curr) => {
        const data = { ...acc };
        for (const field of Object.keys(curr)) {
          if (!curr[field]) {
            continue;
          }

          if (field === 'clientDossierId' || !data[field]) {
            data[field] = curr[field];
            continue;
          }

          if (!Array.isArray(data[field]) || !Array.isArray(curr[field])) {
            if (!isEqual(data[field], curr[field])) {
              throw new Error(
                `Attempted to merge unequal non-list fields!: ${field}; clientDossierId: ${id}`
              );
            }

            data[field] = curr[field];
            continue;
          }

          if (typeof data[field] !== typeof curr[field]) {
            throw new Error(`Misaligned field types!: ${field}`);
          }

          data[field] = [...data[field], ...curr[field]];
        }

        return dedupeListFields(data);
      }, {})
    );
  }

  return result;
};

function createAjvInstance(options: AjvOptions = {}) {
  const ajv = new Ajv({
    strict: false,
    useDefaults: true,
    allErrors: true,
    multipleOfPrecision: 8,
    allowDate: true,
    validateSchema: __isProd ? false : 'log',
    // validateFormats: false,
    ...options,
  });
  ajvFormats(ajv);
  // ajv.addFormat('email', emailRegex);

  return ajv;
}

export const ajv = createAjvInstance({ verbose: true });

type AjvValidateFunction = ReturnType<typeof ajv.compile>;
type Validator = {
  (data: JSONSchema7Object): {
    status: Status;
    messages?: string[];
    data?: JSONSchema7Object;
  };
  validate: AjvValidateFunction;
};

let prev: Validator | undefined; // memo one
export const getValidator = (schema: JSONSchema7) => {
  const validate = ajv.compile(schema);
  if (prev?.validate === validate) {
    return prev;
  }
  const fn: ExtractFunction<Validator> & Partial<Validator> = (data) => {
    const isValid = validate(data);
    if (isValid) {
      return { data, status: 'fulfilled', messages: undefined };
    }
    return {
      data,
      status: 'rejected',
      messages: (validate.errors ?? []).map((errObj) =>
        ajv.errorsText([errObj])
      ),
    };
  };
  fn.validate = validate;
  return fn as Validator;
};
