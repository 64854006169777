import { useState, useEffect, RefObject, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useResize = (ref: RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState(0);

  const _handleResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setWidth(ref.current.offsetWidth);
  }, [setWidth, ref]);

  const handleResize = useDebouncedCallback(_handleResize, 100);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { width };
};
