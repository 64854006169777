import {
  Action,
  CaseReducer,
  createNextState,
  Draft,
  isDraft,
} from '@reduxjs/toolkit';
import type { Empty } from '@owl-lib/type-util';
import { __DEV__ } from './constants';

export const identity = <T>(value: T): T => value;
export const valProp = <T>(value: T) =>
  ({ writable: true, enumerable: true, configurable: true, value } as const);
export const isEmpty = <T extends { [k: string]: any }>(
  o: T | Empty<T>
): o is Empty<T> => Object.keys(o).length === 0;
/** Immutable merge */
export const iMerge = <
  T extends { [k: string]: any },
  U extends { [k: string]: any }
>(
  a: T,
  b: U
): T & U =>
  (a as T & U) === b || isEmpty(b)
    ? (a as T & U)
    : isEmpty(a)
    ? (b as T & U)
    : { ...a, ...b };
export const iConcat = <T, U = T>(a: T[], b: U[]): (T | U)[] =>
  (a as any) === b || b.length === 0 ? a : a.length === 0 ? b : [...a, ...b];
export const composeCaseReducer = <S, A extends Action<any>>(
  f?: CaseReducer<S, A>,
  g?: CaseReducer<S, A>
): CaseReducer<S, A> | undefined =>
  f === g || !g
    ? f
    : !f
    ? g
    : (state, action) => {
        const fResult = f(state, action);
        if (!fResult) {
          return g(state, action);
        }
        if (isDraft(fResult)) {
          return g(fResult as Draft<S>, action);
        }
        return createNextState(
          fResult as S,
          (draft) => g(draft, action) as Draft<S>
        ) as Draft<S>;
      };

export type SliceActionType<
  SliceName extends string,
  ActionKey extends string
> = `${SliceName}/${ActionKey}`;
/** copy and ts4.1-ify of redux-toolkit:src/createSlice:getType */
export function resolveType<SliceName extends string, ActionKey extends string>(
  slice: SliceName,
  actionKey: ActionKey
): SliceActionType<SliceName, ActionKey> {
  return `${slice}/${actionKey}` as const;
}
export function getActionKey<ActionKey extends string>(
  actionType: SliceActionType<string, ActionKey>
): ActionKey {
  const lastSlashIndex = actionType.lastIndexOf('/');
  if (__DEV__ && lastSlashIndex < 0) {
    throw new Error(`Invalid slice action type ${actionType}`);
  }
  return actionType.slice(lastSlashIndex) as ActionKey;
}

export type GetTypeLoose<
  SliceName extends string,
  ActionKey extends string
> = string extends SliceName
  ? SliceActionType<string, ActionKey> | ActionKey
  : SliceName extends ''
  ? ActionKey
  : SliceActionType<SliceName, ActionKey>;
export function resolveTypeLoose<
  SliceName extends string,
  ActionKey extends string
>(slice: SliceName, actionKey: ActionKey): GetTypeLoose<SliceName, ActionKey> {
  type T = GetTypeLoose<SliceName, ActionKey>;
  return (slice ? resolveType(slice, actionKey) : actionKey) as T;
}
