import { generatePath as generatePathRR } from 'react-router-dom';
import type { Empty } from '@owl-frontend/components';
import { CombineObjectType, enumSet } from '@owl-lib/type-util';
import type { GeneratePathParam } from '../../helpers/route-types';

export enum RouteDefs {
  Main = '/',
  Tenants = '/tenants',
  TenantDetails = '/tenants/:tenantId',
  TenantUserDetails = '/tenant-users/:tenantId',
  DossiersUpload = '/tenants/:tenantId/files-upload',
  DossiersPreview = '/tenants/:tenantId/preview-upload',
  Tasks = '/tasks',
  ClaimantInfo = '/tasks/claimant-info',
  Users = '/users',
  FowlTopologies = '/fowl/topologies',
  FulfillmentCapacity = '/fulfillment-capacity',
  Feedback = '/feedback',
  TenantUsers = '/tenant-users',
  Claims = '/claims',
  ClaimsHome = '/claims/home',
  ClaimsList = '/claims/:tenantId/claims-list',
  ClaimDetails = '/claims/:tenantId/claims-list/:dossierId',
  ClaimChunks = '/claims/chunks',
  ClaimChunksTenant = '/claims/chunks/:tenantId',
  ClaimChunksDossier = '/claims/chunks/:tenantId/:dossierId',
  ClaimChunksDocument = '/claims/chunks/:tenantId/:dossierId/:docId',
  ClaimChunkDetails = '/claims/chunks/:tenantId/:dossierId/:docId/:chunkId',
  ClaimsAnalytics = '/claims/analytics',
  ClaimsAnalyticsTenant = '/claims/:tenantId/analytics',
  ClaimsInsightsConfig = '/claims/:tenantId/insights-config',
  ClaimsMLConfig = '/claims/claims-ml-config',
  ClaimsHistory = '/claims/:tenantId/claims-list/:dossierId/history',
  ClaimsAdmin = '/claims-admin',
  ClaimsAdminErrorDocs = '/claims-admin/error-docs',
  ClaimsAdminErrorDocsTenant = '/claims-admin/error-docs/:tenantId',
  ClaimsAdminErrorDocsTenantDoc = '/claims-admin/error-docs/:tenantId/:docId',
  ClaimsAdminInsightsConfig = '/claims-admin/insights-config',
  ClaimsAdminMLConfig = '/claims-admin/ml-config',
}

export const routeSet = enumSet(RouteDefs);

// example
// type LoginRouteParams = ExtractRouteParams<RootRoute.Login>

type GeneratePathRest<Params> = Empty extends Params
  ? [params?: Params]
  : [params: Params];
type TypedGeneratePath = <R extends string>(
  path: R,
  ...rest: GeneratePathRest<CombineObjectType<GeneratePathParam<R>>>
) => string;
export const generatePath = generatePathRR as TypedGeneratePath;
