import type { JSONSchema7Object } from 'json-schema';
import { parse as parseCsv, ParseResult } from 'papaparse';
import { addLast, getIn, setIn } from 'timm';
import type { SagaGenerator } from 'typed-redux-saga';
import { all, call, delay } from 'typed-redux-saga';
import { v4 as uuid } from 'uuid';
import { asyncActionStateMatchers } from '@owl-frontend/redux';
import type { LongTermDisability } from '@owl-lib/form-schema-config';

import {
  columnMappingKeyToSchemaKey,
  columnsToDossierFieldFn,
  getUnmappedFields,
  isFieldKeyAddresses,
  isFieldKeyDate,
  isFieldKeyDossier,
  isFieldKeyList,
  isFieldKeyNames,
  isFieldKeyOccupations,
  mergeByClientDossierId,
  formatRawDateToIso,
  getValidator,
} from './helpers';
import ingestionSlice, {
  ParsedData,
  UploadConfig,
  ValidatedRow,
} from './interface';

const TENANT_ID_REGEX =
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g;

export const extractTenantIdFromFilename = (filename) => {
  if (!filename) {
    return;
  }

  return filename.match(TENANT_ID_REGEX)?.[0];
};

/** 32KiB */
const IN_WORKER_SIZE = 32 << 10; // eslint-disable-line no-bitwise
export async function parseAsync(file: File): Promise<ParseResult<string[]>> {
  return new Promise<ParseResult<string[]>>((resolve, reject) => {
    parseCsv(file, {
      worker: file.size > IN_WORKER_SIZE,
      complete: resolve,
      error: reject,
      skipEmptyLines: true,
    });
  });
}

function* parseUploadFile(action: {
  payload: {
    file: File;
    tenantId: string;
    config: UploadConfig;
  };
}): SagaGenerator<{
  data: string[][];
  filename: string;
  config: UploadConfig;
}> {
  const { file, config, tenantId } = action.payload;

  if (file.type !== 'text/csv') {
    throw new Error('Invalid file type');
  }

  const { data, errors } = yield* call(parseAsync, file);

  if (errors.length > 0) {
    throw new Error(`Failed to parse CSV: ${JSON.stringify(errors, null, 2)}`);
  }

  const tenantIdFromFilename = extractTenantIdFromFilename(file.name);

  if (!tenantIdFromFilename) {
    throw new Error('Missing tenantId in the filename!');
  }

  if (tenantIdFromFilename !== tenantId) {
    throw new Error('tenantId in the filename does not match target tenantId!');
  }

  return {
    data,
    filename: file.name,
    config,
  };
}

function* parseRowsToJson(action: {
  payload: {
    rows: string[][];
    config: UploadConfig;
  };
}): SagaGenerator<{
  unmappedColumnFields: string[];
  data: ParsedData[];
}> {
  // ugly force async (forces state updates on uninitialized -> pending -> fulfilled/rejected)
  yield* delay(0, undefined);

  const { rows, config } = action.payload;
  const headers = rows[0].map((headerField) => headerField.trim());
  const data = rows
    .slice(1)
    .map((row) => row.map((cellData) => cellData.trim()));

  const parsed = yield* all(
    data.map((row) => {
      const entry: ParsedData = {};
      for (const [index, value] of row.entries()) {
        entry[headers[index]] = value;
      }

      return entry;
    })
  );

  return {
    unmappedColumnFields: getUnmappedFields(
      headers,
      config.ingestionConfig.columnMappingConfig
    ),
    data: parsed as ParsedData[],
  };
}

function* normalizeAndMergeParsedJson(action: {
  payload: {
    parsedData: ParsedData[];
    config: UploadConfig;
  };
}): SagaGenerator<{
  unmappedFields: string[];
  normalizedData: JSONSchema7Object[];
}> {
  // ugly force async (forces state updates on uninitialized -> pending -> fulfilled/rejected)
  yield* delay(0, undefined);
  const { parsedData, config } = action.payload;
  const { ingestionConfig } = config;
  const dossierFieldMapping = columnsToDossierFieldFn(
    ingestionConfig.columnMappingConfig
  );
  const unmappedFields: string[] = [];
  const normalizedData = yield* all(
    parsedData.map((d) => {
      let result: Partial<LongTermDisability> = {
        emails: [],
        phoneNumbers: [],
        links: [],
      };

      for (const parsedKey of Object.keys(d)) {
        const fieldKey = dossierFieldMapping[parsedKey];

        if (!fieldKey) {
          unmappedFields.push(parsedKey);
          result = setIn(
            result,
            ['extra', parsedKey],
            d[parsedKey]
          ) as LongTermDisability;
          continue;
        }

        let input = d[parsedKey];

        if (!input) {
          continue;
        }

        if (isFieldKeyDate(fieldKey)) {
          input = formatRawDateToIso({
            raw: input,
            ingestionConfigDateFormat: ingestionConfig.dateFormat,
          });
        }

        if (isFieldKeyNames(fieldKey)) {
          const path = ['names', 0, fieldKey];
          const value = getIn(result, path)
            ? `${getIn(result, path)}, ${input}`
            : input;
          result = setIn(result, path, value) as LongTermDisability;
          continue;
        }

        if (isFieldKeyAddresses(fieldKey)) {
          const path = ['addresses', 0, fieldKey];
          const value = getIn(result, path)
            ? `${getIn(result, path)}, ${input}`
            : input;
          result = setIn(result, path, value) as LongTermDisability;
          continue;
        }

        if (isFieldKeyOccupations(fieldKey)) {
          const path = [
            'occupations',
            0,
            columnMappingKeyToSchemaKey[fieldKey],
          ];
          const value = getIn(result, path)
            ? `${getIn(result, path)}, ${input}`
            : input;
          result = setIn(result, path, value) as LongTermDisability;
          continue;
        }

        if (isFieldKeyDossier(fieldKey)) {
          const path = ['dossier', columnMappingKeyToSchemaKey[fieldKey]];
          const value = getIn(result, path)
            ? `${getIn(result, path)}, ${input}`
            : input;
          result = setIn(result, path, value) as LongTermDisability;
          continue;
        }

        if (isFieldKeyList(fieldKey)) {
          const path = [fieldKey];
          result = setIn(
            result,
            path,
            addLast((getIn(result, path) as Array<string>) ?? [], input)
          ) as LongTermDisability;
          continue;
        }

        if (fieldKey && input) {
          result = setIn(result, [fieldKey], input) as LongTermDisability;
        }
      }

      return result;
    })
  );

  const clientDossierId = (normalizedData as LongTermDisability[]).reduce(
    (acc, curr) => ({
      ...acc,
      [curr.clientDossierId]: acc[curr.clientDossierId]
        ? [...acc[curr.clientDossierId], curr]
        : [curr],
    }),
    {}
  );

  return {
    unmappedFields,
    normalizedData: mergeByClientDossierId(clientDossierId),
  };
}

function* validateData(action: {
  payload: {
    normalizedData: JSONSchema7Object[];
    config: UploadConfig;
  };
}): SagaGenerator<ValidatedRow[]> {
  // ugly force async (forces state updates on uninitialized -> pending -> fulfilled/rejected)
  yield* delay(0, undefined);
  const {
    normalizedData,
    config: { schema },
  } = action.payload;
  const validator = getValidator(schema);
  return normalizedData
    .map((d) => ({
      id: uuid(),
      ...validator(d),
    }))
    .sort((a, b) => {
      if (a.status === 'rejected') {
        return -1;
      }

      if (b.status === 'rejected') {
        return 1;
      }

      return 0;
    });
}

const slice = ingestionSlice
  .addAsyncSagas({
    parseUploadFile,
    parseRowsToJson,
    normalizeAndMergeParsedJson,
    validateData,
  })
  .addReducers({
    'parseUploadFile/fulfilled': (state, action) => {
      state.parsedUploadFile = action.payload.data;
      state.filename = action.payload.filename;
      state.config = action.payload.config;
    },
    'parseRowsToJson/fulfilled': (state, action) => {
      state.unmappedColumnFields = action.payload.unmappedColumnFields;
      state.parsedJson = action.payload.data as ParsedData[];
    },
    'normalizeAndMergeParsedJson/fulfilled': (state, action) => {
      state.normalizedData = action.payload.normalizedData;
    },
    'validateData/fulfilled': (state, action) => {
      state.validatedData = action.payload;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
