import { createAction } from '@reduxjs/toolkit';
import { takeEvery } from 'redux-saga/effects';
import { createLogger } from '@owl-lib/logger';
import type { EmptyOr, RecordOf } from '@owl-lib/type-util';
import { __DEV__ } from '../../constants';
import { resolveType } from '../../helpers';
import type { CaseSagaOrCustom } from '../../sagaToolkit/interface';
import { createActionsFromCases } from '../actions';
import type {
  ActionsDefWithMeta,
  AnyPayloadAction,
  AnySliceCase,
  CasePreparers,
  CasePreparerType,
  CaseSagas,
  MergeDef,
  SliceActionCreators,
  SliceCases,
} from '../interface';
import type { ConfigurableSliceInfo, ConfigurableSliceMembers } from '../slice';
import {
  createAsyncActionPrepare,
  createAsyncSaga,
  createAsyncStatusCases,
} from './create';
import type {
  AsyncActionMeta,
  AsyncActionsDef,
  AsyncSagaConfig,
  AsyncSagaOptions,
  AsyncSagaStatusActionPreparers,
  AsyncSliceCase,
} from './interface';

const logger = createLogger(__filename);

export { rejectWithValue } from './create';

// eslint-disable-next-line @typescript-eslint/ban-types
function extractOrCreateAsyncStatusActions<
  ActionsDef,
  SagaApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
>(
  sliceName: string,
  actionKey: string,
  /** may be mutated */
  cases: SliceCases<any, ActionsDef, any>,
  /** may be mutated */
  actions: SliceActionCreators<ActionsDef, any>,
  options?: AsyncSagaOptions<SagaApiConfig>
): SliceActionCreators<
  AsyncSagaStatusActionPreparers<any, AnyPayloadAction, SagaApiConfig>,
  string
> {
  const pendingKey = resolveType(actionKey, 'pending');
  const fulfilledKey = resolveType(actionKey, 'fulfilled');
  const rejectedKey = resolveType(actionKey, 'rejected');
  const pending = actions[pendingKey];
  const fulfilled = actions[fulfilledKey];
  const rejected = actions[rejectedKey];
  if (pending && fulfilled && rejected) {
    return { pending, fulfilled, rejected };
  }

  const statusActionCases = createAsyncStatusCases(options);
  const actionType: string = resolveType(sliceName, actionKey);
  const statusActions = createActionsFromCases(actionType, statusActionCases);
  cases[pendingKey] = statusActionCases.pending;
  cases[fulfilledKey] = statusActionCases.fulfilled;
  cases[rejectedKey] = statusActionCases.rejected;
  actions[pendingKey] = statusActions.pending;
  actions[fulfilledKey] = statusActions.fulfilled;
  actions[rejectedKey] = statusActions.rejected;
  return statusActions;
}

type AddAsyncActionsResult<
  State,
  Name extends string,
  ActionsDef,
  DefNext,
  ReturnTypes extends RecordOf<DefNext, any>,
  SagaApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
> = EmptyOr<
  Pick<
    ConfigurableSliceMembers<
      State,
      Name,
      MergeDef<
        ActionsDef,
        AsyncActionsDef<DefNext, ReturnTypes, SagaApiConfig, Name>
      >
    >,
    'actions' | 'cases'
  >
>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function addAsyncActions<
  ActionsDef,
  DefNext,
  SagaApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
>(
  slice: ConfigurableSliceInfo<any, any, ActionsDef>,
  preparers: CasePreparers<DefNext>,
  options?: AsyncSagaOptions<SagaApiConfig>
): AddAsyncActionsResult<
  any,
  any,
  ActionsDef,
  DefNext,
  RecordOf<DefNext, any>,
  SagaApiConfig
> {
  type Cur = ConfigurableSliceInfo<any, string, ActionsDef>;
  type NextActionsDef = MergeDef<
    ActionsDef,
    ActionsDefWithMeta<DefNext, AsyncActionMeta>
  >;
  type Next = ConfigurableSliceMembers<any, string, NextActionsDef>;

  const actionKeys = Object.keys(preparers);
  if (actionKeys.length === 0) return {};

  let newCases = false;
  const actions = { ...slice.actions } as Cur['actions'] & Next['actions'];
  const cases: Record<string, AnySliceCase> = { ...slice.cases };
  for (const actionKey of actionKeys) {
    const prepare: CasePreparerType = preparers[actionKey as keyof DefNext];
    const oldCase = cases[actionKey] as AsyncSliceCase<any, any> | undefined;
    if (oldCase) {
      if (__DEV__ && oldCase.prepare.inner !== prepare) {
        throw new Error(
          `Action ${actionKey} already defined as ${oldCase.prepare}`
        );
      }
      if (__DEV__ && oldCase.asyncOptions !== options) {
        logger.warn(oldCase, `Saga ${actionKey} async options mismatch`);
        throw new Error(`Saga ${actionKey} async options mismatch`);
      }
      continue;
    }
    newCases = true;
    const asyncPrepare =
      // prettier-align
      createAsyncActionPrepare<any, SagaApiConfig>(prepare, options);
    cases[actionKey] = { prepare: asyncPrepare, asyncOptions: options };
    actions[actionKey] = createAction(
      resolveType(slice.name, actionKey),
      asyncPrepare
    );
    extractOrCreateAsyncStatusActions(
      slice.name,
      actionKey,
      cases as Next['cases'],
      actions,
      options
    );
  }
  type R = AddAsyncActionsResult<
    any,
    any,
    ActionsDef,
    DefNext,
    RecordOf<DefNext, any>,
    SagaApiConfig
  >;
  return (newCases ? { actions, cases } : {}) as R;
}

type AddAsyncSagasResult<
  State,
  Name extends string,
  ActionsDef,
  DefNext,
  ReturnTypes extends RecordOf<DefNext, any>,
  SagaApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
> = Partial<
  Pick<
    ConfigurableSliceMembers<
      State,
      Name,
      MergeDef<
        ActionsDef,
        ActionsDefWithMeta<ActionsDef, AsyncActionMeta> &
          AsyncActionsDef<DefNext, ReturnTypes, SagaApiConfig, Name>
      >
    >,
    'actions' | 'cases'
  >
>;
export function addAsyncSagas<
  ActionsDef,
  DefNext,
  ReturnTypes extends RecordOf<DefNext, any>,
  SagaApiConfig extends AsyncSagaConfig = {} // eslint-disable-line @typescript-eslint/ban-types
>(
  slice: ConfigurableSliceInfo<any, any, ActionsDef>,
  caseSagas: Partial<CaseSagas<ActionsDef & DefNext, string>>,
  opts?: AsyncSagaOptions<SagaApiConfig>
): AddAsyncSagasResult<
  any,
  any,
  ActionsDef,
  DefNext,
  ReturnTypes,
  SagaApiConfig
> {
  type Cur = ConfigurableSliceMembers<any, any, ActionsDef>;
  type ActionsDefNext = ActionsDefWithMeta<ActionsDef, AsyncActionMeta> &
    AsyncActionsDef<DefNext, ReturnTypes, SagaApiConfig, any>;
  type Next = ConfigurableSliceMembers<any, any, ActionsDefNext>;

  const actionKeys = Object.keys(caseSagas);
  if (actionKeys.length === 0) return {};

  const sliceName = slice.name;

  const globalScheduler = opts?.scheduler || takeEvery;

  let newCases = false;
  const actions = { ...slice.actions } as Cur['actions'] & Next['actions'];
  const cases: Record<string, AnySliceCase> = { ...slice.cases };
  for (const actionKey of actionKeys) {
    let asyncSaga = caseSagas[actionKey as keyof DefNext] as CaseSagaOrCustom;
    let scheduler = globalScheduler;
    if (typeof asyncSaga !== 'function') {
      if (asyncSaga.scheduler) scheduler = asyncSaga.scheduler;
      asyncSaga = asyncSaga.saga;
    }

    const oldCase = cases[actionKey] as AsyncSliceCase<any, any> | undefined;
    if (oldCase?.saga) {
      if (__DEV__ && oldCase.saga.inner !== asyncSaga) {
        logger.warn(oldCase, `Saga ${actionKey} already defined`);
        throw new Error(`Saga ${actionKey} already defined`);
      }
      continue;
    }
    if (__DEV__ && oldCase && oldCase.asyncOptions !== opts) {
      logger.warn(oldCase, `Saga ${actionKey} async options mismatch`);
      throw new Error(`Saga ${actionKey} async options mismatch`);
    }
    newCases = true;
    const statusActions = extractOrCreateAsyncStatusActions(
      slice.name,
      actionKey,
      cases as Next['cases'],
      actions,
      opts
    );
    const saga = createAsyncSaga(statusActions, asyncSaga, opts);
    if (oldCase) {
      cases[actionKey] = { ...oldCase, saga, scheduler, asyncOptions: opts };
    } else {
      const prepare = createAsyncActionPrepare<any, SagaApiConfig>(true, opts);
      cases[actionKey] = { prepare, saga, scheduler, asyncOptions: opts };
      actions[actionKey] = createAction(
        resolveType(sliceName, actionKey),
        prepare
      );
    }
  }
  // const def = {} as ActionsDef & ActionsDefNext as MergeDef<ActionsDef, ActionsDefNext>;
  // type Result = AddAsyncActionsResult<any, any, ActionsDef, DefNext>;
  return (newCases ? { actions, cases } : { cases }) as Cur & Next as any; // as Result;
}
