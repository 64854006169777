import type { Field } from './Filter';
import type { Filter, FilterValue, Value } from './FilterReducer';

export const DEFAULT_OPERATOR = 'in';
export const DEFAULT_OPERATORS = {
  text: '=',
  number: '=',
  'date-range': 'between',
};

const getValue = (val: Value, nullValue: string) =>
  Array.isArray(val)
    ? val.map((v) => (nullValue === v ? null : v))
    : nullValue === val
    ? null
    : val;

export const mapFilterToQuery = <FieldName extends string = string>(
  filters: Filter<FieldName>,
  searchTerm: string | null,
  searchTermKey: FieldName | null,
  fields: Field<FieldName>[]
) => {
  const fieldsMap = fields.reduce(
    (r, { name, ...rest }) => ({
      ...r,
      [name]: rest,
    }),
    {} as { [K in FieldName]: Omit<Field, 'name'> }
  );

  let filterBy = (Object.entries(filters) as [FieldName, FilterValue][])
    .filter(([, filterValue]) => {
      const { value } = filterValue ?? {};
      return (
        (value === 0 || Boolean(value)) &&
        !(Array.isArray(value) && value.length === 0)
      );
    })
    .map(([filter, filterValue]: [FieldName, FilterValue]) => {
      const { type, nullValue, operator, convertValue } =
        fieldsMap[filter] ?? {};
      const { operator: op, value: val } = filterValue ?? {};

      return {
        operator: op ?? operator ?? DEFAULT_OPERATORS[type] ?? DEFAULT_OPERATOR,
        field: filter,
        value: convertValue ? convertValue(val) : getValue(val, nullValue!),
      };
    });

  if (searchTerm && searchTermKey) {
    filterBy = [
      {
        operator: 'ilike',
        field: searchTermKey,
        value: `%${searchTerm}%`,
      },
      ...filterBy,
    ];
  }

  return filterBy;
};
