import React, { ReactNode, useCallback } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { Button, Dropdown, Menu as AntdMenu } from 'antd';
import type { RemapObjectType } from '@owl-lib/type-util';
import type { AriaLabelProps, CoreHTMLProps } from '../../shared/interface';
import Icon from '../Icon/Icon';
import styles from './Menu.module.scss';

export type MenuItemPropsBase<MenuKey extends string = string> = {
  key: MenuKey;
  label?: string | ReactNode;
  icon?: ReactNode;
  href?: string;
  disabled?: boolean;
  divider?: boolean;
  'data-testid'?: string;
};

export type MenuItemProps<MenuKey extends string = string> =
  MenuItemPropsBase<MenuKey> &
    ({ label: string | ReactNode } | { divider: true });

type MenuItemClickEvent = React.MouseEvent<
  HTMLButtonElement | HTMLAnchorElement,
  MouseEvent
>;

export interface MenuProps<MenuKey extends string = string>
  extends CoreHTMLProps,
    AriaLabelProps {
  menuItems?: MenuItemProps<MenuKey>[];
  content?: React.ReactNode;
  onClick?: (key: MenuKey, event: MenuItemClickEvent) => void;
  menuClassName?: string;
  buttonClassName?: string;
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
  disabled?: boolean;
  focusable?: boolean;
  inPortal?: boolean;
}

interface MenuComponent extends RemapObjectType<React.FC<MenuProps>> {
  <MenuKey extends string = string>(
    props: React.PropsWithChildren<MenuProps<MenuKey>>
  ): React.ReactElement<any, any>;
}

const Menu: MenuComponent = (props) => {
  const {
    menuItems,
    onClick,
    children = (
      <Icon>
        <MenuOutlined />
      </Icon>
    ),
    buttonClassName,
    menuClassName,
    content,
    placement,
    'aria-label': ariaLabel = 'menu',
    disabled = !menuItems,
    focusable,
    ...rest
  } = props;

  const onMenuItemClick = useCallback(
    (key, event) => {
      onClick?.(key, event);
    },
    [onClick]
  );

  const menu = (
    <AntdMenu className={menuClassName}>
      {menuItems?.map(({ key, ...menuItemProps }) => {
        if (menuItemProps.divider) {
          return <AntdMenu.Divider key={key} />;
        }

        const { icon, label, disabled: itemDisabled } = menuItemProps;

        return (
          <AntdMenu.Item
            key={key}
            className={clsx(styles.menuItem, itemDisabled && styles.disabled)}
            onClick={(e) => {
              e.domEvent.stopPropagation();
              e.domEvent.preventDefault();
              onMenuItemClick(key, e.domEvent);
            }}
            disabled={itemDisabled}
            {...menuItemProps}
          >
            {icon && <Icon>{icon}</Icon>}
            <span>{label}</span>
          </AntdMenu.Item>
        );
      })}
    </AntdMenu>
  );

  return (
    <Dropdown overlay={menu} placement={placement} disabled={disabled}>
      <Button
        {...rest}
        className={buttonClassName}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        {children}
      </Button>
    </Dropdown>
  );
};

export default Menu;
