import type {
  ReinvestigationsResponse,
  UploadReportRequest,
} from '@owl-frontend/api-client';
import type { ValidatedRow } from '@owl-frontend/components';
import type { AsyncActionState } from '@owl-frontend/redux';
import { setupSlice } from '@owl-frontend/redux';

export type UploadReportProps = Pick<
  UploadReportRequest,
  'emails' | 'bcc' | 'cc' | 'numCreated' | 'duplicateIds'
> & {
  invalidRows: ValidatedRow[];
};

export interface DossiersImportState {
  actions: {
    saveDossiersBatch: AsyncActionState<
      Array<
        | import('./logic').SaveDossierSuccessResponse
        | import('./logic').SaveDossierErrorResponse
      >
    >;
    reinvestigations: AsyncActionState<ReinvestigationsResponse>;
    sendUploadReport: AsyncActionState;
  };
}

const initialState: DossiersImportState = {
  actions: {
    saveDossiersBatch: { status: 'uninitialized' },
    reinvestigations: { status: 'uninitialized' },
    sendUploadReport: { status: 'uninitialized' },
  },
};

const dossiersImportSlice = setupSlice('dossiers-import', initialState);
export default dossiersImportSlice;
