import React from 'react';
import {
  ArrowRightOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Markup } from 'react-render-markup';
import { Badge, Card, Steps, Tag } from 'antd';
import type { InsightsConfig } from '@owl-frontend/api-client/src/interface';
import { Dialog, OwlDisplay, Tooltip } from '@owl-frontend/components';
import { Locale } from '../../../../../../context/AppContainer/AppContainer';
import ClaimsAdminInsightsConfigCardComponent from '../ClaimsAdminInsightsConfigCardComponent/ClaimsAdminInsightsConfigCardComponent';
import { priorityBadge } from '../ClaimsAdminInsightsConfigDetailsContainer/ClaimsAdminInsightsConfigDetailsContainer';
import styles from '../ClaimsAdminInsightsConfigDetailsContainer/ClaimsAdminInsightsConfigDetailsContainer.module.scss';

interface Props {
  configDetails: InsightsConfig;
  setConfigDialog(o: boolean): void;
  visible: boolean;
}
const processSteps = ['question', 'review', 'actions'];
// eslint-disable-next-line redos/no-vulnerable
const SQUARE_BRACKET_REGEX = /\[([^\]]*?)\]/g;
const ClaimsAdminInsightsConfigDialogComponent: React.FC<Props> = ({
  configDetails,
  setConfigDialog,
  visible,
}) => {
  const { messages } = React.useContext(Locale);
  return (
    <Dialog
      title={
        <>
          <p>{messages['claimsAdmin.insightsConfig.dialog.title']}</p>
          <Tooltip
            overlayClassName={styles.toolTipOverlay}
            placement={'bottomRight'}
            title={
              <>
                {messages['claimsAdmin.insightsConfig.steps.general']}
                <div className={styles.toolTipTitle}>
                  {processSteps.map((step, index) => (
                    <>
                      <div key={index}>
                        <b>
                          {
                            messages[
                              `claimsAdmin.insightsConfig.steps.${step}.title`
                            ]
                          }
                        </b>
                        <br />
                        {
                          messages[
                            `claimsAdmin.insightsConfig.steps.${step}.info`
                          ]
                        }
                      </div>
                      {index < Object.keys(processSteps).length - 1 && (
                        <ArrowRightOutlined />
                      )}
                    </>
                  ))}
                </div>
              </>
            }
          >
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </>
      }
      size="medium"
      open={visible}
      onClose={() => {
        setConfigDialog(false);
      }}
      className={styles.dialogContainer}
      destroyOnClose
    >
      <div className={styles.content}>
        <div>
          <b>{messages['claimsAdmin.insightsConfig.columns.name']}</b>
          <p>{configDetails.name}</p>
        </div>
        <div>
          <b>{messages['claimsAdmin.insightsConfig.columns.priority']}</b>
          <br />
          <Badge
            color={priorityBadge(configDetails.priority)}
            text={`${configDetails.priority[0].toUpperCase()}${configDetails.priority
              .slice(1)
              .toLowerCase()}`}
          />
        </div>
        <div>
          <b>
            {messages['claimsAdmin.insightsConfig.dialog.details.insightOwner']}
          </b>
          <br />
          {configDetails['isOwlGenerated'] ? (
            <OwlDisplay label={'Owl Insight'} isInternal={true} />
          ) : (
            messages['claimsAdmin.insightsConfig.dialog.details.customized']
          )}
        </div>
      </div>
      <div className={styles.stepsContainer}>
        <Steps
          direction="vertical"
          current={2}
          progressDot
          className={styles.stepFlow}
        >
          <Steps.Step
            description={
              <ClaimsAdminInsightsConfigCardComponent
                title={
                  messages['claimsAdmin.insightsConfig.steps.question.title']
                }
                toolTipMessage={
                  messages['claimsAdmin.insightsConfig.steps.question.tooltip']
                }
              >
                {configDetails.query}
                <br />
                <br />
                {
                  messages[
                    'claimsAdmin.insightsConfig.dialog.details.docFilters'
                  ]
                }
                <Card>
                  <div>
                    <b>
                      {
                        messages[
                          'claimsAdmin.insightsConfig.dialog.details.docTypes'
                        ]
                      }
                    </b>
                    {configDetails.docFilters.map((docFilter, index) => (
                      <>
                        {index > 0 &&
                        index === configDetails.docFilters.length - 1
                          ? ', AND '
                          : index > 0
                          ? ', '
                          : ''}
                        <Tag>
                          {
                            messages[
                              `claims.documents.uploadDialog.docSubType.${docFilter}`
                            ]
                          }
                        </Tag>
                      </>
                    ))}
                  </div>
                </Card>
              </ClaimsAdminInsightsConfigCardComponent>
            }
          />
          <Steps.Step
            description={
              <ClaimsAdminInsightsConfigCardComponent
                title={
                  messages['claimsAdmin.insightsConfig.steps.review.title']
                }
                toolTipMessage={
                  messages['claimsAdmin.insightsConfig.steps.review.tooltip']
                }
              >
                {messages['claimsAdmin.insightsConfig.dialog.details.template']}
                <Card>
                  <div>
                    <Markup
                      markup={configDetails.explanation.replace(
                        SQUARE_BRACKET_REGEX,
                        `<b>[$1]</b>`
                      )}
                      allowed={['b']}
                    />
                  </div>
                </Card>
                <Steps
                  direction="vertical"
                  current={1}
                  progressDot
                  className={styles.reviewFlow}
                >
                  <Steps.Step
                    description={
                      <Card>
                        <div>
                          Reviewed by <b>Claims Examiner</b>
                        </div>
                      </Card>
                    }
                  />
                </Steps>
              </ClaimsAdminInsightsConfigCardComponent>
            }
          />
          <Steps.Step
            description={
              <ClaimsAdminInsightsConfigCardComponent
                title={
                  messages['claimsAdmin.insightsConfig.steps.actions.title']
                }
                toolTipMessage={
                  messages['claimsAdmin.insightsConfig.steps.actions.tooltip']
                }
              >
                {configDetails.actions.map((action, key) => {
                  return (
                    <Card key={key}>
                      <div className={styles.actionCard}>
                        <b>
                          {
                            messages[
                              `claims.matchInsights.promptsImmediateActionOpts.${action}`
                            ]
                          }
                        </b>
                        <div>
                          <CalendarOutlined /> Due in 1 week
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </ClaimsAdminInsightsConfigCardComponent>
            }
          />
        </Steps>
      </div>
    </Dialog>
  );
};

export default ClaimsAdminInsightsConfigDialogComponent;
