import type {
  InvestigationItem,
  Tenant,
} from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface InvestigationData extends InvestigationItem {
  dueDate: string;
}
export interface TenantsState {
  investigationItems: InvestigationData[];
  items: Tenant[];
  lastCursor: string | null;
  actions: {
    tenantsList: AsyncActionState;
    investigationsList: AsyncActionState;
    updateTenantPriorities: AsyncActionState;
  };
}

const initialState: TenantsState = {
  investigationItems: [],
  items: [],
  lastCursor: null,
  actions: {
    tenantsList: { status: 'uninitialized' },
    investigationsList: { status: 'uninitialized' },
    updateTenantPriorities: { status: 'uninitialized' },
  },
};

const tenantsSlice = setupSlice('tenants', initialState);
export default tenantsSlice;
