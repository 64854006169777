import React from 'react';
import { useDispatch } from 'react-redux';
import type { ClaimsMLConfig } from '@owl-frontend/api-client/interface';
import { useSelector } from '../../../data/store';
import { actions } from '../data/logic';

interface UseMLConfigHookState {
  mlConfig: ClaimsMLConfig;
  updateStatus: string;
}

interface UseMLConfigHookHandler {
  mlConfigFetch(): void;
  updateMLConfig(mlDetails: Partial<ClaimsMLConfig>): void;
}

const useMlConfig = (
  tenantId: string
): [UseMLConfigHookState, UseMLConfigHookHandler] => {
  const dispatch = useDispatch();
  const {
    mlConfigs,
    actions: { updateMLConfig },
  } = useSelector((s) => s['claims-admin']);

  const handler = React.useMemo(
    () => ({
      mlConfigFetch: () => {
        if (!tenantId) {
          return;
        }
        dispatch(actions.mlConfigFetch({ tenantId }));
      },
      updateMLConfig: (mlDetails: Partial<ClaimsMLConfig>) => {
        if (!tenantId) {
          return;
        }
        dispatch(actions.updateMLConfig({ tenantId, mlDetails }));
      },
    }),
    [tenantId]
  );

  return [
    {
      mlConfig: mlConfigs[tenantId],
      updateStatus: updateMLConfig.status,
    },
    handler,
  ];
};

export default useMlConfig;
