// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-frontend-components-src-lib-CopyableText-__copyOnHover--JLt7q {\n  display: inline-flex;\n  z-index: 0;\n  user-select: text;\n  width: 100%;\n  position: relative;\n  justify-content: space-between;\n}\n._-_-frontend-components-src-lib-CopyableText-__copyOnHover--JLt7q .ant-typography-copy {\n  opacity: 0;\n  flex: 0;\n}\n._-_-frontend-components-src-lib-CopyableText-__copyOnHover--JLt7q:hover::before {\n  content: \"\";\n  display: block;\n  position: absolute;\n  z-index: -1;\n  left: -8px;\n  right: -8px;\n  top: -4px;\n  bottom: -4px;\n  border-radius: 3px;\n  background: #00000008;\n}\n._-_-frontend-components-src-lib-CopyableText-__copyOnHover--JLt7q:hover .ant-typography-copy {\n  opacity: 1;\n}", "",{"version":3,"sources":["webpack://./../frontend-components/src/lib/CopyableText/CopyOnHover.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,UAAA;EACA,iBAAA;EAEA,WAAA;EACA,kBAAA;EAGA,8BAAA;AAFF;AAIE;EACE,UAAA;EACA,OAAA;AAFJ;AAKI;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EAEA,UAfM;EAgBN,WAhBM;EAiBN,SAhBM;EAiBN,YAjBM;EAkBN,kBAAA;EACA,qBAAA;AAJN;AAOI;EACE,UAAA;AALN","sourcesContent":[".copyOnHover {\n  display: inline-flex;\n  z-index: 0;\n  user-select: text;\n\n  width: 100%;\n  position: relative;\n  $marginH: -8px;\n  $marginV: -4px;\n  justify-content: space-between;\n\n  :global(.ant-typography-copy) {\n    opacity: 0;\n    flex: 0;\n  }\n  &:hover {\n    &::before {\n      content: '';\n      display: block;\n      position: absolute;\n      z-index: -1;\n\n      left: $marginH;\n      right: $marginH;\n      top: $marginV;\n      bottom: $marginV;\n      border-radius: 3px;\n      background: #00000008;\n    }\n\n    :global(.ant-typography-copy) {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyOnHover": "_-_-frontend-components-src-lib-CopyableText-__copyOnHover--JLt7q"
};
export default ___CSS_LOADER_EXPORT___;
