import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../../fetch';
import { baseURL, deserialize } from '../../helpers';
import type { FalconSettings } from './interface';

const logger = createLogger(__filename);

const falconSettingsApiClient = {
  fetch: async (): Promise<FalconSettings> => {
    const url = new URL('/api/v1/settings', baseURL);
    logger.trace('falconSettingsApiClient.fetch');
    const response = await fetchWrapper(url.href, {
      method: 'GET',
      credentials: 'include',
    });

    logger.debug({ response }, `GET ${url.href}`);
    return deserialize(response);
  },
};

export default falconSettingsApiClient;
