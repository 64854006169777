import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../../fetch';
import { baseURL, deserialize } from '../../helpers';
import type { FalconFrontendEvents } from './interface';

const logger = createLogger(__filename);

const falconFrontendEventsApiClient = {
  log: async (data: { events: FalconFrontendEvents[] }): Promise<void> => {
    const url = new URL('/api/v1/logs', baseURL);
    logger.trace('falconFrontendEventsApiClient.log');
    const response = await fetchWrapper(url.href, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify({
        events: data.events,
      }),
    });

    logger.debug({ response }, `POST ${url.href}`);
    return deserialize(response);
  },
};

export default falconFrontendEventsApiClient;
