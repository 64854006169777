import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Badge, Layout, Tabs } from 'antd';
import type { ClaimsDocument, Tenant } from '@owl-frontend/api-client';
import {
  CopyableText,
  Table,
  Option,
  SelectAntd,
} from '@owl-frontend/components';
import { RouteDefs, generatePath } from '../../../../components/Routing/routes';
import { Locale } from '../../../../context/AppContainer/AppContainer';
import { useTenants } from '../../../tenants/TenantsContainerHooks';
import styles from '../../ClaimsAdminContainer.module.scss';
import useErrorDocuments from '../../hooks/useErrorDocuments';
import ClaimsAdminHeader from '../ClaimsAdminHeaderComponent/ClaimsAdminHeaderComponent';

const DocumentNameCellComponent: React.FC<{
  tenantId: string;
  cellData: ClaimsDocument['docName'];
  rowData: ClaimsDocument;
}> = ({ tenantId, cellData, rowData }) => (
  <Link
    title={cellData}
    to={generatePath(RouteDefs.ClaimsAdminErrorDocsTenantDoc, {
      tenantId,
      docId: rowData.docId,
    })}
  >
    {cellData}
  </Link>
);

interface DocumentListProps {
  data: ClaimsDocument[];
  tenant: Tenant;
}

const DocumentListComponent: React.FC<DocumentListProps> = ({
  data,
  tenant,
}) => {
  const { messages } = React.useContext(Locale);
  const [activeKey, setActiveKey] = React.useState<string>('unreadable');

  const { columns } = React.useMemo(() => {
    return Table.buildColumns<ClaimsDocument>()
      .data('docName', messages['claimsAdmin.errorDocs.columns.docName'], 350, {
        key: 'name',
        cellRenderer: ({ cellData, rowData }) => (
          <DocumentNameCellComponent
            tenantId={tenant.tenantId}
            cellData={cellData}
            rowData={rowData}
          />
        ),
      })
      .data('docId', 'ID', 400, {
        flexGrow: 0,
        cellRenderer: ({ rowData }) => (
          <CopyableText text={rowData.docId}>{rowData.docId}</CopyableText>
        ),
      })
      .data(
        'ingestStatus',
        messages['claimsAdmin.errorDocs.columns.ingestStatus'],
        200
      )
      .data(
        'documentError',
        messages['claimsAdmin.errorDocs.columns.documentError'],
        300,
        {
          cellRenderer: ({ cellData }) =>
            messages[`claimsAdmin.errorDocs.errors.${cellData}`],
        }
      )
      .data(
        'updatedAt',
        messages['claimsAdmin.errorDocs.columns.updatedAt'],
        300
      );
  }, [messages, tenant.tenantId]);

  const counts = React.useMemo(
    () =>
      data.reduce((acc, item) => {
        if (!item.documentError) {
          return acc;
        }

        return {
          ...acc,
          [item.documentError]: (acc[item.documentError] ?? 0) + 1,
        };
      }, {}),
    [data]
  );

  const filteredDocuments = React.useMemo(
    () => data.filter((d) => d.documentError === activeKey),
    [activeKey, data]
  );

  return (
    <Layout.Content className={styles.tableContent}>
      <ClaimsAdminHeader title={messages['claimsAdmin.errorDocs.title']} />
      <Layout.Content className={styles.content}>
        <Tabs
          activeKey={activeKey}
          onChange={setActiveKey}
          items={[
            'unreadable',
            'wrong-doc-type',
            'unknown-doc-type',
            'field-errors',
          ].map((k) => ({
            key: k,
            label: (
              <Badge count={counts[k]} offset={[15, 0]}>
                {messages[`claimsAdmin.errorDocs.errors.${k}`]}
              </Badge>
            ),
          }))}
        />
        <div className="shared-table-container">
          <Table
            rowKey="tenantId"
            columns={columns}
            data={filteredDocuments}
            sortState={{}}
            isLoading={false}
            onEndReachedThreshold={0}
          />
        </div>
      </Layout.Content>
    </Layout.Content>
  );
};

const ClaimsAdminDocsComponent: React.FC = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const { getErrorDocuments, errorDocuments } = useErrorDocuments(tenantId);
  const [{ tenants, loading }, useTenantsHandler] = useTenants();

  const tenantOptions: Option[] = React.useMemo(
    () =>
      tenants.map((tenant) => ({ label: tenant.name, value: tenant.tenantId })),
    [tenants]
  );

  React.useEffect(() => {
    useTenantsHandler.fetchTenants();
  }, [useTenantsHandler]);

  const tenant = React.useMemo(
    () => tenants?.find((t) => t.tenantId === tenantId),
    [tenantId, tenants]
  );

  React.useEffect(() => {
    getErrorDocuments();
  }, [getErrorDocuments]);

  return (
    <div className={styles.content}>
      <SelectAntd
        placeholder="Select a Tenant"
        loading={loading}
        onChange={(tid) =>
          navigate(
            generatePath(RouteDefs.ClaimsAdminErrorDocsTenant, {
              tenantId: tid,
            })
          )
        }
        options={tenantOptions}
        value={tenantId}
      />
      {tenant && errorDocuments && (
        <DocumentListComponent data={errorDocuments} tenant={tenant} />
      )}
    </div>
  );
};

export default ClaimsAdminDocsComponent;
