import React from 'react';
import { Popover as PopoverAntd } from 'antd';
import type { PopoverProps } from 'antd';
export type { PopoverProps } from 'antd';

const Popover: React.FC<PopoverProps> = ({ children, ...props }) => {
  return <PopoverAntd {...props}>{children}</PopoverAntd>;
};

export default Popover;
