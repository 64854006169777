import type { Feedback } from '@owl-frontend/api-client/src/feedback/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface FeedbackState {
  feedback: Feedback;
  actions: {
    getFeedback: AsyncActionState;
  };
}

const initialState: FeedbackState = {
  feedback: { dossiers: [] },
  actions: {
    getFeedback: { status: 'uninitialized' },
  },
};

const feedbackSlice = setupSlice('feedback', initialState);

export default feedbackSlice;
