import type {
  ReservationResponse,
  TaskCount,
  Topology,
} from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';
import type { FindingFormValues } from '../components/find-flags/components/interface';

export interface WorkersState {
  task?: ReservationResponse;
  findingsData: { [key: string]: FindingFormValues[] | undefined };
  topologies: Topology[];
  taskCount: TaskCount[];
  totalTimeOnPlatform: string;
  claimDocumentsPresignedUrls: { [k: string]: string };
  actions: {
    getNextTask: AsyncActionState;
    completeTask: AsyncActionState;
    releaseTask: AsyncActionState;
    getData: AsyncActionState;
    saveData: AsyncActionState;
    getTopologies: AsyncActionState;
    getTaskCount: AsyncActionState;
    getPresignedViewUrl: AsyncActionState<string>;
  };
}

const initialState: WorkersState = {
  findingsData: {},
  topologies: [],
  taskCount: [],
  totalTimeOnPlatform: '',
  claimDocumentsPresignedUrls: {},
  actions: {
    getNextTask: { status: 'uninitialized' },
    completeTask: { status: 'uninitialized' },
    releaseTask: { status: 'uninitialized' },
    getData: { status: 'uninitialized' },
    saveData: { status: 'uninitialized' },
    getTopologies: { status: 'uninitialized' },
    getTaskCount: { status: 'uninitialized' },
    getPresignedViewUrl: { status: 'uninitialized' },
  },
};

const workersSlice = setupSlice('workers', initialState);
export default workersSlice;
