import { createAction } from '@reduxjs/toolkit';
import { createLogger } from '@owl-lib/logger';

import { __DEV__ } from '../constants';
import { resolveType } from '../helpers';
import type { CaseSagaOrCustom, SagaScheduler } from '../sagaToolkit/interface';

import type { AnySliceCase, CaseSagas, MergeDef } from './interface';
import type { ConfigurableSliceInfo, ConfigurableSliceMembers } from './slice';

const logger = createLogger(__filename);

type AddSagasResult<State, Name extends string, ActionsDef> = Partial<
  Pick<ConfigurableSliceMembers<State, Name, ActionsDef>, 'actions' | 'cases'>
>;

export function addSagas<ActionsDef, DefNext>(
  slice: ConfigurableSliceInfo<any, any, ActionsDef>,
  caseSagas: Partial<CaseSagas<ActionsDef & DefNext, string>>,
  globalScheduler: SagaScheduler
): AddSagasResult<any, any, MergeDef<ActionsDef, DefNext>> {
  type Cur = ConfigurableSliceInfo<any, string, ActionsDef>;
  type NextActionsDef = MergeDef<ActionsDef, DefNext>;
  type Next = ConfigurableSliceMembers<any, string, NextActionsDef>;

  const actionKeys = Object.keys(caseSagas);
  if (actionKeys.length === 0) return {};

  let newActions = false;
  const actions = { ...slice.actions } as Cur['actions'] & Next['actions'];
  const cases: Record<string, AnySliceCase> = { ...slice.cases };
  for (const actionKey of actionKeys) {
    let saga = caseSagas[actionKey as keyof ActionsDef] as CaseSagaOrCustom;
    let scheduler = globalScheduler;
    if (typeof saga !== 'function') {
      if (saga.scheduler) scheduler = saga.scheduler;
      saga = saga.saga;
    }
    if (__DEV__ && !scheduler) {
      throw new Error(`Saga scheduler missing for ${actionKey}`);
    }

    const oldCase = cases[actionKey];
    if (oldCase?.saga) {
      if (__DEV__ && oldCase.saga !== saga) {
        logger.warn(oldCase, `Saga ${actionKey} already defined`);
        throw new Error(`Saga ${actionKey} already defined`);
      }
      continue;
    }
    if (oldCase) {
      cases[actionKey] = { ...oldCase, saga, scheduler };
    } else {
      newActions = true;
      cases[actionKey] = { prepare: true, saga, scheduler };
      actions[actionKey] = createAction(resolveType(slice.name, actionKey));
    }
  }
  return (newActions ? { actions, cases } : { cases }) as Cur & Next;
}
