import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StoreState, useSelector } from '../../../data/store';
import { actions as workersActions } from '../data/logic';

export function useTaskCount() {
  const dispatch = useDispatch();
  const { taskCount, totalTimeOnPlatform } = useSelector(
    (s: StoreState) => s['workers']
  );
  const currentUser = useSelector((s) => s['session'].current);
  const taskCountActions = useSelector(
    (s: StoreState) => s['workers'].actions.getTaskCount
  );

  const load = useCallback(() => {
    if (!currentUser) {
      return;
    }

    dispatch(workersActions.getTaskCount({ username: currentUser.username }));
  }, [dispatch, currentUser]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    taskCount,
    totalTimeOnPlatform,
    loading: taskCountActions.status === 'pending',
  };
}
