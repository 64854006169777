import { resolveType, SliceActionType } from '../helpers';
import type {
  OmitEmptyObjectSymbol,
  SliceActionCreators,
  SliceCases,
} from './interface';

export type SliceSelector<State, Name extends string> =
  // prettier-align
  (rootState: { [Slice in Name]: State }) => State;
export const createSliceSelector: <State, Name extends string>(
  name: Name
) => SliceSelector<State, Name> = (name) => (rootState) => rootState[name];

export type SubActionDefs<ActionDefs, BaseActionType extends string> = {
  [Status in keyof ActionDefs as SliceActionType<
    BaseActionType,
    Extract<Status, string>
  >]: ActionDefs[Status];
};

export function flattenSubSlice<
  ActionKey extends string,
  ActionDefs,
  State = any,
  SliceName extends string = string
>(
  actionKey: ActionKey,
  cases: SliceCases<State, ActionDefs, SliceName>
): SliceCases<State, SubActionDefs<ActionDefs, ActionKey>, SliceName>;
export function flattenSubSlice<
  ActionKey extends string,
  ActionDefs,
  SliceName extends string = string
>(
  actionKey: ActionKey,
  cases: SliceActionCreators<ActionDefs, SliceName>
): SliceActionCreators<ActionDefs, SliceName>;
export function flattenSubSlice<ActionKey extends string, ActionDefs>(
  actionKey: ActionKey,
  cases: { [K in keyof SubActionDefs<ActionDefs, ActionKey>]-?: any }
): OmitEmptyObjectSymbol<{
  [K in keyof SubActionDefs<ActionDefs, ActionKey>]-?: any;
}> {
  type R = OmitEmptyObjectSymbol<{
    [K in keyof SubActionDefs<ActionDefs, ActionKey>]-?: any;
  }>;
  const result = {} as R;
  for (const subActionKey of Object.keys(cases)) {
    result[resolveType(actionKey, subActionKey) as keyof R] =
      cases[subActionKey];
  }
  return result;
}
