import { notification } from 'antd';
import type { ArgsProps as OriginalArgsProps } from 'antd/lib/notification';
import styles from './ToastAntd.module.scss';

type CustomNotificationTypes = 'primary' | 'link';

export type ArgsProps = Omit<OriginalArgsProps, 'type'> & {
  type?: OriginalArgsProps['type'] | CustomNotificationTypes;
};

const toast = (props: ArgsProps) => {
  const { type = 'info', ...rest } = props;

  const notificationType =
    type === 'primary' || type === 'link' ? 'info' : type;

  notification[notificationType]({
    className: styles[type],
    placement: 'top',
    ...rest,
  });
};

export default toast;
