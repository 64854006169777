import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Col, Row, Space } from 'antd';
import type { InsightFeedback } from '@owl-frontend/api-client';
import styles from './ClaimsInsightsComponent.module.scss';
import ClaimsInsightsFeedbackComponent from './ClaimsInsightsFeedbackComponent';

export const USER_NAME = 'Julie Smith';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1 min',
    mm: '%d mins',
    h: '1 hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

const createdAtText = (datetime: string) => dayjs(datetime).fromNow();

export const FeedbackComponent: React.FC<
  InsightFeedback & {
    feedbackUpdatedByName?: string;
    feedbackUpdatedAt: string;
  }
> = ({
  feedbackUpdatedByName = USER_NAME,
  feedbackUpdatedAt,
  details,
  nextAction,
}) => (
  <div className={styles.feedbackContainer}>
    <Row>
      <Col span={20}>
        <Space direction="vertical">
          <ClaimsInsightsFeedbackComponent
            createdByName={feedbackUpdatedByName}
            insightDetails={details}
            insightNextAction={nextAction}
          />
        </Space>
      </Col>
      <Col span={4} className={styles.createdAt}>
        {createdAtText(feedbackUpdatedAt)}
      </Col>
    </Row>
  </div>
);
