import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export interface LocalizationState {
  messages: {
    [key: string]: string;
  };
  locale: 'en-ca';
  actions: {
    fetchMessages: AsyncActionState;
  };
}

const initialState: LocalizationState = {
  messages: {
    'dossiers.title': 'Files',
  },
  locale: 'en-ca',
  actions: {
    fetchMessages: { status: 'uninitialized' },
  },
};

const localizationSlice = setupSlice('localization', initialState);
export default localizationSlice;
