import * as React from 'react';
import clsx from 'clsx';
import { Select as SelectAntd, SelectProps } from 'antd';
import formStyles from '../../shared/Form.module.scss';
import type { FieldWrapperProps } from '../../shared/interface';
import styles from './Select.module.scss';

export interface Props extends SelectProps, FieldWrapperProps {}

type UseSingleSelectElement = (props: Props) => [React.ReactElement];
export const useSingleSelectElement: UseSingleSelectElement = (selectProps) => {
  const el = (
    <div className={styles.selectContainer}>
      <SelectAntd {...selectProps} />
    </div>
  );

  return [el];
};

const SingleSelect: React.FC<Props> = (props) => {
  const { label, fieldClassName, trailer, info } = props;

  return (
    <div
      className={clsx(formStyles.field, styles.selectContainer, fieldClassName)}
    >
      {label && <label className={formStyles.label}>{label}</label>}
      <div className={formStyles.control}>
        <SelectAntd {...props} />
      </div>
      {trailer}
      {info && <div className={styles.info}>{info}</div>}
    </div>
  );
};

export default SingleSelect;
