import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { call } from 'typed-redux-saga';
import {
  workersApiClient,
  claimsAssistApiClient,
} from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import type { FindingFormValues } from '../components/find-flags/components/interface';
import { getBatchReservations, setBatchReservations } from './helpers';
import workersSlice from './interface';

function* getNextTask(action: {
  payload: {
    tkey: string;
    taskKey?: string;
  };
}) {
  const { tkey, taskKey } = action.payload;

  if (taskKey) {
    return yield* call(workersApiClient.workers.getNextTask, {
      tkey,
      taskKey,
    });
  }

  const batchReservations = getBatchReservations(tkey);

  const reservations =
    (batchReservations?.reservedItems?.length ?? 0) === 0
      ? yield* call(workersApiClient.workers.getNextBatch, { tkey })
      : batchReservations;

  if (reservations?.reservedItems && reservations.reservedItems.length > 0) {
    const { reservedItem, remaining } = yield* call(
      workersApiClient.workers.reserveNextBatchItem,
      {
        tkey,
        batch: reservations?.reservedItems,
      }
    );

    setBatchReservations(tkey, { reservedItems: remaining });

    if (!reservedItem) {
      throw new Error('Failed to reserve item from batch');
    }

    return reservedItem;
  }
}

const slice = workersSlice
  .addAsyncSagas({
    getNextTask,
    completeTask: invoke(workersApiClient.workers.completeTask),
    releaseTask: invoke(workersApiClient.workers.releaseTask),
    getData: invoke(workersApiClient.workers.data.get),
    saveData: invoke(workersApiClient.workers.data.save),
    getTopologies: invoke(workersApiClient.workers.getTopologies),
    getTaskCount: invoke(workersApiClient.workers.getTaskCount),
    getPresignedViewUrl: invoke(
      claimsAssistApiClient.documents.getPresignedViewUrl
    ),
  })
  .addReducers({
    'getNextTask/fulfilled': (state, action) => {
      state.task = action.payload?.recordId ? action.payload : undefined;
    },
    'completeTask/fulfilled': (state) => {
      state.task = undefined;
    },
    'releaseTask/fulfilled': (state) => {
      state.task = undefined;
    },
    'getData/fulfilled': (state, action) => {
      state.findingsData = {
        ...state.findingsData,
        [action.meta.arg.key]:
          (action.payload.findings as FindingFormValues[]) ?? undefined,
      };
    },
    'getTopologies/fulfilled': (state, action) => {
      state.topologies = action.payload.map((topology) => {
        if (topology.pseudoTasks) {
          const pseudoTaskCounts = topology.tasks.reduce(
            (sum, task) => {
              if (!task.showTask) {
                if (task.total) {
                  sum.total += task.total;
                }
                if (task.reserved) {
                  sum.reserved += task.reserved;
                }
              }
              return sum;
            },
            { total: 0, reserved: 0 }
          );
          topology = {
            ...topology,
            pseudoTasks: [{ ...topology.pseudoTasks[0], ...pseudoTaskCounts }],
          };
        }
        return topology;
      });
    },

    'getTaskCount/fulfilled': (state, action) => {
      state.taskCount = action.payload;

      const totalTime = Object.values(action.payload)
        .flat()
        .reduce((acc, item) => {
          return acc + item.totalTimeSpent;
        }, 0);

      dayjs.extend(duration);

      const formattedDuration = dayjs
        .duration(totalTime, 'minutes')
        .format('H[h] m[m]');
      state.totalTimeOnPlatform = formattedDuration;
    },
    'getPresignedViewUrl/fulfilled': (state, action) => {
      state.claimDocumentsPresignedUrls[action.meta.arg.claimsDocument.docId] =
        action.payload.url;
    },
  })
  .addReducers({
    clearItems: (state) => {
      state.task = undefined;
      state.findingsData = {};
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
