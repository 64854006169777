import { ASYNC_TIMEOUT_MS } from '@owl-lib/util';

export const fetchWrapper = async (
  href: string,
  options: RequestInit,
  timeoutLimit = ASYNC_TIMEOUT_MS
): Promise<Response> => {
  const controller = new AbortController();
  let timeout;
  if (timeoutLimit > 0) {
    timeout = setTimeout(() => controller.abort(), timeoutLimit);
  }
  const response = await fetch(href, {
    ...options,
    signal: controller.signal,
  });
  if (timeout) {
    clearTimeout(timeout);
  }
  return response;
};
