import {
  ErrorCode,
  ErrorCodeCategory,
  errorCodes,
  getErrorCodeCategory,
} from '../generated/error-code';
export * from '../generated/error-code';

export const errorCodeValues: Readonly<Set<ErrorCode>> = Object.freeze(
  new Set(Object.values(ErrorCode))
);

type ErrorCheckCondition = string | number | RegExp;

const hasOwnProperty = Object.prototype.hasOwnProperty;

const badDataKey = Symbol('badData');

interface ErrorOptions {
  cause?: Error;
}

export class OwlError extends Error {
  public static isOwlError(err: unknown): err is OwlError {
    return (err instanceof OwlError ||
      (err !== null &&
        typeof err === 'object' &&
        hasOwnProperty.call(err, 'message') &&
        hasOwnProperty.call(err, 'code') &&
        hasOwnProperty.call(err, 'cause') &&
        Array.isArray((err as any).cause))) as any;
  }

  private static causeToString(c: any): string {
    if (c instanceof OwlError) {
      return c.toString();
    } else if (c instanceof Error) {
      return c.stack ? `${c.stack}` : `${c.name}: ${c.message}`;
    } else if (c === null || typeof c !== 'object') {
      return `${c}`;
    } else {
      const s = c.toString();
      if (s === '[object Object]') {
        try {
          return JSON.stringify(c);
        } catch (err) {
          // nop
        }
      }
      return s;
    }
  }

  // Should be compatible with https://v8.dev/features/error-cause
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore noImplicitOverride
  public cause?: Error;

  public readonly code: ErrorCode;
  [badDataKey]?: any;

  public get details(): any | undefined {
    return this[badDataKey];
  }

  constructor(code: ErrorCode, opts?: ErrorOptions);
  constructor(msg: string, code: ErrorCode, opts?: ErrorOptions);
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(...args) {
    const lastArg = args[args.length - 1];
    const cause =
      lastArg && typeof lastArg === 'object' && 'cause' in lastArg
        ? lastArg.cause
        : undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2554
    super(args[0], { cause });

    // // https://developer.mozilla.org/en-US/docs/web/javascript/reference/global_objects/error#es6_custom_error_class
    // if (Error.captureStackTrace) {
    //   Error.captureStackTrace(this, OwlError);
    // }

    this.cause ??= undefined; // ensure it is present
    this.code =
      args.length === 1 || typeof args[1] === 'object' || args[1] === undefined
        ? args[0]
        : args[1];
    Object.setPrototypeOf(this, OwlError.prototype);
  }

  public withDetails(data: any): OwlError {
    this[badDataKey] = data;
    return this;
  }

  public causedBy(cause: Error | Error[]): OwlError {
    this.cause = Array.isArray(cause) ? new AggregateError(cause) : cause;
    return this;
  }

  /**
   * Invokes the hasCause() instance method on the first parameter.
   *
   * If the error is not an OwlError, it will be wrapped as one.
   */
  public static hasCause(
    e: Error,
    condition:
      | ErrorCheckCondition
      | typeof Error
      | { message?: ErrorCheckCondition; code?: string | RegExp }
  ): boolean {
    return e instanceof OwlError
      ? e.hasCause(condition)
      : OwlError.matchesCause(e, condition);
  }

  /**
   * Was this error caused by X?
   *
   * This error is considered “caused by” a condition if it either matches it
   * directly, or has a cause (transitively) that does.
   *
   * @param condition If a string, check if an error message or code *contains*
   *   the string. If a number, check if an error message or code *equals* the
   *   number. If a RegExp, check if an error message or code *matches* the
   *   regexp. If a function (Error class), check if an error is an instance of
   *   it. If an object, you may specify conditions for message or code separately.
   */
  public hasCause(
    condition:
      | ErrorCheckCondition
      | typeof Error
      | { message?: ErrorCheckCondition; code?: string | RegExp }
  ): boolean {
    if (OwlError.matchesCause(this, condition)) {
      return true;
    }

    for (const c of this.allCauses) {
      if (OwlError.matchesCause(c, condition)) {
        return true;
      }
    }

    return false;
  }

  public get allCauses(): Error[] {
    const res = this.cause ? [this.cause] : [];
    for (const c of res) {
      if (c instanceof OwlError) {
        res.push(...c.allCauses);
      }
    }
    return res;
  }

  private static matchesCause(
    e: OwlError | Error,
    condition:
      | ErrorCheckCondition
      | typeof Error
      | { message?: ErrorCheckCondition; code?: ErrorCheckCondition }
  ) {
    let msgCond: ErrorCheckCondition | undefined;
    let codeCond: ErrorCheckCondition | undefined;
    if (
      typeof condition === 'string' ||
      typeof condition === 'number' ||
      condition instanceof RegExp
    ) {
      msgCond = codeCond = condition;
    } else if (typeof condition === 'object') {
      msgCond = condition.message;
      codeCond = condition.code;
    } else {
      return e instanceof condition;
    }

    return (
      OwlError.matchesCond(msgCond, e.message) ||
      OwlError.matchesCond(codeCond, (e as OwlError).code)
    );
  }

  private static matchesCond(
    cond: ErrorCheckCondition | undefined,
    msgOrCode: string | number | undefined
  ) {
    if (
      cond === null ||
      cond === undefined ||
      msgOrCode === null ||
      msgOrCode === undefined
    ) {
      return false;
    }

    return (
      (typeof cond === 'string' && `${msgOrCode}`.indexOf(cond) !== -1) ||
      (typeof cond === 'number' && msgOrCode === cond) ||
      (cond instanceof RegExp && cond.test(`${msgOrCode}`))
    );
  }

  public override toString(): string {
    let res = `${this.message}\n${this.stack ? `\n${this.stack}` : ''}`;
    if (this.cause) {
      res += `\nCaused by [\n${OwlError.causeToString(this.cause)}\n]`.replace(
        /^/gm,
        '\t'
      );
    }
    return res;
  }

  public toJSON(): {
    message: string;
    code?: string;
    address?: string;
    dest?: string;
    errno?: string;
    info?: string;
    path?: string;
    port?: string | number;
    syscall?: string;
    cause?: Error;
    stack?: string;
  } {
    return {
      address: (this as any).address,
      code: (this as any).code,
      dest: (this as any).dest,
      errno: (this as any).errno,
      info: (this as any).info,
      message: this.message,
      path: (this as any).path,
      port: (this as any).port,
      syscall: (this as any).syscall,
      cause: this.cause,
      stack: this.stack,
    };
  }
}

// noinspection JSUnusedGlobalSymbols
export enum HTTPError {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  PayloadTooLarge = 413,
  URITooLong = 414,
  UnsupportedMediaType = 415,
  RangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  ImATeapot = 418,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  UnorderedCollection = 425,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HTTPVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  BandwidthLimitExceeded = 509,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511,
}

// Specific errors -> HTTP status
const statusByCode: Partial<Record<ErrorCode, number>> = {
  [errorCodes.external.rateLimitExceeded]: HTTPError.TooManyRequests,
  [errorCodes.forbidden.unauthenticated]: HTTPError.Unauthorized,
  [errorCodes.internal.notImplemented]: HTTPError.NotImplemented,
};
// Generic error categories -> HTTP status; used unless there's a specific override
const statusByCategory: Record<ErrorCodeCategory, number> = {
  [ErrorCodeCategory.Ratelimit]: HTTPError.TooManyRequests,
  [ErrorCodeCategory.Forbidden]: HTTPError.Forbidden,
  [ErrorCodeCategory.InvalidParams]: HTTPError.BadRequest,
  [ErrorCodeCategory.NotFound]: HTTPError.NotFound,
  [ErrorCodeCategory.External]: HTTPError.InternalServerError,
  [ErrorCodeCategory.Internal]: HTTPError.InternalServerError,
  [ErrorCodeCategory.Unavailable]: HTTPError.InternalServerError,
};

const errorCodeSet = new Set(Object.values(ErrorCode));

export function isOwlErrorCode<T extends ErrorCode | string | null | undefined>(
  code: T
): T extends ErrorCode ? true : false {
  if (code === null || code === undefined) {
    return false as any;
  }

  return errorCodeSet.has(code as any) as any;
}

export function getHTTPStatus(
  code: ErrorCode | string | null | undefined
): number {
  if (!isOwlErrorCode(code)) {
    return 500;
  }

  if (code! in statusByCode) {
    return statusByCode[code!]!;
  } else {
    return statusByCategory[getErrorCodeCategory(code as ErrorCode)];
  }
}

export function getErrorDetails(err: OwlError | Error): any | undefined {
  return err[badDataKey];
}

export function getErrorCode(err: OwlError | Error): ErrorCode {
  const code: ErrorCode | undefined =
    'code' in err && isOwlErrorCode(err.code)
      ? (err.code as ErrorCode)
      : isOwlErrorCode(err.message)
      ? (err.message as ErrorCode)
      : undefined;
  return code ?? errorCodes.internal.generic;
}

export interface UniqueConstraintDescription {
  /** Table name or wildcard */
  table: string | '*';
  /** Column name or wildcard */
  column: string | '*';
}

/**
 * Check if an error is a unique constraint violation
 * @param error
 * @param constraint
 * @param includeNestedCauses Check nested error causes, if any, not just the top error
 */
export function isUniqueDBViolation(
  error: Error | OwlError,
  constraint: UniqueConstraintDescription,
  includeNestedCauses = true
): boolean {
  const { message, detail, table } = error as Error & {
    detail?: string;
    table?: string;
  };
  if (/violates unique constraint/.test(message)) {
    const tableMatch = constraint.table === '*' || table === constraint.table;
    const columnMatch =
      constraint.column === '*' ||
      // Sample message: 'Key ("clientClaimId")=(39b63648-2be6-43fe-a6df-d40912699196) already exists.'
      // Note that the key will be double-quoted if mixed case, unquoted if lowercase.
      (detail &&
        (detail.includes(`(${constraint.column})=(`) ||
          detail.includes(`("${constraint.column}")=(`)));
    if (tableMatch && columnMatch) {
      return true;
    }
  }

  if (includeNestedCauses && OwlError.isOwlError(error) && error.cause) {
    return isUniqueDBViolation(error.cause, constraint, true);
  } else {
    return false;
  }
}

/**
 * Rethrows an error: the specified error code if a Postgres unique constraint
 * violation is detected, otherwise rethrows the original error.
 *
 * @param constraints Map of error codes to descriptions of the constraints to match
 * @param includeNestedCauses Check nested error causes, if any, not just the top error
 */
export function remapUniqueDBViolation(
  constraints: Partial<Record<ErrorCode, UniqueConstraintDescription>>,
  includeNestedCauses = true
): (err: Error) => any {
  return (error: Error) => {
    for (const [errorCode, constraint] of Object.entries(constraints)) {
      if (isUniqueDBViolation(error, constraint, includeNestedCauses)) {
        throw new OwlError(
          `Unique constraint violation on ${constraint.table}.${constraint.column}`,
          errorCode as ErrorCode
        ).causedBy(error);
      }
    }
    throw error;
  };
}
