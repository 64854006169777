import { claimsAssistAdminApiClient } from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import claimsAdminSlice from './interface';

const slice = claimsAdminSlice
  .addAsyncSagas(
    {
      fetchClaimsAdminInsightsConfig: invoke(
        claimsAssistAdminApiClient.insightsConfig.get
      ),
      updateClaimsAdminInsightsConfig: invoke(
        claimsAssistAdminApiClient.insightsConfig.update
      ),
      createClaimsAdminInsightsConfig: invoke(
        claimsAssistAdminApiClient.insightsConfig.create
      ),
      deleteClaimsAdminInsightsConfig: invoke(
        claimsAssistAdminApiClient.insightsConfig.delete
      ),
      getErrorDocuments: invoke(claimsAssistAdminApiClient.errorDocuments.get),
      mlConfigFetch: invoke(claimsAssistAdminApiClient.mlConfig.get),
      updateMLConfig: invoke(claimsAssistAdminApiClient.mlConfig.update),
    },
    {
      timeoutLimit: -1,
    }
  )
  .addReducers({
    'fetchClaimsAdminInsightsConfig/fulfilled': (state, action) => {
      state.insightsConfigs[action.meta.arg.tenantId] =
        action.payload.insightsConfigs;
    },
    'getErrorDocuments/fulfilled': (state, action) => {
      const { tenantId } = action.meta.arg;

      state.errorDocuments = {
        ...state.errorDocuments,
        [tenantId]: action.payload.documents,
      };
    },
    'mlConfigFetch/fulfilled': (state, action) => {
      state.mlConfigs[action.meta.arg.tenantId] = action.payload;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
