import React from 'react';
import { useField } from 'react-final-form';
import Input, { InputProps } from '../../components/input/Input';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import type { ManagedFieldProps } from '../interface';

type FieldValue = string;
export interface Props extends InputProps, ManagedFieldProps<FieldValue> {
  name: string & InputProps['name'] & ManagedFieldProps<FieldValue>['name'];
  defaultValue?: ManagedFieldProps<FieldValue>['defaultValue'];
  value?: string;
}
const TextAreaManaged = (props: Props) => {
  const { name, defaultValue, validate, ...textAreaProps } = props;
  const { input, meta } = useField(name, { defaultValue, validate });
  const { invalid, modified, touched } = meta;
  const error = meta.error || meta.submitError;

  const modifiedOrTouched = modified || touched;
  const fetchErrorMessage = React.useCallback(() => {
    if (!modifiedOrTouched || !invalid || !error) {
      return null;
    }
    return error;
  }, [modifiedOrTouched, invalid, error]);

  return (
    <Input
      onChange={input.onChange}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      error={modifiedOrTouched && invalid && error}
      value={input.value}
      trailer={<ErrorDisplayComponent errorMessage={fetchErrorMessage()} />}
      {...textAreaProps}
    />
  );
};

export default TextAreaManaged;
