import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { Finding } from './interface';

const logger = createLogger(__filename);

const findingsApiClient = {
  findings: {
    list: async (data: { dossierId: string }): Promise<Finding[]> => {
      const url = new URL(
        `/api/v1/dossiers/${data.dossierId}/findings`,
        baseURL
      );
      logger.trace('findingsApiClient.findings.list()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    setDecision: async (data: {
      dossierId: string;
      findingId: string;
      decision: Finding['decision'];
      decisionDetails: Finding['decisionDetails'];
    }): Promise<Finding> => {
      const url = new URL(
        `/api/v1/dossiers/${data.dossierId}/findings/${data.findingId}`,
        baseURL
      );
      logger.trace('findingsApiClient.findings.setDecision()');
      const response = await fetchWrapper(url.href, {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          decision: data.decision,
          decisionDetails: data.decisionDetails,
        }),
      });
      logger.debug({ response }, `PATCH ${url.href}`);
      return await deserialize(response);
    },
    setDecisionBulk: async (data: {
      dossierId: string;
      findings: {
        findingId: string;
        decision: Finding['decision'];
        decisionDetails: Finding['decisionDetails'];
      }[];
    }): Promise<{ numUpdated: number }> => {
      const url = new URL(
        `/api/v1/dossiers/${data.dossierId}/findings`,
        baseURL
      );
      logger.trace('findingsApiClient.findings.setDecisionBulk()');
      const response = await fetchWrapper(url.href, {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          findings: data.findings,
        }),
      });
      logger.debug({ response }, `PATCH ${url.href}`);
      return await deserialize(response);
    },
  } as const,
} as const;

export default findingsApiClient;
