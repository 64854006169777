import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type {
  FindingsResponse,
  GuestToken,
  OverviewResponse,
  TeamResponse,
} from './interface';

const logger = createLogger(__filename);

const insightsApiClient = {
  insights: {
    overview: async (data: {
      startTime: string;
      endTime: string;
    }): Promise<OverviewResponse> => {
      const url = new URL(`/api/v1/insights/overview`, baseURL);

      url.searchParams.set('startTime', data.startTime);
      url.searchParams.set('endTime', data.endTime);

      logger.trace('insightsApiClient.overview');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    findings: async (data: {
      startTime: string;
      endTime: string;
    }): Promise<FindingsResponse> => {
      const url = new URL(`/api/v1/insights/findings`, baseURL);

      url.searchParams.set('startTime', data.startTime);
      url.searchParams.set('endTime', data.endTime);

      logger.trace('insightsApiClient.findings');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    team: async (data: {
      startTime: string;
      endTime: string;
    }): Promise<TeamResponse> => {
      const url = new URL(`/api/v1/insights/team`, baseURL);

      url.searchParams.set('startTime', data.startTime);
      url.searchParams.set('endTime', data.endTime);

      logger.trace('insightsApiClient.team');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  advancedInsights: {
    guestToken: async (): Promise<GuestToken> => {
      const url = new URL(`/api/v1/insights/guest-token`, baseURL);

      logger.trace('insightsApiClient.advancedInsights.guestToken');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  ltdBuckets: {
    guestToken: async (): Promise<GuestToken> => {
      const url = new URL(`/api/v1/insights/ltd-buckets`, baseURL);

      logger.trace('insightsApiClient.advancedInsights.ltdBuckets');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
};

export default insightsApiClient;
