import type {
  ConfigureStoreOptions,
  ImmutableStateInvariantMiddlewareOptions,
  SerializableStateInvariantMiddlewareOptions,
} from '@reduxjs/toolkit';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import type { Action, AnyAction, Middleware, Reducer, Store } from 'redux';
import { combineReducers, ReducersMapObject } from 'redux';
import {
  BatchAction,
  batchActions,
  enableBatching,
} from 'redux-batched-actions';
// import {
//   devToolsEnhancer /* composeWithDevTools */,
//   EnhancerOptions,
// } from 'redux-devtools-extension/logOnlyInProduction';

import createSagaMiddleware, {
  Saga,
  SagaMiddleware,
  SagaMiddlewareOptions,
} from 'redux-saga';
import { valProp } from './helpers';
import type { SagaMapItem, SagasMapObject } from './interface';

export type DevtoolsEnhancerOptions =
  // prettier-align
  Exclude<ConfigureStoreOptions['devTools'], undefined | boolean>;
// const logRedux = process.env.OWL_LOG_REDUX;
export interface OwlStoreOptions {
  devtools?: DevtoolsEnhancerOptions;
  sagaOptions?: SagaMiddlewareOptions;

  immutableCheck?: ImmutableStateInvariantMiddlewareOptions;
  serializableCheck?: SerializableStateInvariantMiddlewareOptions;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyBaseState {}
export class OwlStore<BaseState = EmptyBaseState> {
  didMountMiddleware = false;
  didInitReducer = false;
  sagaMiddleware: SagaMiddleware;
  reducers: ReducersMapObject<BaseState & { [Extra in string]?: any }>;
  sagas = {} as SagasMapObject<BaseState & { [Extra in string]?: any }>;
  constructor(
    private readonly middleware: Middleware<any, BaseState, any>[],
    baseReducers: ReducersMapObject<BaseState & { [Extra in string]?: any }>,
    private readonly options: OwlStoreOptions = {}
  ) {
    this.reducers = { ...baseReducers };
    this.sagaMiddleware = createSagaMiddleware(this.options.sagaOptions);
  }

  // we construct the store lazily, so that it's started with at least one slice.
  public get reduxStore(): Store<
    BaseState & { [Extra in string]?: any },
    AnyAction
  > {
    const defaultMiddleware = getDefaultMiddleware({
      thunk: false,
      immutableCheck: this.options.immutableCheck,
      serializableCheck: this.options.serializableCheck,
    });
    const reduxStore = configureStore<
      BaseState & { [Extra in string]?: any },
      AnyAction,
      Middleware<any, BaseState, any>[]
    >({
      reducer: enableBatching(this.masterReducer),
      middleware: [
        ...this.middleware,
        this.sagaMiddleware,
        ...defaultMiddleware,
        // TODO: figure out why our old store used 'redux-devtools-extension/logOnlyInProduction'
        // options.devTools ? devToolsEnhancer(options.devTools ?? {})
        // }, devTools: false
      ],
      devTools: this.options.devtools,
    });
    Object.defineProperty(this, 'reduxStore', valProp(reduxStore));
    for (const record of Object.values(this.sagas)) {
      record.task = this.sagaMiddleware.run(record.saga);
    }
    this.didMountMiddleware = true;
    return reduxStore;
  }

  public get masterReducer(): Reducer<BaseState & { [Extra in string]?: any }> {
    this.didInitReducer = true;
    return this.updateMasterReducer();
  }
  private updateMasterReducer() {
    const masterReducer = combineReducers(this.reducers);
    Object.defineProperty(this, 'masterReducer', valProp(masterReducer));
    return masterReducer;
  }

  // TODO: update the type signature of `this` with the slice's state type
  public addSlice(
    slice: { name: string | keyof BaseState; saga?: Saga; reducer?: Reducer },
    replace = false
  ): this {
    const { name, saga, reducer } = slice;
    if (reducer) {
      if (!replace && this.reducers[name]) {
        throw new Error(`Reducer already set for ${String(name)}`);
      }
      this.reducers[name] = reducer;
      if (this.didInitReducer) {
        this.reduxStore.replaceReducer(this.updateMasterReducer());
      }
    }
    if (saga) {
      const oldRecord = this.sagas[name];
      if (oldRecord) {
        if (!replace) {
          throw new Error(`Reducer already set for ${String(name)}`);
        }
        oldRecord.task?.cancel();
      }
      const record: SagaMapItem = { saga };
      this.sagas[name] = record;
      if (this.didMountMiddleware) {
        record.task = this.sagaMiddleware.run(saga);
      }
    }
    return this;
  }

  /**
   * @param actions One or more actions. It is better to dispatch a set of
   * 		actions as a batched call, as it will not cause repeated React updates.
   */
  public dispatch(...actions: AnyAction[]): Action<string> | BatchAction {
    return actions.length === 1
      ? this.reduxStore.dispatch(actions[0])
      : this.reduxStore.dispatch(batchActions(actions));
  }

  public get getState(): BaseState {
    return this.reduxStore.getState();
  }
}

// export class OwlSlice<Key extends string, State> {
//   key:
// }
