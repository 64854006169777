import dayjs from 'dayjs';
import { call, SagaGenerator } from 'typed-redux-saga';
import {
  tenantsApiClient,
  investigationsApiClient,
} from '@owl-frontend/api-client';
import type {
  InvestigationItem,
  Tenant,
} from '@owl-frontend/api-client/interface';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import tenantsSlice from './interface';

export type TenantsListPayload = {
  lastCursor: string | null;
  tenants: Tenant[];
};

export type InvestigationsListPayload = InvestigationItem[];
function* tenantsList(action: {
  payload: {
    cursor?: string;
    limit?: number;
  };
}): SagaGenerator<TenantsListPayload> {
  const { cursor, limit = 100 } = action.payload;
  return yield* call(tenantsApiClient.tenants.list, {
    limit,
    cursor,
  });
}

const slice = tenantsSlice
  .addAsyncSagas({
    investigationsList: invoke(investigationsApiClient.investigations.list),
    tenantsList,
    updateTenantPriorities: invoke(
      tenantsApiClient.tenants.updateTenantPriorities
    ),
  })
  .addReducers({
    'investigationsList/fulfilled': (state, action) => {
      const items = action.payload;
      state.investigationItems = items.map((item) => {
        return {
          ...item,
          dueDate: dayjs(item.investigationDate).add(7, 'day').toISOString(),
        };
      });
    },
    'tenantsList/fulfilled': (state, action) => {
      const { tenants, lastCursor } = action.payload;
      const set = [...state.items, ...tenants].reduce((acc, curr) => {
        return {
          ...acc,
          [curr.tenantId]: curr,
        };
      }, {});
      const sorted: Tenant[] = Object.values(set);
      sorted.sort((a, b) =>
        (a as Tenant).name.localeCompare((b as Tenant).name)
      );
      state.items = sorted;
      state.lastCursor = lastCursor;
    },
  })
  .addReducers({
    clearItems: (state) => {
      state.items = [];
      state.lastCursor = null;
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
