//Corresponding specs at owl.common.schema.findings

export type IdentifyingMethod =
  | {
      matchesDossier: true;
      type: string;
    }
  | {
      matchesDossier: false;
      type: string;
      alternate: string;
      url: string;
    };

export const CRITERION_ORDER = {
  Income: 0,
  Activity: 1,
  Adverse: 2,
  'Life Event': 3,
  'Social Profile': 4,
};

export const CRITERION_BOUNDS = Object.keys(CRITERION_ORDER).length;

export interface Criterion {
  criterionId: string;
  categories: string[];
  eventType: string;
  actor?: string;
  risk?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
}

export enum FindingDecisionDetailAcceptedReason {
  PromptsImmediateAction = 'prompts-immediate-action',
  PromptsFurtherInvestigation = 'prompts-further-investigation',
  ImprovesClaimantKnowledge = 'improves-claimant-knowledge',
  PreviouslyKnown = 'previously-known',
}

export enum FindingDecisionDetailDismissedReason {
  WrongPerson = 'wrong-person',
  TechnicalIssue = 'technical-issue',
  NotMaterial = 'not-material',
}
export enum FindingDecisionDetailScreenshotDismissedReason {
  WrongPerson = 'wrong-person',
  TechnicalIssue = 'technical-issue',
  NotMaterial = 'not-material',
  InaccurateScreenshot = 'inaccurate-screenshot',
}

export enum FindingDecisionDetailInformationalReason {
  SocialProfile = 'social-profile',
}

export type FindingDecisionDetailReason = FindingDecisionDetailAcceptedReason &
  FindingDecisionDetailScreenshotDismissedReason &
  FindingDecisionDetailInformationalReason;

export interface DecisionDetails {
  reason?: FindingDecisionDetailReason;
  investigationId?: string;
  decidedAt?: string;
  comment?: string;
}

export enum FindingSource {
  Public = 'public',
  Internal = 'internal',
}

type Screenshot = { imagePath?: string; imageUrl: string } | { error: string };

export interface Finding {
  findingId: string;
  investigationId?: string;
  content: string;
  decision?: 'accepted' | 'rejected' | 'informational';
  decisionDetails?: DecisionDetails;
  confidence: number;
  source: FindingSource;
  originalUrl?: string;
  files: { mime: string; url: string }[];
  eventDate: string;
  criterion: Criterion;
  // meta
  dossierId: string;
  updatedAt: string;

  deleted?: boolean;
  deletedReason?: string;
  screenshot?: Screenshot;
}
