import { loadable } from '@owl-frontend/components';

// if you get a typing error in this file, you probably need to define your page parts.

export const TenantDetailsPage = loadable(
  () => import('./tenant-details/TenantDetailsLandingContainer'),
  {
    parts: {
      topPanelActions: true,
      breadcrumbs: true,
    },
  }
);
export const TenantUserDetailsPage = loadable(
  () => import('./tenant-users/TenantUserDetailsLandingContainer'),
  {
    parts: {
      topPanelActions: true,
      breadcrumbs: true,
    },
  }
);

export const DossiersUploadPage = loadable(
  () => import('./dossiers-upload/DossiersUploadContainer'),
  {
    parts: {
      topPanelActions: true,
      breadcrumbs: true,
    },
  }
);

export const TenantsPage = loadable(
  () => import('./tenants/TenantsContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const TasksPage = loadable(() => import('./workers/TasksContainer'), {
  parts: {
    topPanelActions: true,
    breadcrumbs: true,
  },
});

export const UsersPage = loadable(() => import('./users/UsersContainer'), {
  parts: {
    breadcrumbs: true,
  },
});

export const TenantUsersPage = loadable(
  () => import('./tenant-users/TenantUsersContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FowlTopologiesPage = loadable(
  () => import('./fowl/TopologiesContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FulfillmentCapacityPage = loadable(
  () => import('./fulfillment-capacity/FulfillmentCapacityContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FeedbackPage = loadable(
  () => import('./feedback/FeedbackContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const FeedbackAdminPage = loadable(
  () => import('./feedback/FeedbackAdminContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const ClaimsContainerPage = loadable(
  () => import('../context/ClaimsContainer/ClaimsContainer')
);

export const ClaimsTenantList = loadable(
  () => import('./claims/ClaimsTenantListComponent')
);

export const ClaimsPage = loadable(() => import('./claims/ClaimsContainer'), {
  parts: {
    topPanelActions: true,
    breadcrumbs: true,
  },
});

export const ClaimDetailsPage = loadable(
  () =>
    import('./claims/components/ClaimDetailsComponent/ClaimDetailsContainer')
);

export const ClaimsMLConfigPage = loadable(
  () => import('./claims-ml-config/ClaimsMLConfigContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const ClaimsInsightsPage = loadable(
  () => import('./claims/InsightsContainer'),
  {
    parts: {
      breadcrumbs: true,
      topPanelActions: true,
    },
  }
);

export const ClaimsAdminPage = loadable(
  () => import('./claims-admin/ClaimsAdminContainer'),
  {
    parts: {
      breadcrumbs: true,
    },
  }
);

export const ClaimsChunksPage = loadable(
  () => import('./claims-chunks/ClaimsChunksContainer')
);

export const ClaimsChunksClaimantPage = loadable(
  () => import('./claims-chunks/ClaimsListComponent')
);

export const ClaimsChunksDossierPage = loadable(
  () => import('./claims-chunks/ClaimsDocumentsListComponent')
);

export const ClaimsChunksDocumentsPage = loadable(
  () => import('./claims-chunks/ClaimsChunksListComponent')
);

export const ClaimsChunksDetailsPage = loadable(
  () => import('./claims-chunks/ClaimsChunksDetailsContainer')
);

export const ClaimsInsightsConfigPage = loadable(
  () => import('./claims-insights-config/ClaimsInsightsConfigContainer')
);

export const ClaimsHistoryPage = loadable(
  () =>
    import('./claims/components/ClaimsHistoryComponent/ClaimsHistoryComponent')
);

export const ClaimantInfoPage = loadable(
  () => import('./workers/components/find-flags/ClaimantInfoPageComponent')
);
