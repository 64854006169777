import { createLogger } from '@owl-lib/logger';
import { ErrorCode, OwlError } from '@owl-lib/util';
import { fetchWrapper } from '../fetch';
import { baseURL, deserialize } from '../helpers';
import type { EmailSubscriptions, Role, User, UserSettings } from './interface';

const logger = createLogger(__filename);

const usersApiClient = {
  users: {
    create: async (data: {
      username: string;
      email: string;
      role?: Role;
      settings?: UserSettings;
    }): Promise<User> => {
      const url = new URL(`/api/v1/users`, baseURL);
      logger.trace('usersApiClient.users.create');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          username: data.username,
          email: data.email,
          role: data.role,
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    delete: async (data: { userId: string }): Promise<void> => {
      const url = new URL(`/api/v1/users/${data.userId}`, baseURL);
      logger.trace('usersApiClient.users.delete');
      const response = await fetchWrapper(url.href, {
        method: 'DELETE',
        credentials: 'include',
      });
      logger.debug({ response }, `DELETE ${url.href}`);
      return deserialize(response);
    },
    updateUserRole: async (data: {
      userId: string;
      role?: Role;
    }): Promise<void> => {
      const url = new URL(`/api/v1/users/${data.userId}`, baseURL);
      logger.trace('usersApiClient.users.updateUserRole');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          role: data.role,
        }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    updateUserSettings: async (data: {
      userId: string;
      settings: UserSettings;
    }): Promise<void> => {
      const url = new URL(`/api/v1/users/${data.userId}/settings`, baseURL);
      logger.trace('usersApiClient.users.updateUserSettings');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          settings: data.settings,
        }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
    current: async (): Promise<User> => {
      const url = new URL(`/api/v1/users/current`, baseURL);
      logger.trace('usersApiClient.users.current');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.status >= 400) {
        throw new OwlError(ErrorCode.ForbiddenGeneric);
      }

      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    falconCurrent: async (): Promise<User> => {
      const url = new URL(`/api/v1/current-user`, baseURL);
      logger.trace('usersApiClient.users.current');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.status >= 400) {
        throw new OwlError(ErrorCode.ForbiddenGeneric);
      }

      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    list: async (): Promise<User[]> => {
      const url = new URL(`/api/v1/users`, baseURL);
      logger.trace('usersApiClient.users.list');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    updateUser: async (data: {
      username: string;
      roles: string[];
    }): Promise<User> => {
      const url = new URL(`/api/v1/users/${data.username}`, baseURL);
      logger.trace('rolesApiClient.roles.updateUser()');
      const response = await fetchWrapper(url.href, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          roles: data.roles,
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    updateEmailSubscriptions: async (data: {
      emailSubscriptions: EmailSubscriptions;
    }) => {
      const url = new URL(`/api/v1/users/current/email-subscriptions`, baseURL);
      logger.trace('usersApiClient.users.updateEmailSubscriptions()');
      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ emailSubscriptions: data.emailSubscriptions }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return deserialize(response);
    },
  },
};

export default usersApiClient;
