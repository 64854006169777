// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pages-claims-components-DocumentViewerComponent-components-TextDocumentViewer-__content--Kes74 {\n  margin-bottom: 0;\n  height: 100%;\n  width: 100%;\n  white-space: pre-wrap;\n}", "",{"version":3,"sources":["webpack://./src/pages/claims/components/DocumentViewerComponent/components/TextDocumentViewer/TextDocumentViewer.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;AACF","sourcesContent":[".content {\n  margin-bottom: 0;\n  height: 100%;\n  width: 100%;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "pages-claims-components-DocumentViewerComponent-components-TextDocumentViewer-__content--Kes74"
};
export default ___CSS_LOADER_EXPORT___;
