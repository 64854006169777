import { createLogger } from '@owl-lib/logger';
import { ErrorCode, OwlError } from '@owl-lib/util';
import { fetchWrapper } from '../fetch';
import type {
  Finding,
  FindingDecisionDetailReason,
} from '../findings/interface';
import { baseURL, deserialize } from '../helpers';
import type { FeaturesResponse, FrontendAction } from './interface';

const logger = createLogger(__filename);

const sessionApiClient = {
  features: {
    get: async (): Promise<FeaturesResponse> => {
      const url = new URL(`/api/v1/features`, baseURL);
      logger.trace('dossierApiClient.features.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
  session: {
    refresh: async (): Promise<void> => {
      const url = new URL(`/api/`, baseURL);
      logger.trace('apiClient.session.refresh()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
      });

      if (response.status === 401) {
        throw new OwlError(ErrorCode.ForbiddenUnauthenticated);
      }
    },
    logEvent: async (data: {
      events: {
        action: FrontendAction;
        dossierId?: string;
        pdfFields?: string[];
        findingId?: string;
        findingDecision?: Finding['decision'];
        findingDecisionReason?: FindingDecisionDetailReason;
        findingDecisionComment?: string;
        isPreReview?: boolean;
        unorderedReviewReasons?: string[];
        unorderedOrder?: number;
        listViewFilterParams?: { [k: string]: string | boolean };
        closeReason?: string;
        source?: string | null;
        tab?: string;
        section?: string;
        overviewCategory?: string;
        index?: number;
        docType?: string;
        newState?: 'expanded' | 'collapsed';
        overallFeedbackReason?: string;
        overallFeedbackReasonDetail?: string;
        searchQuery?: string;
        answer?: string;
        searchVersion?: string;
        datapointKey?: string;
        datapointsSectionFeedback?: string;
        noteSummaryId?: string;
        matches?: {
          docId: string;
          docName: string;
          page: number;
        }[];
        content?: string;
        deletedByUsername?: string;
      }[];
    }): Promise<void> => {
      const url = new URL(`/api/v1/logs`, baseURL);
      logger.trace('sessionApiClient.session.logEvent()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          events: [...data.events],
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
    },
    logout: async () => {
      const url = new URL(`/api/v1/signout`, baseURL);
      logger.trace('sessionApiClient.session.logout()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'GET',
          credentials: 'include',
        },
        -1
      );
      logger.debug({ response }, `GET ${url.href}`);
      window.location.replace(new URL('/api/logout', baseURL));
      return deserialize(response);
    },
    logoutFalcon: async (): Promise<void> => {
      window.location.replace(new URL('/api/v1/logout', baseURL));
    },
  } as const,
} as const;

export default sessionApiClient;
