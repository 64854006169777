import { rolesApiClient } from '@owl-frontend/api-client';
import { asyncActionStateMatchers, invoke } from '@owl-frontend/redux';
import rolesSlice from './interface';

const slice = rolesSlice
  .addAsyncSagas({
    listRoles: invoke(rolesApiClient.roles.list),
  })
  .addReducers({
    'listRoles/fulfilled': (state, action) => {
      state.roles = action.payload;
    },
  })
  .addReducers({
    clearItems: (state) => {
      state.roles = [];
    },
  });
export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
