import React from 'react';
import { Tooltip as TooltipAntd } from 'antd';
import type { TooltipProps } from 'antd/lib/tooltip';
export type { TooltipProps } from 'antd/lib/tooltip';

const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return <TooltipAntd {...props}>{children}</TooltipAntd>;
};

export default Tooltip;
