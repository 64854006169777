import React, { ReactNode } from 'react';
import Logo from '../_assets/Logo.svg';
import Icon from '../Icon/Icon';
import styles from './OwlDisplay.module.scss';

export interface Props {
  label: ReactNode;
  isInternal?: boolean;
}

const OwlDisplay = ({ label, isInternal }: Props) => {
  return (
    <div className={styles.label}>
      {isInternal && (
        <Icon color="red" size="small">
          <Logo viewBox="0 0 64 64" className={styles.internalIcon} />
        </Icon>
      )}
      <span>{label}</span>
    </div>
  );
};

export default OwlDisplay;
