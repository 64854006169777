import React from 'react';
import { shallowEqual } from 'react-redux';
import type { AnyFn } from '../type-helpers';
import { useMemoWithPrevResult } from './previous';

// Stable function references

/**
 * It's just `useCallback` without the type limitation of functions.
 * `useMemo` for really cheap creation
 */
export const useStableValue = React.useCallback as <T>(
  value: T,
  deps: React.DependencyList
) => T;
/**
 * Only use with objects where the set of keys does _not_ change. Generally,
 * that means _only_ object literals
 * @example
 * const obj = useStableObject({ foo: bar });
 * // same effect as
 * const obj = useMemo(() => ({ foo: bar }), bar);
 */
export const useStableObject = <T extends { [key: string]: any }>(value: T) =>
  // eslint-disable-next-line react-hooks2/exhaustive-deps
  useStableValue(value, Object.values(value));

export const stableCompareUse =
  <T>(compare: (a: T, b: T) => boolean) =>
  (value: T) =>
    useMemoWithPrevResult<T>(
      (prev) =>
        prev === undefined ? value : compare(value, prev) ? prev : value,
      [value]
    );
export const useStableShallowCompare = stableCompareUse(shallowEqual);

type StableCallback<F extends AnyFn> = F & Record<keyof F, unknown>;
// See also: react-final-form's useConstantCallback
/** `useCallback` to the extreme - always return the same referentially stable function. */
export function useStableCallback<T extends AnyFn>(fn: T): StableCallback<T> {
  const ref = React.useRef(fn);
  ref.current = fn;
  // eslint-disable-next-line react-hooks2/exhaustive-deps
  return React.useCallback(
    function (this: any, ...args: any) {
      return ref.current.apply(this, args);
    } as StableCallback<T>,
    []
  );
}
/**
 * Primary usage is in datepicker's `useListener`. Otherwise, it's generally to
 * preserve function referential identity like `useStableCallback`, but the
 * function is optional so that things like `useListener` can remove the
 * listener when unused.
 *
 * @example
 * const MyComponent = (props: { onClick?: (event: any) => void }) => {
 *   const onClick = useStableCallbackOptional(props.onClick);
 *   return <OtherComponent onClick={onClick} />;
 * }
 * const Container = () => {
 *   // OtherComponent _wont_ be re-rendered even though we're not using
 *   // "useCallback" for our callback here.
 *   return <MyComponent onClick={(event) => { console.log(event); }} />;
 * }
 */
export function useStableCallbackOptional<A extends any[], R>(
  fn?: (...args: A) => R
): ((...args: A) => R | undefined) | undefined {
  const ref = React.useRef(fn);
  ref.current = fn;
  const listener = React.useCallback(
    function (this: any, ...args: any) {
      return ref.current?.apply(this, args);
    },
    [ref]
  );
  if (fn) return listener;
}
