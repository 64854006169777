import { call, CallEffect, SagaReturnType } from 'redux-saga/effects';
import type { SagaGenerator } from 'typed-redux-saga/dist';
import type { NoInfer } from '@owl-lib/type-util';
import type { AnyPayloadAction } from '../slice/interface';

type AnyFn = (...args: any[]) => any;
type CtxFn<T> = (this: T, ...args: any[]) => any;
/** short for 'payload' */
type Pload = { payload: any };
type DefaultPload<Fn extends AnyFn> = {
  payload: Fn extends (arg: infer A) => any ? A : void;
};
interface Invoke {
  <Fn extends AnyFn, A extends Pload = DefaultPload<Fn>>(
    fn: Fn,
    args?: (action: A) => Readonly<Parameters<NoInfer<Fn>>>
  ): (
    action: A
  ) => SagaGenerator<SagaReturnType<Fn>, CallEffect<SagaReturnType<Fn>>>;
  /**
   * Same as `invoke([context, fn], args?)` but supports passing a `fn` as string.
   * Useful for invoking object's methods, i.e.
   * `yield invoke([localStorage, 'getItem'], 'redux-saga')`
   */
  <
    Ctx extends { [P in Name]: CtxFn<Ctx> },
    Name extends string,
    A extends Pload = DefaultPload<Ctx[Name]>
  >(
    ctxAndFnName: [Ctx, Name],
    args?: (action: A) => Readonly<Parameters<Ctx[Name]>>
  ): (
    action: A
  ) => SagaGenerator<
    SagaReturnType<Ctx[Name]>,
    CallEffect<SagaReturnType<Ctx[Name]>>
  >;
  /**
   * Same as `invoke([context, fn], args?)` but supports passing `context` and
   * `fn` as properties of an object, i.e.
   * `invoke({context: localStorage, fn: localStorage.getItem}, 'redux-saga')`.
   * `fn` can be a string or a function.
   */
  <
    Ctx extends { [P in Name]: CtxFn<Ctx> },
    Name extends string,
    A extends Pload = DefaultPload<Ctx[Name]>
  >(
    ctxAndFnName: { context: Ctx; fn: Name },
    args?: (action: A) => Readonly<Parameters<Ctx[Name]>>
  ): (
    action: A
  ) => SagaGenerator<
    SagaReturnType<Ctx[Name]>,
    CallEffect<SagaReturnType<Ctx[Name]>>
  >;
  /**
   * Same as `invoke(fn, args?)` but supports passing a `this` context to `fn`.
   * This is useful to invoke object methods.
   */
  <Ctx, Fn extends CtxFn<Ctx>, A extends Pload = DefaultPload<Fn>>(
    ctxAndFn: [Ctx, Fn],
    args?: (action: A) => Readonly<Parameters<Fn>>
  ): (
    action: A
  ) => SagaGenerator<SagaReturnType<Fn>, CallEffect<SagaReturnType<Fn>>>;
  /**
   * Same as `invoke([context, fn], args?)` but supports passing `context` and
   * `fn` as properties of an object, i.e.
   * `invoke({context: localStorage, fn: localStorage.getItem}, 'redux-saga')`.
   * `fn` can be a string or a function.
   */
  <Ctx, Fn extends CtxFn<Ctx>, A extends Pload = DefaultPload<Fn>>(
    ctxAndFn: { context: Ctx; fn: Fn },
    args?: (action: A) => Readonly<Parameters<Fn>>
  ): (
    action: A
  ) => SagaGenerator<SagaReturnType<Fn>, CallEffect<SagaReturnType<Fn>>>;
  // Duplicated at the bottom for
  <Fn extends AnyFn, A extends Pload = DefaultPload<Fn>>(
    fn: Fn,
    args?: (action: A) => Readonly<Parameters<NoInfer<Fn>>>
  ): (
    action: A
  ) => SagaGenerator<SagaReturnType<Fn>, CallEffect<SagaReturnType<Fn>>>;
}

type FnWithCtx =
  | AnyFn
  | [any, string | AnyFn]
  | { context: any; fn: string | AnyFn };
type AnyCall = (fn: FnWithCtx, ...args: any) => CallEffect;
/**
 * Creates an Effect description that instructs the middleware to call the
 * function `fn` with `args` as arguments, and returns the result.
 *
 * #### Notes
 *
 * `fn` can be either a *normal* or a Generator function.
 *
 * The middleware invokes the function and examines its result.
 *
 * If the result is an Iterator object, the middleware will run that Generator
 * function, just like it did with the startup Generators (passed to the
 * middleware on startup). The parent Generator will be suspended until the
 * child Generator terminates normally, in which case the parent Generator is
 * resumed with the value returned by the child Generator. Or until the child
 * aborts with some error, in which case an error will be thrown inside the
 * parent Generator.
 *
 * If `fn` is a normal function and returns a Promise, the middleware will
 * suspend the Generator until the Promise is settled. After the promise is
 * resolved the Generator is resumed with the resolved value, or if the Promise
 * is rejected an error is thrown inside the Generator.
 *
 * If the result is not an Iterator object nor a Promise, the middleware will
 * immediately return that value back to the saga, so that it can resume its
 * execution synchronously.
 *
 * When an error is thrown inside the Generator, if it has a `try/catch` block
 * surrounding the current `yield` instruction, the control will be passed to
 * the `catch` block. Otherwise, the Generator aborts with the raised error, and
 * if this Generator was called by another Generator, the error will propagate
 * to the calling Generator.
 *
 * @param fn A Generator function, or normal function which either returns a
 *   Promise as result, or any other value.
 * @param args An function that returns an array of values to be passed as arguments to `fn`
 */
export const invoke: Invoke = (
  fnWithCtx: FnWithCtx,
  args?: (action: AnyPayloadAction) => ReadonlyArray<any>
) =>
  args
    ? function* invoker(action: any) {
        return yield (call as AnyCall)(fnWithCtx, ...args(action));
      }
    : function* invoker(action: any) {
        return yield (call as AnyCall)(fnWithCtx, action.payload);
      };
