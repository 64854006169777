import React from 'react';
import { useNavigate, useMatch } from 'react-router-dom';

const DefaultRedirect = ({ path }: { path: string }) => {
  const navigate = useNavigate();
  const redirectTo = path;
  const isCurrentPage = useMatch(redirectTo);
  // TODO: check for valid route. Or not, and display 404 page
  React.useEffect(() => {
    if (redirectTo && !isCurrentPage) {
      navigate(redirectTo, { replace: true });
    }
  }, [isCurrentPage, navigate, redirectTo]);
  return <React.Fragment />;
};

export default DefaultRedirect;
