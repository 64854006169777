import type { BatchProcessLog } from '@owl-frontend/api-client';
import type {
  IngestionConfig,
  Review,
  Tenant,
  ApprovalResponse,
  TenantUser,
  UploadItem,
  StagedDossier,
} from '@owl-frontend/api-client/interface';
import { AsyncActionState, setupSlice } from '@owl-frontend/redux';

export type UploadApprovalProps = Omit<
  ApprovalResponse,
  'tenantId' | 'uploadId'
>;

export interface TenantsState {
  tenants: {
    [tenantId: string]: Tenant;
  };
  reviews: {
    [tenantId: string]: {
      count: number;
      items: Review[];
    };
  };
  currentUpload: {
    [tenantId: string]: UploadItem;
  };
  uploads: {
    [tenantId: string]: UploadItem[];
  };
  stagedDossiers: {
    [tenantId: string]: {
      items: StagedDossier[];
      lastCursor?: string;
    };
  };
  ingestionConfigs: {
    [tenantId: string]: IngestionConfig[];
  };
  users: {
    [tenantId: string]: TenantUser[];
  };
  latestRerankDossiersLog?: BatchProcessLog;
  actions: {
    fetchTenantDetails: AsyncActionState<Tenant>;
    updateTenantIngestionSettings: AsyncActionState<Tenant>;
    fetchReviews: AsyncActionState<Review[]>;
    fetchReviewsCount: AsyncActionState<number>;
    fetchIngestionConfigs: AsyncActionState<IngestionConfig[]>;
    sendUploadApproval: AsyncActionState;
    listUploads: AsyncActionState<UploadItem[]>;
    updateUpload: AsyncActionState<UploadItem>;
    listStagingDossiers: AsyncActionState<{
      dossiers: StagedDossier[];
      lastCursor?: string;
    }>;
    updateStagingDossier: AsyncActionState<StagedDossier>;
    saveIngestionConfig: AsyncActionState;
    deleteIngestionConfig: AsyncActionState;
    rerankDossiers: AsyncActionState;
    getLatestRerankDossiersLog: AsyncActionState;
    listUsers: AsyncActionState<TenantUser[]>;
    addTenantUser: AsyncActionState;
    addMultipleTenantUsers: AsyncActionState;
  };
}

const initialState: TenantsState = {
  tenants: {},
  reviews: {},
  currentUpload: {},
  uploads: {},
  stagedDossiers: {},
  ingestionConfigs: {},
  users: {},
  actions: {
    fetchTenantDetails: { status: 'uninitialized' },
    updateTenantIngestionSettings: { status: 'uninitialized' },
    fetchReviews: { status: 'uninitialized' },
    fetchReviewsCount: { status: 'uninitialized' },
    fetchIngestionConfigs: { status: 'uninitialized' },
    sendUploadApproval: { status: 'uninitialized' },
    listUploads: { status: 'uninitialized' },
    updateUpload: { status: 'uninitialized' },
    listStagingDossiers: { status: 'uninitialized' },
    updateStagingDossier: { status: 'uninitialized' },
    saveIngestionConfig: { status: 'uninitialized' },
    deleteIngestionConfig: { status: 'uninitialized' },
    rerankDossiers: { status: 'uninitialized' },
    getLatestRerankDossiersLog: { status: 'uninitialized' },
    listUsers: { status: 'uninitialized' },
    addTenantUser: { status: 'uninitialized' },
    addMultipleTenantUsers: { status: 'uninitialized' },
  },
};

const tenantDetailsSlice = setupSlice('tenant-details', initialState);
export default tenantDetailsSlice;
