import React from 'react';
import { Component, createComponent, createHook } from 'reakit-system';
import { default as DialogComponent, DialogProps } from './Dialog';

// TODO: redesign this entire thing. should use context instead of global `document`
// events, hook should be used to access the "show" and "dismiss" methods, etc.

export interface DialogHookProps {
  id: string;
  title?: React.ReactChild;
  message: React.ReactChild;
  size?: DialogProps['size'];
  footer?: DialogProps['footer'];
}

interface DialogMethods {
  show(opts: DialogHookProps): void;
  dismiss(opts: { id: string }): void;
}

const SHOW = 'DialogHook/show';
const DISMISS = 'DialogHook/dismiss';

declare global {
  interface DocumentEventMap {
    [SHOW]: CustomEvent<DialogHookProps>;
    [DISMISS]: CustomEvent<Pick<DialogHookProps, 'id'>>;
  }
}

const useDialog = createHook({
  name: 'Dialog',
  useProps: (props, { ...htmlProps }: React.ComponentProps<any>) => {
    const [queue, setQueue] = React.useState<{
      [id: string]: DialogHookProps;
    }>({});

    const showDialog = React.useCallback(
      (e: CustomEvent<DialogHookProps>) => {
        setQueue({
          ...queue,
          [e.detail.id]: {
            id: e.detail.id,
            title: e.detail.title,
            message: e.detail.message,
            footer: e.detail.footer,
          },
        });
      },
      [queue]
    );

    const dismissDialog = React.useCallback(
      (e: CustomEvent<Pick<DialogHookProps, 'id'>>) => {
        const newQueue = { ...queue };
        delete newQueue[e.detail.id];
        setQueue(newQueue);
      },
      [queue]
    );

    React.useEffect(() => {
      document.addEventListener(SHOW, showDialog);
      document.addEventListener(DISMISS, dismissDialog);
      return () => {
        document.removeEventListener(SHOW, showDialog);
        document.removeEventListener(DISMISS, dismissDialog);
      };
    }, [showDialog, dismissDialog]);

    const children = Object.values(queue).map(
      ({ id, title, message, footer }) => (
        <DialogComponent
          key={id}
          aria-label={title ? 'confirm' : undefined}
          title={title}
          footer={footer}
          size="small"
          open
        >
          {message}
        </DialogComponent>
      )
    );

    return {
      ...htmlProps,
      children,
    };
  },
});

type DialogType = Component<React.ElementType<'div'>, any> & DialogMethods;

export const Dialog: DialogType = createComponent({
  as: 'div',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useHook: useDialog,
}) as DialogType;

Dialog.show = (detail) => {
  document.dispatchEvent(new CustomEvent(SHOW, { detail: { ...detail } }));
};

Dialog.dismiss = ({ id }) => {
  document.dispatchEvent(new CustomEvent(DISMISS, { detail: { id } }));
};

export default function DialogLanding() {
  return <Dialog />;
}
