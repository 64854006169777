import React from 'react';
import highlightWords, { HighlightWords } from 'highlight-words';
import type { MatchAnswer } from '@owl-frontend/api-client/interface';
import styles from './TextDocumentViewerComponent.module.scss';

export interface Props {
  content: string;
  documentMatches?: MatchAnswer[];
}

const TextDocumentViewer: React.FC<Props> = ({
  content,
  documentMatches = [],
}) => {
  const render = React.useMemo(() => {
    const result: React.ReactNode[] = [];
    const chunks: HighlightWords.Chunk[] = [];

    if (!documentMatches || documentMatches.length === 0) {
      return <>{content}</>;
    }

    for (const match of documentMatches) {
      if (!match.snippet) {
        continue;
      }

      const query = match.snippet.match('<em>(.*?)</em>');

      if (!query) {
        continue;
      }

      chunks.push(
        ...highlightWords({
          text: content,
          query: query[1],
          matchExactly: true,
        })
      );
    }

    for (const chunk of chunks) {
      if (chunk.match) {
        result.push(
          <span key={chunk.key} style={{ background: 'yellow' }}>
            {chunk.text}
          </span>
        );
      } else {
        result.push(chunk.text);
      }
    }

    return <>{result}</>;
  }, [documentMatches, content]);

  return <pre className={styles.content}>{render}</pre>;
};

export default TextDocumentViewer;
