import React from 'react';
import { useField } from 'react-final-form';
import MultiSelect, {
  Props as SingleSelectProps,
} from '../../components/select/multi-select/MultiSelectAntd';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import type { ManagedFieldProps } from '../interface';

export interface Props extends SingleSelectProps, ManagedFieldProps {}

const MultiSelectManaged: React.FC<Props> = ({
  name,
  defaultValue,
  validate,
  ...selectProps
}) => {
  const { input, meta } = useField(name, { defaultValue, validate });
  const { error, invalid, modified, touched } = meta;

  const modifiedOrTouched = modified || touched;
  const fetchErrorMessage = React.useCallback(() => {
    if (!modifiedOrTouched || !invalid || !error) {
      return null;
    }
    return error;
  }, [modifiedOrTouched, invalid, error]);

  return (
    <MultiSelect
      onChange={input.onChange}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      value={input.value || []}
      {...selectProps}
      trailer={<ErrorDisplayComponent errorMessage={fetchErrorMessage()} />}
    />
  );
};

export default MultiSelectManaged;
