import React from 'react';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../../context/AppContainer/AppContainer';

interface Props {
  createdByName: string;
  insightDetails?: string;
  insightNextAction?: string;
}

const ClaimsInsightsFeedbackComponent: React.FC<Props> = ({
  createdByName,
  insightDetails,
  insightNextAction,
}) => {
  const { messages } = React.useContext(Locale);
  const intl = useIntl();

  const { nextActionText, detailsText } = React.useMemo(() => {
    return {
      nextActionText:
        messages[
          `claims.matchInsights.promptsImmediateActionOpts.${insightNextAction}`
        ],
      detailsText:
        messages[`dossierDetails.decision.findings.details.${insightDetails}`],
    };
  }, [messages, insightNextAction, insightDetails]);

  return (
    <div>
      {intl.formatMessage(
        { id: 'claims.claimsInsights.notes.findsValuable' },
        {
          name: createdByName,
          insightDetails: detailsText && <>({detailsText})</>,
        }
      )}
      {insightNextAction && insightNextAction !== 'no-action' && (
        <>
          {' '}
          {intl.formatMessage(
            { id: 'claims.claimsInsights.notes.nextAction' },
            {
              name: createdByName,
              nextAction: nextActionText ?? insightNextAction,
            }
          )}
        </>
      )}
    </div>
  );
};

export default ClaimsInsightsFeedbackComponent;
