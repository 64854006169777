/**
 * Do not reference values directly; prefer property-based lookups.
 */
export enum ErrorCode {
  InternalGeneric = "internal.generic",
  InternalCancelled = "internal.cancelled",
  InternalNotImplemented = "internal.notImplemented",
  InternalNotNonfigured = "internal.notNonfigured",
  InternalTimeout = "internal.timeout",
  InternalInvalidState = "internal.invalidState",
  RatelimitGeneric = "ratelimit.generic",
  RatelimitLoginAttempts = "ratelimit.loginAttempts",
  ExternalGeneric = "external.generic",
  ExternalRateLimitExceeded = "external.rateLimitExceeded",
  ExternalUnavailable = "external.unavailable",
  ForbiddenGeneric = "forbidden.generic",
  ForbiddenUnauthenticated = "forbidden.unauthenticated",
  ForbiddenRoleCannotDeleteRoleWithUsers = "forbidden.role.cannotDeleteRoleWithUsers",
  ForbiddenRoleCannotRenameBuiltin = "forbidden.role.cannotRenameBuiltin",
  ForbiddenRoleCannotSetInternalWithExternalUsers = "forbidden.role.cannotSetInternalWithExternalUsers",
  ForbiddenTeamCannotSetInternalWithExternalUsers = "forbidden.team.cannotSetInternalWithExternalUsers",
  ForbiddenUserCannotRenameBuiltin = "forbidden.user.cannotRenameBuiltin",
  ForbiddenUserCannotDeleteSelf = "forbidden.user.cannotDeleteSelf",
  UnavailableDisabled = "unavailable.disabled",
  InvalidParamsGeneric = "invalidParams.generic",
  InvalidParamsTooManyResults = "invalidParams.tooManyResults",
  InvalidParamsIncompatibleChange = "invalidParams.incompatibleChange",
  InvalidParamsInvalidPhoneNumber = "invalidParams.invalid.phoneNumber",
  InvalidParamsInvalidEmail = "invalidParams.invalid.email",
  InvalidParamsPasswordTokenExpired = "invalidParams.passwordToken.expired",
  InvalidParamsPasswordTokenInvalid = "invalidParams.passwordToken.invalid",
  InvalidParamsClaimDuplicateClaimId = "invalidParams.claim.duplicateClaimId",
  InvalidParamsClaimFormIncompatibleSchema = "invalidParams.claim.form.incompatibleSchema",
  InvalidParamsClaimSearchSequenceTitleDuplicate = "invalidParams.claim.searchSequence.title.duplicate",
  InvalidParamsSessionInvalidUsernameOrPassword = "invalidParams.session.invalidUsernameOrPassword",
  InvalidParamsSessionExpired = "invalidParams.session.expired",
  InvalidParamsUserEmailDuplicate = "invalidParams.user.email.duplicate",
  InvalidParamsUserPasswordMissingOldPasswordForUpdate = "invalidParams.user.password.missingOldPasswordForUpdate",
  InvalidParamsUserPasswordWrongPassword = "invalidParams.user.password.wrongPassword",
  InvalidParamsUserUsernameUsernameEmailMatch = "invalidParams.user.username.usernameEmailMatch",
  InvalidParamsUserUsernameDuplicate = "invalidParams.user.username.duplicate",
  NotFoundGeneric = "notFound.generic",
}

export enum ErrorCodeCategory {
  External = "external",
  Forbidden = "forbidden",
  Internal = "internal",
  InvalidParams = "invalidParams",
  NotFound = "notFound",
  Ratelimit = "ratelimit",
  Unavailable = "unavailable",
}

export function getErrorCodeCategory(value: ErrorCode): ErrorCodeCategory {
  return (value as string).replace(/^([^.]*)(\..*)/, "$1") as ErrorCodeCategory;
}

export const errorCodes = {
  internal: {
    generic: ErrorCode.InternalGeneric,
    cancelled: ErrorCode.InternalCancelled,
    notImplemented: ErrorCode.InternalNotImplemented,
    notNonfigured: ErrorCode.InternalNotNonfigured,
    timeout: ErrorCode.InternalTimeout,
    invalidState: ErrorCode.InternalInvalidState,
  },
  ratelimit: {
    generic: ErrorCode.RatelimitGeneric,
    loginAttempts: ErrorCode.RatelimitLoginAttempts,
  },
  external: {
    generic: ErrorCode.ExternalGeneric,
    rateLimitExceeded: ErrorCode.ExternalRateLimitExceeded,
    unavailable: ErrorCode.ExternalUnavailable,
  },
  forbidden: {
    generic: ErrorCode.ForbiddenGeneric,
    unauthenticated: ErrorCode.ForbiddenUnauthenticated,
    role: {
      cannotDeleteRoleWithUsers:
        ErrorCode.ForbiddenRoleCannotDeleteRoleWithUsers,
      cannotRenameBuiltin: ErrorCode.ForbiddenRoleCannotRenameBuiltin,
      cannotSetInternalWithExternalUsers:
        ErrorCode.ForbiddenRoleCannotSetInternalWithExternalUsers,
    },
    team: {
      cannotSetInternalWithExternalUsers:
        ErrorCode.ForbiddenTeamCannotSetInternalWithExternalUsers,
    },
    user: {
      cannotRenameBuiltin: ErrorCode.ForbiddenUserCannotRenameBuiltin,
      cannotDeleteSelf: ErrorCode.ForbiddenUserCannotDeleteSelf,
    },
  },
  unavailable: { disabled: ErrorCode.UnavailableDisabled },
  invalidParams: {
    generic: ErrorCode.InvalidParamsGeneric,
    tooManyResults: ErrorCode.InvalidParamsTooManyResults,
    incompatibleChange: ErrorCode.InvalidParamsIncompatibleChange,
    invalid: {
      phoneNumber: ErrorCode.InvalidParamsInvalidPhoneNumber,
      email: ErrorCode.InvalidParamsInvalidEmail,
    },
    passwordToken: {
      expired: ErrorCode.InvalidParamsPasswordTokenExpired,
      invalid: ErrorCode.InvalidParamsPasswordTokenInvalid,
    },
    claim: {
      duplicateClaimId: ErrorCode.InvalidParamsClaimDuplicateClaimId,
      form: {
        incompatibleSchema: ErrorCode.InvalidParamsClaimFormIncompatibleSchema,
      },
      searchSequence: {
        title: {
          duplicate: ErrorCode.InvalidParamsClaimSearchSequenceTitleDuplicate,
        },
      },
    },
    session: {
      invalidUsernameOrPassword:
        ErrorCode.InvalidParamsSessionInvalidUsernameOrPassword,
      expired: ErrorCode.InvalidParamsSessionExpired,
    },
    user: {
      email: { duplicate: ErrorCode.InvalidParamsUserEmailDuplicate },
      password: {
        missingOldPasswordForUpdate:
          ErrorCode.InvalidParamsUserPasswordMissingOldPasswordForUpdate,
        wrongPassword: ErrorCode.InvalidParamsUserPasswordWrongPassword,
      },
      username: {
        usernameEmailMatch:
          ErrorCode.InvalidParamsUserUsernameUsernameEmailMatch,
        duplicate: ErrorCode.InvalidParamsUserUsernameDuplicate,
      },
    },
  },
  notFound: { generic: ErrorCode.NotFoundGeneric },
} as const;
