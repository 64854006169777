import { createLogger } from '@owl-lib/logger';
import { fetchWrapper } from '../fetch';
import type { Finding } from '../findings/interface';
import { baseURL, deserialize } from '../helpers';
import { UPLOAD_TIMEOUT_LIMIT } from '../uploads/client';
import type {
  AssignmentDetails,
  Dossier,
  DossierFilterParams,
  GetPresignedUploadResponse,
  ImportDossier,
  ReinvestigationsResponse,
  ReservationResponse,
  SaveDossiersBatchResponse,
  SnoozeDetails,
  TopologyTarget,
} from './interface';

const logger = createLogger(__filename);

const dossierApiClient = {
  dossiers: {
    conclude: async (data: {
      dossierId: string;
      groupId: string;
      conclusion: Dossier['conclusion'];
      conclusionDetails: Dossier['conclusionDetails'];
      findings: {
        findingId: string;
        decision: Finding['decision'];
        decisionDetails: Finding['decisionDetails'];
      }[];
    }): Promise<Dossier> => {
      const conclusionUrl = new URL(
        `/api/v1/dossiers/${data.dossierId}/conclusion`,
        baseURL
      );

      logger.trace('dossierApiClient.dossiers.conclude()');

      const conclusionResponse = await fetchWrapper(conclusionUrl.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          conclusion: data.conclusion,
          conclusionDetails: data.conclusionDetails,
          findings: data.findings,
          groupId: data.groupId ?? 'ready',
        }),
      });
      logger.debug({ conclusionResponse }, `POST ${conclusionUrl.href}`);
      return await deserialize(conclusionResponse);
    },
    done: async (data: {
      dossierId: string;
      groupId: string;
    }): Promise<Dossier> => {
      const url = new URL(`/api/v1/dossiers/${data.dossierId}/status`, baseURL);

      logger.trace('dossierApiClient.dossiers.done()');

      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          status: 'done',
          groupId: data.groupId ?? 'ready',
        }),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return await deserialize(response);
    },
    close: async (data: {
      dossierId: string;
      groupId: string;
      closeDate: string;
      closeReason?: string;
    }) => {
      const url = new URL(`/api/v1/dossiers/${data.dossierId}/close`, baseURL);
      const body = {
        closeDate: data.closeDate,
        groupId: data.groupId ?? 'ready',
      };
      if (data.closeReason) {
        body['conclusionDetails'] = { reason: data.closeReason };
      }

      logger.trace('dossierApiClient.dossiers.close()');

      const response = await fetchWrapper(url.href, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(body),
      });
      logger.debug({ response }, `PUT ${url.href}`);
      return await deserialize(response);
    },
    get: async (data: { dossierId: string }): Promise<Dossier> => {
      const url = new URL(`/api/v1/dossiers/${data.dossierId}`, baseURL);
      logger.trace('dossierApiClient.dossiers.get()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    listByStatus: async (data: {
      status: 'all' | 'ready' | 'done';
      lastCursor?: string;
      filterParams?: DossierFilterParams;
    }): Promise<{
      lastCursor: string | null;
      dossiers: Dossier[];
      count?: number;
    }> => {
      const url = new URL('/api/v1/dossiers', baseURL);
      url.searchParams.set('status', data.status);

      if (data.status === 'ready') {
        url.searchParams.set('order', 'rank');
      } else if (data.status === 'done') {
        url.searchParams.set('order', 'conclusion-set-at');
      }

      if (data.filterParams) {
        const {
          clientDossierId,
          name,
          owner,
          conclusion,
          isOwner,
          isReviewedByUser,
          priorityType,
        } = data.filterParams;
        if (clientDossierId) {
          url.searchParams.set('clientDossierId', clientDossierId);
        }

        if (name) {
          url.searchParams.set('dossierName', name);
        }

        if (owner) {
          url.searchParams.set('owner', owner);
        }

        if (conclusion) {
          url.searchParams.delete('status');
          url.searchParams.set('conclusion', conclusion);
        }

        if (isOwner) {
          url.searchParams.set('is-owner', `${isOwner}`);
        }

        if (isReviewedByUser) {
          url.searchParams.set('is-reviewed-by-user', `${isReviewedByUser}`);
        }

        if (priorityType) {
          url.searchParams.set('priority-type', `${priorityType}`);
        }
      }

      if (data.lastCursor) {
        url.searchParams.set('cursor', data.lastCursor);
      }

      logger.trace('dossierApiClient.dossiers.listByStatus()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    listAll: async (data?: {
      clientDossierId?: string;
      status?: 'all' | 'ready' | 'done';
      name?: string;
      owner?: string;
      conclusion?: Dossier['conclusion'];
      limit?: number;
      lastCursor?: string;
    }): Promise<{
      lastCursor: string | null;
      dossiers: Dossier[];
      count?: number;
    }> => {
      const url = new URL('/api/v1/dossiers/all', baseURL);

      if (data?.clientDossierId) {
        url.searchParams.set('clientDossierId', data.clientDossierId);
      }

      if (data?.status) {
        url.searchParams.set('status', data.status);
      }

      if (data?.name) {
        url.searchParams.set('dossierName', data.name);
      }

      if (data?.owner) {
        url.searchParams.set('owner', data.owner);
      }

      if (data?.conclusion) {
        url.searchParams.set('conclusion', data.conclusion);
      }

      if (data?.lastCursor) {
        url.searchParams.set('cursor', data.lastCursor);
      }

      if (data?.limit) {
        url.searchParams.set('limit', `${data.limit}`);
      }

      logger.trace('dossierApiClient.dossiers.listByStatus()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    list: async (
      data: {
        clientDossierId?: string;
        username?: string;
        owner?: string;
        cursor?: string;
        limit?: number;
        conclusion?: Dossier['conclusion'];
        status?: string;
        order?: string;
        startTime?: string;
        endTime?: string;
        includeData?: boolean;
        includeAggCount?: boolean;
        pending?: boolean;
      } = {}
    ): Promise<{
      lastCursor: string | null;
      count?: number;
      dossiers: Dossier[];
    }> => {
      const url = new URL(
        data.pending ? '/api/v1/dossiers/pending' : '/api/v1/dossiers',
        baseURL
      );

      if (data) {
        const {
          cursor,
          clientDossierId,
          username,
          owner,
          limit,
          conclusion,
          status,
          order,
          startTime,
          endTime,
          includeData = true,
          includeAggCount = false,
        } = data;
        if (clientDossierId && clientDossierId.length > 0) {
          url.searchParams.set('clientDossierId', clientDossierId);
        }
        if (username && username.length > 0) {
          url.searchParams.set('username', username);
        }
        if (owner && owner.length > 0) {
          url.searchParams.set('owner', owner);
        }
        if (cursor) {
          url.searchParams.set('cursor', cursor);
        }
        if (limit) {
          url.searchParams.set('limit', `${limit}`);
        }
        if (conclusion) {
          url.searchParams.set('conclusion', conclusion);
        }
        if (status) {
          url.searchParams.set('status', status);
        }
        if (order) {
          url.searchParams.set('order', order);
        }
        if (startTime) {
          url.searchParams.set('startTime', startTime);
        }
        if (endTime) {
          url.searchParams.set('endTime', endTime);
        }
        if (includeAggCount) {
          url.searchParams.set('includeAggCount', `${includeAggCount}`);
        }

        url.searchParams.set('includeData', `${includeData}`);
      }

      logger.trace('dossierApiClient.dossiers.list()');
      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    // Falcon
    saveBatch: async (
      uploadId: string,
      tenantId: string,
      dossierType: Dossier['dossierType'],
      batchData: ImportDossier[],
      topology: TopologyTarget,
      investigationId: string | null
    ): Promise<SaveDossiersBatchResponse> => {
      const url = new URL(`/api/v1/tenants/${tenantId}/dossiers`, baseURL);
      logger.trace('dossierApiClient.dossiers.saveBatch()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            topologyTarget: topology,
            investigationId,
            dossiers: batchData.map((data) => {
              const { owner, metadata, ...rest } = data;
              return {
                uploadId,
                clientDossierId: data.clientDossierId,
                dossierType,
                owner,
                metadata,
                data: rest,
              };
            }),
          }),
        },
        -1
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    reinvestigations: async (payload: {
      uploadId: string;
      tenantId: string;
      dossierType: Dossier['dossierType'];
      data: ImportDossier[];
      topology: TopologyTarget;
    }): Promise<ReinvestigationsResponse> => {
      const { tenantId, topology, uploadId, dossierType, data } = payload;
      const url = new URL(
        `/api/v1/tenants/${tenantId}/reinvestigations`,
        baseURL
      );
      logger.trace('dossierApiClient.dossiers.reinvestigations()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            topologyTarget: topology,
            dossiers: data.map((d) => {
              const { owner, ...rest } = d;
              return {
                uploadId,
                clientDossierId: d.clientDossierId,
                dossierType,
                owner,
                data: rest,
              };
            }),
          }),
        },
        60_000
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    // Phoenix
    getPresignedUpload: async (data: {
      filename: string;
      isReinvestigation: boolean;
    }): Promise<GetPresignedUploadResponse> => {
      const { filename, isReinvestigation } = data;
      const url = new URL(`/api/v1/dossiers`, baseURL);
      logger.trace('dossierApiClient.dossiers.getPresignedUpload()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          originalFilename: filename,
          isReinvestigation,
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    getPresignedUploadMultipart: async (data: {
      fileName: string;
      partNumber: number;
      uploadId: string;
      isReinvestigation?: boolean;
    }): Promise<{ url: string }> => {
      const { fileName, partNumber, uploadId, isReinvestigation } = data;
      const url = new URL(
        `/api/v1/dossiers/upload-id/presigned-upload`,
        baseURL
      );
      logger.trace('dossierApiClient.dossiers.getPresignedUploadMultipart()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            fileName,
            isReinvestigation,
            partNumber,
            uploadId,
          }),
        },
        UPLOAD_TIMEOUT_LIMIT
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    generateMultipartUploadId: async (data: {
      fileName: string;
      isReinvestigation?: boolean;
    }): Promise<{
      bucket: string;
      key: string;
      serverSideEncryption: string;
      uploadId: string;
    }> => {
      const { fileName, isReinvestigation } = data;
      const url = new URL(`/api/v1/dossiers/upload-id`, baseURL);
      logger.trace('dossierApiClient.dossiers.generateMultipartUploadId()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ fileName, isReinvestigation }),
        },
        UPLOAD_TIMEOUT_LIMIT
      );
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    completeMultipartUpload: async (data: {
      fileName: string;
      isReinvestigation?: boolean;
      uploadId: string;
      uploadedParts: {
        eTag: string;
        partNumber: number;
      }[];
    }): Promise<void> => {
      const { fileName, isReinvestigation, uploadId, uploadedParts } = data;
      const url = new URL(`/api/v1/dossiers/upload-id/completion`, baseURL);
      logger.trace('dossierApiClient.dossiers.generateMultipartUploadId()');
      const response = await fetchWrapper(
        url.href,
        {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            fileName,
            isReinvestigation,
            uploadId,
            uploadedParts,
          }),
        },
        UPLOAD_TIMEOUT_LIMIT
      );
      logger.debug({ response, uploadedParts }, `POST ${url.href}`);
      return deserialize(response);
    },
  } as const,
  reservations: {
    peekReservation: async (data: {
      groupId: string;
    }): Promise<ReservationResponse> => {
      const url = new URL(`/api/v1/reservations`, baseURL);
      logger.trace('dossierApiClient.reservations.peekReservation()');

      url.searchParams.set('groupId', data.groupId);

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    getNextReviewItem: async (data: {
      groupId: string;
    }): Promise<ReservationResponse> => {
      const url = new URL(`/api/v1/get-next-review-item`, baseURL);
      url.searchParams.set('groupId', data.groupId);

      logger.trace('dossierApiClient.getNextReviewItem()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });

      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    startReviewById: async (data: {
      recordId: string;
      groupId: string;
    }): Promise<ReservationResponse> => {
      const url = new URL(`/api/v1/reservations/${data.recordId}`, baseURL);

      logger.trace('dossierApiClient.reservations.startReviewById()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          groupId: data.groupId ?? 'ready',
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    startReview: async (data: {
      groupId: string | string[];
    }): Promise<ReservationResponse> => {
      const url = new URL(`/api/v1/reservations`, baseURL);
      logger.trace('dossierApiClient.reservations.startReview()');
      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          groupId: data.groupId,
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    assignmentCount: async (data: {
      groupId: string;
    }): Promise<{
      count: number;
      maxReassignments: number;
    }> => {
      const url = new URL(`/api/v1/assignments/count`, baseURL);
      url.searchParams.set('groupId', data.groupId ?? 'ready');

      logger.trace('dossierApiClient.reservations.assignmentCount()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    reservationDetails: async (data: {
      dossierId: string;
      groupId: string;
    }): Promise<
      ReservationResponse &
        SnoozeDetails & {
          assignmentDetails?: AssignmentDetails;
        }
    > => {
      const url = new URL(`/api/v1/reservations/${data.dossierId}`, baseURL);
      url.searchParams.set('groupId', data.groupId ?? 'ready');

      logger.trace('dossierApiClient.reservations.reservationDetails()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });
      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
    assignDossier: async (data: {
      dossierId: string;
      toUserId: string;
      groupId: string;
    }): Promise<void> => {
      const url = new URL(
        `/api/v1/reservations/${data.dossierId}/reassign`,
        baseURL
      );

      logger.trace('dossierApiClient.reservations.assignDossier()');

      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          toUserId: data.toUserId,
          groupId: data.groupId ?? 'ready',
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    snooze: async (data: { dossierId: string; groupId: string }) => {
      const url = new URL(
        `/api/v1/reservations/${data.dossierId}/snooze`,
        baseURL
      );

      logger.trace('dossierApiClient.reservations.snooze()');

      const response = await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          groupId: data.groupId ?? 'ready',
        }),
      });
      logger.debug({ response }, `POST ${url.href}`);
      return deserialize(response);
    },
    snoozeCount: async (data: { groupId: string }) => {
      const url = new URL(`/api/v1/snoozes/count`, baseURL);
      url.searchParams.set('groupId', data.groupId ?? 'ready');

      logger.trace('dossierApiClient.reservations.snoozeCount()');

      const response = await fetchWrapper(url.href, {
        method: 'GET',
        credentials: 'include',
      });

      logger.debug({ response }, `GET ${url.href}`);
      return deserialize(response);
    },
  },
} as const;

export default dossierApiClient;
