export const promptsImmediateActionNextActionValues = [
  'surveillance',
  'fce',
  'telephone-call',
  'policy-review',
  'telephone-call-er',
  'examiner-financial-review',
  'cpa-review',
  'none',
  'telephone-interview',
  'gather-hospital-records',
  'confirm-incarceration',
  'send-letter-ee',
  'gather-tax-returns',
  'financial-review',
  'referral-to-ptd',
  'check-follow-up-dates',
  'referral-to-vocational-rehab',
  'vocational-referral',
  'request-ssd-file',
  'nurse-review',
  'ime',
  'p2p-call',
  'letter-to-ee',
  'vocational-review',
  'employer-call',
  'ensure-appropriate-follow-ups-set',
  'review-occ-analysis',
  'review-employability-analysis',
  'special-handling-update',
  'employer-contract',
  'updated-medical-records',
  'telephone-call-to-employer',
  'telephone-call-to-ap',
  'telephone-call-to-ee',
  'fcr',
  'occupational-assessment',
  'employability-assessment',
  'update-medical',
  'letter-to-claimant',
  'vocational-review-referral',
];

export const docSubTypes = [
  'activities-of-daily-living',
  'annual-financial-inquiry',
  'attending-physician-statement',
  'attorney-of-record-notification',
  'attorney-request',
  'authorization-to-request-medical-records',
  'claims-examiner-notes-plan-of-action',
  'claimant-info-iso-report',
  'claimant-profile',
  'claimant-profile-reserves',
  'claimant-profile-surveillance',
  'clinical-review',
  'cms',
  'corporate-filings',
  'disability-questionnaire',
  'employability-analysis',
  'employees-statement',
  'employers-statement',
  'employer-info-supervisor-statement',
  'employer-info-witness-statement',
  'enrollment-confirmation',
  'external-evidence',
  'facebook-profile',
  'facebook-post',
  'first-notice-of-loss',
  'functional-capacity-evaluation',
  'fce-appointment-letter',
  'fce-invoice',
  'independent-medical-exam',
  'instagram-post',
  'job-description',
  'labor-market-survey',
  'letter-to-claimant',
  'lien-order',
  'linked-in-profile',
  'linked-in-post',
  'medical-doc-tpc-with-provider',
  'medical-guidelines',
  'medical-referral',
  'medical-treatment-authorization',
  'medical-records',
  'medical-records-hospital-visit',
  'medical-records-independent-exam-appointment-letter',
  'medical-records-invoice-for-independent-exam',
  'medical-review-nurse-case-management-status',
  'mileage-reimbursement-request',
  'notes',
  'nurse-review',
  'nurse-review-referral',
  'occupational-assessment',
  'office-visit-note',
  'operation-report',
  'payroll-records',
  'peer-review',
  'pharmacy-canvas',
  'policy-claimant',
  'policy-employer',
  'policy-state',
  'power-of-attorney-doc',
  'special-authorization-request',
  'social-security-appeal-confirmation',
  'social-security-award-letter',
  'social-security-denial-letter',
  'telephone-call-with-claimant',
  'transferable-skills-analysis',
  'travel-site',
  'work-history-and-education',
];
