export const baseURL = window.location.href;

export class HTTPError extends Error {
  public response: Response;
  public data: any;

  constructor(res: Response, data: any) {
    super(
      typeof data === 'string'
        ? data
        : 'message' in data
        ? data.message
        : 'unknown error'
    );

    this.response = res;
    this.data = data;
  }
}
export function deserializeError(res: Response, data: any): HTTPError {
  return new HTTPError(res, data);
}

const toJson = async (res: Response) => {
  const text = await res.text();
  return text.length ? JSON.parse(text) : {};
};

export function deserialize(res: Response): Promise<any> {
  return res.ok
    ? toJson(res)
    : toJson(res).then(async (data) => {
        throw deserializeError(res, data);
      });
}
