// Missing _small_ tools missing from redux toolkit's exports

import type { EntityState, SerializedError } from '@reduxjs/toolkit';

/** @see https://unpkg.com/browse/@reduxjs/toolkit@1.5.1/src/entities/entity_state.ts#L3 */
export function getInitialEntityState<V>(): EntityState<V> {
  return {
    ids: [],
    entities: {},
  };
}

// https://unpkg.com/browse/@reduxjs/toolkit@1.5.1/src/createAsyncThunk.ts#L38
const commonProperties: Array<keyof SerializedError> = [
  'name',
  'message',
  'stack',
  'code',
];

// https://unpkg.com/browse/@reduxjs/toolkit@1.5.1/src/createAsyncThunk.ts#L45
// Added `cause`
export class RejectWithValue<RejectValue = unknown> {
  public name = 'RejectWithValue';
  public message = 'Rejected';
  constructor(
    public readonly payload: RejectValue,
    public readonly cause?: Error
  ) {}
}

// https://unpkg.com/browse/@reduxjs/toolkit@1.5.1/src/createAsyncThunk.ts#L52
export const miniSerializeError = (value: any): SerializedError => {
  if (typeof value === 'object' && value !== null) {
    const simpleError: SerializedError = {};
    for (const property of commonProperties) {
      if (typeof value[property] === 'string') {
        simpleError[property] = value[property];
      }
    }

    return simpleError;
  }

  return { message: String(value) };
};
