export type ClaimListItem = {
  dossierId: string;
  clientDossierId: string;
  dossierName: string;
  numDocs: number;
  numInsights: number;
  insightTypes: string[];
  acceptedInsights?: string[];
  lastReviewed?: string;
  documentsToReview?: {
    [docTitle: string]: boolean;
  };
  examiner?: string;
  [k: string]:
    | string
    | number
    | string[]
    | { [k: string]: boolean }
    | undefined;
};

export type QueryResult = {
  dossierId: string;
  query: string;
  searchId: string;
  answerRequestId?: string;
};

export type QueryMatchResult = {
  dossierId: string;
  query: string;
  matches: MatchAnswer[];
  matchDocsKey: string;
};

export type InsightFeedback = {
  details: string;
  nextAction: string;
  note: string;
};

export type CreatedBy = 'user' | 'owl';

export type InsightUserData = {
  username: string;
  name: string;
};

export type InsightDecision = 'accepted' | 'rejected';

export type Insight = {
  insightId: string;
  createdBy: CreatedBy;
  query: string;
  title: string;
  answer: string;
  customAnswer: string;
  matches: MatchAnswer[];
  decision?: InsightDecision;
  feedback?: InsightFeedback;
  createdAt: string;
  updatedAt: string;
  createdByUser: InsightUserData;
  updatedByUser: InsightUserData;
  feedbackUpdatedByUser: InsightUserData;
  feedbackUpdatedAt: string;
  addToTimeline?: boolean;
};

export type SaveInsightRequest = Omit<
  Insight,
  | 'insightId'
  | 'createdAt'
  | 'updatedAt'
  | 'createdByUser'
  | 'updatedByUser'
  | 'feedbackUpdatedAt'
  | 'feedbackUpdatedByUser'
> & {
  insightId?: string;
  dossierId: string;
};

export type DecideOnInsightRequest = Omit<
  Insight,
  | 'createdAt'
  | 'updatedAt'
  | 'createdByUser'
  | 'updatedByUser'
  | 'feedbackUpdatedAt'
  | 'feedbackUpdatedByUser'
> & {
  tenantId: string;
  dossierId: string;
  decision: InsightDecision;
};

export type ParsedAnswer = {
  answer: string;
  evidence: string[];
};

export type ClaimsDocumentDeprecated = {
  docId: string;
  docName: string;
  contentTextRef?: string;
  contentRawRef?: string;
  docSize?: number;
  lastModified?: string;
  docSubType?: string;
  docDate?: string;
  isReviewed?: {
    nextAction?: string;
    reviewDate: string;
    reviewerName: string;
    note?: string;
  };
};

export type MatchAnswer = {
  id: string;
  seq: number;
  dot: number;
  chunk: string;
  summary: string;
  document: ClaimsDocumentDeprecated;
  page: number;
  snippet?: string;
  snippetScore?: number;
  bb?: [number, number, number, number];
};

export type MLConfig = {
  summaryPrompt: string;
  searchPrompt: string;
  formatChunkPrompt: string;
  embeddingModel: string;
  completionModel: string;
  maxChunkSize: number;
  topK: number;
};

export type QueryHistoryItem = {
  searchQuery: string;
  timestamp: string;
  parentId?: string;
  searchId: string;
};

export type StagedFileDeprecated = {
  docType?: string;
  docSubType?: string;
  docDate?: string;
  file: File;
};

export type UploadDocument = {
  dossierId: string;
  files: StagedFileDeprecated[];
};

export type DownloadDocuments = {
  dossierId: string;
  documents: ClaimsDocumentDeprecated[];
};

export type ClaimsChunk = {
  id: string;
  seq: number;
  page: number;
  chunk: string;
  summary: string;
  documentUrl: string;
  document: ClaimsDocumentDeprecated;
  embedding: number[];
};

export const CLAIMS_ASSIST_TENANTS = [
  { tenantId: 'demo', tenantName: 'Demo' },
  { tenantId: 'demo-wc', tenantName: 'WC Demo' },
  { tenantId: 'demo-siu', tenantName: 'SIU Demo' },
  { tenantId: 'aaaf1588-9ef3-11ec-aed1-a76b8df35e26', tenantName: 's01' },
  { tenantId: 'a6495ab2-a3c6-11ec-99b1-434e91c17461', tenantName: 's02' },
  { tenantId: '94c482d0-a3c6-11ec-aaa9-2bbd2e0bfe51', tenantName: 'u01' },
];

export const CLAIMS_ASSIST_TENANT_ID_NAME_MAP = CLAIMS_ASSIST_TENANTS.reduce(
  (acc, curr) => ({ ...acc, [curr.tenantId]: curr.tenantName }),
  {}
);

type Trigger = any;
type DocFilter = { docSubType: string };
type Action = { actionName: string };
export interface InsightsConfigEntry {
  id: string;
  isOwlGenerated: boolean;
  name: string; // happens to have the same type as ::user-data ::name
  triggers: Trigger[];
  query: string;
  explanation: string;
  docFilters: DocFilter[];
  review: string;
  priority: 'high' | 'medium' | 'low';
  actions: Action[];
  isEnabled: boolean;
}

export interface ClaimNote {
  noteId: string;
  dossierId: string;
  createdByName: string;
  createdByUsername: string;
  createdAt: string;
  noteContent: string;
  documents: ClaimsDocumentDeprecated[];
  insightId?: string;
  insightContent?: string;
  insightDetails?: string;
  insightNextAction?: string;
}

export interface DocumentSummary {
  dossierId: string;
  docId: string;
  createdAt: string;
  updatedAt: string;
  updatedByName: string;
  updatedByUsername: string;
  documentSummaryContent: string;
}
