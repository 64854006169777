import type { Primitive } from 'ts-essentials';

export interface EnumSet<T> extends ReadonlySet<T> {
  has(value: unknown): value is T;
}

type EnumSetParam<T> = Readonly<Record<any, T>> | ReadonlyArray<T>;
/**
 * To use this with an int enum, you have to manually pass all members of the
 * enum. When passing an array, make sure you use `as const`.
 */
export function enumSet<T extends Primitive>(e: EnumSetParam<T>): EnumSet<T> {
  type IntermediateSetType = Omit<ReadonlySet<T>, 'has'>;
  return new Set(Object.values(e)) as IntermediateSetType as EnumSet<T>;
}
