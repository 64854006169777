import React from 'react';
import { Empty, Typography } from 'antd';
import styles from './EmptyRenderer.module.scss';

interface EmptyRendererComponentProps {
  landingMessage?: string;
}

const EmptyRendererComponent: React.FC<EmptyRendererComponentProps> = ({
  landingMessage,
}) => {
  return (
    <div className={styles.empty}>
      <Empty
        description={
          landingMessage && <Typography.Text>{landingMessage}</Typography.Text>
        }
      />
    </div>
  );
};

export default EmptyRendererComponent;
